import React from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import inventoryApi from '../api/inventoryApi';
import PubSub from 'pubsub-js';
import * as constants from "../constants/CONSTANT";
import fileDownload from 'js-file-download';
import html2pdf from 'html2pdf.js';

const Questions = () => {

    const navigate = useNavigate();
    const location = useLocation();
    console.log('location => ', location.state);
    const [student, setStudent] = useState(location.state ? location.state : {});
    const [isEditable, setIsEditable] = useState(true);
    const [newQuestions, setNewQuestions] = useState({});
    const [assessmentId, setAssessmentId] = useState();
    const [assessmentName, setAssessmentName] = useState();
    const [questionsMarks, setQuestionsMarks] = useState([]);
    const [phsyoMarks, setphsyoMarks] = useState([]);

    useEffect(() => {

        if (location.hasOwnProperty('pathname') && location.pathname.split('/').length >= 3) {
            student.assessmentid = location.pathname.split('/')[4];
        }
        console.log('student.assessmentid:', student.assessmentid);
        if (student.id && student.qstype) {
            async function init() {
                let options = null;
                // get assessment question according to type ,Student id ,assessment id 
                let questions = await inventoryApi.getAssessmentQuestions(student.qstype, student.id, student.assessmentid);
                console.log('getAssessmentQuestions', questions);
                setAssessmentId(questions[0].assessmentid);
                setAssessmentName(questions[0].assessmentname);
                console.log('qstype', student.qstype)
                if (student.qstype === 'PHYSIO') {
                    console.log('call physio')
                    options = await inventoryApi.fetchOptionsByCategory(student.qstype);
                    console.log('options=> ', options);
                    options.forEach((item) => {
                        item.relatedoption.options = sortOptions(item.relatedoption.options);
                    });

                    console.log('data', JSON.stringify(options, null, 2));


                    setphsyoMarks(options);
                }
                let marks = await inventoryApi.getMarks(student.id, questions[0].assessmentid);
                console.log('questions => ', questions);
                console.log('marks => ', marks);
                if (questions && questions?.length > 0) {
                    modifyQuestion(questions, marks, options);

                } else {
                    setQuestionsMarks([]);
                }
            }
            init();
        }
        facpevaluationInfo()
    }, [])


    function sortOptions(options) {
        if (options && options.length > 0) {
            return options.sort((a, b) => a.score - b.score);

        } else {
            return [];
        }
    }
    useEffect(() => {
        physioReportaccess()
    }, [questionsMarks]);

    const [state, setstate] = useState([]);
    const facpevaluationInfo = async () => {
        const result = await inventoryApi.fetchFacpResult(student.id, student.assessmentid);
        console.log('evaluation result', result)
        setstate(result)
    }


    const modifyQuestion = ((questionsArr, marksArr, optionsArr) => {
        let qsAr = [];
        questionsArr.forEach((question) => {
            let qsObj = question;
            qsObj.marks = null;
            if (marksArr) {
                marksArr.forEach((mark) => {
                    if (qsObj.id === mark.questionid) {
                        qsObj.marks = mark.marks;
                        qsObj.selectedoptionid = mark.selectedoptionid;
                    }
                })
            }

            qsObj.options = null;
            if (optionsArr) {

                optionsArr.forEach((option) => {
                    if (qsObj.id === option.relatedoption.id) {

                        qsObj.options = option.relatedoption.options;
                        // qsObj.selectedoptionid = option.
                    }
                })
                //qsObj.options = optArray;
            }
            else {
                console.log('else call optArray')
                let optArray = [{
                    "id": "PP",
                    "name": "PP",
                    "score": "25"
                },
                {
                    "id": "VP",
                    "name": "VP",
                    "score": "50"
                },
                {
                    "id": "GP",
                    "name": "GP",
                    "score": "75"
                },
                {
                    "id": "Complete",
                    "name": "Complete",
                    "score": "100"
                },
                {
                    "id": "Incomplete",
                    "name": "Incomplete",
                    "score": "0"
                },
                {
                    "id": "NE",
                    "name": "NE",
                    "score": "0"
                },
                {
                    "id": "NA",
                    "name": "NA",
                    "score": "0"
                },
                {
                    "id": "Modelling",
                    "name": "Modelling",
                    "score": "0"
                },

                ];
                qsObj.options = optArray;

            }
            qsAr.push(qsObj);
        })
        console.log('qsAr => ', qsAr);
        setQuestionsMarks(qsAr);
       

    })

    const handleMarksSelection = (e) => {
        console.log('questionsMarks => ', questionsMarks)
        console.log('e.target.value => ', e.target.value);
        let marks = e.target.value.split('/')[0];
        let qsId = e.target.value.split('/')[1];
        console.log('qsId', qsId)
        let selectedoptionid = e.target.value.split('/')[2];
        console.log('optionid', selectedoptionid)
        let questionsArray = [];
        console.log('qsid => ', qsId);
        console.log('marks => ', marks);
        let arr = []
        questionsMarks.forEach(question => {
            let obj = {}
            if (question.id === qsId) {
                obj.questionid = question.id;
                if (marks === 'empty') {
                    obj.marks = null;
                    obj.selectedoptionid = null;
                }

                else {
                    obj.marks = marks;
                    obj.selectedoptionid = selectedoptionid;
                }

            } else {
                obj.questionid = question.id;
                obj.marks = question.marks;
                obj.selectedoptionid = question.selectedoptionid;
            }
            question.marks = obj.marks;
            question.selectedoptionid = obj.selectedoptionid;
            questionsArray.push(question);
            if (obj.marks !== null) {
                arr.push(obj);
            }
        });
        console.log('arr => ', arr);
        setNewQuestions({ studentid: student.id, assessmentid: assessmentId, questionsandmarks: arr });
        setQuestionsMarks([...questionsArray]);
        // physioReportaccess()
        //setQuestions(questionsArray);
        console.log('questionsArray => ', questionsArray)
    }




    const handleSaveClick = async () => {
        console.log('questionsMarks => ', questionsMarks)
        console.log('new questions => ', newQuestions)
        if (newQuestions.hasOwnProperty('questionsandmarks')) {
            let marks = await inventoryApi.addMarks(newQuestions);
        }

        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Marks saved successfully' });
        navigate(`/students/${student.id}`)
    }

    const handleCancel = () => {
        navigate(`/students/${student.id}`, { state: student });
    };

    const viewPdf = async (item) => {
        console.log('item', item)
        if (item.fileid) {
            const result = await inventoryApi.downloadFiles(item.fileid);
            console.log('result:', result);
            fileDownload(result, decodeURIComponent(item.filetitle));

        } else {
            alert('No PDF Uploaded')
            console.log('no file access')
        }

    };

    const handleSaveClickGrap = () => {
        state.studentid = student.id
        questionsMarks.studentid = student.id
        navigate(`/evaluation_graph`, { state: questionsMarks })
    };


    const handlePrint = () => {
        const element = document.getElementById('pdfcontainer');

        // Add a black border to the container
        element.style.border = '1px solid black';

        // Centered heading
        const heading = document.createElement('h1');
        heading.textContent = 'Centered Heading';
        heading.style.textAlign = 'center';
        element.appendChild(heading);

        html2pdf(element, {
            margin: 5,
            filename: `${student.name}_Physio_Report.pdf`,
            image: { type: 'jpeg', quality: 1 }, 
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', putOnlyUsedFonts: true, compressText: true },
        });

        // Reset styles and remove added elements after generating the PDF
        element.style.border = '';
        element.removeChild(heading);
    };


    

    useEffect(() => {
        
    }, []);
    const [physiodatareport, setphysiodatareport] = useState({});
    const physioReportaccess = async () => {
        console.log('call')
        let assessmentName = 'PHYSIO';
        //console.log(physioAssessmentId)
        let physioAssessmentId = questionsMarks[0].assessmentid
        let result = await inventoryApi.getAssessmentQuestions(assessmentName, student.id, physioAssessmentId);
        console.log('questions => ', result);
        let marks = await inventoryApi.getMarks(student.id, result[0].assessmentid);
        console.log('questions marks => ', marks);
        result.forEach((question) => {
            marks?.forEach((mark) => {
                if (mark.questionid === question.id) {
                    question.marks = mark.marks;
                }
            });
        });
        console.log('result marks', result)
        setphysiodatareport(result);

    }




    return (
        <>
         <Row className="view-form-header align-items-center">
                                    {student.qstype === 'FACP' ? (
                                        <Col lg={3} xs={6}>
                                            FACP: &nbsp;&nbsp;{assessmentName}
                                        </Col>
                                    ) : (
                                        <Col lg={3} xs={6}>
                                            PHYSIO: &nbsp;&nbsp;{assessmentName}
                                        </Col>
                                    )}


                                    <Col lg={9} xs={6} className="d-flex  justify-content-end">
                                        {student.qstype === 'FACP' ? (
                                            <Button className="btn-sm mx-2" onClick={handleSaveClickGrap}>
                                                Evaluation Graph
                                            </Button>
                                        ) : (
                                            <Button className="btn-sm mx-2" onClick={handlePrint}>
                                                PDF
                                            </Button>
                                        )}



                                        {isEditable && <Button className="btn-sm mx-2" onClick={handleSaveClick}>
                                            Save
                                        </Button>}
                                        <Button
                                            className="btn-sm"
                                            variant="danger"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>

                                </Row>
            <div id="pdfcontainer">


                <Container>
                <div className="section-header my-3">
                   <b className="align-item-center" style={{marginLeft : "36%"}}> Rajasthan Mahila Kalyan Mandal (RMKM)<br></br></b>
                       <b className="align-item-center" style={{marginLeft : "43%"}}>{student.qstype === 'FACP' ? 'FACP SCORING' : 'PHYSIO SCORING'} </b>

                    </div>
                    <Row>
                        <div>
                            <Col>
                               
                                <Row className='pt-3'>
                                    <Col lg={1}>
                                        <b>NAME</b>
                                    </Col>
                                    <Col lg={2}>
                                        {student.name}
                                    </Col>
                                    <Col lg={2}>
                                        <b>FATHER NAME</b>
                                    </Col>
                                    <Col lg={4}>
                                        {student.fathername}<br />
                                    </Col>
                                </Row>
                                <Row className='my-2'>
                                    <Col lg={1}>
                                        <b>AGE</b>
                                    </Col>
                                    <Col lg={2}>{student.childregistrationage}
                                    </Col>
                                    <Col lg={2}>
                                        <b>GENDER</b>
                                    </Col>
                                    <Col lg={4}>
                                        {student.gender}<br />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </Row>
                    <fieldset disabled={!isEditable}>
                        <Row>

                            <Col><div className='px-2'>
                                {questionsMarks && questionsMarks.length ? questionsMarks.map((item, index) => {
                                    const questions = questionsMarks
                                    console.log('questions =====> ', questions)
                                   
                                    return <ol key={index} className="list-group mt-2">
                                        <li className="list-group-item d-flex justify-content-between align-items-start">
                                            <div className=" me-auto">
                                                <span className="">{index + 1}.  </span>
                                                {item.question}
                                            </div>
                                            {item.assessment === 'FACP' ? <Button className="mx-3" onClick={() => viewPdf(item)}>Question PDF</Button> : ''}
                                            <div className="float-end">
                                                <Form.Select style={{ width: '280px' }} aria-label="Enter marks" onChange={handleMarksSelection} name="marks">
                                                    <option value={`empty/${item.id}/null`} >--Marks--</option>
                                                    {item.options && item.options.length ? (
                                                        item.options.map((opt, index) => (

                                                            <option
                                                                key={`${opt.score}/${item.id}/${opt.id}`}
                                                                value={`${opt.score}/${item.id}/${opt.id}`}
                                                                selected={item.selectedoptionid === opt.id}
                                                            >
                                                                {opt.name}
                                                            </option>
                                                        ))
                                                    ) : null}

                                                </Form.Select>
                                            </div>
                                        </li>
                                    </ol>
                                }) : <div className='text-center'>No Question Available!</div>}</div>
                            </Col>
                        </Row>
                    </fieldset>

                </Container>
                {/* REPORT SECTION START */}
                {student.qstype === 'PHYSIO' ? (
                    <div>
                        {/* Header section */}
                        <table style={{ width: "100%", borderCollapse: "collapse", borderBottom: "1px solid #000" }}>
                            <tr>
                                <td style={{ border: "1px solid #000", textAlign: "center" }}>
                                    <h6> STUDENT PHYSIO REPORT </h6>
                                    <h5>{student.serviceareaname}</h5>
                                    <h6>Supported By: Rajasthan Mahila Kalyan Mandal</h6>
                                </td>
                            </tr>
                        </table>

                        {/* Information section */}
                        <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}>
                            <tr>
                                <td style={{ width: "400px", border: "1px solid #000", padding: "10px" }}>
                                    <h5 style={{ fontSize: "15px" }}> PHYSIO Information </h5>
                                </td>
                            </tr>
                        </table>

                        {/* Table for physio data report */}
                        <table className="table table-bordered" style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}>
                            <thead>
                                <tr>
                                    <td style={{ width: "10%", backgroundColor: "#DFE0E1", border: "1px solid #000", padding: "8px" }}>
                                        <b>No.</b>
                                    </td>
                                    <td style={{ width: "80%", backgroundColor: "#DFE0E1", border: "1px solid #000", padding: "8px" }}>
                                        <b>Question</b>
                                    </td>
                                    <td style={{ width: "10%", backgroundColor: "#DFE0E1", border: "1px solid #000", padding: "8px" }}>
                                        <b>Marks %</b>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {physiodatareport.length &&
                                    physiodatareport?.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ border: "1px solid #000", padding: "8px" }}>{index + 1}</td>
                                            <td style={{ border: "1px solid #000", padding: "8px" }}>{item.question}</td>
                                            <td style={{ border: "1px solid #000", padding: "8px" }}>{item.marks}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                ) : ''}
            </div>

        </>
    )
}

export default Questions
