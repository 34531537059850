import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Form, FormSelect, Badge } from "react-bootstrap";
import moment from "moment";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { useNavigate } from "react-router-dom";
import PubSub from "pubsub-js";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import FinalAssessmentEdit from "./FinalAssessmentEdit";
import Select from "react-select";
import Stack from 'react-bootstrap/Stack';


// Add by Aamir khan 26-11-2023
const FinalAssessmentCreate = () => {
    let navigate = useNavigate();
    const [finalassessment, setfinalassessment] = useState({});
    const [state, setstate] = useState({});
    const [YesCount, setYesCount] = useState(0);
    const [YesCount1, setYesCount1] = useState(0);
    const [YesCount2, setYesCount2] = useState(0);
    const [YesCount3, setYesCount3] = useState(0);
    const [YesCount4, setYesCount4] = useState(0);
    const [YesCount5, setYesCount5] = useState(0);
    const [YesCount6, setYesCount6] = useState(0);
    const [YesCount7, setYesCount7] = useState(0);
    const [YesCount8, setYesCount8] = useState(0);
    const [namedArray, setNamedArray] = useState([{ key: '', value: '' }]);
    const [option, setoption] = useState();
    const [optionUsers, setOptionUsers] = useState([]);

    useEffect(() => {

        fetchAllStudents()
    }, []);
    const fetchAllStudents = async () => {
        const result = await inventoryApi.fetchStudents();
        console.log(result)
        if (result) {
            let ar = [];
            result.map((item) => {
                var obj = {};
                obj.value = item.id;
                obj.label = item.name;
                ar.push(obj);
            });
            //console.log('ar', ar)
            setOptionUsers(ar);

        } else {
            setOptionUsers([]);

        }
    }




    const handleSubmit = async (e) => {
        e.preventDefault();
        finalassessment.data = state;

        console.log("finalassessment.data", finalassessment.data);
        console.log("finalassessment ==>", finalassessment);

        finalassessment.sectioncount1 = YesCount;
        finalassessment.sectioncount2 = YesCount1;
        finalassessment.sectioncount3 = YesCount2;
        finalassessment.sectioncount4 = YesCount3;
        finalassessment.sectioncount5 = YesCount4;
        finalassessment.sectioncount6 = YesCount5;
        finalassessment.sectioncount7 = YesCount6;
        finalassessment.sectioncount8 = YesCount7;
        finalassessment.sectioncount9 = YesCount8;

        console.log('finalassessment.sectioncount1 after submit', finalassessment.sectioncount1);
        let result = await inventoryApi.createFinalAssessment(finalassessment);
        console.log('result', result)
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record Create successfully' });

        navigate(`/finalassessmentchecklist`);

    }
    let isChecked = true;
    const [checkedBoxes, setCheckedBoxes] = useState([]);

    const handleChangeTextbox = (e) => {
        console.log("handleChangeTextbox");
        console.log("e.target.name", e.target.name);
        console.log("e.target.value", e.target.value);

        setstate({ ...state, [e.target.name]: e.target.value });
        // settextboxdata({...textboxdata,[e.target.name] : e.target.value})
    };


    //Add by Aamir khan 24-11-2023
    const handleChange = (e) => {
        const { name, value, checked } = e.target;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
        } else {

            setNamedArray([...namedArray, { key: name, value }]);
        }

        console.log('namedArray inside', namedArray);
        // Update the checked boxes state based on the conditions
        setCheckedBoxes((prevState) => {

            const increment = checked
                ? value === 'Yes'
                    ? 1
                    : namedArray.reduce((acc, res) => {
                        if (res.key === name && res.value === 'Yes') {
                            return acc - 1;
                        }
                        return acc;
                    }, 0)
                : 0;
            setYesCount((prevCount) => Math.max(0, prevCount + increment));

            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        console.log('YesCount inside', YesCount);

        // Update the final assessment state with the new value
        setfinalassessment({ ...finalassessment, [name]: value });

    };

    console.log('namedArray outside', namedArray);
    console.log('YesCount outside', YesCount);

    //Add by Aamir khan 27-11-2023
    const handleChange1 = (e) => {
        const { name, value, checked } = e.target;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
        } else {

            setNamedArray([...namedArray, { key: name, value }]);
        }

        console.log('namedArray inside', namedArray);
        // Update the checked boxes state based on the conditions
        setCheckedBoxes((prevState) => {

            const increment = checked
                ? value === 'Yes'
                    ? 1
                    : namedArray.reduce((acc, res) => {
                        if (res.key === name && res.value === 'Yes') {
                            return acc - 1;
                        }
                        return acc;
                    }, 0)
                : 0;
            setYesCount1((prevCount) => Math.max(0, prevCount + increment));

            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        // Update the final assessment state with the new value
        setfinalassessment({ ...finalassessment, [name]: value });
    };

    const handleChange2 = (e) => {
        const { name, value, checked } = e.target;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
        } else {

            setNamedArray([...namedArray, { key: name, value }]);
        }

        console.log('namedArray inside', namedArray);
        // Update the checked boxes state based on the conditions
        setCheckedBoxes((prevState) => {

            const increment = checked
                ? value === 'Yes'
                    ? 1
                    : namedArray.reduce((acc, res) => {
                        if (res.key === name && res.value === 'Yes') {
                            return acc - 1;
                        }
                        return acc;
                    }, 0)
                : 0;
            setYesCount2((prevCount) => Math.max(0, prevCount + increment));

            console.log('prevState==>', prevState);
            console.log('@value==>', value);
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        // Update the final assessment state with the new value
        setfinalassessment({ ...finalassessment, [name]: value });
    };

    const handleChange3 = (e) => {
        const { name, value, checked } = e.target;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
        } else {

            setNamedArray([...namedArray, { key: name, value }]);
        }

        console.log('namedArray inside', namedArray);
        // Update the checked boxes state based on the conditions
        setCheckedBoxes((prevState) => {

            const increment = checked
                ? value === 'Yes'
                    ? 1
                    : namedArray.reduce((acc, res) => {
                        if (res.key === name && res.value === 'Yes') {
                            return acc - 1;
                        }
                        return acc;
                    }, 0)
                : 0;
            setYesCount3((prevCount) => Math.max(0, prevCount + increment));

            console.log('prevState==>', prevState);
            console.log('@value==>', value);
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        // Update the final assessment state with the new value
        setfinalassessment({ ...finalassessment, [name]: value });
    };

    const handleChange4 = (e) => {
        const { name, value, checked } = e.target;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
        } else {

            setNamedArray([...namedArray, { key: name, value }]);
        }

        console.log('namedArray inside', namedArray);
        // Update the checked boxes state based on the conditions
        setCheckedBoxes((prevState) => {

            const increment = checked
                ? value === 'Yes'
                    ? 1
                    : namedArray.reduce((acc, res) => {
                        if (res.key === name && res.value === 'Yes') {
                            return acc - 1;
                        }
                        return acc;
                    }, 0)
                : 0;
            setYesCount4((prevCount) => Math.max(0, prevCount + increment));

            console.log('prevState==>', prevState);
            console.log('@value==>', value);
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        // Update the final assessment state with the new value
        setfinalassessment({ ...finalassessment, [name]: value });
    };

    const handleChange5 = (e) => {
        const { name, value, checked } = e.target;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
        } else {

            setNamedArray([...namedArray, { key: name, value }]);
        }

        console.log('namedArray inside', namedArray);
        // Update the checked boxes state based on the conditions
        setCheckedBoxes((prevState) => {

            const increment = checked
                ? value === 'Yes'
                    ? 1
                    : namedArray.reduce((acc, res) => {
                        if (res.key === name && res.value === 'Yes') {
                            return acc - 1;
                        }
                        return acc;
                    }, 0)
                : 0;
            setYesCount5((prevCount) => Math.max(0, prevCount + increment));

            console.log('prevState==>', prevState);
            console.log('@value==>', value);
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        // Update the final assessment state with the new value
        setfinalassessment({ ...finalassessment, [name]: value });
    };

    const handleChange6 = (e) => {
        const { name, value, checked } = e.target;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
        } else {

            setNamedArray([...namedArray, { key: name, value }]);
        }

        console.log('namedArray inside', namedArray);
        // Update the checked boxes state based on the conditions
        setCheckedBoxes((prevState) => {

            const increment = checked
                ? value === 'Yes'
                    ? 1
                    : namedArray.reduce((acc, res) => {
                        if (res.key === name && res.value === 'Yes') {
                            return acc - 1;
                        }
                        return acc;
                    }, 0)
                : 0;
            setYesCount6((prevCount) => Math.max(0, prevCount + increment));

            console.log('prevState==>', prevState);
            console.log('@value==>', value);
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        // Update the final assessment state with the new value
        setfinalassessment({ ...finalassessment, [name]: value });
    };

    const handleChange7 = (e) => {
        const { name, value, checked } = e.target;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
        } else {

            setNamedArray([...namedArray, { key: name, value }]);
        }

        console.log('namedArray inside', namedArray);
        // Update the checked boxes state based on the conditions
        setCheckedBoxes((prevState) => {

            const increment = checked
                ? value === 'Yes'
                    ? 1
                    : namedArray.reduce((acc, res) => {
                        if (res.key === name && res.value === 'Yes') {
                            return acc - 1;
                        }
                        return acc;
                    }, 0)
                : 0;
            setYesCount7((prevCount) => Math.max(0, prevCount + increment));

            console.log('prevState==>', prevState);
            console.log('@value==>', value);
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        // Update the final assessment state with the new value
        setfinalassessment({ ...finalassessment, [name]: value });
    };

    const handleChange8 = (e) => {
        const { name, value, checked } = e.target;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
        } else {

            setNamedArray([...namedArray, { key: name, value }]);
        }

        console.log('namedArray inside', namedArray);
        // Update the checked boxes state based on the conditions
        setCheckedBoxes((prevState) => {

            const increment = checked
                ? value === 'Yes'
                    ? 1
                    : namedArray.reduce((acc, res) => {
                        if (res.key === name && res.value === 'Yes') {
                            return acc - 1;
                        }
                        return acc;
                    }, 0)
                : 0;
            setYesCount8((prevCount) => Math.max(0, prevCount + increment));

            console.log('prevState==>', prevState);
            console.log('@value==>', value);
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        // Update the final assessment state with the new value
        setfinalassessment({ ...finalassessment, [name]: value });


    }
    const handleChange9 = (e) => {
        const { name, value, checked } = e.target;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
        } else {

            setNamedArray([...namedArray, { key: name, value }]);
        }

        console.log('namedArray inside', namedArray);
        // Update the checked boxes state based on the conditions
        setCheckedBoxes((prevState) => {

            const increment = checked
                ? value === 'Yes'
                    ? 1
                    : namedArray.reduce((acc, res) => {
                        if (res.key === name && res.value === 'Yes') {
                            return acc - 1;
                        }
                        return acc;
                    }, 0)
                : 0;
            setYesCount((prevCount) => Math.max(0, prevCount + increment));

            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        console.log('YesCount inside', YesCount);

        // Update the final assessment state with the new value
        setfinalassessment({ ...finalassessment, [name]: value });

    };

    console.log('finalassessment', finalassessment)

    const handleCancel = () => {
        navigate(`/finalassessmentchecklist`)
    }

    const handleClick = (e) => {
        console.log("Handle Clicked");
    }


    const [selectedUser, setSelectedUser] = useState('');

    const handleUsers = async (event) => {
        console.log('event', event)
        let result = await inventoryApi.fetchStudentById(event.value);
        console.log("result of Student>>", result);
        finalassessment.clientage = result.childregistrationage
        finalassessment.clientsex = result.gender
        finalassessment.clientregistration = result.regnumber
        finalassessment.clientname = result.name
        finalassessment.placeofscreening = result.servicecategoryname
        finalassessment.nameoflearner = result.name
        finalassessment.stage2_nameoflearner = result.name
        setoption(event)
        setSelectedUser(event)
        setfinalassessment({ ...finalassessment, clientid: event.value, clientname: event.label });
    }


    return (

        <>

            <Container id="marbt">
                <Row>
                    <Col lg={12} className="mx-3">
                        <Form className="mt-3" onSubmit={handleSubmit}>
                            <Row className="view-form-header align-items-center">
                                <Col lg={6}>Vision screening format </Col>
                                <Col lg={6} className="d-flex justify-content-end">
                                    <Button
                                        className="btn-sm mx-2"
                                        type="submit"
                                        onSubmit={handleSubmit}
                                    >
                                        Save
                                    </Button>
                                    <Button className="btn-sm" variant="danger" onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    Basic Information Sheet
                </Col>
                <Row lg={12}>
                    {/* <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">Client's Name</Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="clientname"
                                //placeholder="Name of the trainee"
                                value={finalassessment.clientname}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col> */}

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">Case Name</Form.Label>
                            <Select
                                name="clientname"
                                value={option}
                                onChange={handleUsers}
                                options={optionUsers}
                            // getOptionValue={(option) => option.value}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">Age</Form.Label>
                            <Form.Control
                                // required
                                type="number"
                                name="clientage"
                                //placeholder="Sex/age / Date of Birth"
                                value={finalassessment.clientage}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-3 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">Gender</Form.Label>


                            <Form.Select
                                aria-label="Enter Gender"
                                required
                                name="clientsex"
                                value={finalassessment.clientsex}
                                onChange={handleChange}
                            >
                                <option value="" >--Select--</option>
                                <option value="Male">Male </option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Registration No:
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="clientregistration"
                                //placeholder="maritalstatus"
                                value={finalassessment.clientregistration}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">Date </Form.Label>
                            <Form.Control
                                // required
                                type="date"
                                name="datescreening"
                                //placeholder="Name of the trainee"
                                value={finalassessment.datescreening}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">Place of screening: </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="placeofscreening"
                                //placeholder="Name of the trainee"
                                value={finalassessment.placeofscreening}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>


                </Row>

                <Row lg={12}>
                    <Col lg={12} className="section-header mx-4 my-3">
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">

                            <p>This schedule is for screening of vision and hearing problems among children and adults in                  special schools, villages or in camps. Observe the child in his familiar environment and              answer the following questions in YES or NO. Consult with other family members too. If you get consistent and frequent YES answers, on the screening schedule for vision problems then please refer the child/person to an Ophthalmologist/Eye Specialist. Similarly, if you get consistent and frequent YES answers, on the screening schedule for hearing problems then please refer the child/person to ENT/Audiologist for a clinical hearing testing at the nearest centre.</p>

                        </Form.Group>
                    </Col>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    A) Family History
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does anyone in your family have a severe vision loss or eye
                                disease? (e.g., Albinism, amblyopia, cataracts, glaucoma,
                                strabismus, retinoblastoma)
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="familyhistory_1"
                            value="Yes"
                            //checked={finalassessment?.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange9}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory_1"
                            value="No"
                            type="radio"
                            //checked={finalassessment?.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange9}
                        />
                        {/*  value={workerData._4_8_1} */}
                    </Col>

                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="retinoblastomatextbox"
                                //placeholder="Name of the trainee"
                                value={state.retinoblastomatextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Did the child's mother have any serious infection or diseases
                                during pregnancy? (e.8., Rubella, cytomegalovirus,
                                toxoplasmosis, syphilis, herpes,cogan syndrome,chikenpox,kanthmala ,meningitis)
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="familyhistory_2"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange9}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory_2"
                            value="No"
                            type="radio"
                            //checked={finalassessment?.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange9}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="herpestextbox"
                                //placeholder="Name of the trainee"
                                value={state.herpestextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Did the child mother use drugs or alcohol during pregnancy?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="familyhistory_3"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange9}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory_3"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange9}
                        />
                    </Col>
                    {/* className="mx-5 my-4" */}

                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="pregnancytextboxes"
                                //placeholder="Name of the trainee"
                                value={state.pregnancytextboxes}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Has a vision problem been identified or suspected?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="familyhistory_4"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange9}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory_4"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange9}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="suspectedtextboxes1"
                                //placeholder="Name of the trainee"
                                value={state.suspectedtextboxes1}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Do you have any concern about your child's vision ?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="familyhistory_5"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange9}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory_5"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange9}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="visiontextbox"
                                //placeholder="Name of the trainee"
                                value={state.visiontextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Stack direction="horizontal" gap={2} style={{ marginLeft: "55%" }}>

                        <Badge pill bg="primary">
                            Total
                        </Badge>
                        <Badge pill bg="secondary">
                        {YesCount}
                        </Badge>
                    </Stack>
                    {/* <p style={{ marginLeft: "62%" }} onClick={{ handleClick }}>{YesCount}</p> */}

                    {/* <p style={{ marginLeft: "30%" }} onClick={{handleClick}}> Total Count </p> */}

                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    B) Appearances of the Eye (Screening Schedule for Vision Problem in
                    Children)
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Encrusted eye lashes
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_1"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_1"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="childrentextbox"
                                //placeholder="Name of the trainee"
                                value={state.childrentextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Swollen or red eyelid
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_2"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_2"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="eyelidtextbox"
                                //placeholder="Name of the trainee"
                                value={state.eyelidtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Frequent (stye)
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_3"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_3"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="Frequenttextbox"
                                //placeholder="Name of the trainee"
                                value={state.Frequenttextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Discharge in the eye or along the eye lids
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_4"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_4"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="lidstextbox"
                                //placeholder="Name of the trainee"
                                value={state.lidstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Do you have concerns about your child's vision ?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_5"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_5"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="childtextbox"
                                //placeholder="Name of the trainee"
                                value={state.childtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Eyes that are usually red or watery
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_6"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_6"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="waterytextbox"
                                //placeholder="Name of the trainee"
                                value={state.waterytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Pupils of different sizes or clouding of pupils
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_7"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_7"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="pupilstextbox"
                                //placeholder="Name of the trainee"
                                value={state.pupilstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Drooping eyelids
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_8"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_8"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="droopingtextbox"
                                //placeholder="Name of the trainee"
                                value={state.droopingtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Eyes that do not appear straight (Crossed eyes)
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_9"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_9"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="appeartextbox"
                                //placeholder="Name of the trainee"
                                value={state.appeartextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Stack direction="horizontal" gap={2} style={{ marginLeft: "55%" }}>
                        <Badge pill bg="primary">
                            Total
                        </Badge>
                        <Badge pill bg="secondary">
                        {YesCount1}
                        </Badge>
                    </Stack>
                    {/* <p style={{ marginLeft: "62%" }}>{YesCount1}</p> */}
                </Row>
                {/*A) Visual behaviour******* */}
                <Col lg={12} className="section-header my-3 mx-2">
                    A) Visual behaviours
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Complains of aches or pain in the eyes
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_1"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_1"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="behaviourstextbox"
                                //placeholder="Name of the trainee"
                                value={state.behaviourstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Excessive headaches
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_2"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_2"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="headachestextboxes1"
                                //placeholder="Name of the trainee"
                                value={state.headachestextboxes1}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Dizziness or nausea after close eye work
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="mentalretardlevel"
                //placeholder="Level of Mental Retard "
                // value={vocProfile.mentalretardlevel}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_3"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_3"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="eyeworktextbox"
                                //placeholder="Name of the trainee"
                                value={state.eyeworktextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Squinting and constant blinking
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_4"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_4"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="blinkingtextbox"
                                //placeholder="Name of the trainee"
                                value={state.blinkingtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Frowning, facial distortion
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_5"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_5"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="distortiontextbox"
                                //placeholder="Name of the trainee"
                                value={state.distortiontextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Constant rubbing of eyes or attempts to brush away a shadow
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_6"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_6"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="shadowtextbox"
                                //placeholder="Name of the trainee"
                                value={state.shadowtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Tilting of the heads to see
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_7"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_7"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="headstextbox"
                                //placeholder="Name of the trainee"
                                value={state.headstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Closing or covering one eye when looking or reading
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_8"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_8"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="readingtextbox"
                                //placeholder="Name of the trainee"
                                value={state.readingtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Child constantly requests someone to tell what is going on
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_9"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_9"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="goingtextbox"
                                //placeholder="Name of the trainee"
                                value={state.goingtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Child has poor eye hand coordination and unusual
                                awkwardness/clumsiness during task using hands or the child has
                                balance problem during walking
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_10"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_10"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="walkingtextbox"
                                //placeholder="Name of the trainee"
                                value={state.walkingtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Child bumps/clumsy during walking particularly in new
                                environment
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_11"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_11"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="environmenttextbox"
                                //placeholder="Name of the trainee"
                                value={state.environmenttextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Unduly sensitive to bright light, glare or shiny objects
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_12"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_12"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="shinytextbox"
                                //placeholder="Name of the trainee"
                                value={state.shinytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does the child have difficulty seeing at night or in dark?
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_13"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_13"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="darktextbox"
                                //placeholder="Name of the trainee"
                                value={state.darktextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                The child need glasses to see objects
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualbehaviour_14"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_14"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="glassestextbox"
                                //placeholder="Name of the trainee"
                                value={state.glassestextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Stack direction="horizontal" gap={2} style={{ marginLeft: "55%" }}>
                        <Badge pill bg="primary">
                            Total
                        </Badge>
                        <Badge pill bg="secondary">
                        {YesCount2}
                        </Badge>
                    </Stack>
                    {/* <p style={{ marginLeft: "62%" }}>{YesCount2}</p> */}
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    Visual Screening Outcome:
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Vision problem suspected
                            </Form.Label>

                            {/* <Form.Control
                // required
                type="text"
                name="traineename"
                //placeholder="Name of the trainee"
                // value={vocProfile.traineename}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualscreeningoutcome_1"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange3}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualscreeningoutcome_1"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange3}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="suspectedtextboxse1"
                                //placeholder="Name of the trainee"
                                value={state.suspectedtextboxse1}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col lg={2}>
                    <Form.Check
                        inline
                        label="No"
                        name="trainingrecv"
                        type="checkbox"
                        value="Normal school"
                        // onChange={handleFieldChange}
                    />
            </Col> */}
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Referred to Ophthalmologist for clinical assessment
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualscreeningoutcome_2"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange3}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualscreeningoutcome_2"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange3}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="ophthalmologisttextbox"
                                //placeholder="Name of the trainee"
                                value={state.ophthalmologisttextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Referred for Functional Vision Assessment
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="visualscreeningoutcome_3"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange3}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualscreeningoutcome_3"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange3}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="referredtextbox"
                                //placeholder="Name of the trainee"
                                value={state.referredtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Stack direction="horizontal" gap={2} style={{ marginLeft: "55%" }}>
                        <Badge pill bg="primary">
                            Total
                        </Badge>
                        <Badge pill bg="secondary">
                        {YesCount3}
                        </Badge>
                    </Stack>
                    {/* <p style={{ marginLeft: "62%" }}>{YesCount3}</p> */}
                </Row>
                {/* Add by Aamir khan 07-12-2023 */}
                <Col lg={12} className="section-header my-3 mx-2">
                    Screening Schedule for hearing Problem in Children
                </Col>
                <Row lg={12}>


                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does anyone in your family have a severe hearing loss or ear
                                disease? (e.g.,tinitus, otitis media, perforation of ear drum,
                                Meniere's disease, etc.) Pls. Mention
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="familyhistory2_3"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange4}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory2_3"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange4}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="diseasetextbox"
                                //placeholder="Name of the trainee"
                                value={state.diseasetextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Did the child's mother have any serious infections or diseases
                                during Pregnancy? (e.g., rubella, cytomegalovirus,
                                toxoplasmosis, syphilis, Herpes
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="familyhistory2_4"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange4}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory2_4"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange4}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="herpestextboxes1"
                                //placeholder="Name of the trainee"
                                value={state.herpestextboxes1}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Did the child's mother use drugs or alcohol during pregnancy?
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="familyhistory2_5"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange4}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory2_5"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange4}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="pregnancytextbox"
                                //placeholder="Name of the trainee"
                                value={state.pregnancytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Has a hearing problem been identified or suspected?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="earproblem_1"
                            value="Yes"
                            //checked={finalassessment.earproblem_1 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange4}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_1"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange4}
                        />
                    </Col>
                    {/* Add by Aamir khan 07-12-2023*/}
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="herpestextboxes1"
                                //placeholder="Name of the trainee"
                                value={state.herpestextboxes1}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Do you have any concerns about your child's hearing?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="functionalhearing_17"
                            value="Yes"
                            //checked={finalassessment.functionalhearing_17 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange8}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="functionalhearing_17"
                            value="No"
                            type="radio"
                            //checked={finalassessment.functionalhearing_17 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange8}
                        />
                    </Col>
                    {/* Add by Aamir khan 07-12-2023*/}
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="herpestextboxes1"
                                //placeholder="Name of the trainee"
                                value={state.herpestextboxes1}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>

                    <Stack direction="horizontal" gap={2} style={{ marginLeft: "55%" }}>
                        <Badge pill bg="primary">
                            Total
                        </Badge>
                        <Badge pill bg="secondary">
                        {YesCount4}
                        </Badge>
                    </Stack>

                    {/* <p style={{ marginLeft: "62%" }}>{YesCount4}</p> */}
                </Row>


                <Col lg={12} className="section-header my-3 mx-2">
                    A) Ear problem
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Complaints of ear aches
                            </Form.Label>

                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="complaintofear"
                            value="Yes"
                            checked={finalassessment.complaintofear === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="complaintofear"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="complaintstextbox"
                                //placeholder="Name of the trainee"
                                value={state.complaintstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col lg={2}>
                    <Form.Check
                        inline
                        label="No"
                        name="trainingrecv"
                        type="checkbox"
                        value="Normal school"
                        // onChange={handleFieldChange}
                    />
            </Col> */}
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Complaints of headaches
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="earproblem_2"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_2"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="headachestextbox"
                                //placeholder="Name of the trainee"
                                value={state.headachestextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Complaints of ringing sound/sensation in ears or of hearing
                                voices
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="earproblem_3"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_3"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="voicestextbox"
                                //placeholder="Name of the trainee"
                                value={state.voicestextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Reoccurring ear infections
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="earproblem_4"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_4"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="infectionstextbox"
                                //placeholder="Name of the trainee"
                                value={state.infectionstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Frequent colds
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="earproblem_5"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_5"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="coldstextbox"
                                //placeholder="Name of the trainee"
                                value={state.coldstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Any external ear or facial abnormality
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="earproblem_6"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_6"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="abnormalitytextbox"
                                //placeholder="Name of the trainee"
                                value={state.abnormalitytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Stack direction="horizontal" gap={2} style={{ marginLeft: "55%" }}>
                        <Badge pill bg="primary">
                            Total
                        </Badge>
                        <Badge pill bg="secondary">
                        {YesCount5}
                        </Badge>
                    </Stack>
                    {/* <p style={{ marginLeft: "62%" }}>{YesCount5}</p> */}
                </Row>
                {/* *****hearing Behaviour******** */}
                <Col lg={12} className="section-header my-3 mx-2">
                    B) hearing behaviour
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Turning of one ear towards or away from the sound source
                            </Form.Label>

                            {/* <Form.Control
                // required
                type="text"
                name="traineename"
                //placeholder="Name of the trainee"
                // value={vocProfile.traineename}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_1"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_1"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="sourcetextbox"
                                //placeholder="Name of the trainee"
                                value={state.sourcetextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col lg={2}>
                    <Form.Check
                        inline
                        label="No"
                        name="trainingrecv"
                        type="checkbox"
                        value="Normal school"
                        // onChange={handleFieldChange}
                    />
            </Col> */}
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Turning up the radio or television or headset
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_2"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_2"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="televisiontextbox"
                                //placeholder="Name of the trainee"
                                value={state.televisiontextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Sitting very close to the television or radio
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="mentalretardlevel"
                //placeholder="Level of Mental Retard "
                // value={vocProfile.mentalretardlevel}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_3"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_3"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="radiotextbox"
                                //placeholder="Name of the trainee"
                                value={state.radiotextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Appears to ignore conversation or directives
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_4"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_4"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="directivestextbox"
                                //placeholder="Name of the trainee"
                                value={state.directivestextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Seems engrossed in work, oblivious to sounds around
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_5"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_5"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="oblivioustextbox"
                                //placeholder="Name of the trainee"
                                value={state.oblivioustextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Humming (Constant) to possibly provide stimulation
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_6"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_6"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="stimulationtextbox"
                                //placeholder="Name of the trainee"
                                value={state.stimulationtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Startling behaviour to everyday sounds
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_7"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_7"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="everydaytextbox"
                                //placeholder="Name of the trainee"
                                value={state.everydaytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Relies on gestures excessively
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_8"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_8"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="excessivelytextbox"
                                //placeholder="Name of the trainee"
                                value={state.excessivelytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Poor spoken language
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_9"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_9"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="spokentextbox"
                                //placeholder="Name of the trainee"
                                value={state.spokentextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does the child complain that everyone either speaks too loudly
                                or too softly?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_10 "
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_10"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="softlytextbox"
                                //placeholder="Name of the trainee"
                                value={state.softlytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Not able to hear others from distance or from behind
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_11"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_11"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="behindtextbox"
                                //placeholder="Name of the trainee"
                                value={state.behindtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does the child have difficulty identifying familiar persons by
                                their voice?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_12"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_12"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="voicetextbox"
                                //placeholder="Name of the trainee"
                                value={state.voicetextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Difficulty in listening speech in background noise
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_13"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_13"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="noisetextbox"
                                //placeholder="Name of the trainee"
                                value={state.noisetextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Not able to hear when called from other nearby rooms inside
                                house or inside school
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_14"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_14"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="schooltextbox"
                                //placeholder="Name of the trainee"
                                value={state.schooltextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does the child need hearing aids to listen?
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_15"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_15"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="listentextbox"
                                //placeholder="Name of the trainee"
                                value={state.listentextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does the child have problems in balance?
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_16"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_16"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="balancetextbox"
                                //placeholder="Name of the trainee"
                                value={state.balancetextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Stack direction="horizontal" gap={2} style={{ marginLeft: "55%" }}>
                        <Badge pill bg="primary">
                            Total
                        </Badge>
                        <Badge pill bg="secondary">
                        {YesCount6}
                        </Badge>
                    </Stack>  
                    {/* <p style={{ marginLeft: "62%" }}>{YesCount6}</p> */}
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    hearing Screening Outcome:
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                hearing problem suspected
                            </Form.Label>

                            {/* <Form.Control
                // required
                type="text"
                name="traineename"
                //placeholder="Name of the trainee"
                // value={vocProfile.traineename}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className=" mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingscreeningoutcome_1"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange7}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingscreeningoutcome_1"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange7}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="hearingproblemtextbox"
                                //placeholder="Name of the trainee"
                                value={state.hearingproblemtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col lg={2}>
                    <Form.Check
                        inline
                        label="No"
                        name="trainingrecv"
                        type="checkbox"
                        value="Normal school"
                        // onChange={handleFieldChange}
                    />
            </Col> */}
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Referred to Audiologist for Audiometry
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className=" mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingscreeningoutcome_2"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange7}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingscreeningoutcome_2"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange7}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="Audiologisttextbox"
                                //placeholder="Name of the trainee"
                                value={state.Audiologisttextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Referred to ENT specialist for clinical assessment
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className=" mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingscreeningoutcome_3"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange7}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingscreeningoutcome_3"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange7}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="clinicaltextbox"
                                //placeholder="Name of the trainee"
                                value={state.clinicaltextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Referred for Functional hearing Assessment
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className=" mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingscreeningoutcome_4"
                            value="Yes"
                            //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange7}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingscreeningoutcome_4"
                            value="No"
                            type="radio"
                            //checked={finalassessment.mothersufferedpregnancy === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange7}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="hearingtextbox"
                                //placeholder="Name of the trainee"
                                value={state.hearingtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Stack direction="horizontal" gap={2} style={{ marginLeft: "55%" }}>
                        <Badge pill bg="primary">
                            Total
                        </Badge>
                        <Badge pill bg="secondary">
                        {YesCount7}
                        </Badge>
                    </Stack>  
                    {/* <p style={{ marginLeft: "62%" }}>{YesCount7}</p> */}
                </Row>
                <Row>
                    <Col lg={12}>
                        <Form.Group className=" my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Opinion of the Screener:
                            </Form.Label>
                            <Form.Control
                                // required
                                //disabled
                                as="textarea"
                                name="opinionofthescreener"
                                //placeholder="Referral comment"
                                value={finalassessment.opinionofthescreener}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row lg={12}>
                    <Col lg={12}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Referral comment:
                            </Form.Label>
                            <Form.Control
                                // required
                                //disabled
                                as="textarea"
                                name="reffrealcomment"
                                //placeholder="Referral comment"
                                value={finalassessment.reffrealcomment}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Screener's Observations (if any):
                            </Form.Label>
                            <Form.Control
                                // required
                                //disabled
                                type="text"
                                name="screeningobservation"
                                //placeholder="Screener's Observations"
                                value={finalassessment.screeningobservation}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Screener's Inference:
                            </Form.Label>
                            <Form.Control
                                // required
                                //disabled
                                 type="text"
                                name="screenerinfrence"
                                //placeholder="Screener's Inference"
                                value={finalassessment.screenerinfrence}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">Place:</Form.Label>
                            <Form.Control
                                // required
                                //disabled
                                as="textarea"
                                name="place"
                                //placeholder="Screener's Inference"
                                value={finalassessment.place}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col> */}

                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    I: Functional visual Assessment Format for Multi-sensory impaired
                    Learners
                </Col>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Stage One: : Basic awareness
                            </Form.Label>

                        </Form.Group>
                    </Col>
                </Row>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Name of Learner
                            </Form.Label>
                            <Form.Control
                                // required
                                //disabled
                                as="textarea"
                                name="nameoflearner"
                                //placeholder="Screener's Inference"
                                value={finalassessment.nameoflearner}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-3 my-2" controlId="formBasicDate">
                            <Form.Label className="form-view-label">Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="dateoflearner"
                                value={finalassessment.dateoflearner}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>




                {/* START COPY */}
                <Row lg={12}>
                    <Table responsive="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Pupillary Response</td>
                                <td>
                                    <ul><li>Observe without stimulation</li></ul> <br></br>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="pupillaryresponse"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="pupillaryresponse"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.pupcomment} name="pupcomment" onChange={handleChange}></input>
                                    </td>
                                    <ul><li>Do pupils move constantly ?</li></ul>


                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="constantly"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="constantly"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.constantly} name="constantly" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br> <ul><li>Do pupils react to changes in light?</li></ul> 

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="light"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="light"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.light} name="light" onChange={handleChangeTextbox}></input>
                                    </td>

                                </td>

                            </tr>
                            <tr>
                                <td>Blink reflex</td>
                                <td>
                                    <ul><li>Move hand, without making a draught, towards the learner’s face.</li></ul>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="blinkreflex"
                                            value="Yes"
                                            //checked={finalassessment.blinkreflex === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="blinkreflex"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.blinkreflex === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.blinkreflexcomment} name="blinkreflexcomment" onChange={handleChange}></input>
                                    </td>
                                    <br></br>
                                  <ul><li>Does she/he blink?</li></ul>  

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="face"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="face"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.face} name="face" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                            <tr>
                                <td>Response to light</td>
                                <td>
                                    <ul><li>Using different lights in darkened room, does the learner
                                    respond to: </li></ul>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="responseoflight"
                                            value="Yes"
                                            //checked={finalassessment.responseoflight === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="responseoflight"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.responseoflight === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.responseoflightcomment} name="responseoflightcomment" onChange={handleChange}></input>
                                    </td>


                                    <br></br><ul><li>Continuous light?</li></ul> 

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="darkened"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="darkened"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.darkened} name="darkened" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br><ul><li>Flashing
                                    light?</li></ul> 

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="flashing"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="flashing"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.flashing} name="flashing" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br><ul><li>Moving light?</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="moving"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="moving"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.moving} name="moving" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                            <tr>
                                <td>Fixation</td>
                                <td>
                                    <ul><li>Using motivating objects and verbal encouragement, observe whether he/she can fix his/her eyes on a bright object at:</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="fixation"
                                            value="Yes"
                                            //checked={finalassessment.fixation === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="fixation"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.fixation === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.fixationcomment} name="fixationcomment" onChange={handleChange}></input>
                                    </td>



                                    <br></br>

                                    <b className="bullet">• </b>
                                    7 inches
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="inches"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="inches"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.inches} name="inches" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br><ul><li>2 feet</li></ul> 

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="feet2"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="feet2"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.feet2} name="feet2" onChange={handleChangeTextbox}></input>
                                    </td>


                                    <br></br><ul><li>10 feet</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="feet10"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="feet10"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.feet10} name="feet10" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                            <tr>
                                <td>Eye preference</td>
                                <td>
                                <ul><li>Does the learner consistently hold objects near one eye?</li></ul> 
                                    <br></br> <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="eyepreference"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="eyepreference"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.eyepreferencecomment} name="eyepreferencecomment" onChange={handleChange}></input>
                                    </td>




                                    <br></br><ul><li>Left eye</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="lefteye"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="lefteye"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.lefteye} name="lefteye" onChange={handleChangeTextbox}></input>
                                    </td>
                                    <br></br>
                                    <ul><li>Right eye</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="righteye"
                                            value="Yes"
                                            //checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="righteye"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.righteye} name="righteye" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                            <tr>
                                <td>Visual fields</td>
                                <td>
                                   <ul><li>Using a visually interesting object or a light, stand behind
                                    the child and bring it slowly into the visual field. Note when
                                    he/she sees the object</li></ul> 
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="visualfield"
                                            value="Yes"
                                            //checked={finalassessment.visualfield === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            na me="visualfield"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.visualfield === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.visualfieldcomment} name="visualfieldcomment" onChange={handleChange}></input>
                                    </td>


                                    <br></br><ul><li>Above</li></ul>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="above"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="above"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.above} name="above" onChange={handleChangeTextbox}></input>
                                    </td>


                                    {/*  */}



                                    <br></br><ul><li>Below</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="below"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="below"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.below} name="below" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br><ul><li>Right</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="right1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="right1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.right1} name="right1" onChange={handleChangeTextbox}></input>
                                    </td>
                                    <br></br><ul><li>Left</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="left1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="left1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.left1} name="left1" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <Row lg={12}>
                                        <Col lg={6}>
                                            <Form.Group className="" controlId="formBasicname">
                                                <Form.Label className="form-view-label">
                                                    Peripheral Vision
                                                </Form.Label>
                                                <Form.Control
                                                    // required
                                                    as="textarea"
                                                    name="periferalvision"
                                                    //placeholder="Name of the trainee"
                                                    value={finalassessment?.data?.periferalvision}
                                                    onChange={handleChangeTextbox}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="" controlId="formBasicname">
                                                <Form.Label className="form-view-label">
                                                    Upper and lower Vision
                                                </Form.Label>
                                                <Form.Control
                                                    // required
                                                    as="textarea"
                                                    name="lowerupper"
                                                    //placeholder="Name of the trainee"
                                                    value={finalassessment?.data?.lowerupper}
                                                    onChange={handleChangeTextbox}
                                                />
                                            </Form.Group>
                                        </Col>


                                    </Row>

                                </td>

                            </tr>
                            <tr>
                                <td>Focus on objects</td>
                                <td>
                                   <ul><li>Show interesting objects at<br></br>various distances Observe
                                    which he/she sees at:</li></ul> 
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="focusonobjects"
                                            value="Yes"
                                            //checked={finalassessment.focusonobjects === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="focusonobjects"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.focusonobjects === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.focusonobjectscomment} name="focusonobjectscomment" onChange={handleChange}></input>
                                    </td>

                                    <br></br><ul><li>7 Inches</li></ul> 
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="focusinches"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="focusinches"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.focusinches} name="focusinches" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br><ul><li> 2 feet</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="focusfeet2"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="focusfeet2"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.focusfeet2} name="focusfeet2" onChange={handleChangeTextbox}></input>
                                    </td>
                                    <br></br><ul><li> 10 feet</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="focusfeet10"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="focusfeet10"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.focusfeet10} name="focusfeet10" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                            <tr>
                                <td>Eye contact</td>
                                <td>
                                <ul><li>Observe the learner with familiar people and in a variety of
                                    situations e.g. meal times. Does he/she make eye contact:</li></ul>  

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="eyecontact"
                                            value="Yes"
                                            //checked={finalassessment.eyecontact === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="eyecontact"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyecontact === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.eyecontactcomment} name="eyecontactcomment" onChange={handleChange}></input>
                                    </td>


                                    <br></br><ul><li>Briefly</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="briefly1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="briefly1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.briefly1} name="briefly1" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br><ul><li>For three seconds or more</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="three1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="three1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.three1} name="three1" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br>
                                    <ul><li>Maintained</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="maintained1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="maintained1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.maintained1} name="maintained1" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                            <tr>
                                <td>Visual behaviours</td>
                                <td>
                                <ul><li>Observe carefully for behaviours which may indicate use of
                                    residual vision such as:</li></ul>  
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="visualbehaviour"
                                            value="Yes"
                                            //checked={finalassessment.visualbehaviour === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="visualbehaviour"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.visualbehaviour === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.visualbehaviourcomment} name="visualbehaviourcomment" onChange={handleChange}></input>
                                    </td>

                                    <br></br><ul><li>Light gazing</li></ul>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="gazing1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="gazing1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.gazing1} name="gazing1" onChange={handleChangeTextbox}></input>
                                    </td>
                                    <br></br><ul><li>Hand flicking</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="flicking1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="flicking1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.flicking1} name="flicking1" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br><ul><li>Any other</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="anyother1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="anyother1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.anyother1} name="anyother1" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                        </tbody>
                    </Table>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    II. Functional Visual Assessment for Multi-sensory Impaired Learners
                </Col>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Stage Two: Development of Basic Awareness into Functional use of
                                Vision
                            </Form.Label>

                        </Form.Group>
                    </Col>
                </Row>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Name of Learner
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="stage2_nameoflearner"
                                //placeholder="Name of Learner"
                                value={finalassessment.stage2_nameoflearner}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-3 my-2" controlId="formBasicDate">
                            <Form.Label className="form-view-label">Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="stage2_dateoflearner"
                                value={finalassessment.stage2_dateoflearner}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row lg={12}>
                    <Table responsive="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Yes</th>
                                <th>No</th>
                                <th>comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Hand regard</td>
                                <td>
                                    Play hand games. Does the learner
                                    look at your hands?
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="handregard"
                                        value="Yes"
                                        //checked={finalassessment.handregard === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="handregard"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.handregard === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.handregardcomment} name="handregardcomment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr className="custom-list">
                                <td>Tracking</td>
                                <td>
                                 <ul><li>Choose motivating objects. Move them slowly and note if the
                                    learner can visually track the object :</li></ul> 

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="tracking"
                                            value="Yes"
                                            //checked={finalassessment.tracking === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="tracking"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.tracking === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.trackingcomment} name="trackingcomment" onChange={handleChange}></input>
                                    </td>

                                    <br></br><ul><li>From the mid-line to the left</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="visually1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="visually1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.visually1} name="visually1" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br> <ul><li>From the mid-line to the right</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="midline1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="midline1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.midline1} name="midline1" onChange={handleChangeTextbox}></input>
                                    </td>
                                    <br></br><ul><li>Right to left (across mid-line)</li></ul>
                                  


                                    {/* across1 */}

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="across1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="across1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.across1} name="across1" onChange={handleChangeTextbox}></input>
                                    </td>


                                    <br></br><ul><li>Left to right (across mid-line)</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="rightacross1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="rightacross1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.rightacross1} name="rightacross1" onChange={handleChangeTextbox}></input>
                                    </td>


                                    <br></br><ul><li>Going down</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="goingdown1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="goingdown1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.goingdown1} name="goingdown1" onChange={handleChangeTextbox}></input>
                                    </td>
                                    <br></br><ul><li>Going up</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="goingup1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="goingup1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.goingup1} name="goingup1" onChange={handleChangeTextbox}></input>
                                    </td>


                                    <br></br><ul><li>Diagonally</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"

                                            name="diagonally1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="diagonally1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.diagonally1} name="diagonally1" onChange={handleChangeTextbox}></input>
                                    </td>



                                    <br></br> <ul><li>In a circular movement</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="circular1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="circular1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.circular1} name="circular1" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                            <tr>
                                <td>Transfer of attention from one object to another</td>
                                <td>
                                    <ul><li>Using two torches or two of equal interest held a foot apart,
                                    shake one first then the other to see if the learner can shift
                                    his gaze between two objects:</li></ul>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="transferofattention"
                                            value="Yes"
                                            //checked={finalassessment.transferofattention === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="transferofattention"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.transferofattention === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.transferofattentioncomment} name="transferofattentioncomment" onChange={handleChange}></input>
                                    </td>


                                    <br></br><ul><li>At the same distance</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="same1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="same1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.same1} name="same1" onChange={handleChangeTextbox}></input>
                                    </td>


                                    <br></br> <ul><li>Near/distant</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="distant1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="distant1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.distant1} name="distant1" onChange={handleChangeTextbox}></input>
                                    </td>


                                    <br></br> <ul><li>Distant/near</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="near1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="near1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.near1} name="near1" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                            <tr>
                                <td>Scanning</td>
                                <td>
                                <ul><li>Place three objects in a line. Point along the line. Can the
                                    learner scan the line?</li></ul>  
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="learner1"
                                        value="Yes"
                                        //checked={finalassessment.scanning === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="learner1"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.scanning === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={state.learner1} name="learner1" onChange={handleChangeTextbox}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>Turning eyes Towards object</td>
                                <td>
                                <ul><li>Touch the learner with an object. Does he/she turn to look?</li></ul>  

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="turningeyes"
                                            value="Yes"
                                            //checked={finalassessment.turningeyes === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="turningeyes"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.turningeyes === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.turningeyescomment} name="turningeyescomment" onChange={handleChange}></input>
                                    </td>


                                    <br></br><ul><li>To the left</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="touch1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="touch1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.touch1} name="touch1" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br><ul><li>To the right</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="theright1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="theright1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.theright1} name="theright1" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                            <tr>
                                <td>Attempts to reach visually for objects</td>
                                <td>
                                    Does he attempt to
                                    reach them?
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="attemptstoreach"
                                        value="Yes"
                                        //checked={finalassessment.attemptstoreach === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="attemptstoreach"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.attemptstoreach === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.attemptstoreachcomment} name="attemptstoreachcomment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>Visual exploration</td>
                                <td>
                                <ul><li>Does the learner turn objects in his/her hand to explore visually?</li></ul>    

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="visualexploration"
                                            value="Yes"
                                            //checked={finalassessment.visualexploration === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="visualexploration"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.visualexploration === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.visualexplorationcomment} name="visualexplorationcomment" onChange={handleChange}></input>
                                    </td>


                                    <br></br><ul><li>Sometimes</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="sometimes1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="sometimes1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.sometimes1} name="sometimes1" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br><ul><li>Often</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="often1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="often1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.often1} name="often1" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                            <tr>
                                <td>Object recognition</td>
                                <td>
                                <ul><li>Does the learner recognise familiar objects?</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="objectrecognization"
                                            value="Yes"
                                            //checked={finalassessment.objectrecognization === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="objectrecognization"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.objectrecognization === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.objectrecognizationcomment} name="objectrecognizationcomment" onChange={handleChange}></input>
                                    </td>






                                    <br></br><ul><li>A few</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="afew1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="afew1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.afew1} name="afew1" onChange={handleChangeTextbox}></input>
                                    </td>
                                    <br></br><ul><li>Many</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="many1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="many1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.many1} name="many1" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                            <tr>
                                <td>Visual discrimination</td>
                                <td>
                                <ul><li>Does the learner discriminate visually?</li></ul> 

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="visualdiscrimination"
                                            value="Yes"
                                            //checked={finalassessment.visualdiscrimination === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="visualdiscrimination"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.visualdiscrimination === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.visualdiscriminationcomment} name="visualdiscriminationcomment" onChange={handleChange}></input>
                                    </td>







                                    <br></br><ul><li>Size</li></ul>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="size1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="size1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.size1} name="size1" onChange={handleChangeTextbox}></input>
                                    </td>
                                    <br></br><ul><li>Colour</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="colour1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="colour1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.colour1} name="colour1" onChange={handleChangeTextbox}></input>
                                    </td>


                                    <br></br><ul><li>Shape</li></ul>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="shape1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="shape1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.shape1} name="shape1" onChange={handleChangeTextbox}></input>
                                    </td>

                                    <br></br><ul><li>Family members</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="familymembers1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="familymembers1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.familymembers1} name="familymembers1" onChange={handleChangeTextbox}></input>
                                    </td>
                                    <br></br><ul><li>Carers</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="carers1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="carers1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.carers1} name="carers1" onChange={handleChangeTextbox}></input>
                                    </td>
                                    <br></br><ul><li>Strangers</li></ul>

                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="strangers1"
                                            value="Yes"
                                            //checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="strangers1"
                                            value="No"
                                            type="radio"
                                            //checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>

                                    <td>
                                        <input type="textarea" className="form-control" value={state.strangers1} name="strangers1" onChange={handleChangeTextbox}></input>
                                    </td>
                                </td>

                            </tr>
                        </tbody>
                    </Table>
                </Row>

                {/* END COPY */}
                <Col lg={12} className="section-header my-3 mx-2">
                    Functional hearing Assessment
                </Col>
                <Row lg={12}>
                    <Table responsive="sm">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Observations</th>
                                <th>Yes</th>
                                <th>No</th>
                                <th>comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1.</td>
                                <td>
                                    Does the learner show any response to sound?<br></br>e.g.
                                    startle to sudden loud sound, blink at a sound, smile or calm
                                    down in response to music
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_1"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_1 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_1"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_1 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_1comment} name="functionalhearing_1comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td>
                                    Does the learner appear to notice or attend to any particular
                                    sounds?<br></br>e.g. a familiar voice or noise
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_2"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_2 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_2"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_3 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_2comment} name="functionalhearing_2comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td>
                                    How interested is the learner in sound in the environment?
                                    <br></br>Do they respond consistently or inconsistently to
                                    auditory stimuli
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_3"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_3 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_3"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_3 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_3comment} name="functionalhearing_3comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td>
                                    What kind of sound is he or she interested in?<br></br>loud
                                    sounds, music, familiar environmental sounds duration of
                                    sound?<br></br>Is the interest affected by pitch, volume,
                                    direction,
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_4"
                                        value="Yes"
                                        //checked={finalassessment.mothersufferedpregnancy === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_4"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.mothersufferedpregnancy === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.hearingscreeningoutcome_4comment} name="hearingscreeningoutcome_4comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>5.</td>
                                <td>
                                    Does the learner show interest in voices?<br></br>e.g. their
                                    mother or fathers
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_5"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_5 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_5"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_5 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_5comment} name="functionalhearing_5comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>6.</td>
                                <td>
                                    Does the response vary according to the distance between the
                                    learner and the sound?
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_6"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_6 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_6"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_6 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_6comment} name="functionalhearing_6comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>7.</td>
                                <td>
                                    How loud does a sound have to be before attention is gained?
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_7"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_7 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_7"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_7 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_7comment} name="functionalhearing_7comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>8.</td>
                                <td>
                                    Is the learner's ability to respond affected by background
                                    noise?
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_8"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_8 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_8"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_8 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_8comment} name="functionalhearing_8comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>9.</td>
                                <td>
                                    Does the learner show more auditory interest to sounds coming
                                    
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_9"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_9 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_9"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_9 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_9comment} name="functionalhearing_9comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>10.</td>
                                <td>Do they seem to recognise familiar sounds?</td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_10"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_10 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_10"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_10 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_10comment} name="functionalhearing_10comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>11.</td>
                                <td>Can they pick out one sound from a range of sounds?</td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_11"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_11 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_11"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_11 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_11comment} name="functionalhearing_11comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>12.</td>
                                <td>
                                    Do they show an awareness of sound/no sound?<br></br>E.g. does
                                    their behaviour change when a particular sound stops
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_12"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_12 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_12"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_12 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_12comment} name="functionalhearing_12comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>13.</td>
                                <td>
                                    Does the learner move towards sounds?<br></br>How accurate are
                                    they in using the sound to orientate themselves?
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_13"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_13 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_13"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_13 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_13comment} name="functionalhearing_13comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>14.</td>
                                <td>
                                    Observe the speed at which she/he notices and responds to
                                    sounds.<br></br>Is this affected by time or place?<br></br>Is
                                    it affected by the learner's position, comfort level or by
                                    competition from other sensory stimuli?
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_14"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_14 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_14"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_14 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_14comment} name="functionalhearing_14comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>15.</td>
                                <td>
                                    Are there any sounds that the learner appears to dislike or
                                    that cause distress?<br></br>Which sounds?
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_15"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_15 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_15"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_15 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_15comment} name="functionalhearing_15comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>16.</td>
                                <td>Does the learner vocalise?</td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_16"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_16 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_16"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_16 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_16comment} name="functionalhearing_16comment" onChange={handleChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>17.</td>
                                <td>
                                    Are there any consonant sounds as well as open vowels sounds?
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="functionalhearing_17"
                                        value="Yes"
                                        //checked={finalassessment.functionalhearing_17 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="functionalhearing_17"
                                        value="No"
                                        type="radio"
                                        //checked={finalassessment.functionalhearing_17 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange8}
                                    />
                                </td>
                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment.functionalhearing_17comment} name="functionalhearing_17comment" onChange={handleChange}></input>
                                </td>
                            </tr>

                        </tbody>
                    </Table>
                    <p style={{ marginLeft: "68%" }}>{YesCount8}</p>


                </Row>

                <Row>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">Signature</Form.Label>
                            <Form.Control
                                // required
                                //disabled
                                as="textarea"
                                name="signature"
                                //placeholder="Screener's Inference"
                                value={finalassessment.signature}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default FinalAssessmentCreate