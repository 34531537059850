import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Form, FormSelect, Badge } from "react-bootstrap";
import moment from "moment";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { useNavigate } from "react-router-dom";
import PubSub from "pubsub-js";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import IndiaAutismPdfFile from "./IndiaAutismPdfFile";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake";
import * as constants from "../constants/CONSTANT";

const IndianAutismEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [locationData, setLocationData] = useState(
        location.state ? location.state : {}
    );
    console.log("locationData", locationData);
  
    const [indianAutismData, setIndianAutismData] = useState({});
    const [fieldsData, setfieldsData] = useState({});
    //console.log("indianAutismData", indianAutismData);
    const [validated, setValidated] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [specificId, setpecificId] = useState();
    const [totalIndiaAutism,setTotalIndiaAutism] = useState({});

    var [totalCount1, settotalCount1] = useState(null);
    var [totalCount2, settotalCount2] = useState(null);
    var [totalCount3, settotalCount3] = useState(null);
    var [totalCount4, settotalCount4] = useState(null);
    const [switchStates, setSwitchStates] = useState({});

    const [updatedCounts, setupdatedCounts] = useState({})

    const [dataArray, setDataArray] = useState([
        {
            id: 1,
            name: "Has poor eye contact",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 2,
            name: "Lacks social smile",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 3,
            name: "Remains aloof",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 4,
            name: "Does not reach out to others",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 5,
            name: "Unable to relate to people",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 6,
            name: "Unable to respond to social/ environmental cues",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 7,
            name: "Engages in solitary and repetitive play activities",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 8,
            name: "Unable to take turns in social interaction",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 9,
            name: "Does not maintain peer relationships",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 10,
            name: "Shows inappropriate emotional response",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 11,
            name: "Shows exaggerated emotions",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 12,
            name: "Engages in self-stimulating emotions",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 13,
            name: "Lacks fear of danger",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 14,
            name: "Excited or agitated for no apparent reason",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 15,
            name: "Acquired speech and lost it",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 16,
            name: "Has difficulty in using non-verbal language or gestures to communicate",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 17,
            name: "Engages in stereotyped and repetitive use of language",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 18,
            name: "Engages in echolalic speech",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 19,
            name: "Produces infantile squeals/ unusual noises",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 20,
            name: "Unable to initiate or sustain conversation with  others",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 21,
            name: "Uses jargon or meaningless words",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 22,
            name: "Uses pronoun reversals",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 23,
            name: "Unable to grasp pragmatics of communication (real meaning)",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 24,
            name: "Engages in stereotyped and repetitive motor  mannerisms",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 25,
            name: "Shows attachment to inanimate objects",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 26,
            name: "Shows hyperactivity/ restlessness",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 27,
            name: "Exhibits aggressive behavior",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 28,
            name: "Throws temper tantrums",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 29,
            name: "Engages in self-injurious behavior",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 30,
            name: "Insists on sameness",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 31,
            name: "Unusually sensitive to sensory stimuli",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 32,
            name: "Stares into space for long periods of time",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 33,
            name: "Has difficulty in tracking objects",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 34,
            name: "Has unusual vision",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 35,
            name: "Insensitive to pain",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 36,
            name: "Responds to objects/people unusually by smelling, touching or tasting",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 37,
            name: "Inconsistent attention and concentration",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 38,
            name: "Shows delay in responding",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 39,
            name: "Has unusual memory of some kind",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
        {
            id: 40,
            name: "Has ‘savant’ ability",
            q1: false,
            q2: false,
            q3: false,
            q4: false,
            q5: false,
            sectionheading: "2",
        },
    ]);
   

    
    useEffect(() => {
        //console.log('test')
        let sum = 0;

        dataArray?.forEach(obj => {
            if (obj.q1) sum += 1;
            if (obj.q2) sum += 2;
            if (obj.q3) sum += 3;
            if (obj.q4) sum += 4;
            if (obj.q5) sum += 5;
        });
        //console.log('sum', sum)
        if (sum > 0 && sum < 70) {
            settotalCount1(sum);
            settotalCount2(0);
            settotalCount3(0);
            settotalCount4(0);
        } else if (sum >= 70 && sum <= 106) {
            settotalCount1(0);
            settotalCount2(sum);
            settotalCount3(0);
            settotalCount4(0);
        } else if (sum >= 107 && sum <= 153) {
            settotalCount1(0);
            settotalCount2(0);
            settotalCount3(sum);
            settotalCount4(0);
        } else if (sum > 153) {
            settotalCount1(0);
            settotalCount2(0);
            settotalCount3(0);
            settotalCount4(sum);
        }

}, [dataArray]);




useEffect(() => {
    if (locationData) {
        var obj = {};
        obj.value = locationData.childnameid;
        obj.label = locationData.childname;
        setoption(obj)
        console.log("locationData CAll",locationData)
        fetchAllIndianAutism();
    }
}, []);



const fetchAllIndianAutism = async () => {
    console.log('locationData ==>',locationData)
    let result = await inventoryApi.fetchIndianAutishById(locationData.id);
    //console.log("fieldsData =====>>>>", fieldsData)
    console.log("result.data", result);
    setTotalIndiaAutism(result)
    setDataArray(result.data)
    setIndianAutismData(result);
    // handleChangeFields(result);
    setfieldsData(result);  
}; 


const handleChangeFields = (e) => {
  //  //console.log("if call", e.target.name, e.target.value);
    setIndianAutismData({ ...indianAutismData, [e.target.name]: e.target.value });
    setfieldsData({ ...fieldsData, [e.target.name]: e.target.value });
    

};



const [q1count, setq1count] = useState(0);
const [q2count, setq2count] = useState(0);
const [q3count, setq3count] = useState(0);
const [q4count, setq4count] = useState(0);
const [q5count, setq5count] = useState(0);

const handleChange = (itemIndex, optionKey) => {
    //console.log("itemIndex ===>",itemIndex)
        //console.log("optionKey ===>",optionKey)
    const updatedItems = dataArray.map((item, index) => {
        
        //console.log("item ===>",item)
        
        if (item.id === itemIndex) {
            const updatedOptions = { ...item };
            Object.keys(updatedOptions).forEach(key => {
                if (!['name', 'id'].includes(key)) {
                    updatedOptions[key] = updatedOptions[key] ? false : key === optionKey;
                }

            });
            return updatedOptions;
        }
        return item;
    });
    setDataArray([...updatedItems])
    //console.log('updatedItems ==>', updatedItems);
};

//console.log("dataArray", dataArray);

const handleCancel = () => {
    navigate(`/indianautism/`);
};


const checkRequredFields = () => {
    if (
        fieldsData.childname && fieldsData.childname.trim() !== ""
        &&
        fieldsData.autismdate && fieldsData.autismdate.trim() !== ""
        &&
        fieldsData.dob && fieldsData.dob.trim() !== ""
        &&
        fieldsData.gender && fieldsData.gender.trim() !== ""
        &&
        fieldsData.age !== ''
        &&
        fieldsData.examiner && fieldsData.examiner.trim() !== ""

    ) {
        return false;
    }
    return true;
};

//console.log(dataArray)


const handleSubmit = async (e) => {
    console.log("handleSubmit Call");
    e.preventDefault();
    

    //console.log("sum");

    const flattenedDataArray = dataArray.flat();
    console.log("flattenedDataArray",flattenedDataArray);

    let completeData = {};
    completeData = { ...fieldsData, data: flattenedDataArray };
    console.log("completeData ===>", completeData);
    //console.log("...fieldsData ===>", ...fieldsData);
    //console.log("fieldsData ===>", fieldsData);
    let result = {}; 
    if (checkRequredFields()) {
        //console.log("checkRequredFields");
        setValidated(true);
    } 

    if (completeData.id) {
        //console.log(first)
        let completeDataId = completeData.id;
        delete completeData.id


        //console.log('if call edit', completeData, completeDataId);
        result = await inventoryApi.saveIndianAutism(locationData.id, completeData);
        //console.log('result', result)
        if (result.success === true) {
            PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record Update Successfully' });
            navigate(`/indianautism`);
        } else {
            PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Error', message: result instanceof Object ? result.errors.autismdate : null });

        }
     } 
     else {

        //console.log('if creaate call', completeData)
        const result = await inventoryApi.createIndianAutism(completeData);
        if (result) {
            PubSub.publish("RECORD_SAVED_TOAST", {
                title: "Record Saved",
                message: "Record create successfully",
            });
            navigate(`/indianautism`);
        }
        if (result.errors) {
            setShowError(true);
            setErrorMessage(result.errors);
            return;
        }
    }
};
const [option, setoption] = useState();
const [optionUsers, setOptionUsers] = useState([]);
useEffect(() => {
    fetchAllStudents();
}, []);
const fetchAllStudents = async () => {
    const result = await inventoryApi.fetchStudents();
    console.log(result)
    if (result) {
        let ar = [];
        result.map((item) => {
            var obj = {};
            obj.value = item.id;
            obj.label = item.name;
            ar.push(obj);
        });
        //console.log('ar', ar)
        setOptionUsers(ar);
     
    } else {
        setOptionUsers([]);
       
    }
}
const handleUsers = async (event) => {
    console.log('event', event)
    let result = await inventoryApi.fetchStudentById(event.value);
    console.log("result of Student>>", result);
    indianAutismData.dob = result.dob
    indianAutismData.dob = result.dob
    indianAutismData.gender = result.gender
    indianAutismData.age = result.childregistrationage
    fieldsData.dob = result.dob
    fieldsData.gender = result.gender
    fieldsData.age = result.childregistrationage

    setoption(event)
    setfieldsData({ ...fieldsData, childnameid: event.value, childname :  event.label});
}



const pdfShow =()=>{
    const pdfTable = document.getElementById("divToPrint2");
    console.log("pdf table india", pdfTable);
    var html = htmlToPdfmake(pdfTable.innerHTML, {
        tableAutoSize: true,
    });
    console.log('html', html)
    const documentDefinition = {
        content: html, defaultStyle: {
            font: 'arialunicodems1'
        }
    };
    //console.log('documentDefinition', documentDefinition)
    //pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
        arialunicodems1: {
            normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
        },


        // download default Roboto font from cdnjs.com
        Roboto: {
            normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
            bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
            italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
            bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
        }
    }
    //console.log(pdfMake.vfs)
  
      pdfMake.createPdf(documentDefinition).open();
   
      
  }


return (
    <>
        <Container id="marbt">
            <Row>
                <Col lg={12} className="mx-3">
                    <Form
                        className="mt-3"
                        onSubmit={handleSubmit}
                        noValidate
                        validated={validated}
                    >
                        <Row className="view-form-header align-items-center">
                            <Col lg={6}>INDIAN SCALE FOR ASSESSMENT OF AUTISM</Col>
                            <Col lg={6} className="d-flex justify-content-end">
                            <Button
                                    className="btn-sm mx-2"
                                    type="submit"
                                    onClick={pdfShow}
                                >
                                    {" "}
                                    PDF{" "}
                                </Button>
                                <Button
                                    className="btn-sm mx-2"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    {" "}
                                    Save{" "}
                                </Button>
                                <Button
                                    className="btn-sm"
                                    variant="danger"
                                    onClick={handleCancel}
                                >
                                    {" "}
                                    Cancel{" "}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <Row className="my-3">
                <Col lg={6}>
                    <Form.Group className="mx-3 my-1" controlId="formBasicname">
                        <Form.Label className="form-view-label">Name Of Child</Form.Label>
                        <Select
                                name="childname"
                                value={option}
                                onChange={handleUsers}
                                options={optionUsers}
                            // getOptionValue={(option) => option.value}
                            />
                        

                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicDate">
                        <Form.Label className="form-view-label">Date</Form.Label>
                        <Form.Control
                            required
                            type="date"
                            name="autismdate"
                            value={moment(indianAutismData.autismdate).format('YYYY-MM-DD')}
                            onChange={handleChangeFields}
                        />
                    </Form.Group>
                </Col>

            </Row>
            <Row>
                <Col lg={6}>
                    <Form.Group className="mx-3 my-1" controlId="formBasicname">
                        <Form.Label className="form-view-label">Gender</Form.Label>
                        <Form.Select
                            name="gender"
                            onChange={handleChangeFields}
                            value={indianAutismData.gender}
                        >
                            <option>-- Select --</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="other">Other</option>

                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group className="mx-3" controlId="formBasicDate">
                        <Form.Label className="form-view-label">D.O.B</Form.Label>

                        <Form.Control
                            required
                            type="date"
                            name="dob"
                            value={moment(indianAutismData.dob).format('YYYY-MM-DD')}
                            onChange={handleChangeFields}
                        />

                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group className="mx-3 my-1" controlId="formBasicname">
                        <Form.Label className="form-view-label">Age</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="age"
                            placeholder="Age"
                            value={indianAutismData.age}
                            onChange={handleChangeFields}
                        />
                    </Form.Group>
                </Col>

                <Col lg={6}>
                    <Form.Group className="mx-3 my-1" controlId="formBasicname">
                        <Form.Label className="form-view-label">Examiner</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="examiner"
                            placeholder="Examiner"
                            value={indianAutismData.examiner}
                            onChange={handleChangeFields}
                        />
                    </Form.Group>
                </Col>
            </Row>



            <Col lg={12}>
                <Col lg={12} className="my-4 mx-1 row">
                    <Col lg={2}>
                        <b>Items</b>
                    </Col>
                    <Col lg={2}>Rarely Upto 20% Score 1</Col>
                    <Col lg={2}> Sometimes 21 – 40% Score 2</Col>
                    <Col lg={2}>Frequently 41 – 60% Score 3</Col>
                    <Col lg={2}>Mostly 61 - 80 % Score 4</Col>
                    <Col lg={2}>Always 81 - 100 % Score 5</Col>
                </Col>
                {dataArray?.map((item, index) => (
                    <div key={index}>

                        <Row lg={12}>
                            <Col lg={2} style={{ width: '16%' }} className="my-3">
                                {item.name}
                            </Col>
                            <Col lg={2}>
                                <Form.Check
                                    type="switch"
                                    name={`item${item.id}.q1`}
                                    id={`item${item.id}.q1-1`}
                                    checked={item?.q1}
                                    onChange={() => handleChange(item.id, 'q1')}
                                />
                            </Col>
                            <Col lg={2}>
                                <Form.Check
                                    type="switch"
                                    name={`item${item.id}.q2`}
                                    id={`item${item.id}.q1-2`}
                                    checked={item?.q2}
                                    onChange={() => handleChange(item.id, 'q2')}
                                />
                            </Col>
                            <Col lg={2}>
                                <Form.Check
                                    type="switch"
                                    name={`item${item.id}.q3`}
                                    id={`item${item.id}.q1-3`}
                                    checked={item?.q3}
                                    onChange={() => handleChange(item.id, 'q3')}
                                />
                            </Col>
                            <Col lg={2}>
                                <Form.Check
                                    type="switch"
                                    name={`item${item.id}.q4`}
                                    id={`item${item.id}.q1-4`}
                                    checked={item.q4}
                                    onChange={() => handleChange(item.id, 'q4')}
                                />
                            </Col>
                            <Col lg={2}>
                                <Form.Check
                                    type="switch"
                                    name={`item${item.id}.q5`}
                                    id={`item${item.id}.q1-5`}
                                    checked={item?.q5}
                                    onChange={() => handleChange(item.id, 'q5')}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}

                <Row lg={12}>
                    <Col lg={3} className="section-header">
                        <Form.Group className="mx-2">
                            <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicFirstName"
                            >
                                Classification
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="section-header">
                        <Form.Group>
                            <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicLastName"
                            >
                                No Autism &lt; 70
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="totalCount1"
                                disabled
                                value={totalCount1}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="section-header">
                        <Form.Group>
                            <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicEmail"
                            >
                                Mild Autism 70 to 106
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="totalCount2"
                                disabled

                                value={totalCount2}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={3} className="section-header">
                        <Form.Group>
                            <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicPhone"
                            >
                                Moderate Autism 107 to 153
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="totalCount3"
                                disabled
                                value={totalCount3}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="section-header">
                        <Form.Group>
                            <Form.Label
                                className="form-view-label"
                                htmlFor="formBasicEmail"
                            >
                                Severe Autism &gt; 153
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="totalCount4"
                                disabled
                                value={totalCount4}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
            <div className="App container mt-5" style={{ display: 'none' }}>
            <div id="divToPrint2">
                 <IndiaAutismPdfFile totalIndiaAutism = {totalIndiaAutism} totalCount1 = {totalCount1} totalCount2 = {totalCount2} totalCount3 = {totalCount3} totalCount4 = {totalCount4}/>
            </div>
        </div>
        </Container>
    </>
);
};
export default IndianAutismEdit;