import React from 'react'
import{ useState, useEffect } from "react";
import { Button } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import inventoryApi from "../api/authApi";
import ModelPopUp from './ModelPopUp';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToastMessage  from './ToastMessage';
const PermissionList = () => {
    const [permissionList, setPermissionList] = useState([]);
    const [permissionListForInsert, setPermissionListForInsert] = useState([]);
    const [permissionListForUpdate, setPermissionListForUpdate] = useState([]);
    const [isModelPopup, setModlePopup] = useState(false);
    const [isToast, setToast] = useState(false);
    const [isChange, setChange] = useState(false);
    const [messageForToast, setMessageToast] = useState('');
    const [gettingRoles, setgettingRoles] = useState([]);
    async function handleDelete(ids, id) {
        if(id != null && id !== ''){
            console.log('id',id)
            const result = await inventoryApi.deletePermission(id);
            if(result.success){
                setChange(!isChange)
                setMessageToast('Successfully record Deleted');
                setToast(true);
            }
            
        }else{
            setPermissionList(permissionList.filter((permission)=>{
                return  ( permission.ids !== ids);
              }))
        }
        
    }
    function handleClose() {
        setToast(false);
    }
    function handleAddNew() {
        setPermissionList([...permissionList, {ids : permissionList.length+1}])
    }

    async function handleSave() {
        setPermissionListForInsert([]);
        setPermissionListForUpdate([]);
        let PermissionList = document.querySelectorAll(".inputPermission")
        console.log('permissionList',PermissionList)
        PermissionList.forEach(element => {
            console.log(element.dataset.id)
            if(element.vaue !== null && element.value !== '' && (element.dataset.id === '' || element.dataset.id === null ||  element.dataset.id === undefined)){
                permissionListForInsert.push({name:element.value})
            }
            var updateRec = gettingRoles.find((permission)=>{
                return permission.id ===  element.dataset.id
            });
            if(updateRec !== undefined && updateRec.name !== element.value && element.vaue !== null && element.value !== '' && (element.dataset.id !== '' &&  element.dataset.id !== null &&   element.dataset.id !== undefined)){
                permissionListForUpdate.push({name:element.value, id:element.dataset.id })
            }
        });
        console.log('permissionListForInsert',permissionListForInsert);
        if(permissionListForInsert.length > 0){
            const result = await inventoryApi.createPermissions(permissionListForInsert);
        console.log(result)
        if(result.hasOwnProperty('errors')){
            alert(result.errors[0].msg)
        }
        else if(result){
            console.log(result)
            setMessageToast('Successfully record created');
            setToast(true)
            setChange(!isChange)
        }}
        if(permissionListForUpdate.length >0 ){
            const resultUpdate = await inventoryApi.savePermission(permissionListForUpdate);
            console.log(resultUpdate)
            setChange(!isChange)
        }
        
      
    }


    function onTodoChange(event){
        console.log(event.target.dataset.id)
        if(event.target.dataset.id !== '' && event.target.dataset.id !== undefined){
            setPermissionList(permissionList.map((data)=>{
                if(data.id ===  event.target.dataset.id){
                        return {...data, name :event.target.value }
                } else{
                    return data
                }        
            }));
        }

    }

    useEffect(() => {
        async function init() {
          const Permissions = await inventoryApi.fetchPermissions();
          console.log('Permissions',Permissions)
          if (Permissions)
          setPermissionList(Permissions);
          setgettingRoles(Permissions);
        }
        init();
    
      }, [isChange]);
  return (
    <>
    <Container>
        <Row className="">
            <Col >
            <h3><b>Permissions</b></h3>
            </Col>
            <Col>
            <Button  variant="primary" onClick={handleAddNew}>Add New</Button>{' '} <Button variant="danger" style={{width:"5rem"}} onClick={handleSave}>Save</Button>{' '}
            </Col>   
        </Row>
              
    </Container>
    <Container style={{padding:"2rem"}}>
    <Row className="">
        { permissionList.map((permission)=>{
            return   (
               
                   <>
                        
                        <Col  xs lg="3">
                            <Form.Group className="mb-3">
                            <Form.Control placeholder="Permission Name" onChange={e => onTodoChange(e)} value={permission.name} data-id={permission.id}  className="inputPermission"/>
                            </Form.Group>

                        </Col>
                        <Col xs lg={1}>
                        <Button className='btn-sm mx-2' variant='danger' onClick={()=>{handleDelete(permission.ids, permission.id)}}><i class="fa-regular fa-trash-can"></i></Button>
                    
                        </Col>
                        
                        </>
                
               
                
                    
                )
        })}
         </Row>
    </Container>

  
        {
           
           isToast && <ToastMessage show={isToast} handleClose={handleClose} msg={messageForToast}/>
       }

    
    </>
  )
}


export default PermissionList;