
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import inventoryApi from "../api/inventoryApi";
import PdfGenerator from "./PdfGenerator";
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import * as constants from "../constants/CONSTANT";
import AnalysisReportPdf from "./AnalysisReportPdf";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import { Link } from "react-router-dom";


const AnalysisList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [totalAnalysis,setTotalAnalysis] = useState({});
  const [analylist,setanalylist] = useState({});
  let rowId;
  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchJob();
      console.log('##result==>', result);
      if (result) {
      
        setBody(result);
      }
    }
  
    init();
  }, []); 

  const [rowdata, setrowdata] = useState([]);

  
  const fetchJobById = async (id)=>{
    let result = await inventoryApi.fetchJobById(id);
    console.log('#result==>', result);
    setrowdata(result)
   
   
    
  }



  const sumOfAnalysis = async (id)=>{
    let result = await inventoryApi.fetchSumOfJobAnalysis(id);
    console.log("result of totalAnalysis ==>",result);
    // setanalylist(result)
    if(result != null){
      setTotalAnalysis(result); 
      setTimeout(function() {
        pdfShow()
    }, 500);
      
    }
    else{
      setTotalAnalysis({});
    }   
  }



const handleOpenPdf =(row)=>{
  console.log("pdf id ,",row.id);
  sumOfAnalysis(row.id);
  fetchJobById(row.id);
};



const pdfShow =()=>{
  const pdfTable = document.getElementById("divToPrint");
  //console.log("pdf table", pdfTable);
  var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
  });
  console.log('html', html)
  const documentDefinition = {
      content: html, defaultStyle: {
          font: 'arialunicodems1'
      }
  };
  pdfMake.fonts = {
      arialunicodems1: {
          normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
          bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
      },


      // download default Roboto font from cdnjs.com
      Roboto: {
          normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
          bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
          italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
          bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      }
  }
  //console.log(pdfMake.vfs)

    pdfMake.createPdf(documentDefinition).open();
 
    
}


  
  const header = [
    {
      title: 'Job Title',
      prop: '_1_1j',
      isFilterable: true,
      cell: (row) => (
        <Link to={"/analysis/" + row.id} state={row}>
          {row._1_1j}
        </Link>
      ),
    },
    { title: 'Job Site', prop: '_1_2j', isFilterable: true },
    { title: 'Job Trainee', prop: '_1_3j', isFilterable: true },
    { title: 'Job Programmer', prop: '_1_4j', isFilterable: true },
    {
      title: "Action",
      prop: "id",
      cell: (row) => (
          <Button variant="danger" className="btn-sm mx-2" onClick={() => handleOpenPdf(row)}>
              <i class="fa-regular fa-file-pdf"></i>
          </Button>
      ),
    },
  ];
  

  const labels = {
    beforeSelect: ""
  }

  const createJob = () => {
    navigate(`/analysis`);
  }


  return (
    <Row className="g-0">
      <Col lg={2} className="mx-2">
        <Link className="nav-link">Home <i class="fa-solid fa-chevron-right"></i> JOB ANALYSIS</Link>
      </Col>       
      <Col lg={12} className="p-lg-4">
                {body ? (
                    <DatatableWrapper
                        body={body}
                        headers={header}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 50,
                                options: [5, 10, 15, 20],
                            },
                        }}
                    >
                        <Row className="mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Filter />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-start align-items-start"
                            >
                                <PaginationOptions labels={labels} />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Button
                                    className="btn-sm"
                                    variant="outline-primary"
                                    onClick={() => createJob(true)}
                                >
                                   Create Worker
                                </Button>
                            </Col>
                        </Row>
                        <Table striped className="data-table">
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Pagination />
                    </DatatableWrapper>
                ) : (
                    ""
                )}
            </Col>
            <Col lg={2}></Col>

           <div className="App container mt-5" style={{ display: 'none' }}>
            <div id="divToPrint">
                 <AnalysisReportPdf totalAnalysis={totalAnalysis} complatedata = {rowdata}/>
            </div>
          </div>

    </Row>
  )
}
export default AnalysisList