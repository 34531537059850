import React, { useEffect, useState } from "react";
import inventoryApi from "../api/inventoryApi";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { name } from "pubsub-js";
import { Col, Container, Row, Button, Form, FormSelect, Alert } from "react-bootstrap";
import vsmsData from "../constants/VSMS_Score.json";



export default function VocationalPdfFile(props) {
  console.log('pdf data==>', props.completedata)
  console.log('VocationalPdfFile All Data ==>', props.completedata.Physicalhandicap)
  var [checkedBoxes, setCheckedBoxes] = useState([]);
  const [workInfo, setWorkInfo] = useState({});
  const [vocProfile, setVocProfile] = useState({});
  const [checkedValues, setCheckedValues] = useState([]);
  const [trainingrecv, settrainingrecv] = useState([]);
  const [areaguidancereq, setareaguidancereq] = useState([]);

  const handleFieldChange = (e) => {

    if (e.target.name === 'assoccondition') {
      vocProfile.assoccondition = checkedBoxes.join(",");
      const { value, checked } = e.target;
      let updatedCheckedValues;
      if (checked) {
        updatedCheckedValues = [...checkedValues, value];

      } else {
        updatedCheckedValues = checkedValues.filter((item) => item !== value);
      }
      setCheckedValues(updatedCheckedValues);
    }


    if (e.target.name === 'trainingrecv') {
      const { value, checked } = e.target;
      let updatedCheckedValues;

      if (checked) {
        updatedCheckedValues = [...trainingrecv, value];
      } else {
        updatedCheckedValues = trainingrecv.filter((item) => item !== value);
      }

      settrainingrecv(updatedCheckedValues);

    }
    if (e.target.name === 'areaguidancereq') {
      const { value, checked } = e.target;
      let updatedCheckedValues;

      if (checked) {
        updatedCheckedValues = [...areaguidancereq, value];
      } else {
        updatedCheckedValues = areaguidancereq.filter((item) => item !== value);
      }
      setareaguidancereq(updatedCheckedValues);

    }
    setVocProfile({ ...vocProfile, [e.target.name]: e.target.value })
  }

  return (
    <>
      <div>

        <table style={{ width: "100%" }}>
          <tr>
            <td style={{ border: "None", textAlign: "center" }}>
              {/* <h5>{studentInfo["servicecategoryname"]}</h5>
            <label>{studentInfo.location}</label> */}
              <label>RAJASTHAN </label>
            </td>
          </tr>
        </table>
        <hr />

        <table style={{ width: "100%" }}>
          <tr>
            <td style={{ border: "None", textAlign: "center" }}>
              <label>VOCATIONALPDFFILE </label>
            </td>
          </tr>
        </table>
        <hr />
        {/* .......................................Upper Form............................................ */}
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              1.1 Name of the trainee : {
                props.completedata.traineename
              }
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              1.2 Sex / age / Date of Birth:
              {

                moment(props.completedata.sexagedob).format('YYYY-MM-DD')
              }
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", height: "30px" }}>
              1.3 Level of Mental Retardation:{
                props.completedata.mentalretardational
              }
            </td>
            <td style={{ border: "1px solid gray", height: "30px" }}>
              1.4 Marital status of the trainee:{
                props.completedata.marital
              }
            </td>
          </tr>

          <tr>
            <td style={{ border: "1px solid gray", height: "30px" }}>
              Current extension of the child during the program : { }
            </td>
            <td style={{ border: "1px solid gray", height: "30px" }}>
            </td>
          </tr>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          3. SOCIAL ECONOMICS STATUS
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              3.1 Mothly Income of Parents : {
                props.completedata.parentmthincome
              }
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              3.2 Rural / semi urban / urban : {
                props.completedata.rur_semiurb_urb
              }
            </td>
          </tr>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          4. GENERIC SKILLS- REDINESS SKILLS BASED ON GENERIC SKILLS ASSESSMENT CHECKLIST(PAGE NOS:6-10)
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              41. Personal skills : {
                props.completedata.persskills
              }
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4.2 Communication : {
                props.completedata.comm
              }
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4.3 Social behavior : {
                props.completedata.socialbehav
              }
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4.4 Functional academics : {
                props.completedata.funacademics
              }
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4.5 Safety skills : {
                props.completedata.safetyskills
              }
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4.6 Domestic behaviour : {
                props.completedata.domesticbehav
              }
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4.7 Mobility and hand functioning : {
                props.completedata.mobilityhandfunc
              }
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4.8 Occupational : {
                props.completedata.occupational
              }
            </td>
          </tr>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          5. ASSOCIATED CONDITIONS
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              {props.completedata.assoccondition}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
            </td>
          </tr>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          6. TRAINING RECEIVED
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              {props.completedata.trainingrecv}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
            </td>
          </tr>
        </table>

        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          7. DAILY ROUTINES
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              <Form.Group className="mx-3 my-1" controlId="formBasicServiceArea">
                <Form.Select
                  name="Daily routines : "
                >
                </Form.Select>
              </Form.Group>
              {props.completedata.dailyroutines}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>

            </td>
          </tr>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          8. EXPERIENCE IN EMPLOYMENT
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              {
                props.completedata.domesticworks
              }
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              {
                props.completedata.employedwhere
              }
            </td>
          </tr>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          9. POSSIBILITIES OF EMPLOYMENT
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              {
                props.completedata.typeofemp
              }
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              {
                props.completedata.empoppinneighbour
              }
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              {
                props.completedata.selfemppossibility
              }
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              {
                props.completedata.typeofworktraineewant
              }
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              {
                props.completedata.parentexpectsupportson
              }
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              {
                props.completedata.parentsemployment
              }
            </td>
          </tr>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          10. AREAS IN WHICH GUIDANCE REQUIRED
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              {
                props.completedata.areaguidancereq
              }
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>

            </td>
          </tr>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          11. SELECTION OF SUITABLE JOB (BASED ON GENERIC SKILLS ASSESSMENT AND VOCATIONAL PROFILE)
        </Col><br />

        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          <label>11.1 SUITABLE JOB SELECTED - FIRST CHOICE REASONS FOR SELECTION</label>
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

          {Array.isArray(props.completedata.data) && props.completedata.data8.map((item, index) => (
            <tr key={item.id}>
              <th>{item.name}</th>
              <th>{item.q1}</th>
              <td>{item.q2}</td>
              <td>{item.q3}</td>
              <td>{item.q4}</td>
            </tr>
          ))}
        </table>




        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          11.2 SUITABLE JOB SELECTED - FIRST CHOICE REASONS FOR SELECTION
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

          {Array.isArray(props.completedata.data) && props.completedata.data9.map((item, index) => (
            <tr key={item.id}>
              <th>{item.name}</th>
              <th>{item.q1}</th>
              <td>{item.q2}</td>
              <td>{item.q3}</td>
              <td>{item.q4}</td>
            </tr>
          ))}
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          11.3 SUITABLE JOB SELECTED - THIRD CHOICE REASONS FOR SELECTION
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

          {Array.isArray(props.completedata.data) && props.completedata.data10.map((item, index) => (
            <tr key={item.id}>
              <th>{item.name}</th>
              <th>{item.q1}</th>
              <td>{item.q2}</td>
              <td>{item.q3}</td>
              <td>{item.q4}</td>
            </tr>
          ))}
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          12. ANY OTHER REMARKS
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td>
              {props.completedata.anyremark}
            </td>
            <td>
            </td>
          </tr>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          GENERIC SKILLS ASSESSMENT CHECKLIST
        </Col><br />
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          1. PERSONAL
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

          {Array.isArray(props.completedata.data) && props.completedata.data.map((item, index) => (
            <tr key={item.id}>
              <th>{item.name}</th>
              <th>{item.q1}</th>
              <td>{item.q2}</td>
              <td>{item.q3}</td>
              <td>{item.q4}</td>
            </tr>
          ))}
        </table>





        <Col lg={12} style={{ border: "1px solid gray", border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          2.Communication
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

          {Array.isArray(props.completedata.data) && props.completedata.data1.map((item, index) => (
            <tr key={item.id}>
              <th>{item.name}</th>
              <th>{item.q1}</th>
              <td>{item.q2}</td>
              <td>{item.q3}</td>
              <td>{item.q4}</td>
            </tr>
          ))}
        </table>



        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          3. Social Behaviour
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

          {Array.isArray(props.completedata.data) && props.completedata.data2.map((item, index) => (
            <tr key={item.id}>
              <th>{item.name}</th>
              <th>{item.q1}</th>
              <td>{item.q2}</td>
              <td>{item.q3}</td>
              <td>{item.q4}</td>
            </tr>
          ))}
        </table>

        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          4. Functional Academics
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

          {Array.isArray(props.completedata.data) && props.completedata.data3.map((item, index) => (
            <tr key={item.id}>
              <th>{item.name}</th>
              <th>{item.q1}</th>
              <td>{item.q2}</td>
              <td>{item.q3}</td>
              <td>{item.q4}</td>
            </tr>
          ))}
        </table>

        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          5. Safety Skills
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

          {Array.isArray(props.completedata.data) && props.completedata.data4.map((item, index) => (
            <tr key={item.id}>
              <th>{item.name}</th>
              <th>{item.q1}</th>
              <td>{item.q2}</td>
              <td>{item.q3}</td>
              <td>{item.q4}</td>
            </tr>
          ))}
        </table>

        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          6. Domestic Behaviour
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

          {Array.isArray(props.completedata.data) && props.completedata.data5.map((item, index) => (
            <tr key={item.id}>
              <th>{item.name}</th>
              <th>{item.q1}</th>
              <td>{item.q2}</td>
              <td>{item.q3}</td>
              <td>{item.q4}</td>
            </tr>
          ))}
        </table>

        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          7. Mobility and Hand Functionality
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

          {Array.isArray(props.completedata.data) && props.completedata.data6.map((item, index) => (
            <tr key={item.id}>
              <th>{item.name}</th>
              <th>{item.q1}</th>
              <td>{item.q2}</td>
              <td>{item.q3}</td>
              <td>{item.q4}</td>
            </tr>
          ))}
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          8. Occupational Skills
        </Col><br />
        <table style={{ border: "1px solid gray", width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

          {Array.isArray(props.completedata.data) && props.completedata.data7.map((item, index) => (
            <tr key={item.id}>
              <th>{item.name}</th>
              <th>{item.q1}</th>
              <td>{item.q2}</td>
              <td>{item.q3}</td>
              <td>{item.q4}</td>
            </tr>
          ))}
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          Generic Skills Assessment CheckList Score for all sections
        </Col><br />
        <table style={{ fontSize: "15px" }}>
          <tr>
            <td style={{ width: "50%", border: "1px solid gray", height: "30px" }}>Personal </td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.total_main_work ? props.totalVocational.total_main_work * 30 : 0}   </td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.date} </td>
          </tr>
          <tr>
            <td style={{ width: "50%", border: "1px solid gray", height: "30px" }}> Communication  </td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.total_additional ? props.totalVocational.total_additional * 30 : 0}</td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.date} </td>
          </tr>
          <tr>
            <td style={{ width: "50%", border: "1px solid gray", height: "30px" }}> Social Behaviour (Personal) </td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.total_skills ? props.totalVocational.total_skills * 30 : 0}</td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.date} </td>
          </tr>
          <tr>
            <td style={{ width: "50%", border: "1px solid gray", height: "30px" }}>Functional Academics </td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.total_functional ? props.totalVocational.total_functional * 30 : 0}</td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.date} </td>
          </tr>
          <tr>
            <td style={{ width: "50%", border: "1px solid gray", height: "30px" }}>Safety Skills </td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.total_education ? props.totalVocational.total_education * 30 : 0}</td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.date} </td>
          </tr>
          <tr>
            <td style={{ width: "50%", border: "1px solid gray", height: "30px" }}> Domestic Behaviour </td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.total_recreation ? props.totalVocational.total_recreation * 30 : 0}</td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.date} </td>
          </tr>
          <tr>
            <td style={{ width: "50%", border: "1px solid gray", height: "30px" }}> Mobility and Hand Functionality</td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.total_indepndent ? props.totalVocational.total_mobility * 30 : 0}</td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.date} </td>
          </tr>
          <tr>
            <td style={{ width: "50%", border: "1px solid gray", height: "30px" }}> Occupational Skills</td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.total_indepndent ? props.totalVocational.total_occupational * 30 : 0}</td>
            <td style={{ width: "25%", border: "1px solid gray", height: "30px" }}>{props.totalVocational.date} </td>
          </tr>
        </table>
      </div>

    </>



  )
}
