import React, { useEffect, useState } from "react";
import inventoryApi from "../api/inventoryApi";
import moment from "moment";

const PdfCbrCmmWorker = (props) => {
    console.log("props  Jass111", props.name);

    console.log("props.data.name", props.cbrData.name);
    // let temp = props?.data;
    // const [homeCbr, setHomeCbr] = useState({});
    const [cbrWorker, setCbrWorker] = useState({});
    let toggleVar;
    return (
        <div>
            <div
                className="d-flex justify-content-center border"
                style={{ fontSize: "10px" }}
            >
                {/* CBR CMM Report work - */}
                <div className="App container mt-5">
                    <html>
                        <head>
                            <meta charset="UTF-8" />
                        </head>

                        <body>
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td style={{ border: "None", textAlign: "center" }}>
                                        <h6>CBR CMM Report</h6>
                                    </td>
                                </tr>
                            </table>
                            {/* ...........................................Upper Form................................... */}
                            <table
                                style={{
                                    width: "100%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <tr>
                                    <td
                                        style={{
                                            border: "1px solid gray",
                                            width: "50%",
                                            height: "30px",
                                        }}
                                    >
                                        Name of CMM : {props.cbrData.name}
                                    </td>
                                    <td
                                        style={{
                                            border: "1px solid gray",
                                            width: "50%",
                                            height: "30px",
                                        }}
                                    >
                                        Name Of Project : {props.cbrData.projectname}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid gray", height: "30px" }}>
                                        Date : {moment(props.cbrData.cbrdate).format("YYYY-MM-DD")}
                                    </td>
                                    <td style={{ border: "1px solid gray", height: "30px" }}>
                                        CBR Field Area : {props.cbrData.cbrfieldarea}
                                    </td>
                                </tr>
                            </table>
                            {/* ..............................................10 Items Form...................................... */}
                            <table
                                style={{
                                    width: "100%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <tr>
                                    <td>
                                        <b>1. Details of social security schemes</b>
                                    </td>
                                </tr>
                            </table>
                            <table
                                style={{
                                    width: "50%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>1.1 Rail Pass</th>
                                        <th>1.2 Bus Pass</th>
                                        <th>1.3 Disability pension</th>
                                        <th>1.4 Niramaya enrollment</th>
                                        <th>1.5 UID registration</th>
                                        <th>1.6 Astha Cards</th>
                                        <th>1.7 Guardianship</th>
                                        <th>1.8 Aadhar Card</th>
                                        <th>1.9 </th>
                                        <th>1.10 </th>
                                        <th>1.11 </th>
                                        <th>1.12 </th>
                                        <th>1.13 </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Target</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_1t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_2t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_3t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_4t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_5t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_6t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_7t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_8t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_9t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_10t}
                                        </td>

                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_11t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_12t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_13t}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Applied</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_1a}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_2a}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_3a}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_4a}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_5a}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_6a}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_7a}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_8a}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_9a}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_10a}
                                        </td>

                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_11a}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_12a}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_13a}
                                        </td>
                                    </tr>

                                    {/* ===================================== */}

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Received</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_1r}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_2r}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_3r}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_4r}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_5r}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_6r}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_7r}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_8r}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_9r}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_10r}
                                        </td>

                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_11r}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_12r}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._1_13r}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* .......................2............. */}
                            <table
                                style={{
                                    width: "100%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <tr>
                                    <td>
                                        <b>2. Details of assistive devices provided</b>
                                    </td>
                                </tr>
                            </table>
                            <table
                                style={{
                                    width: "50%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>2.1 Wheel chairs</th>
                                        <th>2.2 Calipers</th>
                                        <th>2.3 Specs</th>
                                        <th>2.4 MR kits</th>
                                        <th>2.5 Hearing aids</th>
                                        <th>2.6 Tri cycles</th>
                                        <th>2.7 Neck holders</th>
                                        <th>2.8 Splints</th>
                                        <th>2.9 Braille kits</th>
                                        <th>2.10 Soft ball</th>
                                        <th>2.11 Other</th>
                                        <th>2.12 Other</th>
                                        <th>2.13 Other</th>
                                        <th>2.14 Other</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Target</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_1t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_2t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_3t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_4t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_5t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_6t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_7t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_8t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_9t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_10t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_11t}
                                        </td>

                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_12t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_13t}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_14t}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Distributed</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_1d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_2d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_3d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_4d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_5d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_6d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_7d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_8d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_9d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_10d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_11d}
                                        </td>

                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_12d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_13d}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_14d}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Supported by</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_1s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_2s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_3s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_4s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_5s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_6s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_7s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_8s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_9s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_10s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_11s}
                                        </td>

                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_12s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_13s}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._2_14s}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* .......................3............. */}
                            <table
                                style={{
                                    width: "100%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <tr>
                                    <td>
                                        <b>
                                            3. Details of contact with stakeholders for mainstreaming
                                            and livelihood
                                        </b>
                                    </td>
                                </tr>
                            </table>
                            <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>3.1 Name of Child</th>
                                        <th>3.2 Age</th>
                                        <th>3.3 UID</th>
                                        <th>3.4 Name of stake holder</th>
                                        <th>3.5 Stakeholder Designation / company</th>
                                        <th>3.6 Purpose of visit</th>
                                        <th>3.7 Output</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>1</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_1_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_2_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_3_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_4_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_5_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_6_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_7_1}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>2</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_1_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_2_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_3_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_4_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_5_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_6_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_7_2}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>3</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_1_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_2_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_3_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_4_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_5_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_6_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_7_3}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>4</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_1_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_2_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_3_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_4_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_5_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_6_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_7_4}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>5</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_1_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_2_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_3_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_4_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_5_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_6_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._3_7_5}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* .......................4............. */}
                            <table
                                style={{
                                    width: "100%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <tr>
                                    {/* <td><b>4. Services to the children / Adults</b></td> */}
                                    <td>
                                        <b>4. Details of Intervention with siblings / parents</b>
                                    </td>
                                </tr>
                            </table>
                            <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>4.1 Name of child</th>
                                        <th>4.2 UID</th>
                                        <th>4.3 Age</th>
                                        <th>4.4 Address</th>
                                        <th>4.5 Disability</th>
                                        <th>4.6 Name of siblings / parents</th>
                                        <th>4.7 What the intervention done</th>
                                        <th>4.8 Remarks</th>
                                    </tr>
                                </thead>

                                {/*
                                   
                                         <tr>
                                <td> 1. </td>
                                <td style={{width : '200px'}}> <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_4_1_1"
                                                    onChange={handleChange}
                                                    value={workerData._4_1_1}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col> </td>
                                {/* <td><input type="text" name="_4_1_1"  value={workerData._4_1_1}  className="form-control" onChange={handleChange} /> </td>
                                <td><input type="number" name="_4_2_1"  value={workerData._4_2_1}  className="form-control" onChange={handleChange} /> </td>
                                <td><input type="number" name="_4_3_1"  value={workerData._4_3_1}  className="form-control" onChange={handleChange} /></td>
                                <td><textarea name="_4_4_1"  value={workerData._4_4_1}  className="form-control" onChange={handleChange}></textarea></td>
                                <td><input type="number"  name="_4_5_1"  value={workerData._4_5_1} className="form-control" onChange={handleChange} /></td>
                                <td><input type="text" name="_4_6_1"  value={workerData._4_6_1} className="form-control" onChange={handleChange} /></td>
                                <td><input type="text" name="_4_7_1"  value={workerData._4_7_1}   className="form-control" onChange={handleChange} /></td>
                                <td><input type="number" name="_4_8_1"  value={workerData._4_8_1}  className="form-control" onChange={handleChange} /></td>
                            </tr>
                                    */}
                                <tbody>
                                    <tr>
                                        <td> 1. </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_1_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_2_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_3_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_4_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_5_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_6_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_7_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_8_1}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td> 2. </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_1_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_2_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_3_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_4_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_5_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_6_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_7_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_8_2}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td> 3. </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_1_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_2_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_3_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_4_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_5_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_6_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_7_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_8_3}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td> 4. </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_1_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_2_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_3_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_4_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_5_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_6_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_7_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_8_4}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td> 5. </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_1_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_2_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_3_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_4_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_5_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_6_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_7_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_8_5}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td> 6. </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_1_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_2_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_3_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_4_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_5_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_6_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_7_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_8_6}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td> 7. </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_1_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_2_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_3_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_4_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_5_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_6_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_7_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_8_7}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td> 8. </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_1_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_2_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_3_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_4_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_5_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_6_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_7_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_8_8}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td> 9. </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_1_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_2_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_3_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_4_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_5_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_6_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_7_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_8_9}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td> 10. </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_1_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_2_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_3_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_4_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_5_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_6_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_7_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._4_8_10}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* .......................5............. */}
                            <table
                                style={{
                                    width: "100%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <tr>
                                    <td>
                                        <b>5.Details of interventions done in classrooms</b>
                                    </td>
                                </tr>
                            </table>
                            <table
                                style={{
                                    width: "100%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>5.1 Name of child</th>
                                        <th>5.2 Class</th>
                                        <th>5.3 Name of teacher & school</th>
                                        <th>5.4 Activity done</th>
                                        <th>5.5 Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>1.</th>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_1_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_2_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_3_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_4_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_5_1}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>2.</th>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_1_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_2_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_3_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_4_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_5_2}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>3.</th>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_1_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_2_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_3_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_4_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_5_3}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>4.</th>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_1_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_2_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_3_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_4_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_5_4}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>5.</th>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_1_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_2_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_3_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_4_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_5_5}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>6.</th>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_1_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_2_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_3_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_4_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_5_6}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>7.</th>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_1_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_2_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_3_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_4_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_5_7}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>8.</th>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_1_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_2_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_3_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_4_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_5_8}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>9.</th>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_1_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_2_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_3_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_4_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_5_9}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>10.</th>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_1_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_2_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_3_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_4_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._5_5_10}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* .......................6............. */}
                            <table
                                style={{
                                    width: "100%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <tr>
                                    {/* <td><b>6.Assessment Done</b></td> */}
                                    <td>
                                        <b>6. Details of new IDPG formation</b>
                                    </td>
                                </tr>
                            </table>
                            <table
                                style={{
                                    fontSize: "10px",
                                    width: "100%",
                                    justifyContent: "center",
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th style={{ fontSize: "10px", justifyContent: "center" }}>
                                            6.1 Name of new IDPG
                                        </th>
                                        <th style={{ fontSize: "10px", justifyContent: "center" }}>
                                            6.2 No. of members{" "}
                                        </th>
                                        <th style={{ fontSize: "10px", justifyContent: "center" }}>
                                            6.3 Address
                                        </th>
                                        <th style={{ fontSize: "10px", justifyContent: "center" }}>
                                            6.4 Remark
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>1</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_1_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_2_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_3_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_4_1}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>2</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_1_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_2_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_3_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_4_2}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>3</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_1_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_2_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_3_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_4_3}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>4</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_1_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_2_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_3_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_4_4}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>5</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_1_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_2_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_3_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {props.cbrData._6_4_5}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* ...............7.............. */}
                            <table
                                style={{
                                    width: "100%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <tr>
                                    <td>
                                        <b>7. Details of IDPG meetings</b>
                                    </td>
                                </tr>
                            </table>

                            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                <thead>
                                    <tr>
                                        <th>7.1 Name of IDPG </th>
                                        <th>7.2 Number of members </th>
                                        <th>7.3 Address</th>
                                        <th>7.4 Issues discussed</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_1_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_2_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_3_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_4_1}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_1_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_2_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_3_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_4_2}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_1_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_2_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_3_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_4_3}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_1_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_2_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_3_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_4_4}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_1_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_2_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_3_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._7_4_5}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* ...............8.............. */}

                            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                <tr>

                                    <td><b>8. New identification during the month</b></td>
                                </tr>
                            </table>

                            <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                <thead>
                                    <tr>
                                        <th>8.1 Name</th>
                                        <th>8.2 Father's Name</th>
                                        <th>8.3 Age</th>
                                        <th>8.4 Sex</th>
                                        <th>8.5 DOB</th>
                                        <th>8.6 Disability</th>
                                        <th>8.7 Address</th>
                                        <th>8.8 Date of enrollment</th>
                                        <th>8.9 Contact number</th>
                                        <th>8.10 Aadhar no.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_1_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_2_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_3_1}
                                        </td>

                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_4_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_5_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_6_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_7_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_8_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_9_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_10_1}
                                        </td>
                                    </tr>



                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_1_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_2_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_3_2}
                                        </td>

                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_4_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_5_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_6_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_7_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_8_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_9_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_10_2}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_1_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_2_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_3_3}
                                        </td>

                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_4_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_5_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_6_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_7_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_8_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_9_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_10_3}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_1_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_2_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_3_4}
                                        </td>

                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_4_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_5_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_6_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_7_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_8_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_9_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_10_4}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_1_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_2_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_3_5}
                                        </td>

                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_4_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_5_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_6_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_7_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_8_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_9_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_10_5}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_1_6}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_2_6}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_3_6}
                                        </td>

                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_4_6}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_5_6}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_6_6}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_7_6}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_8_6}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_9_6}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_10_6}
                                        </td>
                                    </tr>


                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_1_7}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_2_7}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_3_7}
                                        </td>

                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_4_7}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_5_7}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_6_7}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_7_7}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_8_7}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_9_7}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_10_7}
                                        </td>
                                    </tr>


                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_1_8}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_2_8}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_3_8}
                                        </td>

                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_4_8}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_5_8}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_6_8}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_7_8}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_8_8}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_9_8}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_10_8}
                                        </td>
                                    </tr>


                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_1_9}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_2_9}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_3_9}
                                        </td>

                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_4_9}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_5_9}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_6_9}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_7_9}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_8_9}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_9_9}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_10_9}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_1_10}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_2_10}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_3_10}
                                        </td>

                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_4_10}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_5_10}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_6_10}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_7_10}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_8_10}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_9_10}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._8_10_10}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* ...............9.............. */}
                            <table
                                style={{
                                    width: "100%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <tr>
                                    <td>
                                        <b>9. Travel details</b>
                                    </td>
                                </tr>
                            </table>
                            <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                <thead>
                                    <tr>
                                        <th>9.1 Total KM traveled in last month</th>
                                        <th>9.2 Total petrol used in last month</th>
                                        <th>9.3 Average / KM</th>
                                        <th>9.4 Total KM traveled in current month</th>
                                        <th>9.5 Total petrol used in current month</th>
                                        <th>9.6 Average / KM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_1_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_2_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_3_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_4_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_5_1}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_6_1}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_1_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_2_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_3_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_4_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_5_2}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_6_2}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_1_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_2_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_3_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_4_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_5_3}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_6_3}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_1_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_2_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_3_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_4_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_5_4}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_6_4}
                                        </td>
                                    </tr>


                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_1_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_2_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_3_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_4_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_5_5}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData._9_6_5}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* ...............10.............. */}
                            <table
                                style={{
                                    width: "100%",
                                    fontSize: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <tr>
                                    <td>
                                        <b>
                                            10.Donation/Community contribution raised during the month
                                        </b>
                                    </td>
                                </tr>
                            </table>

                            <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{ width: "100%", border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData.donation}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            {/* ...............11.............. */}
                            <table
                                style={{
                                    fontSize: "10px",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                <thead>
                                    <th>
                                        11. Any Problem/Challenge occured during the month
                                    </th>
                                </thead>
                            </table>
                            <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{ width: "100%", border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData.monthchallenge}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            {/* ...............12.............. */}
                            <table
                                style={{
                                    fontSize: "10px",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                <thead>
                                    <th>12..Strategy used to resolve the problem/Challenge</th>
                                </thead>
                            </table>

                            <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{ width: "100%", border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData.strategy_resolve}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            {/* ...............13.............. */}
                            <table
                                style={{
                                    fontSize: "10px",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                <thead>
                                    <th>13.Achievement During the month</th>
                                </thead>
                            </table>
                            <table style={{ fontSize: "10px", justifyContent: "center" }}>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{ width: "100%", border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData.achievement}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>



                            <table
                                style={{
                                    fontSize: "10px",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                <thead>
                                    <th>Signature of the Incharge</th>
                                    <th>Signature of CMM</th>
                                  

                                </thead>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData.signofincharge}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData.signofcbrworkercmm}
                                        </td>
                                       
                                     
                                    </tr>
                                </tbody>
                            </table>


                            <table
                                style={{
                                    fontSize: "10px",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                <thead>
                                 
                                    <th>Name of the Incharge</th>
                                    <th>Date</th>

                                </thead>
                                <tbody>
                                    <tr>
                                       
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData.inchargename}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData.date}
                                        </td>
                                       
                                    </tr>
                                </tbody>
                            </table>


                            <table
                                style={{
                                    fontSize: "10px",
                                    justifyContent: "center",
                                    width: "100%",
                                }}
                            >
                                <thead>
                                 
                                
                                    <th>Report Should be Submitted by the last day of every month</th>
                                    <th>Contact No.</th>

                                </thead>
                                <tbody>
                                    <tr>
                                       
                                      
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData.note}
                                        </td>
                                        <td
                                            style={{ border: "1px solid gray", height: "30px" }}
                                        >
                                            {props.cbrData.contactno}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </body>
                    </html>
                </div>
            </div>
        </div>
    );
};

export default PdfCbrCmmWorker;