import React, { useEffect, useState } from "react";
import inventoryApi from "../api/inventoryApi";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { name } from "pubsub-js";
import vsmsData from "../constants/VSMS_Score.json";



export default function IndiaAutismPdfFile(props) {

  console.log('show india pdf data==>', props.totalIndiaAutism)
  const [workInfo, setWorkInfo] = useState({});
  return (
    <div>

 

      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h6>INDIAN SCALE FOR ASSESSMENT OF AUTISM </h6>
          </td>
        </tr>
      </table>
      <hr />


      <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

        <tr>
          <td style={{ border: "1px solid gray", height: "30px" }}>
            Name of child : {props.totalIndiaAutism.childname}
          </td>
          <td style={{ border: "1px solid gray", height: "30px" }}>
            Date : {moment(props.totalIndiaAutism.autismdate).format('YYYY-MM-DD')}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px" }}>
            Gender : {props.totalIndiaAutism.gender}
          </td>
          <td style={{ border: "1px solid gray", height: "30px" }}>
            D.O.B : {moment(props.totalIndiaAutism.dob).format('DD-MM-YYYY')}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px" }}>
            Age : {props.totalIndiaAutism.age}
          </td>
          <td style={{ border: "1px solid gray", height: "30px" }}>
            Examiner : {props.totalIndiaAutism.examiner}
          </td>
        </tr>
      </table>



      <table style={{ fontSize: "15px" }}>
        <tr>
          <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>Items</td>
          <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>Score 1</td>
          <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>Score 2</td>
          <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>Score 3</td>
          <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>Score 4</td>
          <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>Score 5</td>
        </tr>

        {props.totalIndiaAutism.data ? (
          props.totalIndiaAutism.data.map((value, index) => (
            <tr key={index}>
              <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{value.name}</td>
              <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{value.q1.toString()}</td>
              <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{value.q2.toString()}</td>
              <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{value.q3.toString()}</td>
              <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{value.q4.toString()}</td>
              <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{value.q5.toString()}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6" style={{ textAlign: "center" }}>No data available</td>
          </tr>
        )}
      </table>
      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None"}}>
            <h6>Classification</h6>
          </td>
        </tr>
      </table>


      <table style={{ width: "100%", border: "1px solid gray",}}>
        <tr>
          <td style={{ border: "None"}}>
            <h6>No Autism &lt; 70 :  {props.totalCount1}</h6>
          </td>
        </tr>
      </table> 


      <table style={{ width: "100%",border: "1px solid gray", }}>
        <tr>
          <td style={{ border: "None" }}>
            <h6>Mild Autism 70 to 106 :  {props.totalCount2}</h6>
          </td>
        </tr>
      </table>

      <table style={{ width: "100%",border: "1px solid gray", }}>
        <tr>
          <td style={{ border: "None"}}>
            <h6>Moderate Autism 107 to 153 :  {props.totalCount3}</h6>
          </td>
        </tr>
      </table>

      <table style={{ width: "100%",border: "1px solid gray", }}>
        <tr>
          <td style={{ border: "None" }}>
            <h6>Severe Autism &gt; 153  : {props.totalCount4}</h6>
          </td>
        </tr>
      </table>
    </div>
  )
}