import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Form, FormSelect } from "react-bootstrap";
import moment from "moment";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { useNavigate } from "react-router-dom";
import PubSub from "pubsub-js";
import InputGroup from 'react-bootstrap/InputGroup';
import * as constants from "../constants/CONSTANT";
import AnalysisReportPdf from "./AnalysisReportPdf";


import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";

const AnalysisEdit = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [locationData, setLocationData] = useState(location.state ? location.state : {});
    console.log('locationData===>', locationData)
    let studentid = locationData.studen
    const [jobBasedData, setJobBasedData] = useState({});
    const [fieldsData, setfieldsData] = useState({});
    console.log('jobBasedData', jobBasedData)
    const [validated, setValidated] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');



    const [vaps, setvaps] = useState(false);
    
    useEffect(() => {
      if(locationData.id){
        setvaps(true)
      }
    }, []);
  
    const [dataArray, setDataArray] = useState([
        { id: 1, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 2, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 3, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 4, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 5, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 6, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 7, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 8, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 9, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 10, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 11, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 12, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 13, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 14, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 15, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
    ]);

    const [Section3dataArray, setSection3dataArray] = useState([
        { id: 1, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 2, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 3, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 4, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 5, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },

    ]);
    const [Section4dataArray, setSection4dataArray] = useState([
        { id: 1, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 2, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 3, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 4, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 5, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },

    ]);

    // Functional Academic 

    const [SectionFunctionaldataArray, setSectionFunctionaldataArray] = useState([
        { id: 1, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 2, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 3, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 4, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 5, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 6, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 7, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 8, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 9, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 10, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 11, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 12, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 13, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 14, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },
        { id: 15, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "functional" },

    ]);



    const [SectionSexEducationArray, setSectionSexEducationArray] = useState([
        { id: 1, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "sexeducation" },
        { id: 2, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "sexeducation" },
        { id: 3, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "sexeducation" },
        { id: 4, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "sexeducation" },
        { id: 5, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "sexeducation" },

    ]);




    const [SectionRecreationalArray, setSectionRecreationalArray] = useState([
        { id: 1, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "recreational" },
        { id: 2, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "recreational" },
        { id: 3, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "recreational" },
        { id: 4, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "recreational" },
        { id: 5, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "recreational" },
    ]);



    const [SectionIndependentLivingArray, setSectionIndependentLivingArray] = useState([

        { id: 1, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "independentliving" },
        { id: 2, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "independentliving" },
        { id: 3, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "independentliving" },
        { id: 4, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "independentliving" },
        { id: 5, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "independentliving" },
        { id: 6, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "independentliving" },
        { id: 7, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "independentliving" },
        { id: 8, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "independentliving" },
        { id: 9, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "independentliving" },
        { id: 10, name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "independentliving" },
    ]);



    useEffect(() => {

        if (locationData.id) {
            fetchAllJobAnaly();
        }

    }, []);

    const fetchAllJobAnaly = async () => {
        let result = await inventoryApi.fetchJobById(locationData.id);
        console.log('result', result);
        setfieldsData(result)
        setJobBasedData(result);
        // [dataArray,Section3dataArray, Section4dataArray, SectionFunctionaldataArray, SectionSexEducationArray, SectionRecreationalArray, SectionIndependentLivingArray] };
        setDataArray(result.data[0])
        setSection3dataArray(result.data[1]);
        setSection4dataArray(result.data[2]);
        setSectionFunctionaldataArray(result.data[3]);
        setSectionSexEducationArray(result.data[4]);
        setSectionRecreationalArray(result.data[5]);
        setSectionIndependentLivingArray(result.data[6]);


    }

    console.log('jobbaseed data', jobBasedData)

    const handleChangeFields = (e) => {
        console.log('if call', e.target.name, e.target.value)
        if (jobBasedData.id) {
            console.log('if edit call')
            setJobBasedData({ ...jobBasedData, [e.target.name]: e.target.value });
        }
        setfieldsData({ ...fieldsData, [e.target.name]: e.target.value });
        //setJobBasedData({ ...jobBasedData, [e.target.name]: e.target.value});
    };






    const handleChange = (event, id, property) => {
        let countq1 = 0;
        let countq2 = 0;
        let countq3 = 0;
        let countq4 = 0;
        const updatedDataArray = dataArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });


        console.log('updatedDataArray', updatedDataArray)
        setDataArray(updatedDataArray);

    }

    console.log('dataArray', dataArray);

    const handleChangeSection3 = (event, id, property) => {

        const updatedDataArraySection3 = Section3dataArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });

        setSection3dataArray(updatedDataArraySection3);


    }




    const handleChangeSection4 = (event, id, property) => {

        const updatedDataArraySection4 = Section4dataArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });

        setSection4dataArray(updatedDataArraySection4);
    }


    // Section functional 


    const handleChangeSectionFunctional = (event, id, property) => {

        const updatedDataArraySectionFunctional = SectionFunctionaldataArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });

        setSectionFunctionaldataArray(updatedDataArraySectionFunctional);

    }



    // SEX EDUCATION 


    const handleChangeSectionSexEducation = (event, id, property) => {

        const updatedDataArraySectionSexEducation = SectionSexEducationArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });

        setSectionSexEducationArray(updatedDataArraySectionSexEducation);
    }



    // RECREATIONAL


    const handleChangeSectionRecreational = (event, id, property) => {

        const updatedDataArraySectionRecreational = SectionRecreationalArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });

        setSectionRecreationalArray(updatedDataArraySectionRecreational);
    }

    // INDEPENDENT LIVING



    const handleChangeIndependentLive = (event, id, property) => {

        const updatedDataArrayIndependentLive = SectionIndependentLivingArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });

        setSectionIndependentLivingArray(updatedDataArrayIndependentLive);

    }

    const handleCancel = () => {
        navigate(`/students`);
    };


    const handleSubmit =()=>{
        let completeData = {};
        completeData = { ...fieldsData, jobanalysisdata: [dataArray, Section3dataArray, Section4dataArray, SectionFunctionaldataArray, SectionSexEducationArray, SectionRecreationalArray, SectionIndependentLivingArray] };
        console.log('completeData', completeData)
    }


    // const handleSubmit = async (e) => {
    //     //console.log('section2',dataArray)
    //     console.log('section3', Section3dataArray)
    //     console.log('section4', Section4dataArray)
    //     e.preventDefault();
    //     let completeData = {};
    //     completeData = { ...fieldsData, jobanalysisdata: [dataArray, Section3dataArray, Section4dataArray, SectionFunctionaldataArray, SectionSexEducationArray, SectionRecreationalArray, SectionIndependentLivingArray] };
    //     console.log('completeData', completeData)
    //     let result = {};
    //     let completeDataId = completeData.id
    //     if (completeData.id) {
    //         delete completeData.id
    //         console.log('if call edit', completeData);
    //         console.log('completeData',completeData)
    //         console.log('completeData',completeData)
            
    //         result = await inventoryApi.saveJob(completeDataId, completeData);
    //         console.log('result', result)

    //         PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record Update Successfully' });
    //         navigate(`/students/${studentid}`);
    //     } else {

    //         console.log('if creaate call', completeData)
            
    //         completeData.parentidstudent = locationData
    //         const result = await inventoryApi.createJob(completeData);
    //         if (result) {
    //             PubSub.publish("RECORD_SAVED_TOAST", {
    //                 title: "Record Saved",
    //                 message: "Record create successfully",
    //             });
    //             navigate(`/students/`, { state: locationData });
    //         }
    //         if (result.errors) {
    //             setShowError(true);
    //             setErrorMessage(result.errors);
    //             return;
    //         }
    //     }
    // };


    const pdfShow =()=>{
        const pdfTable = document.getElementById("divToPrint");
        //console.log("pdf table", pdfTable);
        var html = htmlToPdfmake(pdfTable.innerHTML, {
            tableAutoSize: true,
        });
        console.log('html', html)
        const documentDefinition = {
            content: html, defaultStyle: {
                font: 'arialunicodems1'
            }
        };
        pdfMake.fonts = {
            arialunicodems1: {
                normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
                bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            },
      
      
            // download default Roboto font from cdnjs.com
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            }
        }
        //console.log(pdfMake.vfs)
      
          pdfMake.createPdf(documentDefinition).open();
       
          
      }



    return (
        <>
            <Container id="marbt">
                <Row>
                    <Col lg={12} className="mx-3">
                        <Form
                            className="mt-3"
                            onSubmit={handleSubmit}
                            noValidate
                            validated={validated}
                        >
                            <Row className="view-form-header align-items-center">
                                <Col lg={6}>JOB ANALYSIS FORMAT </Col>
                                <Col lg={6} className="d-flex justify-content-end">
                                {vaps &&(
                                <Button className="btn-sm mx-2" type="submit" onClick={pdfShow}> PDF </Button>
                                )}

                                    <Button className="btn-sm mx-2" type="submit" onClick={handleSubmit}> Save </Button>
                                    {/* <Button className="btn-sm" variant="danger" onClick={handleCancel} > Cancel </Button> */}

                                </Col>
                            </Row>
                        </Form>
                    </Col>

                </Row>

                <Col lg={12} className="section-header my-3 mx-2">
                    {" "}
                    1. Job Selected
                </Col>


                <Row className="my-3">
                    <Col lg={6}>
                        <Form.Group className="mx-3" controlId="formBasicDate">
                            <Form.Label className="form-view-label">Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="jobanalysisdate"
                                value={moment(jobBasedData?.jobanalysisdate).format("YYYY-MM-DD")}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6}>
                        <Form.Group className="mx-3 my-1" controlId="formBasicname">
                            <Form.Label className="form-view-label">Job Title</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="_1_1j"
                                placeholder="Job Title"
                                value={jobBasedData._1_1j}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-3 my-1" controlId="formBasicname">
                            <Form.Label className="form-view-label">Job Site</Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_1_2j"
                                placeholder="Job Site"
                                value={jobBasedData._1_2j}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-3 my-1" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Job trainee
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_1_3j"
                                placeholder="Job Trainee"
                                value={jobBasedData._1_3j}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>

                        <Form.Group className="mx-3 my-1" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Job programmer
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_1_4j"
                                placeholder="Job Programmer"
                                value={jobBasedData._1_4j}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                </Row>


                <Col lg={12} className="section-header my-3 mx-2">
                    2. Main work areas
                </Col>


                <Col lg={12}>
                    <Col lg={12} className="my-3 mx-3 row">
                        <Col lg={4}></Col>
                        <Col lg={2}>Q1</Col>
                        <Col lg={2}>Q2</Col>
                        <Col lg={2}>Q3</Col>
                        <Col lg={2}>Q4</Col>
                    </Col>
                    {dataArray.map(item => (
                        <div key={item.id} >
                            <Row lg={12}>
                                <Col className="my-3 ">{item.id}</Col>
                                <Col lg={3} style={{ width: '30%' }}>

                                    <input
                                        className="form-control my-3"
                                        value={item.name}
                                        onChange={event => handleChange(event, item.id, 'name')}
                                    />
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q1}
                                        onChange={event => handleChange(event, item.id, 'q1')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>

                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q2}
                                        onChange={event => handleChange(event, item.id, 'q2')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q3}
                                        onChange={event => handleChange(event, item.id, 'q3')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q4}
                                        onChange={event => handleChange(event, item.id, 'q4')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>

                            </Row>
                        </div>
                    ))}
                    <Row lg={12}>
                        <Col lg={4}></Col>
                        <Col lg={2}>
                            {dataArray.reduce((accumulator, obj) => (
                                obj.q1 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>
                        <Col lg={2}>
                            {dataArray.reduce((accumulator, obj) => (
                                obj.q2 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>

                        <Col lg={2}>{dataArray.reduce((accumulator, obj) => (
                            obj.q3 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                        <Col lg={2}>{dataArray.reduce((accumulator, obj) => (
                            obj.q4 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                    </Row>



                </Col>






                {/* Section 3 START  */}


                <Col lg={12} className="section-header my-3 mx-2">
                    3. Additional duties/tasks
                </Col>
                <Col lg={12}>
                    {Section3dataArray.map(item => (
                        <div key={item.id} >
                            <Row lg={12}>
                                <Col className="my-3 ">{item.id}</Col>
                                <Col lg={3} style={{ width: '30%' }}>

                                    <input
                                        className="form-control my-3"
                                        value={item.name}
                                        onChange={event => handleChangeSection3(event, item.id, 'name')}
                                    />
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q1}
                                        onChange={event => handleChangeSection3(event, item.id, 'q1')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>

                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q2}
                                        onChange={event => handleChangeSection3(event, item.id, 'q2')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q3}
                                        onChange={event => handleChangeSection3(event, item.id, 'q3')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q4}
                                        onChange={event => handleChangeSection3(event, item.id, 'q4')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>

                            </Row>
                        </div>
                    ))}
                    <Row lg={12} >
                        <Col lg={4}></Col>
                        <Col lg={2}>
                            {Section3dataArray.reduce((accumulator, obj) => (
                                obj.q1 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>
                        <Col lg={2}>
                            {Section3dataArray.reduce((accumulator, obj) => (
                                obj.q2 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>

                        <Col lg={2}>{Section3dataArray.reduce((accumulator, obj) => (
                            obj.q3 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                        <Col lg={2}>{Section3dataArray.reduce((accumulator, obj) => (
                            obj.q4 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                    </Row>



                </Col>



                {/* SECTION 4  */}


                <Col lg={12} className="section-header my-3 mx-2">
                    4. Work Related Skills  (Personal)
                </Col>
                <Col lg={12}>
                    {Section4dataArray.map(item => (
                        <div key={item.id} >
                            <Row lg={12}>
                                <Col className="my-3 ">{item.id}</Col>
                                <Col lg={3} style={{ width: '30%' }}>

                                    <input
                                        className="form-control my-3"
                                        value={item.name}
                                        onChange={event => handleChangeSection4(event, item.id, 'name')}
                                    />
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q1}
                                        onChange={event => handleChangeSection4(event, item.id, 'q1')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>

                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q2}
                                        onChange={event => handleChangeSection4(event, item.id, 'q2')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q3}
                                        onChange={event => handleChangeSection4(event, item.id, 'q3')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q4}
                                        onChange={event => handleChangeSection4(event, item.id, 'q4')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>

                            </Row>
                        </div>
                    ))}
                    <Row lg={12}>
                        <Col lg={4}></Col>
                        <Col lg={2}>
                            {Section4dataArray.reduce((accumulator, obj) => (
                                obj.q1 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>
                        <Col lg={2}>
                            {Section4dataArray.reduce((accumulator, obj) => (
                                obj.q2 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>

                        <Col lg={2}>{Section4dataArray.reduce((accumulator, obj) => (
                            obj.q3 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                        <Col lg={2}>{Section4dataArray.reduce((accumulator, obj) => (
                            obj.q4 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                    </Row>

                </Col>

                {/* FUNCTIONAL ACADEMINC */}
                <Col lg={12} className="section-header my-3 mx-2">
                    Functional Academics
                </Col>




                <Col lg={12}>
                    {SectionFunctionaldataArray.map(item => (
                        <div key={item.id} >
                            <Row lg={12}>
                                <Col className="my-3 ">{item.id}</Col>
                                <Col lg={3} style={{ width: '30%' }}>

                                    <input
                                        className="form-control my-3"
                                        value={item.name}
                                        onChange={event => handleChangeSectionFunctional(event, item.id, 'name')}
                                    />
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q1}
                                        onChange={event => handleChangeSectionFunctional(event, item.id, 'q1')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>

                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q2}
                                        onChange={event => handleChangeSectionFunctional(event, item.id, 'q2')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q3}
                                        onChange={event => handleChangeSectionFunctional(event, item.id, 'q3')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q4}
                                        onChange={event => handleChangeSectionFunctional(event, item.id, 'q4')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>

                            </Row>
                        </div>
                    ))}
                </Col>



                {/* SEX EDUCATION */}

                <Col lg={12} className="section-header my-3 mx-2">
                    Sex Education
                </Col>



                <Col lg={12}>
                    {SectionSexEducationArray.map(item => (
                        <div key={item.id} >
                            <Row lg={12}>
                                <Col className="my-3 ">{item.id}</Col>
                                <Col lg={3} style={{ width: '30%' }}>

                                    <input
                                        className="form-control my-3"
                                        value={item.name}
                                        onChange={event => handleChangeSectionSexEducation(event, item.id, 'name')}
                                    />
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q1}
                                        onChange={event => handleChangeSectionSexEducation(event, item.id, 'q1')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>

                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q2}
                                        onChange={event => handleChangeSectionSexEducation(event, item.id, 'q2')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q3}
                                        onChange={event => handleChangeSectionSexEducation(event, item.id, 'q3')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q4}
                                        onChange={event => handleChangeSectionSexEducation(event, item.id, 'q4')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>

                            </Row>
                        </div>
                    ))}
                </Col>



                {/* recreational */}

                <Col lg={12} className="section-header my-3 mx-2">
                    Recreational
                </Col>

                <Col lg={12}>
                    {SectionRecreationalArray.map(item => (
                        <div key={item.id} >
                            <Row lg={12}>
                                <Col className="my-3 ">{item.id}</Col>
                                <Col lg={3} style={{ width: '30%' }}>

                                    <input
                                        className="form-control my-3"
                                        value={item.name}
                                        onChange={event => handleChangeSectionRecreational(event, item.id, 'name')}
                                    />
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q1}
                                        onChange={event => handleChangeSectionRecreational(event, item.id, 'q1')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>

                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q2}
                                        onChange={event => handleChangeSectionRecreational(event, item.id, 'q2')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q3}
                                        onChange={event => handleChangeSectionRecreational(event, item.id, 'q3')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item?.q4}
                                        onChange={event => handleChangeSectionRecreational(event, item.id, 'q4')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>

                            </Row>
                        </div>
                    ))}
                </Col>



                {/* INDEPENDENT LIVING */}


                <Col lg={12} className="section-header my-3 mx-2">
                    Independent Living
                </Col>
                <Col lg={12}>
                    {SectionIndependentLivingArray.map(item => (
                        <div key={item.id} >
                            <Row lg={12}>
                                <Col className="my-3 ">{item.id}</Col>
                                <Col lg={3} style={{ width: '30%' }}>

                                    <input
                                        className="form-control my-3"
                                        value={item.name}
                                        onChange={event => handleChangeIndependentLive(event, item.id, 'name')}
                                    />
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q1}
                                        onChange={event => handleChangeIndependentLive(event, item.id, 'q1')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>

                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q2}
                                        onChange={event => handleChangeIndependentLive(event, item.id, 'q2')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q3}
                                        onChange={event => handleChangeIndependentLive(event, item.id, 'q3')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q4}
                                        onChange={event => handleChangeIndependentLive(event, item.id, 'q4')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="modelling">Modelling</option>
                                        <option value="verbal promoting">Verbal promoting</option>
                                        <option value="physical promoting">Physical promoting</option>
                                        <option value="totally depended">Totally depended</option>

                                    </select>
                                </Col>

                            </Row>
                        </div>
                    ))}
                    <Row lg={12}>



                        <Col lg={4}></Col>
                        <Col lg={2}>
                            {SectionIndependentLivingArray.reduce((accumulator, obj) => (
                                obj.q1 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>
                        <Col lg={2}>
                            {SectionIndependentLivingArray.reduce((accumulator, obj) => (
                                obj.q2 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>

                        <Col lg={2}>{SectionIndependentLivingArray.reduce((accumulator, obj) => (
                            obj.q3 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                        <Col lg={2}>{SectionIndependentLivingArray.reduce((accumulator, obj) => (
                            obj.q4 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                    </Row>
                </Col>


                <Col lg={12} className="section-header my-3 mx-2">
                    5. Work behavior
                </Col>
                <Col lg={12} className="section-header my-3 mx-2">
                    1. General Information
                </Col>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                1. Name of the Special Employee"

                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_5_1_1_g"
                                value={jobBasedData._5_1_1_g}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                2 Age/Sex
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_5_1_2_g"
                                value={jobBasedData._5_1_2_g}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                3.Level of Mental Retardation


                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_5_1_3_g"
                                value={jobBasedData._5_1_3_g}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4 Job site
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_5_1_4_g"
                                value={jobBasedData._5_1_4_g}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                5. Job Identified
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_5_1_5_g"
                                // placeholder="Enter Time"
                                value={jobBasedData._5_1_5_g}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-3 mx-2">
                    2. Report (Based on the checklist)
                </Col>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                1. Physical appearance
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_5_2_1_r"
                                value={jobBasedData._5_2_1_r}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                2 Personal interaction
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_5_2_2_r"
                                // placeholder="Enter Child's Age"
                                value={jobBasedData._5_2_2_r}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                3 Regularity and punctuality


                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_5_2_3_r"
                                // placeholder="Enter C.B.R. Worker"
                                value={jobBasedData._5_2_3_r}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4. Communication and Social manners
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_5_2_4_r"
                                // placeholder="Enter Time"
                                value={jobBasedData._5_2_4_r}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                5. Quality and quantity aspects
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_5_2_5_r"
                                // placeholder="Enter Time"
                                value={jobBasedData._5_2_5_r}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {/* <Row>
        <Col lg={12} className="section-header my-3 mx-2">
                {" "}
                Comments
              </Col>         
              {/* value={jobBasedData._5_3_c} */}

                {/* <Form.Control as="textarea" name="_5_3_c" value={jobBasedData._5_3_c}  onChange={handleChangeFields} aria-label="With textarea" />
   
      </Row>    */}

                <Col lg={12} className="section-header my-3 mx-2">
                    Comments
                </Col>
                <Col lg={12}>
                    <Form.Group className="mx-3 my-1" controlId="contribution">
                        <Form.Control
                            as="textarea"
                            name="_5_3_c"
                            value={jobBasedData._5_3_c}
                            onChange={handleChangeFields}
                        />
                    </Form.Group>
                </Col>



                <Col lg={12} className="section-header my-3 mx-2">
                    6. Job requirements
                </Col>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                1. Equipments and materials

                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_6_1_1"
                                // placeholder="Enter Child's Name"
                                value={jobBasedData._6_1_1}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                2 Cost of production
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_6_1_2"
                                // placeholder="Enter Child's Age"
                                value={jobBasedData._6_1_2}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                3. Profit expected


                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_6_1_3"
                                // placeholder="Enter C.B.R. Worker"
                                value={jobBasedData._6_1_3}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4. Adaptive devices
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_6_1_4"
                                // placeholder="Enter Time"
                                value={jobBasedData._6_1_4}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {/* 777777777777777777777777777777777777777777 */}

                <Col lg={12} className="section-header my-3 mx-2">
                    7. Job Training Procedures
                </Col>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                1. Training Strategies


                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_7_1_1"
                                // placeholder="Enter Child's Name"
                                value={jobBasedData._7_1_1}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                2. Training in simulated setting
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_7_1_2"
                                // placeholder="Enter Child's Age"
                                value={jobBasedData._7_1_2}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                3. On the Job training


                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_7_1_3"
                                // placeholder="Enter C.B.R. Worker"
                                value={jobBasedData._7_1_3}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4. Reinforcement/wages/salary
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_7_1_4"
                                // placeholder="Enter Time"
                                value={jobBasedData._7_1_4}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={12} className="section-header my-3 mx-2">
                        7.5   Job placement
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                a Open/ Supported/group/sheltered
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_7_1_5_1_1a"
                                // placeholder="Enter Child's Name"
                                value={jobBasedData._7_1_5_1_1a}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                b.Self/home based
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_7_1_5_1_1b"
                                // placeholder="Enter Child's Age"
                                value={jobBasedData._7_1_5_1_1b}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-3 mx-2">
                    8. Trainer's responsibilities
                </Col>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                1. Employer contacts


                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_8_1_1"
                                // placeholder="Enter Child's Name"
                                value={jobBasedData._8_1_1}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                2. Co-workers awareness
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_8_1_2"
                                // placeholder="Enter Child's Age"
                                value={jobBasedData._8_1_2}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                3. Ongoing assessment


                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_8_1_3"
                                // placeholder="Enter C.B.R. Worker"
                                value={jobBasedData._8_1_3}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId=
                            "formBasicname">
                            <Form.Label className="form-view-label">
                                4. Continued support and fading out
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_8_1_4"
                                // placeholder="Enter Time"
                                value={jobBasedData._8_1_4}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-3 mx-2">
                    9. Parents Cooperation
                </Col>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                1. Checking regularity and punctuality


                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="_9_1_1"
                                // placeholder="Enter Child's Name"
                                value={jobBasedData._9_1_1}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                2. Providing healthy food / lunch
                            </Form.Label>
                            <Form.Control

                                type="text"
                                name="_9_1_2"

                                value={jobBasedData._9_1_2}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                3. Implementation of programme


                            </Form.Label>
                            <Form.Control

                                type="text"
                                name="_9_1_3"
                                value={jobBasedData._9_1_3}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4. Discussion with employer
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="_9_1_4"
                                value={jobBasedData._9_1_4}
                                onChange={handleChangeFields}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <div className="App container mt-5" style={{ display: 'none' }}>
            <div id="divToPrint">
                 <AnalysisReportPdf complatedata = {locationData}/>
            </div>
          </div>
            </Container>
        </>
    );
};
export default AnalysisEdit;
