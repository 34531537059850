import React, { useEffect, useState } from "react";
import inventoryApi from "../api/inventoryApi";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { name } from "pubsub-js";
import vsmsData from "../constants/VSMS_Score.json";



export default function CbrCmhPdf(props) {

    console.log('Cbr data==>', props.totalCbrCrm)
    console.log('_6_1@@=>', props.totalCbrCrm._6_1)
    const [workInfo, setWorkInfo] = useState({});
    return (
        <div>

            {/* <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None", textAlign: "center" }}>
                        <h5>Rajasthan Mahila Kalyan Mandal</h5>
           
                        <h6>Rajasthan</h6>
                    </td>
                </tr>
            </table> */}
            {/* <hr /> */}

            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None", textAlign: "center" }}>
                        <h6>CMH Worker Report</h6>
                    </td>
                </tr>
            </table>
            <hr />


            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>

                <tr>
                    <td style={{ border: "1px solid gray", height: "30px", fontSize: "15px" }}>
                        Name of CMH : {props.totalCbrCrm.name}
                    </td>
                    <td style={{ border: "1px solid gray", height: "30px", fontSize: "15px" }}>
                        Name of Project : {props.totalCbrCrm.projectname}
                    </td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid gray", height: "30px", fontSize: "15px" }}>
                        Date :  {moment(props.totalCbrCrm.cmhdate).format('YYYY-MM-DD')}
                    </td>
                    <td style={{ border: "1px solid gray", height: "30px", fontSize: "15px" }}>
                        CBR Field Area : {props.totalCbrCrm.cmhfieldarea}
                    </td>
                </tr>
            </table>



            <table style={{ fontSize: "15px" }}>
                <tr>
                    <td style={{ width: "10%", border: "1px solid gray", height: "20px" }}>S.No</td>
                    <td style={{ width: "30%", border: "1px solid gray", height: "20px" }}>Activity</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>Target</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>Planned</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>Achieved</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>Remark</td>
                </tr>

                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>1</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>Number of field visit</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark1}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>2</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>Number of application submitted/ reffered for disability certificate</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark2}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>2.1</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}></td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved2_1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned2_1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target2_1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark2_1}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>2.2</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}></td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved2_2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned2_2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target2_2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark2_2}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>2.3</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}></td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved2_3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned2_3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target2_3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark2_3}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>2.4</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}></td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved2_4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned2_4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target2_4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark2_4}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>2.5</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}></td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved2_5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned2_5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target2_5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark2_5}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>3</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>Number of disability certificates received</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark3}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>3.1</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}></td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved3_1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned3_1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target3_1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark3_1}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>3.2</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}></td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved3_2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned3_2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target3_2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark3_2}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>3.3</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}></td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved3_3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned3_3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target3_3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark3_3}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>3.4</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}></td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved3_4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned3_4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target3_4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark3_4}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>3.5</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}></td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved3_5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned3_5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target3_5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark3_5}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>4</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>Number of stakeholder contacted</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark4}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>4.1</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>Other clinic visits</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved4_1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned4_1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target4_1}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark4_1}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>4.2</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>PRIs/MMC</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved4_2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned4_2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target4_2}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark4_2}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>4.3</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>ANM</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved4_3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned4_3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target4_3}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark4_3}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>4.4</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>Parnts</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved4_4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned4_4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target4_4}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark4_4}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>4.5</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>Doctors</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved4_5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned4_5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target4_5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark4_5}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>4.6</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>Government officer</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved4_6}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned4_6}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target4_6}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark4_6}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>4.7</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>Others</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved4_7}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned4_7}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target4_7}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark4_7}</td>
                </tr>
                <tr>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>5</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>Number of follow up calls/ contact</td>
                    <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.achieved5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.planned5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.target5}</td>
                    <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm.remark5}</td>
                </tr>

                {/* <tr>
            <td colSpan="6" style={{ textAlign: "center" }}>No data available</td>
          </tr> */}

            </table>
            <div>
                6. Detail of service at NICU
                <table style={{ fontSize: "15px" }}>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            6.1<br></br> Parent counselling
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            6.2 <br></br> Identification of high risk babies
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            6.3 <br></br> Refer to EIC
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            6.4 <br></br> Refer to CBR
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._6_1}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._6_2}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._6_3}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._6_4}
                        </td>

                    </tr>
                </table>
            </div>

            <div>
                7. Details of service provided during OPD
                <table style={{ fontSize: "15px" }}>
                    <tr>
                        <td style={{ width: "11%", border: "1px solid gray" }}>
                            7.1<br></br> Assist in assessments
                        </td>
                        <td style={{ width: "11%", border: "1px solid gray" }}>
                            7.2 <br></br> Different type of test
                        </td>
                        <td style={{ width: "11%", border: "1px solid gray" }}>
                            7.3 <br></br> Support in medicine
                        </td>
                        <td style={{ width: "11%", border: "1px solid gray" }}>
                            7.4 <br></br> Counselling
                        </td>
                        <td style={{ width: "11%", border: "1px solid gray" }}>
                            7.5<br></br> Medical check up
                        </td>
                        <td style={{ width: "11%", border: "1px solid gray" }}>
                            7.6 <br></br>No. of children identified
                        </td>
                        <td style={{ width: "11%", border: "1px solid gray" }}>
                            7.7 <br></br> Refer to daycare centre
                        </td>
                        <td style={{ width: "11%", border: "1px solid gray" }}>
                            7.8 <br></br> Refer to CBR program
                        </td>
                        <td style={{ width: "11%", border: "1px solid gray" }}>
                            7.9 <br></br> Remark
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._7_1}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._7_2}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._3__7_33m}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._7_4}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._7_5}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._7_6}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._7_7}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._7_8}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._7_9}
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                8. New Identification during month
                <table style={{ fontSize: "15px" }}>
                    <tr>
                        <td style={{ width: "12%", border: "1px solid gray", height: "20px" }}>
                            S. No.
                        </td>
                        <td style={{ width: "12%", border: "1px solid gray", height: "20px" }}>
                            8.1<br></br> Name of children
                        </td>
                        <td style={{ width: "12%" }}>
                            8.2 <br></br> Name of parents
                        </td>
                        <td style={{ width: "12%", border: "1px solid gray", height: "20px" }}>
                            8.3 <br></br> Age
                        </td>
                        <td style={{ width: "12%", border: "1px solid gray", height: "20px" }}>
                            8.4 <br></br> Address
                        </td>
                        <td style={{ width: "12%", border: "1px solid gray", height: "20px" }}>
                            8.5<br></br> Contact no.
                            the month{" "}
                        </td>
                        <td style={{ width: "12%", border: "1px solid gray", height: "20px" }}>
                            8.6 <br></br> Refer to
                        </td>
                        <td style={{ width: "12%", border: "1px solid gray", height: "20px" }}>
                            8.7 <br></br> Remarks
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_1_1}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_2_1}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_3_1}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_4_1}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_5_1}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_6_1}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_7_1}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_1_2}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_2_2}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_3_2}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_4_2}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_5_2}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_6_2}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_7_2}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_1_3}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_2_3}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_3_3}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_4_3}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_5_3}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_6_3}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_7_3}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_1_4}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_2_4}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_3_4}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_4_4}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_5_4}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_6_4}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_7_4}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_1_5}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_2_5}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_3_5}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_4_5}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_5_5}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_6_5}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_7_5}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_1_6}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_2_6}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_3_6}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_4_6}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_5_6}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_6_6}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_7_6}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_1_7}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_2_7}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_3_7}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_4_7}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_5_7}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_6_7}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_7_7}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_1_8}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_2_8}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_3_8}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_4_8}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_5_8}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_6_8}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_7_8}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        {props.totalCbrCrm._8_1_9}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_2_9}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_3_9}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_4_9}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_5_9}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_6_9}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_7_9}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                        {props.totalCbrCrm._8_1_10}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_2_10}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_3_10}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_4_10}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_5_10}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_6_10}
                        </td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>
                            {props.totalCbrCrm._8_7_10}
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                9. Travel Detail
                <table style={{ fontSize: "15px" }}>
                    <tr>
                        <td style={{ width: "22%", border: "1px solid gray", height: "20px" }}>9.1 <br></br>Total KM travelled in the last month</td>
                        <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>9.2 <br></br> Total Petrol used in last month</td>
                        <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>9.3<br></br> Average KM{" "}</td>
                        <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>9.4<br></br>Total KM travelled in current month</td>
                        <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>9.5 <br></br> Total Petrol used in current month</td>
                        <td style={{ width: "15%", border: "1px solid gray", height: "20px" }}>9.6 <br></br> Average/KM</td>
                    </tr>
                    <tr>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm._9_1}</td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm._9_2}</td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm._9_3}</td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm._9_4}</td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm._9_5}</td>
                        <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}>{props.totalCbrCrm._9_6}</td>
                    </tr>
                </table>
                <div>
                    <div>
                        <table>
                            <tr>
                                <td style={{ width: "73%", border: "1px solid gray", height: "20px" }}>
                                    10. Donation/Community contributions raised during the month
                                </td>
                                <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}> 
                                {props.totalCbrCrm.donation10}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div>
                <table>
                        <tr>
                                <td style={{ width: "73%", border: "1px solid gray", height: "20px" }}>
                                    11. Any Problem/Challenge occurred during the month
                                </td>
                                <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}> 
                                {props.totalCbrCrm.anyproblem11}
                                </td>
                            </tr>
                        </table>
                </div>
                <div>
                    <table>
                        <tr>
                                <td style={{ width: "73%", border: "1px solid gray", height: "20px" }}>
                                12. Strategy used to resolve the problem/Challenge
                                </td>
                                <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}> 
                                {props.totalCbrCrm.strategy12}
                                </td>
                            </tr>
                        </table>
                </div>
                <div>
                    <table>
                        <tr>
                                <td style={{ width: "73%", border: "1px solid gray", height: "20px" }}>
                                13. Achievement During the month
                                </td>
                                <td style={{ width: "25%", border: "1px solid gray", height: "20px" }}> 
                                {props.totalCbrCrm.Achievement13}
                                </td>
                            </tr>
                        </table>
                </div>

                <div>
                    <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                        <tr>
                            <td style={{ border: "1px solid gray", height: "30px", fontSize: "15px" }}>
                                14. Signature of the Incharge: {props.totalCbrCrm.signofincharge}
                            </td>
                            <td style={{ border: "1px solid gray", height: "30px", fontSize: "15px" }}>
                                Signature of CMH : {props.totalCbrCrm.signofcmhworker}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: "1px solid gray", height: "30px", fontSize: "15px" }}>
                                15. Name of the Incharge:  {props.totalCbrCrm.inchargename}
                            </td>
                            <td style={{ border: "1px solid gray", height: "30px", fontSize: "15px" }}>
                                Date : {moment(props.totalCbrCrm.date).format('YYYY-MM-DD')}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: "1px solid gray", height: "30px", fontSize: "15px" }}>
                                16. Report Should be Submitted: {props.totalCbrCrm.note}
                            </td>
                            <td style={{ border: "1px solid gray", height: "30px", fontSize: "15px" }}>
                                Contact No: {props.totalCbrCrm.contactno}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}