import React, { useEffect, useState } from "react";
import inventoryApi from "../api/inventoryApi";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { name } from "pubsub-js";
import vsmsData from "../constants/VSMS_Score.json";

const CaseHistory = (props) => {
  let student = props.data;

  let ageDataValue = 0
  const [agedata, setagedata] = useState();
  const [daYear, setdaYear] = useState();
  const [damonth, setdamonth] = useState();
  const [vsmsdatamark, setvsmsdatamark] = useState();
  const [sqdata, setsqdata] = useState(0);

  useEffect(() => {
    let registrationAge = null;
    console.log('student===>', student)
    const registrationDate = moment(student.Currentage);
    let dobDate = moment(student.dob);
    const yearsDifference = registrationDate.diff(dobDate, 'years');
    console.log('yearsDifference', yearsDifference)
    dobDate.add(yearsDifference, 'years');
    const monthsDifference = registrationDate.diff(dobDate, 'months');
    console.log('monthsDifference', monthsDifference)
    let ageInDecimal = yearsDifference + (monthsDifference / 12);
    console.log('ageinDecimal', ageInDecimal);
    ageInDecimal = parseFloat(ageInDecimal.toFixed(1));
    console.log('Age difference in decimal:', ageInDecimal);
    ageDataValue = ageInDecimal
    setagedata()
    //console.log('ageDataValue',ageDataValue)

  }, []);



  //console.log("enter", student);
  const location = useLocation();
  const [studentInfo, setStudentInfo] = useState({});
  const [ParentInfo, setParentInfo] = useState([]);
  const [personalInfo, setPersonalInfo] = useState([]);
  const [studentAssessment, setStudentAssessment] = useState([]);
  const [dstInfo, setDstInfo] = useState([]);
  const [vsmsInfo, setVSMSInfo] = useState([]);
  const [gdtInfo, setGDTInfo] = useState([]);
  const [score, setscore] = useState(0);
  const [ageData, setAge] = useState();
  const [dstScore, setDSTscore] = useState(0);
  const [body, setBody] = useState([]);
  const [damarks, setDamarks] = useState([]);
  useEffect(() => {
    fetchStudentInfo();
    fetchChildPhoto();
    fetchParentInfo();
    fetchPersonalInfo();
    fetchAssessmentInfo();
    fetchDSTInfo();
    fetchVSMSInfo();
    gdtFetch();
  }, []);
  const fetchStudentInfo = async () => {
    if (
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      //console.log(location);
      //console.log("data", location.pathname.split("/")[2]);
      student.id = location.pathname.split("/")[2];
    }
    let result = await inventoryApi.fetchStudentById(student.id);
    console.log("result in related info student:", result);
    // result.dob = moment(dob).format('YYYY-MM-DD')
    const formattedDate = moment(result.dob).format("YYYY-MM-DD");
    //console.log(formattedDate);
    result.dob = formattedDate;

    setStudentInfo(result);
  };

  const fetchParentInfo = async () => {
    let result = await inventoryApi.fetchStudentParent(student);
    //console.log("parent info ======> ", result);
    setParentInfo(result);
  };

  const fetchPersonalInfo = async () => {
    let result = await inventoryApi.fetchStudentPersonalInfo(student);
    //console.log("personal info", result);
    setPersonalInfo(result);
  };

  const fetchAssessmentInfo = async () => {
    let result = await inventoryApi.fetchStudentAssessment(student);
    ////console.log('call edit button for fetch result', result)
    setStudentAssessment(result);
  };

  const fetchDSTInfo = async () => {
    const result = await inventoryApi.fetchCaseHistoryResults(
      student.id,
      "DST"
    );
    console.log('result dst', result)
    countOfDstMarks(result);
    setDstInfo(result);
  };

  const countOfDstMarks = async (result) => {

    let calculatedScore = 0;
    let ageInMonths = ageDataValue * 12;
    //console.log("age", ageInMonths);
    //console.log("result", result);
    let historyQuestions = await inventoryApi.fetchCaseHistoryQuestions("DST");
    //console.log("historyQuestions", historyQuestions);
    const updatedResult = result.map((result) => {
      // Find the corresponding history question
      const question = historyQuestions.find(
        (q) => q.id === result.casehistorytestid
      );

      // If a question is found, extract the sectionheading and add it to the result object
      if (question) {
        return {
          ...result,
          sectionheading: question.sectionheading,
        };
      }
      return result;
    });

    //console.log("updatedResult", updatedResult);
    const groupedData = updatedResult.reduce((acc, curr) => {
      const section = curr.sectionheading;
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(curr);
      return acc;
    }, {});
    //console.log("groupedData", groupedData);

    // Next, transform the grouped data into the desired format
    const array = Object.keys(groupedData).map((section) => {
      const questions = groupedData[section].map((q) => ({
        casehistorytestid: q.casehistorytestid,
        studentid: q.studentid,
        marks: q.marks,
        category: q.category,
        question: q.question,
        days: q.days,
      }));
      return { sectionheading: section, questions };
    });
    //console.log("array", array);
    //console.log("if dst call");
    let totalMarks = 0;
    let questionArrayLength = 0;
    let sectionMarks3Month = 0;
    let sectionMarks6Month = 0;
    let sectionMarks9Month = 0;
    let sectionMarks1Y = 0;
    let sectionMarks1_2y = 0;
    let sectionMarks2y = 0;
    let sectionMarks3y = 0;
    let sectionMarks4y = 0;
    let sectionMarks5y = 0;
    let sectionMarks6y = 0;
    let sectionMarks7y = 0;
    let sectionMarks8y = 0;
    let sectionMarks9y = 0;
    let sectionMarks10y = 0;
    let sectionMarks11y = 0;
    let sectionMarks12y = 0;
    let sectionMarks13y = 0;
    let sectionMarks15y = 0;
    let previousMonth = 0;

    array.forEach((section) => {
      if (section.sectionheading === "3 Months") {
        questionArrayLength = section.questions.length;
        section.questions.forEach((question) => {
          //console.log(question.marks);
          sectionMarks3Month += parseFloat(question.marks) || 0;
        });
        //console.log("sectionMarks3Month", sectionMarks3Month);
        totalMarks =
          (1 / questionArrayLength) *
          sectionMarks3Month *
          (parseFloat(section.sectionheading.replace(" Months", "")) || 0);
        previousMonth = section.sectionheading.replace(" Months", "");
        //console.log("totalMarks 3 month", totalMarks);
      } else if (section.sectionheading === "6 Months") {
        questionArrayLength = section.questions.length;
        let previousMonthLess =
          section.sectionheading.replace(" Months", "") - previousMonth;
        //console.log("previousMonthLess", previousMonthLess);
        section.questions.forEach((question) => {
          sectionMarks6Month += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks6Month *
          (parseFloat(previousMonthLess) || 0);
        //console.log("6 Months totalMarks", totalMarks);
        previousMonth = section.sectionheading.replace(" Months", "");
      } else if (section.sectionheading === "9 Months") {
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        let previousMonthLess =
          section.sectionheading.replace(" Months", "") - previousMonth;
        //console.log("previousMonthLess 9", previousMonthLess);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks9Month += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks9Month *
          (parseFloat(previousMonthLess) || 0);
        //console.log("9 Months totalMarks", totalMarks);

        previousMonth = section.sectionheading.replace(" Months", "");
      } else if (section.sectionheading === "1 Years") {
        //console.log("1 year call");
        //console.log(section.sectionheading.replace(" Years", ""));
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks1Y += parseFloat(question.marks) || 0;
        });
        //console.log("sectionMarks1Y", sectionMarks1Y, "totalMarks", totalMarks);
        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks1Y *
          (parseFloat(previousMonthLess) || 0);
        //console.log("1 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "1 - 1/2 Years") {
        let convertYearToMonth = 18;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month 1/2", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          sectionMarks1_2y += parseFloat(question.marks) || 0;
        });
        // //console.log(
        //   "sectionMarks1_2y",
        //   sectionMarks1_2y,
        //   "totalMarks",
        //   totalMarks
        // );
        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks1_2y *
          (parseFloat(previousMonthLess) || 0);
        //console.log("1 - 1/2 Years  totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "2 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks2y += parseFloat(question.marks) || 0;
        });
        //console.log("sectionMarks2y", sectionMarks2y, "totalMarks", totalMarks);
        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks2y *
          (parseFloat(previousMonthLess) || 0);
        //console.log("2 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "3 Years") {
        //console.log(section.sectionheading.replace(" Months", ""));
        questionArrayLength = section.questions.length;
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks3y += parseFloat(question.marks) || 0;
        });
        //console.log("sectionMarks3y", sectionMarks3y, "totalMarks", totalMarks);
        totalMarks +=
          (1 / questionArrayLength) *
          sectionMarks3y *
          (parseFloat(previousMonthLess) || 0);
        //console.log("3 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "4 Years") {
        questionArrayLength = section.questions.length;
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks4y += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          parseFloat(sectionMarks4y) *
          previousMonthLess || 0;
        //console.log("4 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "5 Years") {
        questionArrayLength = section.questions.length;
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks5y += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          parseFloat(sectionMarks5y) *
          previousMonthLess || 0;
        //console.log("5 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "6 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks6y += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          parseFloat(sectionMarks6y) *
          previousMonthLess || 0;
        //console.log("6 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "7 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks7y += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          parseFloat(sectionMarks7y) *
          previousMonthLess || 0;
        //console.log("7 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "8 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks8y += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          parseFloat(sectionMarks8y) *
          previousMonthLess || 0;
        //console.log("8 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "9 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks9y += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          parseFloat(sectionMarks9y) *
          previousMonthLess || 0;
        //console.log("9 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "10 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks10y += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          parseFloat(sectionMarks10y) *
          previousMonthLess || 0;
        //console.log("10 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "11 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);

        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks11y += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          parseFloat(sectionMarks11y) *
          previousMonthLess || 0;
        //console.log("11 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "12 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks12y += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          parseFloat(sectionMarks12y) *
          previousMonthLess || 0;
        //console.log("12 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "13 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks13y += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          parseFloat(sectionMarks13y) *
          previousMonthLess || 0;
        //console.log("13 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      } else if (section.sectionheading === "15 Years") {
        let convertYearToMonth =
          section.sectionheading.replace(" Years", "") * 12;
        let previousMonthLess = convertYearToMonth - previousMonth;
        //console.log("month less", previousMonthLess, convertYearToMonth);
        questionArrayLength = section.questions.length;
        //console.log("questionArrayLength", questionArrayLength);
        section.questions.forEach((question) => {
          //console.log("parseFloat(question.marks)");
          sectionMarks15y += parseFloat(question.marks) || 0;
        });

        totalMarks +=
          (1 / questionArrayLength) *
          parseFloat(sectionMarks15y) *
          previousMonthLess || 0;
        //console.log("15 Year totalMarks", totalMarks);
        previousMonth = convertYearToMonth;
      }
    });
    //console.log("Total marks for All section : " + totalMarks.toFixed(2));
    setDamarks(totalMarks.toFixed(2))
    console.log('DA ===> ', totalMarks)
    const years = Math.floor(totalMarks);
    const months = Math.round((totalMarks - years) * 12);
    console.log('Years', years)
    console.log('months', months)
    setdamonth(months)
    setdaYear(years)
    let ageinMonths = student.childregistrationage * 12
    let DstScoreMarks = totalMarks.toFixed(2) * (100 / ageinMonths);
    //console.log("DstScoreMarks", DstScoreMarks);
    if (isNaN(DstScoreMarks)) {
      //console.log("if dst score nan");
      setDSTscore(0);
    }
    setDSTscore(DstScoreMarks.toFixed(2));
  };

  const fetchVSMSInfo = async () => {
    const result = await inventoryApi.fetchCaseHistoryResults(
      student.id,
      "VSMS"
    );
    console.log('result vsms ===>', result)
    countOfMarks(result);
    setVSMSInfo(result);
  };

  const countOfMarks = (fetchVSMSInfo) => {
    //console.log("vsms info", vsmsInfo);
    //console.log("student dob", student.dob);
    let age = null;
    let calculatedScore = 0;

    const dob = moment(student.dob, "YYYY-MM-DD");
    age = moment().diff(dob, "years");
    //console.log("age", age);
    setAge(age);
    age = moment().diff(dob, "years");
    console.log('currentage', student.childregistrationage)
    let ageInMonths = student.childregistrationage * 12;
    console.log("age", ageInMonths);
    let totalMarks = 0;
    const sumOfMarks = fetchVSMSInfo.filter((value) => {
      if (value.marks) totalMarks += parseFloat(value.marks);
    });
    //console.log("if call");
    if (typeof totalMarks === "number" && totalMarks !== 0) {
      let vsmsDataMarks = null;
      {
        vsmsData?.map((item) => {
          if (item.marks === totalMarks) {
            vsmsDataMarks = item.months;
          }
        });
      }
      setvsmsdatamark(vsmsDataMarks)

      if (vsmsDataMarks) {
        //console.log("VSMS IF CALL", vsmsDataMarks,  ageInMonths);
        console.log('data for vsms', vsmsDataMarks, ageInMonths, totalMarks)
        calculatedScore = (parseFloat(vsmsDataMarks) / ageInMonths) * 100;
        setsqdata(calculatedScore)

        setscore(totalMarks);
      }
    } else {
      setscore(0);
    }
    //console.log("score ===", score);
    if (isNaN(score) || !isFinite(score)) {
      //console.log("is nan call");
      setscore(0);
    }
  };

  const gdtFetch = async () => {
    const qusResult = await inventoryApi.fetchCaseHistoryQuestions("GDT");
    const marksResult = await inventoryApi.fetchCaseHistoryResults(
      student.id,
      "GDT"
    );
    //console.log("marksResult", marksResult);
    if (qusResult) {
      const currentUrl = window.location.href;
      //console.log(currentUrl);
      for (const qusObj of qusResult) {
        const path = `/shapes_images/${qusObj.question}.png`;
        try {
          const response = await fetch(path);
          if (response.ok) {
            const blob = await response.blob();
            const dataURL = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(blob);
            });
            qusObj.path = dataURL.split(",")[1];
          } else {
            //console.log(`Failed to fetch image: ${response.status}`);
          }
        } catch (error) {
          //console.log(`Error loading image: ${error}`);
        }
        qusObj.marks = null;
        for (const result of marksResult) {
          if (result.casehistorytestid === qusObj.id) {
            qusObj.marks = result.marks;
          }
        }
      }
      setGDTInfo(qusResult);
    }
  };

  const fetchChildPhoto = async () => {
    try {
      const files = await inventoryApi.fetchStudentPhoto(student.id);
      //console.log("files:", files);
      if (files && files.length > 0) {
        const arr = [];

        const file = files[0];
        if (
          file.filetype === "jpg" ||
          file.filetype === "jpeg" ||
          file.filetype === "png"
        ) {
          const result = await inventoryApi.downloadFiles(file.id);

          const base64Data = await getDataUrl(result);
          //console.log("download filed ", base64Data);
          file["body"] = base64Data;
        } else {
          file["body"] = "";
        }
        arr.push(file);

        setBody([...arr]);
      } else {
        setBody([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDataUrl = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div
      className="d-flex justify-content-center border"
      style={{ fontSize: "10px" }}
    >
      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h5>{studentInfo["servicecategoryname"]}</h5>
            <h6>{studentInfo.location}</h6>
            <h6>Rajasthan </h6>
          </td>
        </tr>
      </table>
      <hr />

      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h6>Case History Report </h6>
          </td>
        </tr>
      </table>
      <hr />

      {/* Student Detail  */}
      <table>


        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>Student Information</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>

      {/* <table width="100%">
        <tr style={{ border: "none" }}>
          <td>
            <table>
              <thead>
                {header.map((item) => (
                  <tr key={item.prop} style={{ border: "none" }}>
                    <td>{item.title} </td>
                    {studentInfo ? (
                      <td> - {studentInfo[item.prop]}</td>
                    ) : (
                      <td>-</td>
                    )}
                  </tr>
                ))}
              </thead>
            </table>
          </td>
     

          
          <td style={{ border: "None", textAlign: "center" }}>
            {body.map((item, index) => (
              <div>
                {item.body && (
                  <img
                    src={item.body}
                    style={{ border: "5px solid #555", width: "200px" }}
                  />
                )}
              </div>
            ))}
          </td> 

        </tr>
      </table>*/}

      <table style={{ fontSize: "15px" }}>
        <tr>
          <td
            style={{ width: "50%", height: "30px", border: "1px solid gray" }}
          >
            Name : {studentInfo.name}{" "}
          </td>
          <td style={{ width: "50%", border: "1px solid gray" }} rowspan="4">
            {body.map((item, index) => (
              <div>
                {item.body && (
                  <img
                    src={item.body}
                    style={{
                      border: "5px solid #555",
                      width: "150px",
                      height: "150px",
                      marginLeft: 100,
                    }}
                  />
                )}
              </div>
            ))}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>
            Father Name : {studentInfo.fathername}{" "}
          </td>
        </tr>


        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>DOB : {moment(studentInfo.dob).format("YYYY-MM-DD")}</td>

        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>
            Child Registration Date :{" "}
            {moment(studentInfo.Childregistrationdate).format("YYYY-MM-DD")}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Child Registration Age : {studentInfo.childregistrationage}</td>


          <td style={{ border: "1px solid gray", padding: "10px" }}>Phone : {studentInfo.phone}</td>

        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Aadhar Card : {studentInfo.adharcard}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Problem : {studentInfo.problem}</td>
        </tr>
        {/* <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Street : {studentInfo.street}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>City : {studentInfo.city}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>State : {studentInfo.state}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Country : {studentInfo.country}</td>
        </tr> */}
        {/* <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Pincode : {studentInfo.pincode}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Phone : {studentInfo.phone}</td>
        </tr> */}
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Gender : {studentInfo.gender}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Religion : {studentInfo.religion}</td>

        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Category : {studentInfo.category}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Mother Tongue : {studentInfo.mothertongue}</td>

        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Education : {studentInfo.education}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>School Name : {studentInfo.schoolname}</td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>REFERRED BY : {studentInfo.refferby}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Service Category : {studentInfo.servicearea}</td>

        </tr>

        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Name of Center : {studentInfo.servicecategoryname}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Remark : {studentInfo.remark}</td>

        </tr>

        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>User type : {studentInfo.usertype}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>User Role : {studentInfo.userrole}</td>

        </tr>


        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>

            Refer To Staff Name :
            {studentInfo?.refertostaff?.map((item, index) => (
              <span key={item.value}>
                {item.label}
                {index !== studentInfo.refertostaff.length - 1 ? ", " : ""}
              </span>
            ))}
          </td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Status : {studentInfo.status}</td>


        </tr>

        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Current Street : {studentInfo.street}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Current Landmark : {studentInfo.landmark}</td>

        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Current Ward : {studentInfo.ward}</td>

          <td style={{ border: "1px solid gray", padding: "10px" }}>Current City : {studentInfo.city}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Current state : {studentInfo.state}</td>

          <td style={{ border: "1px solid gray", padding: "10px" }}>Current Country : {studentInfo.country}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Current Pincode : {studentInfo.pincode}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}></td>
        </tr>



        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Permanent Street : {studentInfo.permstreet}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Permanent Landmark : {studentInfo.permlandmark}</td>

        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Permanent Ward : {studentInfo.permward}</td>

          <td style={{ border: "1px solid gray", padding: "10px" }}>Permanent City : {studentInfo.permcity}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Permanent state : {studentInfo.permstate}</td>

          <td style={{ border: "1px solid gray", padding: "10px" }}>Permanent Country : {studentInfo.permcountry}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Permanent Pincode : {studentInfo.permpincode}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}></td>
        </tr>
        <tr>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Dropout Date : {moment(studentInfo.dropoutdate).format("YYYY-MM-DD")}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Dropout Reason : {studentInfo.dropoutreason}</td>

        </tr>

        {/* <tr>


          <td style={{ border: "1px solid gray", padding: "10px" }}>Service Category Name : {studentInfo.servicecategoryname}</td>
        </tr> */}

      </table>

      {/* <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h5 style={{ fontSize: "15px" }}> Parent Information</h5>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table> */}

      <table style={{ fontSize: "15px", borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}>
              <h4 style={{ fontSize: "17px", margin: "0" }}>
                <b>Parent Information</b>
              </h4>
            </th>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: "1px solid gray", padding: "10px" }}>
              Father Name: {ParentInfo.fathername}
            </td>
            <td style={{ border: "1px solid gray", padding: "10px" }}>
              Father Education: {ParentInfo.fathereducation}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", padding: "10px" }}>
              Father Occupation: {ParentInfo.fatheroccupation}
            </td>
            <td style={{ border: "1px solid gray", padding: "10px" }}>
              Father Income: {ParentInfo.fatherincome}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", padding: "10px" }}>
              Mother Name: {ParentInfo.mothername}
            </td>
            <td style={{ border: "1px solid gray", padding: "10px" }}>
              Mother Income: {ParentInfo.motherincome}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", padding: "10px" }}>
              Mother Education: {ParentInfo.mothereducation}
            </td>
            <td style={{ border: "1px solid gray", padding: "10px" }}>
              Family Income: {ParentInfo.fatherincome}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", padding: "10px" }}>
              Social Economic Status: {ParentInfo.socialeconomicstatus}
            </td>
            <td style={{ border: "1px solid gray", padding: "10px" }}>
              BPL Holder: {ParentInfo.bplcardholder}
            </td>
          </tr>
        </tbody>
      </table>


      {/* <table>
        <thead>

          {parentHeaders.map((item, index) =>

            <tr key={item.prop} style={{ border: "none" }}>
              <td>{item.title}</td>
            </tr>
          )}

        </thead>
      </table> */}

      {/* <table style={{ fontSize: "15px" }}>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}><b>Personal Information</b> </h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table> */}

      <table style={{ fontSize: "15px", borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}>
              <h4 style={{ fontSize: "17px", margin: "0" }}>
                <b>Personal Information</b>
              </h4>
            </th>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}></th>
          </tr>
        </thead>
        <tr>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Complaints: {personalInfo.complations}
          </td>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Responded by: {personalInfo.respondedby}
          </td>
        </tr>


        <thead>
          <tr>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}>
              <h4 style={{ fontSize: "17px", margin: "0" }}>
                <b> 1. Prenatal</b>
              </h4>
            </th>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}></th>
          </tr>
        </thead>
        <tr>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Mother Age at Conception: {personalInfo.motherageconception}
          </td>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Father Age At Conception: {personalInfo.fatherageconception}
          </td>
        </tr>
        <tr>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Mother Nutrition During Pregnancy: {personalInfo.mothernutritionpregnancy}
          </td>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Fetal Movement: {personalInfo.fetalmovement}
          </td>

        </tr>
        <tr>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Medicine During Pregnancy: {personalInfo.medicinepregnancy}
          </td>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Mother Antenatal Checkup: {personalInfo.motherantentalcheckup}
          </td>
        </tr>
        <tr>

          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Remark: {personalInfo.babysufferingremark}
          </td>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}></td>
        </tr>


        <thead>
          <tr>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}>
              <h4 style={{ fontSize: "17px", margin: "0" }}>
                <b> 2. Natal</b>
              </h4>
            </th>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}></th>
          </tr>
        </thead>
        <tr>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Type of Delivery: {personalInfo.deliverytype}
          </td>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Term of Delivery: {personalInfo.termofdelivery}
          </td>
        </tr>
        <tr>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Birth Cry: {personalInfo.birthcry}
          </td>
          <td style={{ width: "50%", border: "1px solid gray", height: "30px", padding: "10px" }}>
            Birth Weight: {personalInfo.birthweight}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            First Milk Given: {personalInfo.firstmilkgiven}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Delivery At: {personalInfo.deliveryat}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Cord Around Neck: {personalInfo.cordaroundneck}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Baby Color: {personalInfo.bobycolor}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Breach Delivery: {personalInfo.deliverybreach}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Labor Duration : {personalInfo.labourduration}
          </td>
        </tr>
        <thead>
          <tr>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}>
              <h4 style={{ fontSize: "17px", margin: "0" }}>
                <b>3. Post Natal</b>
              </h4>
            </th>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}></th>
          </tr>
        </thead>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Baby was Active: {personalInfo.babyactive}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            The baby was immunized as per the schedule: {personalInfo.babyimmunize}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            The baby was suffering from medical issues: {personalInfo.babysufferedproblem}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            If Yes Remark: {personalInfo.babysufferingremark}
          </td>
        </tr>

        <thead>
          <tr>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}>
              <h4 style={{ fontSize: "17px", margin: "0" }}>
                <b>4. Other Details</b>
              </h4>
            </th>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}></th>
          </tr>
        </thead>

        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Medical History: {personalInfo.medicalhistory}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Family History: {personalInfo.familyhistory}
          </td>
        </tr>
        <thead>
          <tr>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}>
              <h4 style={{ fontSize: "17px", margin: "0" }}>
                <b>5. Developmental History</b>
              </h4>
            </th>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}></th>
          </tr>
        </thead>
        <tr>

          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Neck Holding: {personalInfo.neckholding}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Neck Holding Delayed Note : {personalInfo.nechholdingdelayed}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Mother Recognition: {personalInfo.motherrecongnition}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Mother Recognition Delayed Note : {personalInfo.motherrecogdelayed}
          </td>

        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Smile: {personalInfo.smile}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Smile Delayed Note : {personalInfo.sittingdelayed}
          </td>


        </tr>

        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Sitting: {personalInfo.sitting}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Sitting Delayed Note : {personalInfo.sittingdelayed}
          </td>

        </tr>

        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Standing: {personalInfo.standing}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Standing Delayed Note : {personalInfo.standingdelayed}
          </td>
        </tr>


        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Walking Delayed Note : {personalInfo.walkingdelayed}
          </td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Babbling: {personalInfo.babbling}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Babbling Delayed Note : {personalInfo.babblingdelayed}
          </td>
        </tr>



        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            First Word Phrase: {personalInfo.firstwardphase}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            First Word Delayed Note : {personalInfo.firstwarddelayed}
          </td>

        </tr>
        <tr>

          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Two Phrase: {personalInfo.twowardphase}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Two Word Phrase Delayed Note : {personalInfo.twowarddelayed}
          </td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Toilet Control: {personalInfo.toiletcontrol}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Toilet Control Delayed Note : {personalInfo.toiletcontroldelayed}
          </td>
        </tr>
        <tr>
          <thead>
            <tr>
              <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}>
                <h4 style={{ fontSize: "17px", margin: "0" }}>
                  <b>6. Speech and Language Development History</b>
                </h4>
              </th>
              <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}></th>
            </tr>
          </thead>


          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Imitate Sounds: {personalInfo.imitatesounds}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>

          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Vowel Consonant Sounds: {personalInfo.vowelconsonantsounds}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Exchange Sounds: {personalInfo.exchangesounds}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Pay Attention to Name: {personalInfo.payattentionname}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Play Peekaboo: {personalInfo.playspeekboo}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Repetitive Babbling: {personalInfo.repetitivebabbling}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Gestures Words: {personalInfo.gestureswords}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Vocal Communication: {personalInfo.vocalcommunication}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Understand Names: {personalInfo.understandnames}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Respond Firmly (No): {personalInfo.respondfirmno}
          </td>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px" }}>
            Mimic Actions: {personalInfo.mimicactions}
          </td>
        </tr>



      </table>

      <table>
        <thead>
          <tr>
            <th style={{ border: "1px solid gray", height: "30px", padding: "10px", width: '100%' }}>
              7.School History:
            </th>

          </tr>
        </thead>
      </table>
      <table>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px", width: '100%' }}>
            {personalInfo.schoolhistory}
          </td>
        </tr>
      </table>
      <table>
        <thead>
          <tr>
            <th style={{ border: "1px solid gray", height: "30px", padding: "10px", width: '100%' }}>
              8.Physical Deformity and Sensory Impairment:
            </th>

          </tr>
         
        </thead>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px", width: '100%' }}>
            {personalInfo.physicalimpairment}
          </td>
        </tr>
      </table>

      <table>
        <thead>
          <tr>
            <th style={{ border: "1px solid gray", height: "30px", padding: "10px", width: '100%' }}>
              9. Behavioral Problems:
            </th>
           
          </tr>
        </thead>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", height: "30px", padding: "10px", width: '100%' }}>
          {personalInfo.behavioralproblems}
          </td>
        </tr>
      </table>

      <table style={{ fontSize: "15px" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}>
              <h4 style={{ fontSize: "17px", margin: "0" }}>
                <b>10. Assessment Information</b>
              </h4>
            </th>
            <th style={{ border: "1px solid gray", padding: "10px", textAlign: "left", width: "50%" }}></th>
          </tr>
        </thead>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Motor : {studentAssessment.motor} </td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>
            Self Help : {studentAssessment.selfhelp}
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Communication : {studentAssessment.communication}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Academics : {studentAssessment.academics}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Socialization: {studentAssessment.socialization} </td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Education Status : {studentAssessment.educationstatus}</td>
        </tr>

        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>Vocational Status : {studentAssessment.vocationalstatus}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}> 11. Primary Diagnosis : {studentAssessment.primarydiagnosis}</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}> 12. Severity: {studentAssessment.severity}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}>13. Co-morbidity : {studentAssessment.comobality}</td>


        </tr>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px" }}>14. Required Documents: {studentAssessment.requireddocuments}</td>
          <td style={{ border: "1px solid gray", padding: "10px" }}></td>


        </tr>
      </table>

      {/* <table>
        <thead>
          {personalInfoHeader.map((item) => (
            <tr key={item.prop} style={{ border: "none" }}>
              <td>{item.title} </td>
              {personalInfo ? (
                <td> - {personalInfo[item.prop]}</td>
              ) : (
                <td> - </td>
              )}
            </tr>
          ))}
        </thead>
      </table> */}
      {/* Assessment Info  */}
      {/* <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h5 style={{ fontSize: "15px" }}> Assessment Information</h5>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table> */}

      {/* <table>
        <thead>
          {AssessmentHeaders.map((item) => (
            <tr key={item.prop} style={{ border: "none" }}>
              <td>{item.title} </td>
              {personalInfo.length > 0 ? (
                <td> - {studentAssessment[item.prop]}</td>
              ) : (
                <td>-</td>
              )}
            </tr>
          ))}
        </thead>
      </table> */}

      <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>DST Information</b> </h4>
          </td>
          <td style={{ width: "100px", border: "None", backgroundColor: "#DFE0E1" }}>
            DA : {daYear} Month
          </td>
          <td style={{ width: "100px", border: "None", backgroundColor: "#DFE0E1" }}>
            DQ : {dstScore}
          </td>
        </tr>
      </table>

      <table class="table table-bordered" style={{ fontSize: "15px" }}>
        <thead>
          <tr>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1", border: "1px solid gray", height: "30px" }}>
              <b>No.</b>
            </td>
            <td style={{ width: "80%", backgroundColor: "#DFE0E1", border: "1px solid gray", height: "30px" }}>
              <b>Question</b>
            </td>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1", border: "1px solid gray", height: "30px" }}>
              <b>Marks</b>
            </td>
          </tr>
        </thead>
        <tbody>
          {dstInfo?.map((item, index) => (
            <div>
              <tr key={index}>
                <td style={{ border: "1px solid gray", padding: "10px" }}>{index + 1}</td>
                <td style={{ border: "1px solid gray", padding: "10px" }}>{item.question}</td>
                <td style={{ border: "1px solid gray", padding: "10px" }}>{item.marks}</td>
              </tr>
            </div>
          ))}
        </tbody>
      </table>

      <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}><b> VSMS Information </b></h4>
          </td>
          <td style={{ width: "200px", border: "None", backgroundColor: '#DFE0E1' }}>
            Total Score : {score}  SA : {vsmsdatamark} Months  SQ : {sqdata.toFixed(2)}
          </td>
        </tr>
      </table>

      <table class="table table-bordered" style={{ fontSize: "15px" }}>
        <thead>
          <tr>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1", border: "1px solid gray", height: "30px" }}>
              <b>No.</b>
            </td>
            <td style={{ width: "80%", backgroundColor: "#DFE0E1", border: "1px solid gray", height: "30px" }}>
              <b>Question</b>
            </td>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1", border: "1px solid gray", height: "30px" }}>
              <b>Marks</b>
            </td>
          </tr>
        </thead>
        <tbody>
          {vsmsInfo?.map((item, index) => (
            <div>
              <tr key={index}>
                <td style={{ border: "1px solid gray", padding: "10px" }}>{index + 1}</td>
                <td style={{ border: "1px solid gray", padding: "10px" }}>{item.question}</td>
                <td style={{ border: "1px solid gray", padding: "10px" }}>{item.marks}</td>
              </tr>
            </div>
          ))}
        </tbody>
      </table>

      <table>
        <tr>
          <td style={{ width: "400px", border: "None" }}>
            <h5 style={{ fontSize: "15px" }}> GDT Information</h5>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>

      <table class="table table-bordered">
        <thead>
          <tr>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1", border: "1px solid gray", height: "30px" }}>
              <b>No.</b>
            </td>
            <td style={{ width: "80%", backgroundColor: "#DFE0E1", border: "1px solid gray", height: "30px" }}>
              <b>Question</b>
            </td>
            <td style={{ width: "10%", backgroundColor: "#DFE0E1", border: "1px solid gray", height: "30px" }}>
              <b>Years</b>
            </td>
          </tr>
        </thead>
        <tbody>
          {gdtInfo?.map((item, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid gray", padding: "10px" }}>{index + 1}</td>
              <td style={{ textAlign: "center", border: "1px solid gray", height: "30px" }}>
                <img
                  style={{ width: "40px", height: "30px" }}
                  src={`data:image/png;base64,${item.path}`}
                />
              </td>
              <td style={{ border: "1px solid gray", padding: "10px" }}>{item.marks}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CaseHistory;
