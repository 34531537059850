
import "./App.css";
import "./Sidebar.css"
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import Sidebar from "./components/Sidebar";
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import * as constants from './constants/CONSTANT';
import StaffMemberEdit from "./components/StaffMemberEdit";
import StaffMemberList from "./components/StaffMemberList";
import StaffMemberView from "./components/StaffMemberView";
import ServiceAreaList from "./components/ServiceAreaList";
import ServiceAreaView from "./components/ServiceAreaView";
import ServiceAreaEdit from "./components/ServiceAreaEdit";
import StudentList from "./components/StudentList";
import StudentEdit from "./components/StudentEdit";
import StudentView from "./components/StudentView";
import ParentEdit from "./components/ParentEdit";
import StudentAssessmentEdit from "./components/StudentAssessmentEdit";
import PersonalInfoPartC from "./components/PersonalInfoPartC";
import UserAttendanceView from "./components/UserAttendanceView";
import IEPEdit from "./components/IEPEdit";
import IEPList from "./components/IEPList";
import QuestionList from "./components/QuestionList";
import QuestionEdit from "./components/QuestionEdit";
import DSTForm from "./components/DSTForm";
import Questions from "./components/Questions";
import AssignQuestions from "./components/AssignQuestions";
import PermissionList from "./components/PermissionList";
import RolesList from "./components/RolesList";
import StudentScoring from "./components/StudentScoring";
import CbrCmhEdit from "./components/CbrCmhEdit";
import CbrCmhList from "./components/CbrCmhList";
import CbrWorkerCmm from "./components/CbrWorkerCmm";
import CbrWorkerCmmList from "./components/CbrWorkerCmmList";




import Assessmentlist from "./components/AssessmentList";
import AssessmentEdit from "./components/AssessmentEdit";
import AssessmentView from "./components/AssessmentView";
import Assessment from "./components/Assessment";
import AssignStudents from "./components/AssignStudents";
import ReportList from "./components/ReportList";
import ReportView from "./components/ReportView";

import PubSub from 'pubsub-js';
import { Toast, ToastContainer } from "react-bootstrap";
import ChangePassword from "./components/ChangePassword";
import GDTScoring from "./components/GDTScoring";
import FacpSectionResult from "./components/FacpSectionResult";
import PhysioAssessment from "./components/PhysioAssessment";
import CBRWorkerView from "./components/CBRWorkerView";
import CBRWorkerList from "./components/CBRWorkerList";
import HomeBasedCbrView from "./components/HomeBasedCbrView";
import HomeBasedCbrEdit from "./components/HomeBasedCbrEdit";
import HomeBasedCbrList from "./components/HomeBasedCbrList";
import CbrWorkerEdit from "./components/CbrWorkerEdit";
import CbrWorkerReportList from "./components/CbrWorkerReportList";
import CbrWorkerReportEdit from "./components/CbrWorkerReportEdit";
import EditProfile from "./components/EditProfile";
import WorkerBehaviour from "./components/WorkerBehaviour";
import AnalysisEdit from "./components/AnalysisEdit";
import AnalysisList from "./components/AnalysisList";
import VocationalProfileEdit from "./components/VocationalProfileEdit";
import VocationalProfileList from "./components/VocationalProfileList";
import IndianAutismList from "./components/IndianAutismList";
import FinalAssesmentCheckList from "./components/FinalAssesmentCheckList";
import FinalAssessmentEdit from "./components/FinalAssessmentEdit";
import FinalAssessmentCreate from "./components/FinalAssessmentCreate";
import IndianAutismEdit from "./components/IndianAutismEdit";
import BackupList from "./components/BackupList";

import IndianAutismCreate from "./components/IndianAutismCreate";
import SpeechHearingList from "./components/SpeechHearingList";
import SpeechHearingEdit from "./components/SpeechHearingEdit";
import BarChart from "./components/charts/BarChart";
import StudentBeaviourAssessment from "./components/StudentBeaviourAssessment";
import StudentBeaviourAssessmentEdit from "./components/StudentBeaviourAssessmentEdit";

function App() {
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState('Confirmation');
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('');
  const mySubscriber = (msg, data) => {

    switch (msg) {
      case 'RECORD_SAVED_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('success')
        console.log('mytopic: RECORD_SAVED_TOAST'); // add your desired log message
        break;
      case 'RECORD_ERROR_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('danger')
        console.log('mytopic: RECORD_ERROR_TOAST'); // add your desired log message
        break;
      default:
        console.log('mytopic: default'); // add your desired log message
        break;
    }
  };

  useEffect(() => {
    //dispatch(fetchAccounts());
    PubSub.subscribe('RECORD_SAVED_TOAST', mySubscriber);
    PubSub.subscribe('RECORD_ERROR_TOAST', mySubscriber);
  }, []);

  return (
    <>
      <ToastContainer className="p-3" position='top-center'>
        <Toast show={modalShow} onClose={() => setModalShow(false)} delay={3000} bg={variant} className="text-white" autohide>

          {variant === 'success' ?
            <div className="p-1 m-1" style={{ backgroundColor: '#198754', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
            :
            <div className="p-1 m-1" style={{ backgroundColor: '#DC3545', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
          }

          <Toast.Body>{message instanceof Array ? message.map((item) => {
            return <span>{item.msg}</span>
          }) : message instanceof Object ? <span>{message.detail}</span> : <span>{message}</span>}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Router basename="/">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <Home />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/rolesandpermission"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <div style={{ paddingTop: "5rem", margin: "2rem" }}>
                      <PermissionList />
                    </div>
                    <br></br>
                    <hr></hr>
                    <div style={{ paddingTop: "5rem", margin: "2rem" }}>
                      <RolesList />
                    </div>
                  </div>
                </div>
              </>
            }
          />




          {/* --------------------------------staff member ------------------------------------- */}


          <Route
            path="/staffmembers/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <StaffMemberView />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/staffmembers/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <StaffMemberEdit />
                  </div>
                </div>
              </>
            }
          />




          <Route
            path="/staffmembers/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <StaffMemberEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/staffmembers"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STAFF) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <StaffMemberList />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/staffmembers/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STAFF) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <StaffMemberView />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />





          {/* --------------------------------Service Area ------------------------ */}

          <Route
            path="/serviceareas"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_SERVICE) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <ServiceAreaList />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/serviceareas/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {/* {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_SERVICE) >= 0
                                                          || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ? */}
                    <ServiceAreaView />
                    {/* :''} */}
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="serviceareas/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_SERVICE) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <ServiceAreaEdit />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          {<Route
            path="/serviceareas/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <Alert error-alert variant='danger' className="alert error-alert">
                      <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                    </Alert>
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_SERVICE) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <ServiceAreaEdit />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />}
          {/* ---------------------------------Student HISTORY --------------------------------------- */}
          <Route
            path="/students/:id/facpresult"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <FacpSectionResult />

                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/students"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STUDENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <StudentList />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/ques/:name"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_QUESTION) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <Questions />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/ques/DST"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_QUESTION) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <Questions />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/students/e"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STUDENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <StudentEdit />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>
                    }
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/:id/e"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STUDENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <StudentEdit />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/parent/:id"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STUDENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <ParentEdit />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/:id/parent"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STUDENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <ParentEdit />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STUDENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <StudentView />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/students/:type/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <StudentScoring />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/students/:id/DST"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <DSTForm />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/personalInfo/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STUDENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <PersonalInfoPartC />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/students/:id/personalInfo"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STUDENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <PersonalInfoPartC />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/:id/parent"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STUDENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <ParentEdit />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/:id/assessmentEdit"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_STUDENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <StudentAssessmentEdit />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/assessmentEdit/:id"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <StudentAssessmentEdit />

                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/:id/:type/:aid"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_QUESTION) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <Questions />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />



          <Route
            path="/questions"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_QUESTION) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <QuestionList />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}

                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/questions/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <QuestionEdit />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/questions/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <QuestionEdit />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/student/physioassessment/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <PhysioAssessment />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/student/:id/physioassessment"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <PhysioAssessment />
                  </div>
                </div>
              </>
            }
          />

          <Route path="/attendance"
            element={
              <>
                <div class="wrapper">
                  <Sidebar />
                  <div id="content"> <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_ATTENDANCE) >= 0) ?
                      <UserAttendanceView />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}

                  </div>
                </div>
              </>
            } />

          <Route
            path="/ieps"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <IEPList />


                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/ieps/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <IEPEdit />

                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/ieps/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <IEPEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <AssignQuestions />


                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/ieps/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <IEPEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/assessments"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_ASSESSMENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <Assessmentlist />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/assessments/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <Assessment />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/assessments/e/assignquestion"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <AssignQuestions />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/assessments/e/assignstudents"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <AssignStudents />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/assessments/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_ASSESSMENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <AssessmentEdit />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/assessments/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    {localStorage.getItem('permissions') && (localStorage.getItem('permissions').indexOf(constants.VIEW_ASSESSMENT) >= 0
                      || localStorage.getItem('permissions').indexOf(constants.MODIFY_ALL) >= 0) ?
                      <AssessmentView />
                      : <Alert error-alert variant='danger' className="alert error-alert">
                        <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                      </Alert>}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/reports"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <ReportList />
                  </div>
                </div>
              </>
            }
          />
          <Route path="reports/:id" element={
            <>
              <div class="wrapper">
                <Sidebar />
                <div id="content">
                  <Header />
                  <ReportView />
                </div>
              </div>
            </>
          }
          />

          <Route
            path="/changepassword"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <ChangePassword />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/GDT/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <GDTScoring />
                  </div>
                </div>
              </>
            }
          />


          {/* --------------------------------CbrWorkerReport  ------------------------------------- */}

          <Route
            path="/cbrworkerreport/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <CbrWorkerReportEdit />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/cbrworkerreport/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <CbrWorkerReportEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/cbrworkerreport"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <CbrWorkerReportList />

                  </div>
                </div>
              </>
            }
          />



          {/* --------------------------------HomeBasedCbr  ------------------------------------- */}

          <Route
            path="/homebasedcbr/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <HomeBasedCbrEdit />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/homebasedcbr/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <HomeBasedCbrEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/homebasedcbr"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <HomeBasedCbrList />

                  </div>
                </div>
              </>
            }
          />

          {/******** Edit Profile *******/}
          <Route
            path="/editprofiles"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <EditProfile />
                  </div>
                </div>
              </>
            }
          />
          {/* ***************************CBR CMM ****************************** */}

          <Route
            path="/cmmworkerreports"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <CbrWorkerCmm />

                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/cmmworkerreports/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <CbrWorkerCmm />

                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/cbrcmmworkerreports"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <CbrWorkerCmmList />

                  </div>
                </div>
              </>
            }
          />
          {/*******************************CMH Cbr*******************************/}
          <Route
            path="/cbrcmhworkerreports"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <CbrCmhList />

                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/cbrcmhworkerreports/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <CbrCmhEdit />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/cbrcmhworkerreports/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <CbrCmhEdit />
                  </div>
                </div>
              </>
            }
          />

          {/* --------------------------------CbrWorkerReport  ------------------------------------- */}

          <Route
            path="/cbrworkerreport/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <CbrWorkerReportEdit />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/cbrworkerreport/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <CbrWorkerReportEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/cbrworkerreport"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <CbrWorkerReportList />

                  </div>
                </div>
              </>
            }
          />


          {/* --------------------------------HomeBasedCbr  ------------------------------------- */}

          <Route
            path="/homebasedcbr/:id/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <HomeBasedCbrEdit />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/homebasedcbr/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <HomeBasedCbrEdit />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/homebasedcbr"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <HomeBasedCbrList />

                  </div>
                </div>
              </>
            }
          />

          {/* ***************************Worker Behaviour ****************************** */}

          <Route
            path="/students/:id/workerbehaviours"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <WorkerBehaviour />

                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/students/workerbehaviours/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <WorkerBehaviour />

                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/analysislist"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <AnalysisList />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/analysis"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <AnalysisEdit />
                  </div>
                </div>
              </>
            }
          />

          < Route
            path="/analysis/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />

                    <AnalysisEdit />

                  </div>
                </div>
              </>
            }
          />


          {/* VOCATIONAL PROFILE */}


          <Route
            path="/vocationalprofile"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <VocationalProfileList />
                  </div>
                </div>
              </>
            }
          />

          < Route
            path="/vocationalprofile/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <VocationalProfileEdit />
                  </div>
                </div>
              </>
            }
          />


          < Route
            path="/vocationalprofile/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <VocationalProfileEdit />
                  </div>
                </div>
              </>
            }
          />


          < Route
            path="/indianautism/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <IndianAutismEdit />
                  </div>
                </div>
              </>
            }
          />

          < Route
            path="/indianautism/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <IndianAutismCreate />
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/indianautism"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <IndianAutismList />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/finalassessmentchecklist/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <FinalAssessmentCreate />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/finalassessmentchecklist/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <FinalAssessmentEdit />
                  </div>
                </div>
              </>
            }
          />



          <Route
            path="/finalassessmentchecklist"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <FinalAssesmentCheckList />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/backup"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <BackupList />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/indianautism/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <IndianAutismCreate />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/speechhearings"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <SpeechHearingList />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/speechhearings/e"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <SpeechHearingEdit />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/speechhearings/:id"
            element={
              <>
                <div className="wrapper">
                  <Sidebar />
                  <div id="content">
                    <Header />
                    <SpeechHearingEdit />
                  </div>
                </div>
              </>
            }
          />

          {/* Aamir khan 22-11-2023 */}
          <Route
            path="/evaluation_graph"
            element={
              <>
                <div className="wrapper">
                  {/* <Sidebar /> */}
                  <div id="content">
                    {/* <Header /> */}
                    <BarChart />
                  </div>
                </div>
              </>
            }
          />


          <Route
            path="/studentbehaviour"
            element={
              <>
                <div className="wrapper">
                
                  <div id="content">
                   <StudentBeaviourAssessment/>
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/studentbehaviour/:id"
            element={
              <>
                <div className="wrapper">
                 
                  <div id="content">
                  <StudentBeaviourAssessmentEdit/>
                  </div>
                </div>
              </>
            }
          />

        </Routes>
      </Router>
    </>
  );
}

export default App;
