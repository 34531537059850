import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { useLocation } from "react-router-dom";
import PubSub from "pubsub-js";

  
  
const GDTScoring = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const[questions, setQuestions] = useState([]);
  const[studentId, setStudentId] = useState('');
  const[student, setStudent] = useState(location.state ? location.state : {});
  let ageFactor = [{
                  shape : 'lines',
                  age : 2
                  },
                  {
                    shape : 'circle',
                    age : 3
                    },
                    {
                      shape : 'plus',
                      age : 4
                      },
                      {
                        shape : 'squre',
                        age : 5
                        },
                        {
                          shape : 'triangle',
                          age : 6
                          },
                          {
                            shape : 'diamond',
                            age : 7
                            },
                            {
                              shape : 'cylender',
                              age : 8
                              },
                              {
                                shape : 'squreplus',
                                age : 9
                                },
                                {
                                  shape : 'polygon-out',
                                  age : 10
                                  },{
                                    shape : 'polygon-in',
                                    age : 12
                                    }]

  // ====useEffect=====
  useEffect(() => {
    let stdId;
    
    // get values from url
    if (location.hasOwnProperty('pathname') && location.pathname.split('/').length >= 3) {
      stdId = location.pathname.split('/')[3]
      setStudentId(stdId);
    }

    // init
    async function init() {
      const qusResult = await inventoryApi.fetchCaseHistoryQuestions(
        'GDT'
      );
      const marksResult = await inventoryApi.fetchCaseHistoryResults(stdId,'GDT');
      if (qusResult) {
          qusResult.map((qusObj)=> {
            qusObj.path = '/shapes_images/'+qusObj.question+'.png';
            qusObj.marks = null;
            marksResult.map((result)=> {
              if(result.casehistorytestid == qusObj.id) {
                qusObj.marks = result.marks;
              }
            }) 

          }) 
          setQuestions(qusResult);
      } 
    }

    init();
  }, []);


  // === handle change ===
  const handleChange = (e) => {
    //let tempAr = questions;
    questions.map((obj)=> {
      if(e.target.id == obj.id) {
        obj.marks = e.target.value == '' ? null:e.target.value;
      }
    })
    
    console.log(questions);
  };

  const handleSubmit = async () => {

    let newMarksAr = [];
    questions.map((item)=>{
      var obj = {};
      obj.casehistorytestid = item.id; // this is a questionId
      obj.marks = item.marks;
      obj.studentid = studentId;
      newMarksAr.push(obj);
    })
    console.log(newMarksAr);
    const marksResult = await inventoryApi.saveCaseHistoryQuestions(newMarksAr);

    if (marksResult) {
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Record Saved",
        message: " Record saved successfully",
      });
    }
      
    
  }

  const handleCancel = () => {
    navigate(`/students/${studentId}`);
  };


  return (
    <Container>
      <Row>
      <Col lg={1}></Col>
      <Col lg={10}>
      <Row className="view-form-header align-items-center mx-1">
        
        <Col lg={3}>
          <h5>Edit GDT Info</h5>
          <h4>{student.name}</h4>
        </Col>
        <Col lg={9} className="d-flex justify-content-end">
       
            <Button className="btn-sm mx-2" onClick={handleSubmit}>
              Save
            </Button>
    
          <Button className="btn-sm" variant="danger" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
    
      </Row>
      </Col>
      <Col lg={1}></Col>
      </Row>

            
        <Row>
            <Col lg={2}></Col>
            <Col lg={8} className="p-lg-4">
                    <Table className="data-table childtable" style={{backgroundColor: '#fff'}}>
                        <thead>
                            <tr>
                                <th style={{textAlign : 'center'}}>Images</th>
                                <th style={{textAlign : 'center'}}>Age</th>
                                <th style={{textAlign : 'center'}}>Years</th>
                                
                            </tr>
                        </thead>

                        <tbody>
                          {questions.map((item) => (
                                      
                                <tr key={item.id} style={{ border: '1px solid #ccc' }}>
                                    <td style={{textAlign : 'center', border: '1px solid #ccc'}}> 
                                    <img src={item.path} height="100px" width="100px"  /> </td>
                                    <td style={{textAlign : 'center'}}>
                                      {ageFactor.map((shp) => {
                                        if(shp.shape === item.question) 
                                          return shp.age;
                                          }
                                          )
                                      }</td>
                                    <td style={{verticalAlign:"middle", border: '1px solid #ccc', textAlign:'center'}}>
                                      <Form.Select style={{display: 'inline-block', width:"50%"}} id={item.id} name={item.question} onChange={handleChange}>
                                          <option selected={item.marks == null ? true:false} value="">-- Select --</option>
                                          <option selected={item.marks == 0 ? true:false} value="0">0</option>
                                          <option selected={item.marks == 1 ? true:false} value="1">1</option>
                                          <option selected={item.marks == 2 ? true:false} value="2">2</option>
                                          <option selected={item.marks == 3 ? true:false} value="3">3</option>
                                          <option selected={item.marks == 4 ? true:false} value="4">4</option>
                                          <option selected={item.marks == 5 ? true:false} value="5">5</option>
                                          <option selected={item.marks == 6 ? true:false} value="6">6</option>
                                          <option selected={item.marks == 7 ? true:false} value="7">7</option>
                                          <option selected={item.marks == 8 ? true:false} value="8">8</option>
                                          <option selected={item.marks == 9 ? true:false} value="9">9</option>
                                          <option selected={item.marks == 10 ? true:false} value="10">10</option>
                                          <option selected={item.marks == 11 ? true:false} value="11">11</option>
                                          <option selected={item.marks == 12 ? true:false} value="12">12</option>
                                          <option selected={item.marks == 13 ? true:false} value="13">13</option>
                                          <option selected={item.marks == 14 ? true:false} value="14">14</option>
                                          <option selected={item.marks == 15 ? true:false} value="15">15</option>


                                      </Form.Select>

                                    </td>
                                </tr>
                          ))}
                        </tbody>
                </Table>
            </Col>
            <Col lg={2}></Col>
            
        </Row>
   
    </Container>
  );
};

export default GDTScoring;
