import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import RelatedListQuestions from "./RelatedListQuestions";
import RelatedListStudents from "./RelatedListStudents";
import inventoryApi from "../api/inventoryApi";
import moment from 'moment';


const Assessmentview = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState(location.state ? location.state : {});

  const [modalShow, setModalShow] = useState(false);
  const [questionTabShow, setQuestionTabShow] = useState(true);
  const [studentTabShow, setStudentTabShow] = useState(false);
  const [assessmentAndRelated, setAssessmentAndRelated] = useState();
  const [age, setAge] = useState();

  useEffect(() => {
    console.log('questions => ', questions);

    async function init() {
      if (location.hasOwnProperty('pathname') && location.pathname.split('/').length >= 3) {
        questions.id = location.pathname.split('/')[2];
      }
      const result = await inventoryApi.fetchAssessmentsAndRelated(questions.id);

      console.log('result=>', result);
      if (result) {

        // result?.assessmentstudents.map((value) => {
        //   //console.log(localStorage.getItem("permissions"));
        //   let age = null;
        //   const dob = moment(value.dob, 'YYYY-MM-DD');
        //   age = moment().diff(dob, 'years');
        //   //console.log('age', age)
        //   setAge(age);
        //   age = moment().diff(dob, 'years');
        // })


        setAssessmentAndRelated(result);
        setQuestions(result);
        console.log('assessmentAndRelated => ', assessmentAndRelated);
      } else {
        setAssessmentAndRelated(null);

      }
    }
    init();

  }, []);

  //   const editAssessment = () => {
  //     navigate(`/assessments/${questions.id}/e`, { state: questions });
  //   };

  const handleSelect = (key) => {
    console.log('key => ', key);
    if (key === 'questions') {
      setStudentTabShow(false);
      setQuestionTabShow(true);
    } else if (key === 'students') {
      setStudentTabShow(true);
      setQuestionTabShow(false);
    }
  }

  const handleDelete = async () => {
    setModalShow(true);
  }

  const deleteRecord = async () => {
    setModalShow(false);
    const result = await inventoryApi.deleteAssessment(questions.id);
    navigate(`/assessments`);
  }

  console.log('questions==>',questions)
  return (
    <div>
      {modalShow &&
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteRecord={deleteRecord}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"

        />}
      {questions &&
        <Container>

          <Row className="view-form">
            <Col></Col>

            <Col lg={8}>

              <Row className="view-form-header align-items-center">

                <Col lg={3}>
                  IEP Information
                </Col>
                <Col lg={3}>

                </Col>



              </Row>
              <Row>
                <Col lg={6}>
                  <label>Category</label>
                  <span>
                    {questions.category}
                  </span>
                </Col>




                <Col lg={6}>
                  <label>Period</label>
                  <span>{questions.period}</span>
                </Col>
                <Col lg={6}>
                  <label>Staff</label>
                  <span>{questions.staffname}</span>
                </Col>
                <Col lg={6}>
                  <label>Type</label>
                  <span>{questions.category}</span>
                </Col>


              </Row>
            </Col>
            <Col></Col>
          </Row>





          <Row className="view-form">
            <Col></Col>

            <Col lg={8}>

              <Row className="view-form-header align-items-center">

                <Col lg={3}>
                  Assessment Information
                </Col>
                <Col lg={3}>

                </Col>
                <Col lg={3}>

                </Col>
                {/* <Col lg={3} className="d-flex justify-content-end">
                  <Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete()} >Delete</Button>
                </Col> */}


              </Row>
              <Row>
                <Col lg={6}>
                  <label>Name</label>
                  <span>
                    {questions.name}
                  </span>
                </Col>

                {/* <Col lg={6}>
                  <label>Service Category</label>
                  <span>
                    {questions.category}
                  </span>
                </Col> */}


                <Col lg={6}>
                  <label>Period</label>
                  <span>{questions.period}</span>
                </Col>
                {/* <Col lg={6}>
                  <label>Staff</label>
                  <span>{questions.staffname}</span>
                </Col> */}
                <Col lg={6}>
                  <label>Type</label>
                  <span>{questions.category}</span>
                </Col>


              </Row>
            </Col>
            <Col></Col>
          </Row>
          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">

              {assessmentAndRelated?.category === 'FACP' && (
                <Tabs defaultActiveKey="questions" id="uncontrolled-tab-example" onSelect={(key) => handleSelect(key)}>
                  <Tab eventKey="questions" title="Questions ">
                  </Tab>
                  <Tab eventKey="students" title="Students">

                  </Tab>
                </Tabs>
              )}

              {assessmentAndRelated?.category === 'PHYSIO' && (
                <Tabs defaultActiveKey="students" id="uncontrolled-tab-example" onSelect={(key) => handleSelect(key)}>
                  <Tab eventKey="students" title="Students">
                  </Tab>
                </Tabs>
              )}

            </Card.Header>
            <Card.Body>
              {assessmentAndRelated?.category === 'FACP' && questionTabShow && assessmentAndRelated?.assessmentquestions && (
                <RelatedListQuestions questions={assessmentAndRelated.assessmentquestions} />
              )}
              {assessmentAndRelated?.category === 'FACP' && studentTabShow && (
                <RelatedListStudents students={assessmentAndRelated?.assessmentstudents} />
              )}
              {assessmentAndRelated?.category === 'PHYSIO' && (
                <RelatedListStudents students={assessmentAndRelated?.assessmentstudents} />
              )}
            </Card.Body>

          </Card>
        </Container>}
    </div>
  );
};

export default Assessmentview;