import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import inventoryApi from "../api/inventoryApi";
import moment from "moment"
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake";
import * as constants from "../constants/CONSTANT";
import IndiaAutismPdfFile from "./IndiaAutismPdfFile";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import { Link } from "react-router-dom";


const IndianAutismList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [totalIndiaAutism,setTotalIndiaAutism] = useState({});
  useEffect(() => {
    async function init() {
      const result = await inventoryApi.fetchIndianAutismList();
    //   let result = [{
    //     "name" : "jaseem"
    //   }]
      console.log(result)
      if (result && result.length) {
        setBody(result);
      }
    }
    init();
  }, []);


  const header = [
    { 
      title: 'Child Name', prop: 'childname', isFilterable: true,
      cell: (row) => (
        <Link to={"/indianautism/" + row.id} 
        state={row}
        >
        {row.childname}
        </Link>
      ),

    },
    { title: 'Examiner', prop: 'examiner', isFilterable: true},
    { title: 'Date', prop: 'autismdate', isFilterable: true,cell: (row) => moment(row.autismdate).format('MM/DD/YYYY')},
    { title: 'Date of Birth', prop: 'dob', isFilterable: true,cell: (row) => moment(row.dob).format('MM/DD/YYYY')},
    { title: 'Gender', prop: 'gender', isFilterable: true},
    
  ];

  const labels = {
    beforeSelect: ""
  }

  const create = () => {
    navigate(`/indianautism/e`);
  }
  const sumOfVocational = async (id)=>{
    console.log('sumOfVocational@@=>',id);
    let result = await inventoryApi.fetchIndianAutishById(id);
    console.log("result of totalAnalysis>>",result);
    if(result){
      setTotalIndiaAutism(result);
      setTimeout(function() {
          pdfShow()
        }, 500);
        
                
    }
    else{
      setTotalIndiaAutism({});
    }   

  }
  const handleOpenPdf =(row)=>{
    console.log('handlePfdf@@@+>',row);
    sumOfVocational(row.id);
  };
  const pdfShow =()=>{
    const pdfTable = document.getElementById("divToPrint2");
    console.log("pdf table india", pdfTable);
    var html = htmlToPdfmake(pdfTable.innerHTML, {
        tableAutoSize: true,
    });
    console.log('html', html)
    const documentDefinition = {
        content: html, defaultStyle: {
            font: 'arialunicodems1'
        }
    };
    //console.log('documentDefinition', documentDefinition)
    //pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
        arialunicodems1: {
            normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
        },


        // download default Roboto font from cdnjs.com
        Roboto: {
            normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
            bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
            italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
            bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
        }
    }
    //console.log(pdfMake.vfs)
  
      pdfMake.createPdf(documentDefinition).open();
   
      
  }
  return (
    <Row className="g-0">
      <Col lg={2} className="mx-2">
        <Link className="nav-link">Home<i class="fa-solid fa-chevron-right"></i> Indian Autism</Link>
      </Col>
      <Col lg={12} className="p-lg-4">

        {body ?
          <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
            initialState: {
              rowsPerPage: 20,
              options: [5, 10, 15, 20]
            }
          }}>
            <Row className="mb-4">
              <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              >

              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button className="btn-sm" variant="outline-primary" onClick={() => create(true)}>Create</Button>
              </Col>
            </Row>
            <Table striped className="data-table">
              <TableHeader />
              <TableBody />
            </Table>


          </DatatableWrapper> : ''}
      </Col>
      <Col lg={2}></Col>
      {/* <div className="App container mt-5" style={{ display: 'none' }}>
            <div id="divToPrint2">
                 <IndiaAutismPdfFile totalIndiaAutism = {totalIndiaAutism}/>
            </div>
        </div> */}
    </Row>
  )
}

export default IndianAutismList