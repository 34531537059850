import React, { useEffect, useState } from 'react'
import inventoryApi from '../api/inventoryApi';
import moment from 'moment';

const PdfGenerator = ({ data }) => {

    const [homeCbr, setHomeCbr] = useState(data);
    const [cbrWorker, setCbrWorker] = useState({});
    let toggleVar;




    const fetchHomeBasedCbr = async () => {
        // let result = await inventoryApi.fetchHomeBasedCbrById(temp.id);
        // console.log('pdf generator result >>>> ', result.servicearea);
    }


    return (
        <div>


            <div
                className="d-flex justify-content-center border"
                style={{ fontSize: "10px" }}
            >
                <div className="App container mt-5" >
                    <html>
                        <head>
                            <meta charset="UTF-8" />
                        </head>
                        <body>
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td style={{ border: "None", textAlign: "center" }}>
                                        <h6>Home Based CBR</h6>
                                    </td>
                                </tr>
                            </table>

                            {/* .......................................Upper Form............................................ */}
                            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                                <tr>
                                    <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
                                        Service Area : {homeCbr.servicearea}
                                    </td>
                                    <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
                                        Child's Name : {homeCbr.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid gray", height: "30px" }}>
                                        Child's Age : {homeCbr.age}
                                    </td>
                                    <td style={{ border: "1px solid gray", height: "30px" }}>
                                        C.B.R. Worker : {homeCbr.cbrworker}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid gray", height: "30px" }}>
                                        Time : {homeCbr.time}
                                    </td>
                                    <td style={{ border: "1px solid gray", height: "30px" }}>
                                        Date : {homeCbr.date}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid gray", height: "30px" }}>
                                        Current extension of the child during the program : {homeCbr.chidextension}
                                    </td>
                                    <td style={{ border: "1px solid gray", height: "30px" }}>

                                    </td>
                                </tr>

                            </table>
                            {/* .........................................8 Item.................................................. */}

                            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                                <thead>
                                    <tr style={{ border: "1px solid gray", height: "30px" }}>
                                        <th>According to target area</th>
                                        <th>Work done by C.B.R. worker</th>
                                        <th>Materials used</th>
                                        <th>Instructions for Parents</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Personal :</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalwork_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalmaterials_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalinstructions}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalwork_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalmaterials_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalwork_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                            {homeCbr.personalmaterials_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalwork_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalmaterials_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalwork_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalmaterials_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalwork_6}

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalmaterials_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalwork_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalmaterials_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalwork_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalmaterials_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalwork_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalmaterials_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalwork_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.personalmaterials_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>

                                    <tr style={{ marginTop: "2px" }}>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Social :</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialwork_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialmaterials_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialinstructions}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialwork_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialmaterials_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialwork_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialmaterials_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialwork_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialmaterials_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialwork_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialmaterials_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialwork_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialmaterials_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialwork_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialmaterials_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialwork_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialmaterials_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialwork_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialmaterials_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialwork_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.socialmaterials_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Business :</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businesswork_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businessmaterials_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businessinstructions}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businesswork_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businessmaterials_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businesswork_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businessmaterials_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businesswork_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businessmaterials_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businesswork_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businessmaterials_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businesswork_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businessmaterials_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businesswork_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businessmaterials_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businesswork_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businessmaterials_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businesswork_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businessmaterials_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businesswork_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.businessmaterials_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>

                                    <tr style={{ marginTop: "2px" }}>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Educational :</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalwork_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalmaterials_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalinstructions}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalwork_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalmaterials_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalwork_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalmaterials_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalwork_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalmaterials_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalwork_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalmaterials_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalwork_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalmaterials_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalwork_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalmaterials_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalwork_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalmaterials_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalwork_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalmaterials_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalwork_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.educationalmaterials_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Recreational :</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentwork_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentmaterials_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentinstructions}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentwork_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentmaterials_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentwork_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentmaterials_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentwork_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentmaterials_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentwork_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentmaterials_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentwork_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentmaterials_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentwork_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentmaterials_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentwork_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentmaterials_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentwork_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentmaterials_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentwork_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.entertainmentmaterials_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>

                                    <tr style={{ marginTop: "2px" }}>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Community Inclusion :</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionwork_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionmaterials_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusioninstructions}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionwork_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                            {homeCbr.communityinclusionmaterials_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionwork_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionmaterials_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionwork_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionmaterials_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionwork_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionmaterials_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionwork_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionmaterials_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionwork_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionmaterials_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionwork_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionmaterials_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionwork_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionmaterials_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionwork_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionmaterials_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Language and communication :</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationwork_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationmaterials_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationinstructions}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationwork_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationmaterials_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationwork_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationmaterials_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationwork_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationmaterials_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationwork_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationmaterials_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.communityinclusionmaterials_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationmaterials_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationwork_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationmaterials_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationwork_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationmaterials_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationwork_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationmaterials_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationwork_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.languagecommunicationmaterials_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>

                                    <tr style={{ marginTop: "2px" }}>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            <b>Visitor :</b>
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitorwork_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitormaterials_1}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitorinstructions}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitorwork_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitormaterials_2}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitorwork_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitormaterials_3}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitorwork_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitormaterials_4}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitorwork_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitormaterials_5}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitorwork_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitormaterials_6}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitorwork_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitormaterials_7}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitorwork_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitormaterials_8}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "None",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitorwork_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitormaterials_9}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{
                                            borderLeft: "5px solid gray", borderTop: "None", borderBottom: "5px solid gray",
                                            height: "30px"
                                        }}>

                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitorwork_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>
                                            {homeCbr.visitormaterials_10}
                                        </td>
                                        <td style={{ border: "1px solid gray", height: "30px" }}>

                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                            {/* ...........................................Footer Form..................................................... */}
                            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center" }}>
                                <tr>
                                    <td style={{ border: "1px solid gray", height: "30px", width: "33%" }}>
                                        Next Program : {homeCbr.nextprogram}
                                    </td>
                                    <td style={{ border: "1px solid gray", height: "30px", width: "33%" }}>
                                        Guardian Signature : {homeCbr.guardiansign}
                                    </td>
                                    <td style={{ border: "1px solid gray", height: "30px", width: "33%" }}>
                                        Signature of C.B.R. Worker : {homeCbr.cbrworkersign}
                                    </td>
                                </tr>
                            </table>
                        </body>
                    </html>
                </div>
            </div>




        </div >
    )
}

export default PdfGenerator;


