import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Form, FormSelect, Alert } from "react-bootstrap";
import moment from "moment";
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import * as constants from "../constants/CONSTANT";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { useNavigate } from "react-router-dom";
import PubSub from "pubsub-js";
import VocationalPdfFile from "./VocationalPdfFile";

const VocationalProfileEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [vocProfile, setVocProfile] = useState({});
    const [checkedValues, setCheckedValues] = useState([]);
    const [trainingrecv, settrainingrecv] = useState([]);
    const [areaguidancereq, setareaguidancereq] = useState([]);
    const [showDetails, setshowDetails] = useState(false);
    const [fieldsData, setfieldsData] = useState({});
    const [validated, setValidated] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [locationData, setLocationData] = useState(location.state ? location.state : {});
    console.log('locationData', locationData);
    const [vocdata, setvocdata] = useState({});
    const [totalVocational, setTotalVocational] = useState({});

    const [vocational, setvocational] = useState(false);


    const sumOfVocational = async (id) => {
        let result = await inventoryApi.fetchSumOfVocational(id);
        console.log("result of totalAnalysis>>", result);
        if (result != null) {
            setTotalVocational(result);
        }
        else {
            setTotalVocational({});
        }
    }

    useEffect(() => {
        if (locationData) {
            setvocational(true);
        }
    }, []);

    const pdfShow = () => {
        const pdfTable = document.getElementById("divToPrint");
        //console.log("pdf table", pdfTable);
        var html = htmlToPdfmake(pdfTable.innerHTML, {
            tableAutoSize: true,
        });
        console.log('html', html)
        const documentDefinition = {
            content: html, defaultStyle: {
                font: 'arialunicodems1'
            }
        };
        pdfMake.fonts = {
            arialunicodems1: {
                normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
                bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            },


            // download default Roboto font from cdnjs.com
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            }
        }
        //console.log(pdfMake.vfs)

        pdfMake.createPdf(documentDefinition).open();


    }


    useEffect(() => {

        if (locationData) {
            console.log('if use effect call')
            fetchAllVocAnaly(locationData);
        }

    }, []);

    let assocconditionArray;
    let checkboxes;
    var [checkedBoxes, setCheckedBoxes] = useState([]);

    const fetchAllVocAnaly = async (locationData) => {
        let result = await inventoryApi.fetchVocById(locationData);
        sumOfVocational(locationData)
        setvocdata(result);
        console.log('result', result);
        let assocconditionArray = result.assoccondition?.split(", ");
        let trainingrecvArray = result.trainingrecv?.split(", ");
        let areaguidancereqArray = result.areaguidancereq?.split(", ");
        console.log('trainingrecvArray', trainingrecvArray)
        console.log('assocconditionArray', assocconditionArray);
        let checkboxes = document.querySelectorAll('input[type="checkbox"]');
        console.log("checkboxes===>>>", checkboxes);
        checkboxes.forEach((checkbox) => {
            if (checkbox.name === 'assoccondition') {
                let checkboxValue = checkbox.value;
                if (assocconditionArray.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setCheckedValues((prevState) => [...prevState, checkboxValue]);
                    console.log("Checkbox value", checkboxValue, "is included in assocconditionArray");
                }
            }
        });


        checkboxes.forEach((checkbox) => {
            if (checkbox.name === 'trainingrecv') {
                let checkboxValue = checkbox.value;
                if (trainingrecvArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    settrainingrecv((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in trainingrecvArray");
                }
            }
        });


        checkboxes.forEach((checkbox) => {
            if (checkbox.name === 'areaguidancereq') {
                let checkboxValue = checkbox.value;
                if (areaguidancereqArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setareaguidancereq((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in areaguidancereqArray");
                }
            }
        });
        setVocProfile(result);
        setPersonalArray(result?.data);
        console.log('result', result)
        setCommunicationArrayArray(result.data1);
        setSocialArray(result?.data2);
        setFunctionalArray(result?.data3);
        setSafetyskilsArray(result?.data4);
        setDomesticArray(result?.data5);
        setMobilityArray(result?.data6);
        setOccupationalArray(result?.data7);
        setselectionSkillsAssessment11_1(result?.data8);
        setselectionSkillsAssessment11_2(result?.data9);
        setselectionSkillsAssessment11_3(result?.data10);

    }

    console.log('checkbox change', checkedBoxes)
    const handleFieldChange = (e) => {

        if (e.target.name === 'assoccondition') {
            vocProfile.assoccondition = checkedBoxes.join(",");
            const { value, checked } = e.target;
            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...checkedValues, value];

            } else {
                updatedCheckedValues = checkedValues.filter((item) => item !== value);
            }
            setCheckedValues(updatedCheckedValues);
        }


        if (e.target.name === 'trainingrecv') {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...trainingrecv, value];
            } else {
                updatedCheckedValues = trainingrecv.filter((item) => item !== value);
            }

            settrainingrecv(updatedCheckedValues);

        }
        if (e.target.name === 'areaguidancereq') {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...areaguidancereq, value];
            } else {
                updatedCheckedValues = areaguidancereq.filter((item) => item !== value);
            }

            setareaguidancereq(updatedCheckedValues);

        }
        setVocProfile({ ...vocProfile, [e.target.name]: e.target.value })
    }



    const checkedValuesString = checkedValues.join(', ');
    console.log('checkedValuesString', checkedValuesString)

    const [personalArray, setPersonalArray] = useState([
        { id: 1, name: "Anticipates needs, uses toilets independently", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
        { id: 2, name: "Maintains cleanliness unaided (brushing, bathing, combing)", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
        { id: 3, name: "Eats properly and observes manners in a family situation without supervision", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
        { id: 4, name: "Mariages dressing unaided and maintains a neat appearance", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
    ]);


    const handleChangepersonalArray = (event, id, property) => {
        const updatedDataArray = personalArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });
        console.log('updatedDataArray', updatedDataArray)
        setPersonalArray(updatedDataArray);
    }

    const [communicationArray, setCommunicationArrayArray] = useState([
        { id: 1, name: "Can use gestures as an adjunct to verbal communication", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 2, name: "Communicates using words", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 3, name: "Gesturally or verbally makes himself understood to", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 4, name: "Communicates properly in sentences others", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
        { id: 5, name: "Engages in meaningful conversation", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
    ]);
    const handleChangecommunicationArray = (event, id, property) => {
        const updatedDataArray = communicationArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });
        console.log('updatedDataArray', updatedDataArray)
        setCommunicationArrayArray(updatedDataArray);
    }
    const [socialArray, setSocialArray] = useState([
        { id: 1, name: "Sits properly in a class room situation", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 2, name: "Greets peers and elders appropriately", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 3, name: "Cooperates in group situation", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 4, name: "Offers help when needed without prompting", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 5, name: "Behaves acceptably and makes visitors feel welcome", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 5, name: "Recognizes and protects his own property", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 7, name: "Asks permission to use the property of others", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 8, name: "Maintains discipline in a given situation.", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 9, name: "Leads peer group in simple activities", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
        { id: 10, name: "Follows routine", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
    ]);

    const handleChangesocialArray = (event, id, property) => {
        const updatedDataArray = socialArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });
        console.log('updatedDataArray', updatedDataArray)
        setSocialArray(updatedDataArray);
    }
    const [functionalArray, setFunctionalArray] = useState([
        { id: 1, name: "Reads his/her name", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 2, name: "Reads his/her address", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 3, name: "Reads survival words (poison, man, woman, hot, stop, toilet etc)", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 4, name: "Comprehends what is read by him/her", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 5, name: "Reads simple sentences", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 6, name: "Writes his/her name", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 7, name: "Writes his/her address", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 8, name: "Copies simple sentences", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 9, name: "Writes simple sentences", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 10, name: "Counts meaningfully upto 10", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 11, name: "Identifies and writes the numbers upto 10", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 12, name: "Counts upto 100", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 13, name: "Identifies and writes the numbers upto 100", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 14, name: "Does simple single digit addition", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 15, name: "Does simple double digit addition.", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 16, name: "Does simple single digit subtraction", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 17, name: "Does simple double digit subtraction", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 18, name: "Identifies coins of all denominations", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 19, name: "Identifies rupee notes of all denominations", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 20, name: "Exchange coins for one rupee", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 21, name: "Makes purchases and gets balance for 1 rupee", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 22, name: "Makes purchases and gets balance for 5 rupees", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 23, name: "Makes purchases and gets balance for 10 rupees", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 24, name: "Answers correctly day or night, morning or afternoon, yesterday, today or tomorrow", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 25, name: "Tells the position of the long and short hand on the clock", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 26, name: "Reads the number on the slack", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 27, name: "Tells his/her date of birth", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 28, name: "Tells time to the minute on the clock", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 29, name: "Tells hours, 30 mnts, 15 mnts, 45 mnts", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
        { id: 30, name: "Associates time to the daily routine", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    ]);
    const handleChangefunctionalArray = (event, id, property) => {
        const updatedDataArray = functionalArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });
        console.log('updatedDataArray', updatedDataArray)
        setFunctionalArray(updatedDataArray);

    }

    const [safetyskilsArray, setSafetyskilsArray] = useState([
        { id: 1, name: "Uses stairs and corridors in a safe manner", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
        { id: 2, name: "Aware of hazards in the environment", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
        { id: 3, name: "Knows dangers of fire", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
        { id: 4, name: "Aware of traffic signals", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
        { id: 5, name: "Crosses street safely", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
        { id: 6, name: "Uses sharp objects safely", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
        { id: 7, name: "Uses household electrical items safely", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
    ]);
    const handleChangesafetyskilsArray = (event, id, property) => {
        const updatedDataArray = safetyskilsArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });
        console.log('updatedDataArray', updatedDataArray)
        setSafetyskilsArray(updatedDataArray);

    }
    const [domesticArray, setDomesticArray] = useState([
        { id: 1, name: "Sweeps rooms", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 2, name: "Dusts the furniture", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 3, name: "Sets a table for lunch", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 4, name: "Washes and dries dishes", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 5, name: "Operates a grindur or mixle", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 6, name: "Washes clothes", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 7, name: "Dries clothes", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 8, name: "Buys necessary things for cooking it listed and given", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 9, name: "Prepares coffee", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 10, name: "Prepares and serves a meal", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 11, name: "Rides a bicycle", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 12, name: "Can retain verbal messages and convey to the respective person", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
        { id: 13, name: "Can attend to telephone call", q1: "", q2: "", q3: "", q4: "", sectionheading: "6" },
    ]);


    const handleChangedomesticArray = (event, id, property) => {
        const updatedDataArray = domesticArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });
        console.log('updatedDataArray', updatedDataArray)
        setDomesticArray(updatedDataArray);
    }
    const [mobilityArray, setMobilityArray] = useState([
        { id: 1, name: "Walks independently", q1: "", q2: "", q3: "", q4: "", sectionheading: "7" },
        { id: 2, name: "Climbs and descents with alternate feet", q1: "", q2: "", q3: "", q4: "", sectionheading: "7" },
        { id: 3, name: "Can pour without spilling", q1: "", q2: "", q3: "", q4: "", sectionheading: "7" },
        { id: 4, name: "Can cut a drawn rectangle with scissors", q1: "", q2: "", q3: "", q4: "", sectionheading: "7" },
        { id: 5, name: "Can pick up pins from a surface using fingers.", q1: "", q2: "", q3: "", q4: "", sectionheading: "7" },
    ]);


    const handleChangemobilityArray = (event, id, property) => {
        const updatedDataArray = mobilityArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });
        console.log('updatedDataArray', updatedDataArray)
        setMobilityArray(updatedDataArray);
    }

    const [occupationalArray, setOccupationalArray] = useState([
        { id: 1, name: "Attends to an assigned task without disturbing others for one hour", q1: "", q2: "", q3: "", q4: "", sectionheading: "8" },
        { id: 2, name: "Goes to an assigned area without reminder in a routine daily programme", q1: "", q2: "", q3: "", q4: "", sectionheading: "8" },
        { id: 3, name: "Understands and completes a task", q1: "", q2: "", q3: "", q4: "", sectionheading: "8" },
        { id: 4, name: "Increases speed of work when guided", q1: "", q2: "", q3: "", q4: "", sectionheading: "8" },
        { id: 5, name: "Rises and leaves from residence to school on time", q1: "", q2: "", q3: "", q4: "", sectionheading: "8" },
        { id: 6, name: "Travels by bus independently", q1: "", q2: "", q3: "", q4: "", sectionheading: "8" },
    ]);

    const handleChangeoccupationalArray = (event, id, property) => {
        const updatedDataArray = occupationalArray.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });
        console.log('updatedDataArray', updatedDataArray)
        setOccupationalArray(updatedDataArray);
    }

    const [selectionSkillsAssessment11_1, setselectionSkillsAssessment11_1] = useState([
        { id: "a", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.1" },
        { id: "b", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.1" },
        { id: "c", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.1" },
        { id: "d", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.1" },
    ]);

    const [selectionSkillsAssessment11_2, setselectionSkillsAssessment11_2] = useState([
        { id: "a", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.2" },
        { id: "b", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.2" },
        { id: "c", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.2" },
        { id: "d", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.2" },
    ]);

    const [selectionSkillsAssessment11_3, setselectionSkillsAssessment11_3] = useState([
        { id: "a", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.3" },
        { id: "b", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.3" },
        { id: "c", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.3" },
        { id: "d", name: "", q1: "", q2: "", q3: "", q4: "", sectionheading: "11.3" },
    ]);

    const pdfRow = () => {
        setshowDetails(true);
        const pdfTable = document.getElementById("divToPrint");
        var html = htmlToPdfmake(pdfTable.innerHTML, {
            tableAutoSize: true,
        });
        const documentDefinition = {
            content: html,
            defaultStyle: {
                font: "arialunicodems1",
            },
        };
        pdfMake.fonts = {
            arialunicodems1: {
                normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
                bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            },
            Roboto: {
                normal:
                    "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
                bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
                italics:
                    "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
                bolditalics:
                    "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
            },
        };
        pdfMake.createPdf(documentDefinition).open();
    };

    const trainingrecvString = trainingrecv.join(', ');
    console.log('trainingrecvString', trainingrecvString)

    const areaguidancereqValuesString = areaguidancereq.join(', ');
    console.log('areaguidancereqValuesString', areaguidancereqValuesString)

    const handleCancel = () => {
        navigate(`/students`);
    };


    const handleSubmit = async (event) => {

        vocProfile.assoccondition = checkedBoxes;
        event.preventDefault();
        vocProfile.assoccondition = checkedValuesString
        vocProfile.trainingrecv = trainingrecvString
        vocProfile.areaguidancereq = areaguidancereqValuesString
        console.log('vocProfile', vocProfile)
        console.log('======handleSubmit CAll============');
        let completeData = {};
        completeData = { ...vocProfile, data: [personalArray, communicationArray, socialArray, functionalArray, safetyskilsArray, domesticArray, mobilityArray, occupationalArray, selectionSkillsAssessment11_1, selectionSkillsAssessment11_2, selectionSkillsAssessment11_3] };
        console.log('completeData', completeData)

        let result = {};
        let completeDataId = completeData.id
        if (completeData.id) {
            delete completeData.id
            delete completeData.studentid
            delete completeData.staffid
            console.log('if call edit', completeData);
            result = await inventoryApi.saveVoc(completeDataId, completeData);
            console.log('result', result)
            PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record Update Successfully' });
            // navigate(`/vocationalprofile/`, { state: result });

        } else {

            console.log('if create call', completeData)
            completeData.parentidstudent = locationData
            console.log('completeData', completeData)
            const result = await inventoryApi.createVoc(completeData);
            if (result) {
                PubSub.publish("RECORD_SAVED_TOAST", {
                    title: "Record Saved",
                    message: "Record create successfully",
                });
                navigate(`/students/${locationData}`);
            }
            if (result.errors) {
                setShowError(true);
                setErrorMessage(result.errors);
                return;
            }
        }

    }


    const handleChange11_1 = (event, id, property) => {
        const updatedDataArray = selectionSkillsAssessment11_1.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });
        console.log('updatedDataArray', updatedDataArray)
        setselectionSkillsAssessment11_1(updatedDataArray);

    }


    const handleChange11_2 = (event, id, property) => {
        const updatedDataArraySection3 = selectionSkillsAssessment11_2.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });
        setselectionSkillsAssessment11_2(updatedDataArraySection3);
    }



    const handleChange11_3 = (event, id, property) => {
        const updatedDataArraySection3 = selectionSkillsAssessment11_3.map(item => {
            if (item.id === id) {
                return { ...item, [property]: event.target.value };
            }
            return item;
        });
        setselectionSkillsAssessment11_3(updatedDataArraySection3);
    }
    return (
        <>
            <Container id="marbt">
                <Row>
                    <Col lg={12} className="mx-3">
                        <Form
                            className="mt-3"
                            onSubmit={handleSubmit}

                        >


                            <Row className="view-form-header align-items-center">
                                <Col lg={6}>VOCATIONAL PROFILE </Col>
                                <Col lg={6} className="d-flex justify-content-end">
                                    {/* <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => {
                      pdfRow();
                    }}
                  >
                    Print
                  </Button> */}

                                    <Button variant="danger" className="btn-sm mx-2" onClick={pdfShow}>
                                        <i class="fa-regular fa-file-pdf"></i>
                                    </Button>
                                    <Button
                                        className="btn-sm mx-2"
                                        type="submit"
                                        onSubmit={handleSubmit}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        className="btn-sm"
                                        variant="danger"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    1. Identification data:
                </Col>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                1.1 Name of the trainee(Person with Mental Retardation(above 15
                                years)
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="traineename"

                                value={vocProfile.traineename}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                1.2 Sex / age / Date of Birth
                            </Form.Label>
                            <Form.Control
                                // required
                                type="date"
                                name="sexagedob"

                                value={vocProfile.sexagedob}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                1.3 Level of Mental Retardation
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="mentalretardational"

                                value={vocProfile.mentalretardational}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                1.4 Marital status of the trainee
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="marital"

                                value={vocProfile.marital}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                1.5 Father's / guardian's name occupation & address
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="fatherguardianoccupaddr"

                                value={vocProfile.fatherguardianoccupaddr}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-3 mx-2">
                    2. Family History (Pedigree Chart)
                </Col>
                <Row>
                    <Col lg={9} style={{ marginLeft: '60px' }}>
                        <Alert variant="danger">Please Upload you Family History (Pedigree Chart) in related file section</Alert>
                    </Col>
                </Row>


                <Col lg={12} className="section-header my-3 mx-2">
                    3. Socio economics status
                </Col>

                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                3.1 Mothly Income of Parents
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="parentmthincome"

                                value={vocProfile.parentmthincome}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                3.2 Rural / semi urban / urban
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="rur_semiurb_urb"

                                value={vocProfile.rur_semiurb_urb}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-3 mx-2">
                    4. Generic skills- Rediness skills based on generic skills assessment
                    checklist(page nos:6-10)
                </Col>

                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                41. Personal skills
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="persskills"

                                value={vocProfile.persskills}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4.2 Communication
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="comm"

                                value={vocProfile.comm}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4.3 Social behavior
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="socialbehav"

                                value={vocProfile.socialbehav}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4.4 Functional academics
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="funacademics"

                                value={vocProfile.funacademics}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4.5 Safety skills
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="safetyskills"

                                value={vocProfile.safetyskills}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4.6 Domestic behaviour
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="domesticbehav"

                                value={vocProfile.domesticbehav}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4.7 Mobility and hand functioning
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="mobilityhandfunc"

                                value={vocProfile.mobilityhandfunc}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                4.8 Occupational
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="occupational"

                                value={vocProfile.occupational}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    5. Associated conditions
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="a. Epilepsy"
                        name="assoccondition"
                        type="checkbox"
                        value="Epilepsy"
                        onChange={handleFieldChange}

                    />
                    <Form.Check
                        inline
                        label="b. Physical handicap"
                        name="assoccondition"
                        type="checkbox"
                        value="Physical handicap"
                        onChange={handleFieldChange}

                    />
                    <Form.Check
                        inline
                        label="c. Hearing handicap"
                        name="assoccondition"
                        type="checkbox"
                        value="Hearing handicap"
                        onChange={handleFieldChange}

                    />
                    <Form.Check
                        inline
                        label="d. Visual handicap"
                        name="assoccondition"
                        type="checkbox"
                        value="Visual handicap"
                        onChange={handleFieldChange}

                    />
                    <Form.Check
                        inline
                        label="e. Psychiatric features"
                        name="assoccondition"
                        type="checkbox"
                        value="Psychiatric features"
                        onChange={handleFieldChange}

                    />
                </Col>
                <Col lg={12} className="section-header my-3 mx-2">
                    6. Training received
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="a. Normal school"
                        name="trainingrecv"
                        type="checkbox"
                        value="Normal school"
                        onChange={handleFieldChange}
                    />
                    <Form.Check
                        inline
                        label="b. Special school"
                        name="trainingrecv"
                        type="checkbox"
                        value="Special school"
                        onChange={handleFieldChange}
                    />
                    <Form.Check
                        inline
                        label="c. Vocational training"
                        name="trainingrecv"
                        type="checkbox"
                        value="Vocational training"
                        onChange={handleFieldChange}
                    />
                    <Form.Check
                        inline
                        label="d. Any other"
                        name="trainingrecv"
                        type="checkbox"
                        value="Any other"
                        onChange={handleFieldChange}
                    />
                </Col>
                <Col lg={12} className="section-header my-3 mx-2">
                    7. Daily routines
                </Col>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-3 my-1" controlId="formBasicServiceArea">
                            <Form.Select
                                name="dailyroutines"
                                value={vocProfile.dailyroutines}
                                onChange={handleFieldChange}
                            >
                                <select
                                    onChange={handleFieldChange}
                                    className="form-select"
                                ></select>
                                <option></option>
                                <option value="06.00 A.M. - 09.00 A.M.">
                                    06.00 A.M. - 09.00 A.M.{" "}
                                </option>
                                <option value="09.00 A.M. - 01.00 P.M.">
                                    09.00 A.M. - 01.00 P.M.
                                </option>
                                <option value="01.00 P.M. - 05.00 P.M.">
                                    01.00 P.M. - 05.00 P.M.
                                </option>
                                <option value="05.00 P.M. - 09.00 P.M.">
                                    05.00 P.M. - 09.00 P.M.
                                </option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    8. Experience in employment
                </Col>

                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                8.1 Domestic works
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="domesticworks"

                                value={vocProfile.domesticworks}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                8.2 IF Employed where? what type of work? salary / wages
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="employedwhere"

                                value={vocProfile.employedwhere}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    9. Possibilities of employment
                </Col>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                9.1 In what type of employement family involved
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="typeofemp"

                                value={vocProfile.typeofemp}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                9.2 Potential employment opprtunities in the neighbourhood
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="empoppinneighbour"

                                value={vocProfile.empoppinneighbour}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-3" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                9.3 Possibilities of self employment
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="selfemppossibility"

                                value={vocProfile.selfemppossibility}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-3" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                9.4 What type of work the trainee wants to do?
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="typeofworktraineewant"

                                value={vocProfile.typeofworktraineewant}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-3" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                9.5 What type of support the parents expect for their
                                son/daughter?
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="parentexpectsupportson"

                                value={vocProfile.parentexpectsupportson}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                9.6 What type of support the parents expect for employment of
                                their son/daughter.
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="parentsemployment"

                                value={vocProfile.parentsemployment}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                9.7 Financial position
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="financialpos"

                                value={vocProfile.financialpos}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    10. Areas in which guidance required
                </Col>
                <Row lg={12} className="mx-4 my-2">
                    <Col>
                        <Form.Check
                            inline
                            label="a. Medical"
                            name="areaguidancereq"
                            type="checkbox"
                            value="Medical"
                            onChange={handleFieldChange}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            inline
                            label="b. Educational"
                            name="areaguidancereq"
                            type="checkbox"
                            value="Educational"
                            onChange={handleFieldChange}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            inline
                            label="c. Family counselling"
                            name="areaguidancereq"
                            type="checkbox"
                            value="Family counselling"
                            onChange={handleFieldChange}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            inline
                            label="d. Guidance to select a job"
                            name="areaguidancereq"
                            type="checkbox"
                            value="Guidance to select a job"
                            onChange={handleFieldChange}
                        />
                    </Col>
                </Row>
                <Row lg={12} className="mx-4 my-2">
                    <Col>
                        <Form.Check
                            inline
                            label="e. Vocational training"
                            name="areaguidancereq"
                            type="checkbox"
                            value="Vocational training"
                            onChange={handleFieldChange}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            inline
                            label="f. Mobilisation of funds"
                            name="areaguidancereq"
                            type="checkbox"
                            value="Mobilisation of funds"
                            onChange={handleFieldChange}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            inline
                            label="g. Project preparation"
                            name="areaguidancereq"
                            type="checkbox"
                            value="Project preparation"
                            onChange={handleFieldChange}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            inline
                            label="h. Any other"
                            name="areaguidancereq"
                            type="checkbox"
                            value="Any other"
                            onChange={handleFieldChange}
                        />
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-3 mx-2">
                    11. Selection of Suitable Job (Based on generic skills assessment and Vocational Profile)
                </Col>


                <Col lg={12} className="section-header my-4 mx-2">
                    11.1  Suitable Job Selected - First choice Reasons for selection
                </Col>

                {selectionSkillsAssessment11_1?.map(item => (
                    <div key={item.id} >
                        <Row lg={12}>
                            <Col lg={1} className="my-3 ">{item.id}</Col>
                            <Col lg={10} style={{ marginLeft: '-67px' }}>

                                <input
                                    className="form-control my-3"
                                    value={item.name}
                                    onChange={event => handleChange11_1(event, item.id, 'name')}
                                />
                            </Col>

                        </Row>
                    </div>
                ))}



                <Col lg={12} className="section-header my-4 mx-2">
                    11.2  Suitable Job Selected - Second choice Reasons for selection
                </Col>

                {selectionSkillsAssessment11_2?.map(item => (
                    <div key={item.id} >
                        <Row lg={12}>
                            <Col lg={1} className="my-3 ">{item.id}</Col>
                            <Col lg={10} style={{ marginLeft: '-67px' }}>

                                <input
                                    className="form-control my-3"
                                    value={item.name}
                                    onChange={event => handleChange11_2(event, item.id, 'name')}
                                />
                            </Col>

                        </Row>
                    </div>
                ))}

                <Col lg={12} className="section-header my-4 mx-2">
                    11.3  Suitable Job Selected - third choice Reasons for selection
                </Col>

                {selectionSkillsAssessment11_3?.map(item => (
                    <div key={item.id} >
                        <Row lg={12}>
                            <Col lg={1} className="my-3 ">{item.id}</Col>
                            <Col lg={10} style={{ marginLeft: '-67px' }}>

                                <input
                                    className="form-control my-3"
                                    value={item.name}
                                    onChange={event => handleChange11_3(event, item.id, 'name')}
                                />
                            </Col>

                        </Row>
                    </div>
                ))}


                <Col lg={12} className="section-header my-3 mx-2">
                    12. Any Other Remarks
                </Col>

                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-2 my-2" controlId="formBasicname">
                            <Form.Control
                                type="text"
                                name="anyremark"
                                value={vocProfile.anyremark}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Col lg={12} className="section-header my-4 mx-2">
                    Generic Skills Assessment CheckList
                </Col>


                <Col lg={12} className="section-header my-4 mx-2">
                    <b>1. PERSONAL</b>
                </Col>

                <Col lg={12}>
                    <Col lg={12} className="my-3 mx-3 row">
                        <Col lg={4}></Col>
                        <Col lg={2}>Q1</Col>
                        <Col lg={2}>Q2</Col>
                        <Col lg={2}>Q3</Col>
                        <Col lg={2}>Q4</Col>
                    </Col>
                    {personalArray?.map(item => (
                        <div key={item.id} >
                            <Row lg={12}>
                                <Col className="my-3 ">{item.id}</Col>
                                <Col lg={3} className="my-3" style={{ width: '30%' }}>

                                    {item.name}
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q1}
                                        onChange={event => handleChangepersonalArray(event, item.id, 'q1')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>

                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q2}
                                        onChange={event => handleChangepersonalArray(event, item.id, 'q2')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>

                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q3}
                                        onChange={event => handleChangepersonalArray(event, item.id, 'q3')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q4}
                                        onChange={event => handleChangepersonalArray(event, item.id, 'q4')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>
                                </Col>

                            </Row>

                        </div>
                    ))}

                    <Row lg={12}>



                        <Col lg={4}></Col>
                        <Col lg={2}>
                            {personalArray.reduce((accumulator, obj) => (
                                obj.q1 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>
                        <Col lg={2}>
                            {personalArray.reduce((accumulator, obj) => (
                                obj.q2 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>

                        <Col lg={2}>{personalArray.reduce((accumulator, obj) => (
                            obj.q3 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                        <Col lg={2}>{personalArray.reduce((accumulator, obj) => (
                            obj.q4 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                    </Row>
                </Col>


                <Col lg={12} className="section-header my-4 mx-2">
                    <b>2. Communication</b>
                </Col>

                <Col lg={12}>
                    <Col lg={12} className="my-3 mx-3 row">
                        <Col lg={4}></Col>
                        <Col lg={2}>Q1</Col>
                        <Col lg={2}>Q2</Col>
                        <Col lg={2}>Q3</Col>
                        <Col lg={2}>Q4</Col>
                    </Col>
                    {communicationArray.map(item => (
                        <div key={item.id} >
                            <Row lg={12}>
                                <Col className="my-3 ">{item.id}</Col>
                                <Col lg={3} className="my-3" style={{ width: '30%' }}>

                                    {item.name}
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q1}
                                        onChange={event => handleChangecommunicationArray(event, item.id, 'q1')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>

                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q2}
                                        onChange={event => handleChangecommunicationArray(event, item.id, 'q2')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>

                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q3}
                                        onChange={event => handleChangecommunicationArray(event, item.id, 'q3')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q4}
                                        onChange={event => handleChangecommunicationArray(event, item.id, 'q4')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>
                                </Col>

                            </Row>

                        </div>
                    ))}

                    <Row lg={12}>



                        <Col lg={4}></Col>
                        <Col lg={2}>
                            {communicationArray.reduce((accumulator, obj) => (
                                obj.q1 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>
                        <Col lg={2}>
                            {communicationArray.reduce((accumulator, obj) => (
                                obj.q2 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>

                        <Col lg={2}>{communicationArray.reduce((accumulator, obj) => (
                            obj.q3 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                        <Col lg={2}>{communicationArray.reduce((accumulator, obj) => (
                            obj.q4 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                    </Row>
                </Col>


                <Col lg={12} className="section-header my-4 mx-2">
                    <b>3. Social Behaviour</b>
                </Col>

                <Col lg={12}>
                    <Col lg={12} className="my-3 mx-3 row">
                        <Col lg={4}></Col>
                        <Col lg={2}>Q1</Col>
                        <Col lg={2}>Q2</Col>
                        <Col lg={2}>Q3</Col>
                        <Col lg={2}>Q4</Col>
                    </Col>
                    {socialArray?.map(item => (
                        <div key={item.id} >
                            <Row lg={12}>
                                <Col className="my-3 ">{item.id}</Col>
                                <Col lg={3} className="my-3" style={{ width: '30%' }}>

                                    {item.name}
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q1}
                                        onChange={event => handleChangesocialArray(event, item.id, 'q1')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>

                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q2}
                                        onChange={event => handleChangesocialArray(event, item.id, 'q2')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>

                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q3}
                                        onChange={event => handleChangesocialArray(event, item.id, 'q3')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q4}
                                        onChange={event => handleChangesocialArray(event, item.id, 'q4')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>
                                </Col>

                            </Row>

                        </div>
                    ))}

                    <Row lg={12}>
                        <Col lg={4}></Col>
                        <Col lg={2}>
                            {socialArray.reduce((accumulator, obj) => (
                                obj.q1 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>
                        <Col lg={2}>
                            {socialArray.reduce((accumulator, obj) => (
                                obj.q2 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>

                        <Col lg={2}>{socialArray.reduce((accumulator, obj) => (
                            obj.q3 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                        <Col lg={2}>{socialArray.reduce((accumulator, obj) => (
                            obj.q4 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                    </Row>
                </Col>



                <Col lg={12} className="section-header my-4 mx-2">
                    <b>4. Functional Academics</b>
                </Col>

                <Col lg={12}>
                    <Col lg={12} className="my-3 mx-3 row">
                        <Col lg={4}></Col>
                        <Col lg={2}>Q1</Col>
                        <Col lg={2}>Q2</Col>
                        <Col lg={2}>Q3</Col>
                        <Col lg={2}>Q4</Col>
                    </Col>
                    {functionalArray?.map(item => (
                        <div key={item.id} >
                            <Row lg={12}>
                                <Col className="my-3 ">{item.id}</Col>
                                <Col lg={3} className="my-3" style={{ width: '30%' }}>

                                    {item.name}
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q1}
                                        onChange={event => handleChangefunctionalArray(event, item.id, 'q1')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>

                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q2}
                                        onChange={event => handleChangefunctionalArray(event, item.id, 'q2')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>

                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q3}
                                        onChange={event => handleChangefunctionalArray(event, item.id, 'q3')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>
                                </Col>
                                <Col lg={2}>
                                    <select
                                        className="form-select my-3"
                                        value={item.q4}
                                        onChange={event => handleChangefunctionalArray(event, item.id, 'q4')}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="independent">Independent</option>
                                        <option value="dependent">Dependent</option>
                                    </select>
                                </Col>

                            </Row>


                        </div>
                    ))}

                    <Row lg={12}>
                        <Col lg={4}></Col>
                        <Col lg={2}>
                            {functionalArray.reduce((accumulator, obj) => (
                                obj.q1 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>
                        <Col lg={2}>
                            {functionalArray.reduce((accumulator, obj) => (
                                obj.q2 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}
                        </Col>

                        <Col lg={2}>{functionalArray.reduce((accumulator, obj) => (
                            obj.q3 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                        <Col lg={2}>{functionalArray.reduce((accumulator, obj) => (
                            obj.q4 === 'independent' ? accumulator + 1 : accumulator
                        ), 0)}</Col>
                    </Row>



                    <Col lg={12} className="section-header my-4 mx-2">
                        <b>5. Safety Skills</b>
                    </Col>

                    <Col lg={12}>
                        <Col lg={12} className="my-3 mx-3 row">
                            <Col lg={4}></Col>
                            <Col lg={2}>Q1</Col>
                            <Col lg={2}>Q2</Col>
                            <Col lg={2}>Q3</Col>
                            <Col lg={2}>Q4</Col>
                        </Col>
                        {safetyskilsArray?.map(item => (
                            <div key={item.id} >
                                <Row lg={12}>
                                    <Col className="my-3 ">{item.id}</Col>
                                    <Col lg={3} className="my-3" style={{ width: '30%' }}>

                                        {item.name}
                                    </Col>
                                    <Col lg={2}>
                                        <select
                                            className="form-select my-3"
                                            value={item.q1}
                                            onChange={event => handleChangesafetyskilsArray(event, item.id, 'q1')}
                                        >
                                            <option value="">--Select--</option>
                                            <option value="independent">Independent</option>
                                            <option value="dependent">Dependent</option>
                                        </select>

                                    </Col>
                                    <Col lg={2}>
                                        <select
                                            className="form-select my-3"
                                            value={item.q2}
                                            onChange={event => handleChangesafetyskilsArray(event, item.id, 'q2')}
                                        >
                                            <option value="">--Select--</option>
                                            <option value="independent">Independent</option>
                                            <option value="dependent">Dependent</option>

                                        </select>
                                    </Col>
                                    <Col lg={2}>
                                        <select
                                            className="form-select my-3"
                                            value={item.q3}
                                            onChange={event => handleChangesafetyskilsArray(event, item.id, 'q3')}
                                        >
                                            <option value="">--Select--</option>
                                            <option value="independent">Independent</option>
                                            <option value="dependent">Dependent</option>
                                        </select>
                                    </Col>
                                    <Col lg={2}>
                                        <select
                                            className="form-select my-3"
                                            value={item.q4}
                                            onChange={event => handleChangesafetyskilsArray(event, item.id, 'q4')}
                                        >
                                            <option value="">--Select--</option>
                                            <option value="independent">Independent</option>
                                            <option value="dependent">Dependent</option>
                                        </select>
                                    </Col>

                                </Row>

                            </div>
                        ))}


                        <Row lg={12}>
                            <Col lg={4}></Col>
                            <Col lg={2}>
                                {safetyskilsArray.reduce((accumulator, obj) => (
                                    obj.q1 === 'independent' ? accumulator + 1 : accumulator
                                ), 0)}
                            </Col>
                            <Col lg={2}>
                                {safetyskilsArray.reduce((accumulator, obj) => (
                                    obj.q2 === 'independent' ? accumulator + 1 : accumulator
                                ), 0)}
                            </Col>

                            <Col lg={2}>{safetyskilsArray.reduce((accumulator, obj) => (
                                obj.q3 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}</Col>
                            <Col lg={2}>{safetyskilsArray.reduce((accumulator, obj) => (
                                obj.q4 === 'independent' ? accumulator + 1 : accumulator
                            ), 0)}</Col>
                        </Row>


                        <Col lg={12} className="section-header my-4 mx-2">
                            <b>6. Domestic Behaviour</b>
                        </Col>

                        <Col lg={12}>
                            <Col lg={12} className="my-3 mx-3 row">
                                <Col lg={4}></Col>
                                <Col lg={2}>Q1</Col>
                                <Col lg={2}>Q2</Col>
                                <Col lg={2}>Q3</Col>
                                <Col lg={2}>Q4</Col>
                            </Col>
                            {domesticArray?.map(item => (
                                <div key={item.id} >
                                    <Row lg={12}>
                                        <Col className="my-3 ">{item.id}</Col>
                                        <Col lg={3} className="my-3" style={{ width: '30%' }}>

                                            {item.name}
                                        </Col>
                                        <Col lg={2}>
                                            <select
                                                className="form-select my-3"
                                                value={item.q1}
                                                onChange={event => handleChangedomesticArray(event, item.id, 'q1')}
                                            >
                                                <option value="">--Select--</option>
                                                <option value="independent">Independent</option>
                                                <option value="dependent">Dependent</option>
                                            </select>

                                        </Col>
                                        <Col lg={2}>
                                            <select
                                                className="form-select my-3"
                                                value={item.q2}
                                                onChange={event => handleChangedomesticArray(event, item.id, 'q2')}
                                            >
                                                <option value="">--Select--</option>
                                                <option value="independent">Independent</option>
                                                <option value="dependent">Dependent</option>

                                            </select>
                                        </Col>
                                        <Col lg={2}>
                                            <select
                                                className="form-select my-3"
                                                value={item.q3}
                                                onChange={event => handleChangedomesticArray(event, item.id, 'q3')}
                                            >
                                                <option value="">--Select--</option>
                                                <option value="independent">Independent</option>
                                                <option value="dependent">Dependent</option>
                                            </select>
                                        </Col>
                                        <Col lg={2}>
                                            <select
                                                className="form-select my-3"
                                                value={item.q4}
                                                onChange={event => handleChangedomesticArray(event, item.id, 'q4')}
                                            >
                                                <option value="">--Select--</option>
                                                <option value="independent">Independent</option>
                                                <option value="dependent">Dependent</option>
                                            </select>
                                        </Col>

                                    </Row>

                                </div>
                            ))}


                            <Row lg={12}>
                                <Col lg={4}></Col>
                                <Col lg={2}>
                                    {domesticArray.reduce((accumulator, obj) => (
                                        obj.q1 === 'independent' ? accumulator + 1 : accumulator
                                    ), 0)}
                                </Col>
                                <Col lg={2}>
                                    {domesticArray.reduce((accumulator, obj) => (
                                        obj.q2 === 'independent' ? accumulator + 1 : accumulator
                                    ), 0)}
                                </Col>

                                <Col lg={2}>{domesticArray.reduce((accumulator, obj) => (
                                    obj.q3 === 'independent' ? accumulator + 1 : accumulator
                                ), 0)}</Col>
                                <Col lg={2}>{domesticArray.reduce((accumulator, obj) => (
                                    obj.q4 === 'independent' ? accumulator + 1 : accumulator
                                ), 0)}</Col>
                            </Row>


                            <Col lg={12} className="section-header my-4 mx-2">
                                <b>7. Mobility and Hand Functionality</b>
                            </Col>

                            <Col lg={12}>
                                <Col lg={12} className="my-3 mx-3 row">
                                    <Col lg={4}></Col>
                                    <Col lg={2}>Q1</Col>
                                    <Col lg={2}>Q2</Col>
                                    <Col lg={2}>Q3</Col>
                                    <Col lg={2}>Q4</Col>
                                </Col>
                                {mobilityArray?.map(item => (
                                    <div key={item.id} >
                                        <Row lg={12}>
                                            <Col className="my-3 ">{item.id}</Col>
                                            <Col lg={3} className="my-3" style={{ width: '30%' }}>

                                                {item.name}
                                            </Col>
                                            <Col lg={2}>
                                                <select
                                                    className="form-select my-3"
                                                    value={item.q1}
                                                    onChange={event => handleChangemobilityArray(event, item.id, 'q1')}
                                                >
                                                    <option value="">--Select--</option>
                                                    <option value="independent">Independent</option>
                                                    <option value="dependent">Dependent</option>
                                                </select>

                                            </Col>
                                            <Col lg={2}>
                                                <select
                                                    className="form-select my-3"
                                                    value={item.q2}
                                                    onChange={event => handleChangemobilityArray(event, item.id, 'q2')}
                                                >
                                                    <option value="">--Select--</option>
                                                    <option value="independent">Independent</option>
                                                    <option value="dependent">Dependent</option>

                                                </select>
                                            </Col>
                                            <Col lg={2}>
                                                <select
                                                    className="form-select my-3"
                                                    value={item.q3}
                                                    onChange={event => handleChangemobilityArray(event, item.id, 'q3')}
                                                >
                                                    <option value="">--Select--</option>
                                                    <option value="independent">Independent</option>
                                                    <option value="dependent">Dependent</option>
                                                </select>
                                            </Col>
                                            <Col lg={2}>
                                                <select
                                                    className="form-select my-3"
                                                    value={item.q4}
                                                    onChange={event => handleChangemobilityArray(event, item.id, 'q4')}
                                                >
                                                    <option value="">--Select--</option>
                                                    <option value="independent">Independent</option>
                                                    <option value="dependent">Dependent</option>
                                                </select>
                                            </Col>

                                        </Row>

                                    </div>
                                ))}

                                <Row lg={12}>
                                    <Col lg={4}></Col>
                                    <Col lg={2}>
                                        {mobilityArray.reduce((accumulator, obj) => (
                                            obj.q1 === 'independent' ? accumulator + 1 : accumulator
                                        ), 0)}
                                    </Col>
                                    <Col lg={2}>
                                        {mobilityArray.reduce((accumulator, obj) => (
                                            obj.q2 === 'independent' ? accumulator + 1 : accumulator
                                        ), 0)}
                                    </Col>

                                    <Col lg={2}>{mobilityArray.reduce((accumulator, obj) => (
                                        obj.q3 === 'independent' ? accumulator + 1 : accumulator
                                    ), 0)}</Col>
                                    <Col lg={2}>{mobilityArray.reduce((accumulator, obj) => (
                                        obj.q4 === 'independent' ? accumulator + 1 : accumulator
                                    ), 0)}</Col>
                                </Row>


                                <Col lg={12} className="section-header my-4 mx-2">
                                    <b>8. Occupational Skills</b>
                                </Col>

                                <Col lg={12}>
                                    <Col lg={12} className="my-3 mx-3 row">
                                        <Col lg={4}></Col>
                                        <Col lg={2}>Q1</Col>
                                        <Col lg={2}>Q2</Col>
                                        <Col lg={2}>Q3</Col>
                                        <Col lg={2}>Q4</Col>
                                    </Col>
                                    {occupationalArray?.map(item => (
                                        <div key={item.id} >
                                            <Row lg={12}>
                                                <Col className="my-3 ">{item.id}</Col>
                                                <Col lg={3} className="my-3" style={{ width: '30%' }}>

                                                    {item.name}
                                                </Col>
                                                <Col lg={2}>
                                                    <select
                                                        className="form-select my-3"
                                                        value={item.q1}
                                                        onChange={event => handleChangeoccupationalArray(event, item.id, 'q1')}
                                                    >
                                                        <option value="">--Select--</option>
                                                        <option value="independent">Independent</option>
                                                        <option value="dependent">Dependent</option>
                                                    </select>

                                                </Col>
                                                <Col lg={2}>
                                                    <select
                                                        className="form-select my-3"
                                                        value={item.q2}
                                                        onChange={event => handleChangeoccupationalArray(event, item.id, 'q2')}
                                                    >
                                                        <option value="">--Select--</option>
                                                        <option value="independent">Independent</option>
                                                        <option value="dependent">Dependent</option>

                                                    </select>
                                                </Col>
                                                <Col lg={2}>
                                                    <select
                                                        className="form-select my-3"
                                                        value={item.q3}
                                                        onChange={event => handleChangeoccupationalArray(event, item.id, 'q3')}
                                                    >
                                                        <option value="">--Select--</option>
                                                        <option value="independent">Independent</option>
                                                        <option value="dependent">Dependent</option>
                                                    </select>
                                                </Col>
                                                <Col lg={2}>
                                                    <select
                                                        className="form-select my-3"
                                                        value={item.q4}
                                                        onChange={event => handleChangeoccupationalArray(event, item.id, 'q4')}
                                                    >
                                                        <option value="">--Select--</option>
                                                        <option value="independent">Independent</option>
                                                        <option value="dependent">Dependent</option>
                                                    </select>
                                                </Col>

                                            </Row>

                                        </div>
                                    ))}


                                    <Row lg={12}>
                                        <Col lg={4}></Col>
                                        <Col lg={2}>
                                            {occupationalArray.reduce((accumulator, obj) => (
                                                obj.q1 === 'independent' ? accumulator + 1 : accumulator
                                            ), 0)}
                                        </Col>
                                        <Col lg={2}>
                                            {occupationalArray.reduce((accumulator, obj) => (
                                                obj.q2 === 'independent' ? accumulator + 1 : accumulator
                                            ), 0)}
                                        </Col>

                                        <Col lg={2}>{occupationalArray.reduce((accumulator, obj) => (
                                            obj.q3 === 'independent' ? accumulator + 1 : accumulator
                                        ), 0)}</Col>
                                        <Col lg={2}>{occupationalArray.reduce((accumulator, obj) => (
                                            obj.q4 === 'independent' ? accumulator + 1 : accumulator
                                        ), 0)}</Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Col>

                    </Col>
                </Col>
                <div className="App container mt-5" style={{ display: 'none' }}>
                    <div id="divToPrint">
                        <VocationalPdfFile totalVocational={totalVocational} completedata={vocdata} />
                    </div>
                </div>
            </Container>
        </>
    );
};
export default VocationalProfileEdit;
