import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import inventoryApi from "../api/authApi";
import { useState, useEffect } from "react";
const ModelPopUp = ({ handleClose, show, roleid }) => {
  const [rolesAndPermissionViewList, setRolesAndPermissionViewList] = useState(
    []
  );
  const [rolesAndPermissionListForInsert, setRolesAndPermissionListForInsert] =
    useState([]);
  const [rolesAndPermissionListForDelete, setRolesAndPermissionListForDelete] =
    useState([]);
  useEffect(() => {
    async function init1() {
      const RolesAndPermissions =
        await inventoryApi.fetchRolesAndPermissionsByRoleId(roleid);
      console.log("RolesAndPermissions", RolesAndPermissions);
      if (RolesAndPermissions) {
        const Permissions = await inventoryApi.fetchPermissions();
        console.log("Permissions", Permissions);
        let viewList = [];
        if (Permissions) {
          Permissions.forEach((permission) => {
            console.log("permission.Id", permission.id);
            console.log("Condtion", RolesAndPermissions);
            let rolePermissionDetail = RolesAndPermissions.filter(
              (permissionRole) => permission.id === permissionRole.permissionid
            );
            if (rolePermissionDetail.length > 0) {
              console.log("if", permission.id);
              viewList = [
                ...viewList,
                {
                  name: permission.name,
                  value: true,
                  id: rolePermissionDetail[0].id,
                  roleId: roleid,
                  permissionId: permission.id,
                },
              ];
            } else {
              console.log("else", permission.name);
              viewList = [
                ...viewList,
                {
                  name: permission.name,
                  id: "",
                  roleId: roleid,
                  permissionId: permission.id,
                },
              ];
            }
          });
          setRolesAndPermissionViewList(viewList);
          console.log("rolesAndPermissionViewList", rolesAndPermissionViewList);
        }
      }
    }
    init1();
  }, []);

  function handleSaveChanges() {
    setRolesAndPermissionListForInsert([]);
    setRolesAndPermissionListForDelete([]);
    let checkboxList = document.querySelectorAll('input[name="topping"]');
    console.log("checkboxList", checkboxList);
    checkboxList.forEach((rolesAndPermisison) => {
      if (
        rolesAndPermisison.checked &&
        (rolesAndPermisison.dataset.id === "" ||
          rolesAndPermisison.dataset.id === undefined)
      ) {
        rolesAndPermissionListForInsert.push({
          permissionid: rolesAndPermisison.dataset.permissionid,
          roleid: roleid,
        });
      }
      if (
        !rolesAndPermisison.checked &&
        rolesAndPermisison.dataset.id !== "" &&
        rolesAndPermisison.dataset.id !== undefined
      ) {
        rolesAndPermissionListForDelete.push({
          id: rolesAndPermisison.dataset.id,
        });
      }
    });
    console.log(
      "rolesAndPermissionListForInsert",
      rolesAndPermissionListForInsert
    );
    console.log(
      "rolesAndPermissionListForDelete",
      rolesAndPermissionListForDelete
    );
    if (rolesAndPermissionListForInsert.length > 0) {
      const result = inventoryApi.createRolesPermissions(
        rolesAndPermissionListForInsert
      );
      console.log(result);
    }
    if (rolesAndPermissionListForDelete.length > 0) {
      const result = inventoryApi.deleteRolesPermissions(
        rolesAndPermissionListForDelete
      );
      console.log(result);
    }
    handleClose();
  }

  function onTodoChange(event) {
    console.log(event)
    if(event.target.dataset.id !== '' && event.target.dataset.id !== undefined){
      console.log('evetn',event)
      setRolesAndPermissionViewList(rolesAndPermissionViewList.map((data)=>{
           if(data.permissionId ===  event.target.dataset.permissionid){
                   return {...data, value :event.target.checked }
           } else{
               return data
           }        
           }));
       }
    console.log(rolesAndPermissionViewList);
  }

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Role Permission Assignment
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {rolesAndPermissionViewList &&
            rolesAndPermissionViewList.map((permission) => {
              return (
                <div className="topping">
                  <input
                    type="checkbox"
                    className="chechbox"
                    data-id={permission.id}
                    onChange={(e) => onTodoChange(e)}
                    data-permissionid={permission.permissionId}
                    name="topping"

                    checked={permission.value}
                  />{" "}
                  {permission.name}
                </div>
              );
            })}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModelPopUp;
