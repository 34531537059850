import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import inventoryApi from "../api/inventoryApi";

import {
  DatatableWrapper,
  Filter,
  Pagination,
 
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";

const Assessmentlist = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  useEffect(() => {
    fetchAssessments();

    // let temp = [{
    //   "id" : "1234",
    //   "name" : "Test",
    //   "period" :"JAN to JUNE",
    //   "index" : "1"
    // }]

    // setBody(temp);
  }, []);

  const fetchAssessments = async(e) => {
    const result = await inventoryApi.fetchAssessments();
    if (result) {
      console.log('result', result)
      //let tempBody = result.map((data, index) => ({ ...data, index: index + 1 }));
      setBody(result);
    } else {
        setBody([]);
    }
  
  
  }
  
  // Create table headers consisting of 4 columns.
  const header = [
   
    { title: "Name", prop: "name", isFilterable: true,
      cell: (row) => (
        <Link
          to={"/assessments/" + row.id}
          state={row}
        >
          {row.name}
        </Link>)},
        
        { title: "Service Category", prop: "iepservicecategory", isFilterable: true},

        { title: "Type", prop: "category", isFilterable: true},
    { title: "Period", prop: "period", isFilterable: true}
  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
 



  const createAssessment = () => {
    navigate(`/assessments/e`);
  };

  return (
    <Row className="g-0">
      <Col lg={2} className="mx-2">
      <Link className="nav-link">
            Home <i className="fa-solid fa-chevron-right"></i> Assessments
          </Link>
      </Col>

      <Col lg={12} className="p-lg-4">
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button
                className="btn-sm"
                variant="outline-primary"
                onClick={() => createAssessment(true)}
              >
                Create Assessment
              </Button>
            </Col>
          </Row>
          {/*  {body.length>0 ? */}
          <Table striped className="data-table">
            <TableHeader />
            <TableBody />
          </Table>
          {/* :
             ""} */}
          <Pagination />
        </DatatableWrapper>
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};

export default Assessmentlist;