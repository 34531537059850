import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import inventoryApi from "../api/inventoryApi";
import { ShimmerTable } from "react-shimmer-effects";
import * as constants from '../constants/CONSTANT';
import Confirm from './Confirm';

import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { Link } from "react-router-dom";
import PubSub from 'pubsub-js';
import InfoPill from "./InfoPill";


const StudentList = () => {
    const navigate = useNavigate();
    const [body, setBody] = useState();
    const [regFormList, setRegFormList] = useState();
    const [serviceArea, setServiceArea] = useState([]);
    let [filterObj, setFilterObj] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [studentid, setstudentid] = useState('');
    const [servicearea, setservicearea] = useState([]);
    useEffect(() => {
        async function init() {
            const result = await inventoryApi.fetchServiceArea();
            console.log(result)
            if (result && result.length) {
                setservicearea(result)
            }
        }
        init();
    }, []);

    useEffect(() => {
        fetchAllStudents();

    }, []);

    useEffect(() => {
        doFilterData();
    }, [filterObj]);

    const fetchAllStudents = async () => {

        const result = await inventoryApi.fetchStudents();

        console.log(result)
        if (result) {
            setBody(result);
            setRegFormList(result);
        } else {
            setBody([]);
            setRegFormList([]);
        }
    }


    const onFilterSelection = (event) => {
        setFilterObj({ ...filterObj, [event.target.name]: event.target.value });

    }

    const doFilterData = (event) => {
        if (body) {
            let tempData = body.filter((item) => {
                if (!filterObj.servicearea || filterObj.servicearea === '' || item.servicearea === filterObj.servicearea) {
                    return item;
                }
            });
            console.log('temp data',tempData)
            console.log('filterobj',filterObj)
            tempData = tempData.filter((item) => {
                if (!filterObj.servicecategoryname || filterObj.servicecategoryname === '' || item.servicecategoryname === filterObj.servicecategoryname) {
                    return item;
                }
            });
 
            tempData = tempData.filter((item) => {
                if (!filterObj.status || filterObj.status === '' || item.status === filterObj.status) {
                    return item;
                }
            });

            setRegFormList(tempData);
        }

    }


    // const deleteStudent = async (studentid) => {
    //     const result = await inventoryApi.deleteStudent(studentid);
    //     if (result.success) {
    //         setstudentid('');
    //         setModalShow(false);
    //         PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Delete Record Successfully' });
    //         console.log('result', result);
    //         fetchAllStudents();
    //       }

    // }

    const handleDelete = (row) => {
        setModalShow(true);
        setstudentid(row)
    }

    const deleteStudent = async () => {
        console.log('studentid', studentid)
        const result = await inventoryApi.deleteStudent(studentid.id);
        console.log('result', result)
        if (result.success === true) {
            setModalShow(false);
            fetchAllStudents();

        }
    }

    const header = [
        { title: 'Reg No', prop: 'regnumber', isFilterable: true, isSortable: true },
        {
            title: 'Student Name', prop: 'name', isFilterable: true, isSortable: true,
            cell: (row) => (
                <Link
                    to={"/students/" + row.id}
                    state={row}
                >
                    {row.name}
                </Link>
            )
        },
        { title: 'Age', prop: 'age', isFilterable: true },
        { title: 'Father Name', prop: 'fathername', isFilterable: true },
        { title: 'Phone', prop: 'phone', isFilterable: true },
        { title: 'Gender', prop: 'gender', isFilterable: true },
        { title: 'Address', prop: 'address', isFilterable: true },
        { title: 'Service Area', prop: 'servicearea', isFilterable: true, isSortable: true },
        { title: 'Status', prop: 'status', isFilterable: true },
        {
            title: "Action",
            prop: "id",
            cell: (row) => (
                <Button variant="danger" className="btn-sm mx-2" onClick={() => handleDelete(row)}>
                    <i className="fa fa-trash" ></i>
                </Button>
            ),
        },
    ];

    // Note that the fields are all using the `prop` field of the headers.
    const labels = {
        beforeSelect: " "
    }

    const createStudent = () => {
        navigate(`/students/e`);
    }

    return (
        <Row className="g-0">
            {modalShow &&
                <Confirm
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    deleteStudent={deleteStudent}
                    title="Confirm delete?"
                    message="You are going to delete the record. Are you sure?"
                    table="student"
                />}
            <Col lg={5} className="mx-2">
                <Link className="nav-link">Home <i className="fa-solid fa-chevron-right"></i> Student Registration List</Link>
            </Col>
            <Col lg={12} className="p-lg-4">

                {regFormList ?
                    <DatatableWrapper body={regFormList} headers={header} paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 20,
                            options: [5, 10, 15, 20]
                        }
                    }}>


                        <Row className="mb-4">
                            <Col
                                xs={12}
                                lg={2}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Filter />

                            </Col>
                            <Col
                                xs={12}
                                sm={4}
                                lg={2}
                                className="d-flex flex-col justify-content-start align-items-start"
                            >

                                <Form.Group className="mx-3 mt-4" controlId="formBasicServiceCategory">
                                    <Form.Select aria-label="Enter status" name="servicearea" onChange={onFilterSelection}>
                                        <option value="">--Service Category--</option>
                                        <option value="School">School</option>
                                        <option value="CBR">CBR</option>
                                        <option value="Day Care">Day Care</option>
                                    </Form.Select>
                                </Form.Group>

                            </Col>

                            <Col
                                xs={12}
                                sm={4}
                                lg={2}
                                className="d-flex flex-col justify-content-start align-items-start"
                            >

                                <Form.Group className="mx-3 mt-4" controlId="formBasicServiceCategory">
                                    <Form.Select aria-label="Enter status" name="servicecategoryname" onChange={onFilterSelection}>
                                        <option value="">--Service Area--</option>
                                        {servicearea.map((value, index, array) => (
                                            <option key={index} value={value.name}>
                                                {value.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>


                            </Col>

                            <Col
                                xs={12}
                                sm={2}
                                lg={4}
                                className="d-flex flex-col justify-content-start align-items-center"
                            >

                                <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                                    <Form.Select aria-label="Enter status" name="status" onChange={onFilterSelection}>
                                        <option value="">--All Students--</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Rejected">Rejected</option>
                                        <option value="Dropout">Dropout</option>
                                    </Form.Select>
                                </Form.Group>
                                <InfoPill left="Count" right={regFormList?.length} />

                            </Col>


                            <Col
                                xs={12}
                                sm={6}
                                lg={2}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Button className="btn-sm" variant="outline-primary" onClick={() => createStudent(true)}>New Registration</Button>
                            </Col>
                        </Row>
                        <Table striped className="data-table">
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Pagination />

                    </DatatableWrapper> : <ShimmerTable row={10} col={8} />}
            </Col>
            <Col lg={2}></Col>
        </Row>
    );
};

export default StudentList;

