import React, { useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import inventoryApi from "../api/inventoryApi";
import PubSub from 'pubsub-js';


const ChangePassword = ({ userId }) => {
    const [validated, setValidated] = useState(false);
    const [user, setUser] = useState({password: '', confirmpassword: ''});
    const handleChange = (e) => {
        console.log(e.target.value)
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return; // Exit early if form is not valid
        }
    
        setValidated(true);
        
        console.log('userId', userId);
        console.log('user', user);
        console.log('password',user.password)
        console.log('confirmpassword',user.confirmpassword)

        // Check if password and confirm password match and are not empty
        if (user.password === user.confirmpassword && user.password !== '' && user.confirmpassword !== '') {
            try {
                // const result = await inventoryApi.updateUser(user);
                // console.log('Password updated successfully:', result);
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Password updated successfully' });
            } catch (error) {
                console.error('Error updating user:', error);
                // Handle error here if needed
            }
        } else {
            console.log('Please enter a valid password.');
            // Optionally display a message to the user indicating that passwords don't match or are empty
        }
    };
    

    return (
        <div>
            <Container className="view-form">
                <Row>
                    <Col></Col>
                    <Col lg={8}>
                        <Form className="mt-3" onSubmit={handleSubmit} validated={validated}>
                            <Row className="view-form-header align-items-center">
                                <Col lg={3}>
                                    Change Password
                                </Col>
                                <Col lg={9} className="d-flex justify-content-end">
                                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                        Update
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mx-3" controlId="formBasicFirstName">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicFirstName"
                                        >
                                          New Password
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder="Enter Your password"
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mx-3" controlId="formBasicPhone">
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicPhone"
                                        >
                                            Confirm Password
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="confirmpassword"
                                            placeholder="Enter confirm password"
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                           
                        </Form>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChangePassword;
