import React from 'react'

export const SpeechHearingPdf = ({ speechData }) => {
  console.log('speechData==>', speechData)
  return (
    <div
      className="d-flex justify-content-center border"
      style={{ fontSize: "10px" }}
    >
      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h5>Speech Hearing Assessment</h5>
          </td>
        </tr>
      </table>
      <hr></hr>


      <table>
        <tr style={{ backgroundColor: "#DFE0E1" }}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b><p>
              Minu Manovikas Mandir Inclusive School Village - Chachiyawas Sikar
              Road Distt Ajmer 305023 "Sanjay Inclusive School"Seth
              Ghishu Lal Munot ColonyOut Side Nehru Gate,Beawar Distt,Ajmer
              "ADWAIT" An Early Intervention Center Early Intervention
              Centre C Block,Panchsheel Nagar Near Kshetrapal Hospital Makdwali
              Road,Ajmer Ummeed Day Care Centre C/O Old Rangji
              Temple,Pushkar - 305001
            </p></b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>

      <table>
        <tr style={{ backgroundColor: "#DFE0E1" }}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>PART-1 CASE PARTICULARS:</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Case Name : {speechData.childname}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Age : {speechData.age}</td>

        </tr>
      </table>
      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Case No: {speechData.caseno}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Date : {speechData.date}</td>

        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Information: {speechData.imformation}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Date of Birth : {speechData.dob}</td>

        </tr>
      </table>
      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Referred By: {speechData.referby}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Address: {speechData.address}</td>

        </tr>
      </table>


      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Phone: {speechData.phoneno}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Village/House No: {speechData.village}</td>

        </tr>
      </table>
      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Place: {speechData.place}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>P.O: {speechData.po}</td>

        </tr>
      </table>


      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>P.S: {speechData.ps}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Dist.: {speechData.dist}</td>

        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Community:: {speechData.community}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>PIN: {speechData.pincode}</td>

        </tr>
      </table>


      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Mother Tongue: {speechData.motherto}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Language Know: {speechData.languageknow}</td>

        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Religion: {speechData.religion}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}></td>

        </tr>
      </table>
      {/* <table>
        <th style={{ border: "1px solid gray", padding: "10px", width: '20%' }}> Religion: {speechData.religion}</td>
        <td style={{ border: "1px solid gray", padding: "10px", width: '20%' }}></td>
        <td style={{ border: "1px solid gray", padding: "10px", width: '20%' }}> Religion: {speechData.religion}</td>
        <td style={{ border: "1px solid gray", padding: "10px", width: '20%' }}></td>

      </table> */}
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>Parental Information</th>
            <th>Name</th>
            <th>Age</th>
            <th>Education</th>
            <th>Occupation</th>
            <th>Income Slab</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Mother</td>
            <td>{speechData.mothername}</td>
            <td>{speechData.motherage}</td>
            <td>{speechData.mothereducation}</td>
            <td>{speechData.motherocc}</td>
            <td>{speechData.motherincome}</td>
          </tr>
          <tr>
            <td>Father</td>
            <td>{speechData.fathername}</td>
            <td>{speechData.fatherage}</td>
            <td>{speechData.fathereducation}</td>
            <td>{speechData.fatherocc}</td>
            <td>{speechData.fatherincome}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>                                     Consanguinity: (Specify relationship)
            : {speechData.consan}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Family History: Nuclear /Joint Family : {speechData.familyhistory}</td>

        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  General complaint : {speechData.generalcomplaint}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Pedigree: Check in student files</td>

        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  Purpose of Visiting:{speechData.purposevisiting}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Age at which hearing/speech-language difficulty was noticed: {speechData.agediffculty}</td>

        </tr>
      </table>


      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  Earlier treatment/Investigation/Hearing aid used: :{speechData.earliertreatment}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Probable cause of problem : {speechData.probablecause}</td>

        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  Progress of problem: Static/Improving/Deteriorating::{speechData.progressproblem}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Amount of quality time spend with the child (Hours)  : {speechData.amountquality}</td>

        </tr>
      </table>
      <table>
        <tr style={{ backgroundColor: "#DFE0E1" }}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>MEDICAL HISTORY::</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>
      <table>
        <tr style={{ backgroundColor: "#DFE0E1" }}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>PART-2 DEVELOPMENTAL HISTORY:(LANGUAGE DEVELOPMENT:PARENTAL
              PERCEPTION)</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>

      <table>
        <tr style={{}}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>LANGUAGE DEVELOPMENT:PARENTAL PERCEPTION-</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>
      <hr></hr>
      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '100%' }}>{speechData.parentalperception}</td>

        </tr>
      </table>
      <table>
        <tr>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b> AUDITORY BEHAVIOUR:</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>
      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '100%' }}>{speechData.auditorybehaviour}</td>

        </tr>
      </table>
      <table>
        <tr>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b> Parental Perception</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>
      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '100%' }}>{speechData.motordevelopement}</td>

        </tr>
      </table>
      <table>
        <tr>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b> Handedness</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>
      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  Grasp : {speechData.handnessgrasp}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Writing : {speechData.amountquality}</td>

        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  Eating : {speechData.handnesseating}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Play with toys : {speechData.handnessplaywithtoy}</td>

        </tr>
      </table>


      <table>
        <tr style={{}}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b> SOCIAL/COGNITIVE DEVELOPMENT:</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>


      <table>
        <tr style={{}}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b> Parental Perception:</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>{speechData.socialcognativeparentalperception}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}></td>

        </tr>
      </table>


      <table>
        <tr style={{}}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>  EDUCATION HISTORY:</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>{speechData.educationhistory}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}></td>

        </tr>
      </table>

      <table>
        <tr style={{}}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>  RECOMMENDATION</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>
      {speechData?.recommendation && Array.isArray(speechData.recommendation) && speechData.recommendation.map((item, index) => (
        <table key={index}>
          <tr>
            <td style={{ border: "1px solid gray", padding: "10px", width: '100%' }}>{item.value}</td>
          </tr>
        </table>
      ))}


      <table>
        <tr style={{}}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>  REMARKS </b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>{speechData.remark}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}></td>

        </tr>
      </table>

      <table>
        <tr style={{}}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>  (Name & Signature) </b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>
    </div>

  )
}
