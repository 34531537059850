import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";

import moment from "moment"

import {
  DatatableWrapper,
  Filter,
  Pagination,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";

const IEPList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  useEffect(() => {
    fetchAllAssessment();

    
  }, []);
  const fetchAllAssessment =async()=>{
  
      const result = await inventoryApi.fetchAssessments();
      if (result) {
        console.log('result', result)
        //let tempBody = result.map((data, index) => ({ ...data, index: index + 1 }));
        setBody(result);
      } else {
          setBody([]);
      }
    
    
    
  
  }
  // Create table headers consisting of 4 columns.
  const header = [
    
    { title: "Category", prop: "category", isFilterable: true ,
    cell: (row) => (
      <Link
          to={"/assessments/" + row.id}
          state={row}
      >
          {row.category}
      </Link>
  )
  
  },
    { title: "Start Date", prop: "startdate", isFilterable: true,cell: (row) => moment(row.startdate).format("DD-MM-YYYY"), },
    { title: "End Date", prop: "enddate", isFilterable: true,cell: (row) => moment(row.enddate).format("DD-MM-YYYY"), },
    { title: "Status", prop: "status", isFilterable: true },
    { title: "Staff Name", prop: "staffname", isFilterable: true },
    { title: "Remarks", prop: "remarks", isFilterable: true },
    // {
    //   title: "Actions",
    //   prop: "id",
    //   cell: (row) => (
    //     <Button className="btn-sm mx-2" onClick={() => editIEP({ row })}>
    //       <i className="fa-regular fa-pen-to-square"></i>
    //     </Button>
    //   ),
    // },
  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.

  const editIEP = (row) => {
    console.log("row", row);
    navigate(`/ieps/${row.row.id}/e`, { state: row });
  };

  const createIEP = () => {
    navigate(`/ieps/e`);
  };

  return (
    <Row className="g-0">
      <Col lg={2} className="mx-2">
        <Link className="nav-link">
          Assessments <i className="fa-solid fa-chevron-right"></i> IEP
        </Link>
      </Col>

      <Col lg={10} className="p-lg-4">
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button
                className="btn-sm"
                variant="outline-primary"
                onClick={() => createIEP(true)}
              >
                Create IEP
              </Button>
            </Col>
          </Row>
          {/*  {body.length>0 ? */}
          <Table striped className="data-table">
            <TableHeader />
            <TableBody />
          </Table>
          {/* :
             ""} */}
          <Pagination />
        </DatatableWrapper>
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};

export default IEPList;
