import React from 'react'

const StudentBehaviourAssessPdf = ({ result, workbehavetable, workbehavecount, totalcountanalysis, JobAnalysisAreas, skillAreas, totalcount }) => {
    console.log('result==>', result)

    return (
        <div>




            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None", textAlign: "center" }}>
                        <h5>Rajasthan Mahila Kalyan Mandal</h5>
                        <h6>Student VAPS Information</h6>
                        <h6>Rajasthan </h6>
                    </td>
                </tr>
            </table>
            <table>
                <tr>
                    <td style={{ width: "100%", border: "None", backgroundColor: "#DFE0E1" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>Vocational Data
                        </b></h4>
                    </td>
                </tr>
            </table>

            <table style={{ width: "100%" }}>
                <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }}>1. Identification data</h6>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px" }}> Name of the trainee : {result?.data?.vocProfile?.traineename}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}> Date of Birth : {result?.data?.vocProfile?.sexagedob}</td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px" }}> Level of Mental Retardation : {result?.data?.vocProfile?.mentalretardational}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}> Marital status of the trainee : {result?.data?.vocProfile?.marital}</td>
                </tr>

                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>Father name : {result?.data?.vocProfile?.fatherguardianoccupaddr}</td>
                    <td></td>
                </tr>

                {/* <tr >
                    <td style={{ border: "1px solid gray", padding: "10px" }}> Name of the trainee : {result?.data?.vocProfile?.parentmthincome}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}></td>
                </tr> */}
            </table>

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }}>
                        <h4 style={{ fontSize: "17px" }}> <b>2. Family History (Pedigree Chart) (Check this chart  in file section file)</b></h4>
                    </td>
                    <td style={{ width: "265px", border: "None" }}></td>
                </tr>
            </table>
            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >3. Socio economics status</h6>
            <table style={{ width: "100%" }}>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px" }}>3.1 Monthly Income of Parents: {result?.data?.vocProfile?.parentmthincome}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>3.2 Rural / semi urban / urban : {result?.data?.vocProfile?.rur_semiurb_urb}</td>
                </tr>

            </table>
            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >4. Generic skills- Rediness skills based on generic skills assessment checklist(page nos:6-10)</h6>
            <table style={{ width: "100%" }}>

                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>41. Personal skills {result?.data?.vocProfile?.persskills}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>4.2 Communication {result?.data?.vocProfile?.comm}</td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>4.3 Social behavior {result?.data?.vocProfile?.funacademics}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>4.4 Functional academics{result?.data?.vocProfile?.safetyskills}</td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>4.5 Safety skills{result?.data?.vocProfile?.safetyskills}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>4.6 Domestic behaviour{result?.data?.vocProfile?.domesticbehav}</td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>4.7 Mobility and hand functioning{result?.data?.vocProfile?.mobilityhandfunc}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>4.8 Occupational{result?.data?.vocProfile?.occupational
                    }</td>
                </tr>
            </table>
            <table table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>5. Associated conditions : {result?.data?.vocProfile?.assoccondition}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>6. Training received:  {result?.data?.vocProfile?.trainingrecv}</td>

                </tr>
            </table>


            <table table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>7. Daily routines {result?.data?.vocProfile?.dailyroutines}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}></td>
                </tr>
            </table>


            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >8. Experience in employment</h6>
            <table table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>8.1 Domestic works {result?.data?.vocProfile?.domesticworks}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>8.2 IF Employed where? what type of work? salary / wages{result?.data?.vocProfile?.employedwhere}</td>
                </tr>
            </table>

            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >9. Possibilities of employment</h6>
            <table table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>9.1 In what type of employement family involved {result?.data?.vocProfile?.typeofemp}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>9.2 Potential employment opprtunities in the neighbourhood{result?.data?.vocProfile?.empoppinneighbour}</td>
                </tr>
            </table>
            <table>
                <td style={{ border: "1px solid gray", padding: "10px" }}>9.3 Possibilities of self employment {result?.data?.vocProfile?.selfemppossibility}</td>
                <td style={{ border: "1px solid gray", padding: "10px" }}>9.4 What type of work the trainee wants to do{result?.data?.vocProfile?.typeofworktraineewant}</td>

            </table>
            <table>
                <td style={{ border: "1px solid gray", padding: "10px" }}>9.5 What type of support the parents expect for their son/daughter{result?.data?.vocProfile?.parentexpectsupportson}</td>
                <td style={{ border: "1px solid gray", padding: "10px" }}>9.6 What type of support the parents expect for employment of their son/daughter{result?.data?.vocProfile?.parentsemployment}</td>

            </table>
            <table table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>10. Areas in which guidance required {result?.data?.vocProfile?.areaguidancereq}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}></td>
                </tr>
            </table>
            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >11.1 Suitable Job Selected - First choice Reasons for selection</h6>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                {Array.isArray(result?.data?.vocationaldata[8]) && result?.data?.vocationaldata[8].map((item, index) => (
                    <tr key={item.id}>
                        <td>{item.name}</td>
                    </tr>
                ))}
            </table>

            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >11.2 Suitable Job Selected - Second choice Reasons for selection</h6>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                {Array.isArray(result?.data?.vocationaldata[9]) && result?.data?.vocationaldata[9].map((item, index) => (
                    <tr key={item.id}>
                        <td>{item.name}</td>

                    </tr>
                ))}
            </table>

            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >11.3 Suitable Job Selected - third choice Reasons for selection</h6>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                {Array.isArray(result?.data?.vocationaldata[10]) && result?.data?.vocationaldata[10].map((item, index) => (
                    <tr key={item.id}>
                        <td>{item.name}</td>

                    </tr>
                ))}
            </table>



            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >1. PERSONAL</h6>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                {Array.isArray(result?.data?.vocationaldata[0]) && result?.data?.vocationaldata[0].map((item, index) => (
                    <tr key={item.id}>
                        <th>{item.name}</th>
                        <td>{item.q1}</td>
                        <td>{item.q2}</td>
                        <td>{item.q3}</td>
                        <td>{item.q4}</td>
                    </tr>
                ))}
            </table>
            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >2. Communication</h6>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                {Array.isArray(result?.data?.vocationaldata[1]) && result?.data?.vocationaldata[1].map((item, index) => (
                    <tr key={item.id}>
                        <th>{item.name}</th>
                        <td>{item.q1}</td>
                        <td>{item.q2}</td>
                        <td>{item.q3}</td>
                        <td>{item.q4}</td>
                    </tr>
                ))}
            </table>
            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >3. Social Behaviour</h6>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                {Array.isArray(result?.data?.vocationaldata[2]) && result?.data?.vocationaldata[2].map((item, index) => (
                    <tr key={item.id}>
                        <th>{item.name}</th>
                        <td>{item.q1}</td>
                        <td>{item.q2}</td>
                        <td>{item.q3}</td>
                        <td>{item.q4}</td>
                    </tr>
                ))}
            </table>
            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >4. Functional Academics</h6>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                {Array.isArray(result?.data?.vocationaldata[3]) && result?.data?.vocationaldata[3].map((item, index) => (
                    <tr key={item.id}>
                        <th>{item.name}</th>
                        <td>{item.q1}</td>
                        <td>{item.q2}</td>
                        <td>{item.q3}</td>
                        <td>{item.q4}</td>
                    </tr>
                ))}
            </table>
            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >5. Safety Skills</h6>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                {Array.isArray(result?.data?.vocationaldata[4]) && result?.data?.vocationaldata[4].map((item, index) => (
                    <tr key={item.id}>
                        <th>{item.name}</th>
                        <td>{item.q1}</td>
                        <td>{item.q2}</td>
                        <td>{item.q3}</td>
                        <td>{item.q4}</td>
                    </tr>
                ))}
            </table>
            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >6. Domestic Behaviour</h6>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                {Array.isArray(result?.data?.vocationaldata[5]) && result?.data?.vocationaldata[5].map((item, index) => (
                    <tr key={item.id}>
                        <th>{item.name}</th>
                        <td>{item.q1}</td>
                        <td>{item.q2}</td>
                        <td>{item.q3}</td>
                        <td>{item.q4}</td>
                    </tr>
                ))}
            </table>
            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >7. Mobility and Hand Functionality</h6>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                {Array.isArray(result?.data?.vocationaldata[6]) && result?.data?.vocationaldata[6].map((item, index) => (
                    <tr key={item.id}>
                        <th>{item.name}</th>
                        <td>{item.q1}</td>
                        <td>{item.q2}</td>
                        <td>{item.q3}</td>
                        <td>{item.q4}</td>
                    </tr>
                ))}
            </table>

            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >8. Occupational Skills</h6>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                {Array.isArray(result?.data?.vocationaldata[7]) && result?.data?.vocationaldata[7].map((item, index) => (
                    <tr key={item.id}>
                        <th>{item.name}</th>
                        <td>{item.q1}</td>
                        <td>{item.q2}</td>
                        <td>{item.q3}</td>
                        <td>{item.q4}</td>
                    </tr>
                ))}
            </table>
            <h6 style={{ fontSize: "17px", width: "100%", border: "None", backgroundColor: "#DFE0E1" }} > Generic Skills Assessment CheckList Score for all sections</h6>

            <table style={{ width: '100%', border: 'None' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Skill Areas</th>
                        <th>Total </th>
                        <th>Periodical Evaluation</th>
                    </tr>
                </thead>
                <tbody>
                    {skillAreas?.map((value, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{value.name}</td>
                            <td>{value.count}</td>
                            <td></td>
                        </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td>Total</td>
                        <td>{totalcount}</td>
                        <td></td>
                    </tr>


                </tbody>

            </table>

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None", backgroundColor: "#DFE0E1" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>JOB ANALYSIS FORMAT
                        </b></h4>
                    </td>
                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>1. Job Selected

                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table style={{ width: "100%" }}>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px" }}> Date : {result?.data?.fieldsData?.jobanalysisdate}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}> Job Title : {result?.data?.fieldsData?._1_1j}</td>

                </tr>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px" }}> Job Site : {result?.data?.fieldsData?._1_2j}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}> Job trainee : {result?.data?.fieldsData?._1_3j}</td>

                </tr>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px" }}> Job programmer : {result?.data?.fieldsData?._1_4j}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}></td>
                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>2. Main work areas


                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>



            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
                <tbody>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.jobanalysisdata[0]) &&
                    result?.data?.jobanalysisdata[0]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
                    </tbody>
            </table>



            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>3. Additional duties/tasks



                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>

            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
            <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.jobanalysisdata[1]) &&
                    result?.data?.jobanalysisdata[1]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
            </table>





            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>4. Work Related Skills (Personal)
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
            <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.jobanalysisdata[2]) &&
                    result?.data?.jobanalysisdata[2]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
            </table>



            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>Functional Academics
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>

            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
            <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.jobanalysisdata[3]) &&
                    result?.data?.jobanalysisdata[3]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
            </table>
            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>Sex Education
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>

            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
            <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.jobanalysisdata[4]) &&
                    result?.data?.jobanalysisdata[4]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
            </table>

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>Recreational

                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>

            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
            <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.jobanalysisdata[5]) &&
                    result?.data?.jobanalysisdata[5]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
            </table>

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>Independent Living


                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>

            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
            <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.jobanalysisdata[6]) &&
                    result?.data?.jobanalysisdata[6]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
            </table>


            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>6. Job requirements
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> 1. Equipments and materials : {result?.data?.fieldsData?._6_1_1}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>2 Cost of production : {result?.data?.fieldsData?._6_1_2}</td>
                </tr>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> 3. Profit expected: {result?.data?.fieldsData?._6_1_3}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>4. Adaptive devices: {result?.data?.fieldsData?._6_1_4}</td>
                </tr>

            </table>
            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>7. Job Training Procedures

                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>1. Training Strategies : {result?.data?.fieldsData?._7_1_1}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>2. Training in simulated setting : {result?.data?.fieldsData?._7_1_2}</td>
                </tr>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> 3. On the Job training : {result?.data?.fieldsData?._7_1_3}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>4. Reinforcement/wages/salary: {result?.data?.fieldsData?._7_1_4}</td>
                </tr>

            </table>
            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>8 Job placement


                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>

            <table>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>a. Open/ Supported/group/sheltered : {result?.data?.fieldsData?._7_1_5_1_1a}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>b.Self/home based : {result?.data?.fieldsData?._7_1_5_1_1b
                    }</td>
                </tr>


            </table>

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>9 Trainer's responsibilities


                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>1. Employer contacts : {result?.data?.fieldsData?._8_1_1}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> 2. Co-workers awareness: {result?.data?.fieldsData?._8_1_2}</td>
                </tr>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> 3. Ongoing assessment: {result?.data?.fieldsData?._8_1_3}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> 4. Continued support and fading out : {result?.data?.fieldsData?._8_1_4}</td>
                </tr>
            </table>
            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>10. Parents Cooperation
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px" }}>1. Checking regularity and punctuality : {result?.data?.fieldsData?._9_1_1}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>2. Providing healthy food / lunch: {result?.data?.fieldsData?._9_1_2}</td>
                </tr>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px" }}> 3. Implementation of programme: {result?.data?.fieldsData?._9_1_3}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}> 4. Discussion with employer : {result?.data?.fieldsData?._9_1_4}</td>
                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None", backgroundColor: "#DFE0E1" }} className="section-header my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>Job Analysis Data
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table style={{ width: '100%', border: 'None' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Skill Areas</th>
                        <th>Total</th>
                        <th>Periodical Evaluation</th>
                    </tr>
                </thead>
                <tbody>
                    {JobAnalysisAreas?.map((value, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{value.name}</td>
                            <td>{value.count}</td>
                            <td>{/* Fill this with appropriate data for Periodical Evaluation */}</td>
                        </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td>Total</td>
                        <td>{totalcountanalysis}</td>
                        <td>{/* Fill this with appropriate data for Total Periodical Evaluation */}</td>
                    </tr>
                </tbody>
            </table>


            {/* 


            

            

           

            

           */}

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None", backgroundColor: "#DFE0E1" }} >
                        <h4 style={{ fontSize: "12px" }}> <b>WORK BEHAVIOUR ASSESSMENT CHECKLIST FOR PERSONS WITH MENTAL RETARDATION
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>1. General Information
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>

            <table>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px", width: "50%" }}> 1. Name of the Special Employee : {result?.data?.fieldsData?._5_2_1_g}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: "50%" }}>2 Age/Sex : {result?.data?.fieldsData?._5_2_2_g}</td>
                </tr>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px" }}> 3.Level of Mental Retardation : {result?.data?.fieldsData?._5_2_3_g}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}>4 Job site : {result?.data?.fieldsData?._5_2_4_g}</td>
                </tr>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px" }}> 5. Job Identified : {result?.data?.fieldsData?._5_2_5_g}</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}></td>
                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>2. Report (Based on the checklist)
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> 1. Physical appearance : {result?.data?.fieldsData?._5_2_1_r}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>2 Personal interaction : {result?.data?.fieldsData?._5_2_2_r}</td>
                </tr>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>3 Regularity and punctuality : {result?.data?.fieldsData?._5_2_3_r}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>4. Communication and Social manners : {result?.data?.fieldsData?._5_2_4_r}</td>
                </tr>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> 5. Quality and quantity aspects : {result?.data?.fieldsData?._5_2_5_r
                    }</td>
                    <td style={{ border: "1px solid gray", padding: "10px" }}></td>
                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ width: "100%", border: "None" }} className="my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>Comments


                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>

            <table>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px", width: '100%' }}> Comment : {result?.data?.fieldsData?._5_3_c}</td>
                    {/* <td style={{ border: "1px solid gray", padding: "10px" }}></td> */}
                </tr>
            </table>
            <table style={{ width: "100%" }}>
                <tr >
                    <td style={{ border: "1px solid gray", padding: "10px" }}> Date : {result?.data?.workerData?.workbehavedate}</td>
                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ width: "600px", border: "None" }}>
                        <h4 style={{ fontSize: "17px" }}> <b>1. Physical_appearance</b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
            <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.workbehavedata[0]) &&
                    result?.data?.workbehavedata[0]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
            </table>

            <table>
                <tr>
                    <td style={{ width: "600px", border: "None" }}>
                        <h4 style={{ fontSize: "17px" }}> <b>2. Personal Intrection
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
            <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.workbehavedata[1]) &&
                    result?.data?.workbehavedata[1]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
            </table>

            <table>
                <tr>
                    <td style={{ width: "600px", border: "None" }}>
                        <h4 style={{ fontSize: "17px" }}> <b>3. Regularity and punctuality
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
            <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.workbehavedata[2]) &&
                    result?.data?.workbehavedata[2]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
            </table>
            <table>
                <tr>
                    <td style={{ width: "600px", border: "None" }}>
                        <h4 style={{ fontSize: "17px" }}> <b>4. Communication /Social Manner</b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
            <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.workbehavedata[3]) &&
                    result?.data?.workbehavedata[3]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
            </table>
            <table>
                <tr>
                    <td style={{ width: "600px", border: "None" }}>
                        <h4 style={{ fontSize: "17px" }}> <b>5. Quality and Quantity Aspects of Work
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>
            <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
            <thead>
                    <tr>
                        <th>Name</th>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                    </tr>
                </thead>
                {Array.isArray(result?.data?.workbehavedata[4]) &&
                    result?.data?.workbehavedata[4]?.map((item, index) => (
                        <tr key={item.id}>
                            <th>{item.name}</th>
                            <td>{item.q1}</td>
                            <td>{item.q2}</td>
                            <td>{item.q3}</td>
                            <td>{item.q4}</td>
                        </tr>
                    ))}
            </table>
            <table>
                <tr>
                    <td style={{ width: "100%", border: "None", backgroundColor: "#DFE0E1" }} className="section-header my-3">
                        <h4 style={{ fontSize: "17px" }}> <b>Work Behaviour Assessment Data
                        </b></h4>
                    </td>
                    <td style={{ border: "None" }}></td>
                </tr>
            </table>


            <table style={{ width: '100%', border: 'None' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Areas</th>
                        <th>Total </th>
                        <th>Periodical Evaluation </th>
                    </tr>
                </thead>
                <tbody>
                    {workbehavetable?.map((value, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{value.name}</td>
                            <td>{value.count}</td>
                            <td></td>
                        </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td>Total</td>
                        <td>{workbehavecount}</td>
                        <td></td>
                    </tr>


                </tbody>

            </table>


        </div>
    )
}

export default StudentBehaviourAssessPdf