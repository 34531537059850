import React, { useEffect, useState } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BarChart from './charts/BarChart';
import PieChart from './charts/PieChart';
import inventoryApi from '../api/inventoryApi';

const Home = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const rec = await inventoryApi.fetchDashBoardData();
      console.log('rec',rec)
      setData(rec);
    };
    fetchData();
  }, []);
console.log('call home page 1')
  return (
    <Container>
      <Row>
        
        <Col lg={3}>
          <Link to="/serviceareas" className="text-decoration-none text-reset">
            <div
              className="p-3 d-flex align-items-center m-3"
              style={{ backgroundColor: 'white', borderLeft: '4px solid #1a293b' }}
            >
              <span className="fa-stack small">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#1a293b' }}></i>
                <i className="fa-solid fa-building fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">BRANCHES</h6>
                <h1 className="mb-0 d-inline">{data?.branch}</h1>
                <Badge bg="light" text="dark">
                  Running
                </Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/students" className="text-decoration-none text-reset">
            <div
              className="p-3 d-flex align-items-center m-3"
              style={{ backgroundColor: 'white', borderLeft: '4px solid tomato' }}
            >
              <span className="fa-stack small">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: 'tomato' }}></i>
                <i className="fa-solid fa-child-reaching fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">STUDENTS</h6>
                <h1 className="mb-0 d-inline">{data?.student}</h1>
                <Badge bg="light" text="dark">
                  Total
                </Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/staffmembers" className="text-decoration-none text-reset">
            <div
              className="p-3 d-flex align-items-center m-3"
              style={{ backgroundColor: 'white', borderLeft: '4px solid #198754' }}
            >
              <span className="fa-stack small">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#198754' }}></i>
                <i className="fa-solid fa-user-tie fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">STAFF</h6>
                <h1 className="mb-0 d-inline">{data?.staff}</h1>
                <Badge bg="light" text="dark">
                  Active
                </Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/assessments" className="text-decoration-none text-reset">
            <div
              className="p-3 d-flex align-items-center m-3"
              style={{ backgroundColor: 'white', borderLeft: '4px solid #4798b5' }}
            >
              <span className="fa-stack small">
                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#4798b5' }}></i>
                <i className="fa-solid fa-list-check fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">ASSESSMENTS</h6>
                <h1 className="mb-0 d-inline">{data?.assessment}</h1>
                <Badge bg="light" text="dark">
                  In Progress
                </Badge>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      <Row className="mt-5">
        {/* <Col lg={6} className="text-center">
          <p>Branch wise count</p>
          <div style={{ height: '400px' }}>
            <BarChart />
          </div>
        </Col> */}
        <Col lg={6} className="text-center">
          <p className="d-block"></p>
          <div className="text-center" style={{ height: '400px' }}>
            <p>Branch wise performance</p>
            <PieChart />
          </div>
        </Col> 
      </Row>
    </Container>
  );
};

export default Home;
