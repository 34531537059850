import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Pagination,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { Link } from "react-router-dom";

const RelatedListStudents = (props) => {

    const[body, setBody] = useState([])

    useEffect(()=>{
        console.log('std')
        console.log('student prop =>',props)
        async function init() {
            
           console.log(props)
            if (props.students) {
                console.log('if part')
                setBody(props.students);
            } else {
                console.log('else part')
                setBody([]);
            }
        }
        init();
    },[])

    // Create table headers consisting of 4 columns.
    const header = [
        {
            title: 'Student Name', prop: 'name', isFilterable: true,
            cell: (row) => (
                <Link
                    to={"/students/" + row.studentid}
                    state={row}
                >
                    {row.name}
                </Link>
            )
        },
       

    ];

  return (
    <>
      {body ?  <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
            
        }}>

            <Table striped className="data-table">
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Pagination />
        </DatatableWrapper> : 'No records found!'}
    </>
  )
}

export default RelatedListStudents
