import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import PubSub from "pubsub-js";
import moment from 'moment';
import { useNavigate } from 'react-router-dom'
const CbrCmhEdit = () => {
    const location = useLocation();
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [formEnable, setFormEnable] = useState(true);
    const [workerData, setWorkerData] = useState(location.state ? location.state : {});
    const [editForm, setEditForm] = useState(false);
    const navigate = useNavigate();
    const [editFor, setEditFor] = useState(false);
    const [nameChild,setNameChild]=useState([]);
    useEffect(() => {                          //called when it get rendered
        if (location.state === null) {
            setFormEnable(false);
            setEditForm(true);
            fetchCBRcmh();
        }
    }, []);

    useEffect(() => {
        async function init() {
            const result = await inventoryApi.fetchStudents();

            console.log("fetchStudents",result)
            if (result) {
                setNameChild(result);
                //setRegFormList(result);
            } else {
                setNameChild([]);
                //setRegFormList([]);
            }
        }
        init();

    }, []);
       

   



const fetchCBRcmh  = async() =>{
   // const result = await inventoryApi.fetchDatabyId();


}



    const handleChange = (e) => {
        setWorkerData({ ...workerData, [e.target.name]: e.target.value });

    };
    console.log("workerData",workerData);

    const checkRequredFields = () => {
        if (
            workerData.name && workerData.name.trim() !== ""
            &&
            workerData.projectname && workerData.projectname.trim() !== ""
            &&
            workerData.cmhfieldarea && workerData.cmhfieldarea.trim() !== ""
            &&
            workerData.cmhdate && workerData.cmhdate.trim() !== ""
            &&
            workerData.signofincharge && workerData.signofincharge.trim() !== ""
            &&
            workerData.signofcmhworker && workerData.signofcmhworker.trim() !== ""
            &&
            workerData.inchargename && workerData.inchargename.trim() !== ""
            &&
            workerData.date && workerData.date.trim() !== ""
            &&
            workerData.contactno && workerData.contactno.trim() !== ""
        ) {
            return false;
        }
        return true;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (checkRequredFields()) {
        //     console.log('====true======');
        //     setValidated(true);
        //     return;
        // }
        console.log('====Outrue======');
        // console.log("CbrWorkerReportData", workerData);
        if (workerData.id) {
            console.log("====edit-====");
            const result = await inventoryApi.saveCmhWorkerReport(workerData);
            if (result.errors) {
                setShowError(true);
                setErrorMessage(result.errors);
                return;
            }
            if (result.success) {
                console.log('if result true');
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });
                //navigate(`/cbrworkerreport/${workerData.id}`, { state: result });
                setFormEnable(true);
                // setDuplicateWorkerData({id : ''});
                setEditForm(false);
            }
        } else {
            console.log('create call',workerData)
            const result = await inventoryApi.createCmhWorkerReport(workerData);
            console.log("result",result)
            if (result) {
                PubSub.publish("RECORD_SAVED_TOAST", {
                    title: "Record Saved",
                    message: "Record create successfully",
                });
                navigate(`/cbrcmhworkerreports/`, { state: result });
            }
            if (result.errors) {
                setShowError(true);
                setErrorMessage(result.errors);
                return;
            }
        }
    };
    const handleEdit = () => {
        setFormEnable(false);
        // setDuplicateWorkerData({id : ''});
        setEditForm(true);
    }

    const handleCancel = () => {
        navigate(`/cbrcmhworkerreports/`);
    };


    return (

        <Container>
            <Row>
                <Col lg={12} className="mx-4">
                    <Form
                        className="mt-4"
                        onSubmit={handleSubmit}
                        noValidate validated={validated}
                    >
                        <Row className="view-form-header align-items-center">
                            <Col lg={6}>CMH Worker Report</Col>
                            {editForm === false && (
                                <Col lg={6} className="d-flex justify-content-end">
                                    <Button className="btn-sm mx-2" variant="primary" onClick={handleEdit}> <i class="fa-regular fa-pen-to-square"></i> </Button>
                                </Col>)}
                            {editForm === true && (
                                <Col lg={6} className="d-flex justify-content-end">
                                    <Button className="btn-sm mx-2" type="submit" onClick={handleSubmit}> Save </Button>
                                    <Button className="btn-sm" variant="danger" onClick={handleCancel} > Cancel </Button>
                                </Col>)
                            }

                        </Row>
                    </Form>
                </Col>
                <Col/>
            </Row>
            <fieldset disabled={formEnable}>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-4" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Name of CMH
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="name"
                                placeholder="Enter Name"
                                value={workerData.name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="my-4" controlId="formBasicProjectName">
                            <Form.Label className="form-view-label">Name Of Project</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="projectname"
                                placeholder="Enter Project Name"
                                value={workerData.projectname}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 " controlId="formBasicDate">
                            <Form.Label className="form-view-label">Date</Form.Label>
                            <Form.Control
                                type="date"
                                required
                                name="cmhdate"
                                value={moment(workerData.cmhdate).format('YYYY-MM-DD')}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    {/* <Col lg={6}>
                        <Form.Group className="mx-2 " controlId="formBasicFiledArea">
                            <Form.Label className="form-view-label">CBR Filed Area</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                name="cmhfieldarea"
                                placeholder="Enter Field Area"
                                value={workerData.cmhfieldarea}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col> */}
                    <Col lg={6}>
                    <Form.Group controlId="formBasicFiledArea">
                        <Form.Label
                        className="form-view-label"
                        >
                        CBR field Area
                        </Form.Label>
                        <Form.Select name="cmhfieldarea"  value={workerData.cmhfieldarea} required onChange={handleChange}>
                        <option value="">-- Select --</option>
                        <option value="School">School</option>
                        <option value="CBR">CBR</option>
                        <option value="Day Care">Day Care</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                             Please provide a valid service area.
              </Form.Control.Feedback>
                </Form.Group>
            </Col>
                    <Col lg={12} className="my-4 mx-4">
               
                    </Col>
                    <Col>
                        <table className="table">
                            <thead>
                                <tr style={{textAlign:"center"}}>
                                    <th>
                                        S.No.
                                    </th>
                                    <th>
                                        <br></br> Activity
                                    </th>
                                    <th>
                                         <br></br> Target
                                    </th>
                                    <th>
                                         <br></br> Planned
                                    </th>
                                    <th>
                                         <br></br> Achieved
                                    </th>
                                    <th>
                                         <br></br> Remarks
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        Number of field visit
                                    </td>
                                    <td>
                                        <input type="number" name="target1" value={workerData.planned1} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned1" value={workerData.planned1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved1" value={workerData.achieved1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark1" value={workerData.remark1} className="form-control" onChange={handleChange} />
                                    </td>
                                </tr>

                                <tr>
                                    <td>2</td>
                                    <td>
                                        Number of application submitted/ referred for disability certificate
                                    </td>
                                    <td>
                                        <input type="number" name="target2" value={workerData.target2} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned2" value={workerData.planned2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved2" value={workerData.achieved2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark2" value={workerData.remark2} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>2.1</td>
                                    <td>
                                       
                                    </td>
                                    <td>
                                        <input type="number" name="target2_1" value={workerData.target2_1} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned2_1" value={workerData.planned2_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved2_1" value={workerData.achieved2_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark2_1" value={workerData.remark2_1} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>2.2</td>
                                    <td>
                                       
                                    </td>
                                    <td>
                                        <input type="number" name="target2_2" value={workerData.target2_2} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned2_2" value={workerData.planned2_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved2_2" value={workerData.achieved2_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark2_2" value={workerData.remark2_2} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>2.3</td>
                                    <td>
                                       
                                    </td>
                                    <td>
                                        <input type="number" name="target2_3" value={workerData.target2_3} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned2_3" value={workerData.planned2_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved2_3" value={workerData.achieved2_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark2_3" value={workerData.remark2_3} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>2.4</td>
                                    <td>
                                       
                                    </td>
                                    <td>
                                        <input type="number" name="target2_4" value={workerData.target2_4} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned2_4" value={workerData.planned2_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved2_4" value={workerData.achieved2_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark2_4" value={workerData.remark2_4} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>2.5</td>
                                    <td>
                                       
                                    </td>
                                    <td>
                                        <input type="number" name="target2_5" value={workerData.target2_5} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned2_5" value={workerData.planned2_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved2_5" value={workerData.achieved2_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark2_5" value={workerData.remark2_5} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                            Number of disability certificates received
                                    </td>
                                    <td>
                                        <input type="number" name="target3" value={workerData.target3} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned3" value={workerData.planned3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved3" value={workerData.achieved3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark3" value={workerData.remark3} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>3.1</td>
                                    <td>
                                       
                                    </td>
                                    <td>
                                        <input type="number" name="target3_1" value={workerData.target3_1} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned3_1" value={workerData.planned3_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved3_1" value={workerData.achieved3_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark3_1" value={workerData.remark3_1} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>3.2</td>
                                    <td>
                                       
                                    </td>
                                    <td>
                                        <input type="number" name="target3_2" value={workerData.target3_2} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned3_2" value={workerData.planned3_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved3_2" value={workerData.achieved3_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark3_2" value={workerData.remark3_2} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>3.3</td>
                                    <td>
                                       
                                    </td>
                                    <td>
                                        <input type="number" name="target3_3" value={workerData.target3_3} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned3_3" value={workerData.planned3_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved3_3" value={workerData.achieved3_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark3_3" value={workerData.remark3_3} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>3.4</td>
                                    <td>
                                       
                                    </td>
                                    <td>
                                        <input type="number" name="target3_4" value={workerData.target3_4} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned3_4" value={workerData.planned3_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved3_4" value={workerData.achieved3_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark3_4" value={workerData.remark3_4} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>3.5</td>
                                    <td>
                                       
                                    </td>
                                    <td>
                                        <input type="number" name="target3_5" value={workerData.target3_5} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned3_5" value={workerData.planned3_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved3_5" value={workerData.achieved3_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark3_5" value={workerData.remark3_5} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>
                                        Number of Stakeholder Contacted
                                    </td>
                                    <td>
                                        <input type="number" name="target4" value={workerData.target4} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned4" value={workerData.planned4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved4" value={workerData.achieved4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark4" value={workerData.remark4} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>4.1</td>
                                    <td>
                                        Other Clinic visits
                                    </td>
                                    <td>
                                        <input type="number" name="target4_1" value={workerData.target4_1} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned4_1" value={workerData.planned4_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved4_1" value={workerData.achieved4_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark4_1" value={workerData.remark4_1} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>4.2</td>
                                    <td>
                                            PRIs/MMC
                                    </td>
                                    <td>
                                        <input type="number" name="target4_2" value={workerData.target4_2} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned4_2" value={workerData.planned4_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved4_2" value={workerData.achieved4_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark4_2" value={workerData.remark4_2} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>4.3</td>
                                    <td>
                                        ANM
                                    </td>
                                    <td>
                                        <input type="number" name="target4_3" value={workerData.target4_3} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned4_3" value={workerData.planned4_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved4_3" value={workerData.achieved4_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark4_3" value={workerData.remark4_3} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>4.4</td>
                                    <td>
                                        Parents
                                    </td>
                                    <td>
                                        <input type="number" name="target4_4" value={workerData.target4_4} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned4_4" value={workerData.planned4_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved4_4" value={workerData.achieved4_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark4_4" value={workerData.remark4_4} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>4.5</td>
                                    <td>
                                        Doctors
                                    </td>
                                    <td>
                                        <input type="number" name="target4_5" value={workerData.target4_5} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned4_5" value={workerData.planned4_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved4_5" value={workerData.achieved4_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark4_5" value={workerData.remark4_5} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>4.6</td>
                                    <td>
                                             Government officer
                                    </td>
                                    <td>
                                        <input type="number" name="target4_6" value={workerData.target4_6} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned4_6" value={workerData.planned4_6} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved4_6" value={workerData.achieved4_6} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark4_6" value={workerData.remark4_6} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>4.7</td>
                                    <td>
                                            Others
                                    </td>
                                    <td>
                                        <input type="number" name="target4_7" value={workerData.target4_7} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned4_7" value={workerData.planned4_7} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved4_7" value={workerData.achieved4_7} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark4_7" value={workerData.remark4_7} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>
                                            Number of follow up calls/ contact
                                    </td>
                                    <td>
                                        <input type="number" name="target5" value={workerData.target5} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="planned5" value={workerData.planned5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="achieved5" value={workerData.achieved5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="remark5" value={workerData.remark5} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                               
                            </tbody>
                        </table>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                      6. Detail of service at NICU
                    </Col>

                    <Col>
                        <table className="table">
                            <thead style={{textAlign:"center"}}>
                                <tr>
                                    <th></th>
                                    <th>
                                        6.1<br></br> Parent counselling
                                    </th>
                                    <th>
                                        6.2 <br></br> Identification of high risk babies
                                    </th>
                                    <th>
                                        6.3 <br></br> Refer to EIC
                                    </th>
                                    <th>
                                        6.4 <br></br> Refer to CBR
                                    </th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                   <td></td>
                                    <td>
                                        <input type="number" name="_6_1" value={workerData._6_1} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="_6_2" value={workerData._6_2} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="_6_3" value={workerData._6_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="_6_4" value={workerData._6_4} className="form-control" onChange={handleChange} />
                                    </td>
           
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        7. Details of service provided during OPD
                    </Col>
                    <Col>
                        <table className="table">
                            <thead style={{textAlign:"center"}}>
                                <tr>
                                    <th></th>
                                    <th>
                                        7.1<br></br> Assist in assessments
                                    </th>
                                    <th>
                                        7.2 <br></br> Different type of test
                                    </th>
                                    <th>
                                        7.3 <br></br> Support in medicine
                                    </th>
                                    <th>
                                        7.4 <br></br> Counselling
                                    </th>
                                    <th>
                                        7.5<br></br> Medical check up
                                    </th>
                                    <th>
                                        7.6 <br></br>No. of children identified
                                    </th>
                                    <th>
                                        7.7 <br></br> Refer to daycare centre
                                    </th>
                                    <th>
                                        7.8 <br></br> Refer to CBR program
                                    </th>
                                    <th>
                                        7.9 <br></br> Remark
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <input type="number" name="_7_1" value={workerData._7_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="_7_2" value={workerData._7_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="_7_3" value={workerData._7_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="_7_4" value={workerData._7_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="_7_5" value={workerData._7_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="_7_6" value={workerData._7_6} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="_7_7" value={workerData._7_7} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="_7_8" value={workerData._7_8} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_7_9" value={workerData._7_9} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>

                            </tbody>
                        </table>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        8. New identification during month
                    </Col>
                    <Col>
                        <table className="table">
                            <thead style={{textAlign:"center"}}>
                                <tr>
                                    <th>
                                        S. No.
                                    </th>
                                    <th>
                                        8.1<br></br> Name of Child
                                    </th>
                                    <th>
                                        8.2 <br></br> Name of parents
                                    </th>
                                    <th>
                                        8.3 <br></br> Age
                                    </th>
                                    <th>
                                        8.4 <br></br> Address
                                    </th>
                                    <th>
                                        8.5<br></br> Contact No.{" "}
                                    </th>
                                    <th>
                                        8.6 <br></br> Refer to
                                    </th>
                                    <th>
                                        8.7 <br></br> Remarks
                                    </th>
                                 
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                       
                                    </td>
                                    <td><input type="text" name="_8_1_1" value={workerData._8_1_1} className="form-control" onChange={handleChange} /></td>

                                    {/* <td style={{width:"200px"}}>
                                    <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_8_1_1"
                                                    onChange={handleChange}
                                                    value={workerData._8_1_1}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </td> */}
                                    
                                    <td>
                                        <input type="text" name="_8_2_1" value={workerData._8_2_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_3_1" value={workerData._8_3_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_4_1" value={workerData._8_4_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_5_1" value={workerData._8_5_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_6_1" value={workerData._8_6_1} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_7_1" value={workerData._8_7_1} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>
                                       
                                    </td>
                                    <td><input type="text" name="_8_1_2" value={workerData._8_1_2} className="form-control" onChange={handleChange} /></td>

                                    {/* <td style={{width:"200px"}}>
                                    <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_8_1_2"
                                                    onChange={handleChange}
                                                    value={workerData._8_1_2}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </td> */}
                                    <td>
                                        <input type="text" name="_8_2_2" value={workerData._8_2_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_3_2" value={workerData._8_3_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_4_2" value={workerData._8_4_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_5_2" value={workerData._8_5_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_6_2" value={workerData._8_6_2} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_7_2" value={workerData._8_7_2} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>
                                       
                                    </td>
                                    <td><input type="text" name="_8_1_3" value={workerData._8_1_3} className="form-control" onChange={handleChange} /></td>

                                    {/* <td style={{width:"200px"}}>
                                    <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_8_1_3"
                                                    onChange={handleChange}
                                                    value={workerData._8_1_3}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </td> */}
                                    <td>
                                        <input type="text" name="_8_2_3" value={workerData._8_2_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_3_3" value={workerData._8_3_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_4_3" value={workerData._8_4_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_5_3" value={workerData._8_5_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_6_3" value={workerData._8_6_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_7_3" value={workerData._8_7_3} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>
                                       
                                    </td>
                                    <td><input type="text" name="_8_1_4" value={workerData._8_1_4} className="form-control" onChange={handleChange} /></td>

                                    {/* <td style={{width:"200px"}}>
                                    <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_8_1_4"
                                                    onChange={handleChange}
                                                    value={workerData._8_1_4}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </td> */}
                                    <td>
                                        <input type="text" name="_8_2_4" value={workerData._8_2_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_3_4" value={workerData._8_3_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_4_4" value={workerData._8_4_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_5_4" value={workerData._8_5_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_6_4" value={workerData._8_6_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_7_4" value={workerData._8_7_4} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>
                                       
                                    </td>
                                    <td><input type="text" name="_8_1_5" value={workerData._8_1_5} className="form-control" onChange={handleChange} /></td>

                                    {/* <td style={{width:"200px"}}>
                                    <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_8_1_5"
                                                    onChange={handleChange}
                                                    value={workerData._8_1_5}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </td> */}
                                    <td>
                                        <input type="text" name="_8_2_5" value={workerData._8_2_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_3_5" value={workerData._8_3_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_4_5" value={workerData._8_4_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_5_5" value={workerData._8_5_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_6_5" value={workerData._8_6_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_7_5" value={workerData._8_7_5} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>
                                       
                                    </td>
                                    {/* <td style={{width:"200px"}}>
                                    <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_8_1_6"
                                                    onChange={handleChange}
                                                    value={workerData._8_1_6}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </td> */}
                                    <td><input type="text" name="_8_1_6" value={workerData._8_1_6} className="form-control" onChange={handleChange} /></td>

                                    <td>
                                        <input type="text" name="_8_2_6" value={workerData._8_2_6} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_3_6" value={workerData._8_3_6} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_4_6" value={workerData._8_4_6} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_5_6" value={workerData._8_5_6} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_6_6" value={workerData._8_6_6} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_7_6" value={workerData._8_7_6} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>
                                       
                                    </td>
                                    <td><input type="text" name="_8_1_7" value={workerData._8_1_7} className="form-control" onChange={handleChange} /></td>

                                    {/* <td style={{width:"200px"}}>
                                    <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_8_1_7"
                                                    onChange={handleChange}
                                                    value={workerData._8_1_7}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </td> */}
                                    <td>
                                        <input type="text" name="_8_2_7" value={workerData._8_2_7} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_3_7" value={workerData._8_3_7} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_4_7" value={workerData._8_4_7} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_5_7" value={workerData._8_5_7} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_6_7" value={workerData._8_6_7} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_7_7" value={workerData._8_7_7} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>
                                       
                                    </td>
                                    <td><input type="text" name="_8_1_8" value={workerData._8_1_8} className="form-control" onChange={handleChange} /></td>
                                    {/* <td style={{width:"200px"}}>
                                    <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_8_1_8"
                                                    onChange={handleChange}
                                                    value={workerData._8_1_8}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </td> */}
                                    <td>
                                        <input type="text" name="_8_2_8" value={workerData._8_2_8} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_3_8" value={workerData._8_3_8} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_4_8" value={workerData._8_4_8} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_5_8" value={workerData._8_5_8} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_6_8" value={workerData._8_6_8} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_7_8" value={workerData._8_7_8} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>
                                       
                                    </td>
                                    {/* <td style={{width:"200px"}}>
                                    <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_8_1_9"
                                                    onChange={handleChange}
                                                    value={workerData._8_1_9}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </td> */}
                                    <td><input type="text" name="_8_1_9" value={workerData._8_1_9} className="form-control" onChange={handleChange} /></td>
                                    <td>
                                        <input type="text" name="_8_2_9" value={workerData._8_2_9} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_3_9" value={workerData._8_3_9} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_4_9" value={workerData._8_4_9} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_5_9" value={workerData._8_5_9} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_6_9" value={workerData._8_6_9} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_7_9" value={workerData._8_7_9} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                                <tr>
                                    <td>
                                       
                                    </td>
                                    <td><input type="text" name="_8_1_10" value={workerData._8_1_10} className="form-control" onChange={handleChange} /></td>

                                    {/* <td style={{width:"200px"}}>
                                    <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_8_1_10"
                                                    onChange={handleChange}
                                                    value={workerData._8_1_10}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </td> */}
                                    <td>
                                        <input type="text" name="_8_2_10" value={workerData._8_2_10} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_3_10" value={workerData._8_3_10} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_4_10" value={workerData._8_4_10} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_5_10" value={workerData._8_5_10} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_6_10" value={workerData._8_6_10} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="text" name="_8_7_10" value={workerData._8_7_10} className="form-control" onChange={handleChange} />
                                    </td>
                                   
                                </tr>
                            </tbody>
                        </table>
                    </Col>

                    <Col lg={12} className="section-header my-4 mx-4">
                        9. Travel Detail
                    </Col>
                    <Col>
                        <table className="table">
                            <thead style={{textAlign:"center"}}>
                                <tr>
                                    <th>
                                        9.1 <br></br>Total KM travelled in the last month
                                    </th>
                                    <th>
                                        9.2 <br></br> Total Petrol used in last month
                                    </th>
                                    <th>
                                        9.3<br></br> Average KM{" "}
                                    </th>
                                    <th>
                                        9.4<br></br>Total KM travelled in current month
                                    </th>
                                    <th>
                                        9.5 <br></br> Total Petrol used in current month
                                    </th>
                                    <th>
                                        9.6 <br></br> Average/KM
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="number" name="_9_1" value={workerData._9_1} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="_9_2" value={workerData._9_2} className="form-control" onChange={handleChange} />{" "}
                                    </td>
                                    <td>
                                        <input type="number" name="_9_3" value={workerData._9_3} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="_9_4" value={workerData._9_4} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input
                                            type="text" name="_9_5" value={workerData._9_5} className="form-control" onChange={handleChange} />
                                    </td>
                                    <td>
                                        <input type="number" name="_9_6" value={workerData._9_6} className="form-control" onChange={handleChange} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>

                    <Col lg={12} className="section-header my-4 mx-4">
                        10.Donation/Community contribution raised during the month
                    </Col>
                    <Col lg={12}>
                        <Form.Group className="mx-4" controlId="contribution">
                            <Form.Control as="textarea" name="donation10" value={workerData.donation10} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        11. Any Problem/Challenge occurred during the month
                    </Col>
                    <Col lg={12}>
                        <Form.Group className="mx-4" controlId="FormControlProblemChallenge">
                            <Form.Control as="textarea" name="anyproblem11" value={workerData.anyproblem11} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        12.Strategy used to resolve the problem/Challenge
                    </Col>
                    <Col lg={12}>
                        <Form.Group className="mx-4" controlId="strategyProblemChalenge">
                            <Form.Control as="textarea" name="strategy12" value={workerData.strategy12} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        13.Achievement during the month
                    </Col>
                    <Col lg={12}>
                        <Form.Group className="mx-4" controlId="AchieventDuringMonth">
                            <Form.Control as="textarea" name="achievement13" value={workerData.achievement13} onChange={handleChange} />
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="fromControlenchange">
                            <form-label>14.Signature of the Incharge </form-label>
                            <Form.Control type="text" name="signofincharge" required value={workerData.signofincharge} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="fromControlpathologist">
                            <form-label>Signature of CMH : </form-label>
                            <Form.Control type="text" name="signofcmhworker" required value={workerData.signofcmhworker} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="FromControlIncharge">
                            <form-label>15.Name of the Incharge </form-label>
                            <Form.Control type="text" name="inchargename" required value={workerData.inchargename} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="FronControlldate">
                            <form-label>Date </form-label>
                            <Form.Control type="date" name="date" required value={moment(workerData.date).format('YYYY-MM-DD')} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="reportSubmitMonth">
                            <form-label>
                                16.Report Should be Submitted by the last day of every month{" "}
                            </form-label>
                            <Form.Control type="text" name="note" value={workerData.note} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="contacttext">
                            <form-label>Contact No.</form-label>
                            <Form.Control type="number" required name="contactno" value={workerData.contactno} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>
        </Container >
    );
};

export default CbrCmhEdit;
