import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import PubSub from "pubsub-js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import * as constants from "../constants/CONSTANT";
import CaseHistory from "./CaseHistory";


const WorkerBehaviour = () => {

  const location = useLocation();
  const [validated, setValidated] = useState(false);
  const [formEnable, setFormEnable] = useState(true);
  const [workerData, setWorkerData] = useState({});

  const [locationdata, setlocationdata] = useState(location.state ? location.state : {});
  const [fetchResult, setFetchResult] = useState({});
  console.log('locationdata', locationdata);

  const [editForm, setEditForm] = useState(false);
  const navigate = useNavigate();
  const [section1, setSection1] = useState({});
  const [section2, setSection2] = useState({});
  const [section3, setSection3] = useState({});
  const [section4, setSection4] = useState({});
  const [section5, setSection5] = useState({});
  const [countq1, setcountq1] = useState(0);
  const [countq2, setcountq2] = useState(0);
  const [countq3, setcountq3] = useState(0);
  const [countq4, setcountq4] = useState(0);
  const [id, setid] = useState();


  useEffect(() => {

  }, [countq1, countq2, countq3, countq4]);


  const [physicalArray, setPhysica1Array] = useState([
    { id: 1, name: "Wears proper dress", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
    { id: 2, name: "Dress is washed and pressed", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
    { id: 3, name: "Hair is combed", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
    { id: 4, name: "Shaves regularly/maintains mentrual hygiene", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
    { id: 5, name: "Keeps finger nails neatly", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
    { id: 6, name: "Takes care of toilet needs", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
    { id: 7, name: "Takes clean food", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
    { id: 8, name: "Follows mealtime manners", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
    { id: 9, name: "Seeks assistance while taking medicine", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
    { id: 10, name: "Avoids smoking", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
  ]);

  console.log('physicalArray', physicalArray)

  const [personalArrayData, setPersonalArrayData] = useState([
    { id: 1, name: "Respects supervisor", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
    { id: 2, name: "Cooperates with coworkers", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
    { id: 3, name: "Controls emotions", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
    { id: 4, name: "Requests help if necessary", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
    { id: 5, name: "Avoids quarrels", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
    { id: 6, name: "Maintains appropriate sex behaviour", q1: "", q2: "", q3: "", q4: "", sectionheading: "1" },
    { id: 7, name: "Respects others belongings", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
    { id: 8, name: "Takes care of personal belongings", q1: "", q2: "", q3: "", q4: "", sectionheading: "2" },
  ]);

  const [regularityArray, setRegularityArray] = useState([
    { id: 1, name: "Comes to work daily", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
    { id: 2, name: "Reaches work place on time", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
    { id: 3, name: "Attends to arrival routines", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
    { id: 4, name: "If late, follows job site rules", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
    { id: 5, name: "Says politely the reason for late coming", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
    { id: 6, name: "Informs when takes leave", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
    { id: 7, name: "Comes back to work place after break", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
    { id: 8, name: "Utilizes the break time appropriately", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
    { id: 9, name: "Continues work till closing time", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
    { id: 10, name: "Follows the departure routine", q1: "", q2: "", q3: "", q4: "", sectionheading: "3" },
  ]);

  const [communicationArrayData, setCommunicationArrayData] = useState([
    { id: 1, name: "Follows instructions", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    { id: 2, name: "Communicates needs", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    { id: 3, name: "Avoids unnecessary talking", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    { id: 4, name: "Uses telephone when necessary", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    { id: 5, name: "Uses Sorry, Thank you, Please properly", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    { id: 6, name: " Maintains eye contact while talking", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    { id: 7, name: "Avoids shouting during work", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    { id: 8, name: "Asks relevant questions", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    { id: 9, name: "Avoids unnecessary complaints", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    { id: 10, name: "Accepts corrections", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    { id: 11, name: "Works in a group without disturbing", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
    { id: 12, name: "Joins social activities in the work place", q1: "", q2: "", q3: "", q4: "", sectionheading: "4" },
  ]);

  const [qualityArray, setQualityArray] = useState([
    { id: 1, name: "Shows improvement in quality of work", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
    { id: 2, name: "Works satisfactorily", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
    { id: 3, name: "Reports work problems", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
    { id: 4, name: "Increases speed of work", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
    { id: 5, name: "Uses tools safely", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
    { id: 6, name: "Leaves tools and products in place", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
    { id: 7, name: "Avoids stealing things from work area", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
    { id: 8, name: "Keeps work area clean", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
    { id: 9, name: "Reports missing/broken items", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
    { id: 10, name: "Continues or stops work as per instruction", q1: "", q2: "", q3: "", q4: "", sectionheading: "5" },
  ]);



  useEffect(() => {
    
    if (locationdata) {
      fetchWorkbyId(locationdata.id)
    }

  }, []);




  const handleChangeDate = (e) => {
    setWorkerData({ ...workerData, [e.target.name]: e.target.value });

  }


  const handleChangeSection2 = (event, id, property) => {
    console.log('event', event.target.value);
    const updatedDataArraySection2 = personalArrayData.map(item => {
      if (item.id === id) {
        return { ...item, [property]: event.target.value };
      }
      return item;
    });
    setPersonalArrayData(updatedDataArraySection2);
    console.log('updatedDataArraySection2', updatedDataArraySection2);
    console.log("Count of objects with 'q1' as 'independent' for ID", id, ":", countq1);
    console.log("Count of objects with 'q2' as 'independent' for ID", id, ":", countq2);
    console.log("Count of objects with 'q3' as 'independent' for ID", id, ":", countq3);
    console.log("Count of objects with 'q4' as 'independent' for ID", id, ":", countq4);
  }


  const handleChangeSection1 = (event, id, property) => {
    console.log('event', event.target.value);
    const updatedDataArraySection1 = physicalArray.map(item => {
      if (item.id === id) {
        return { ...item, [property]: event.target.value };
      }
      return item;
    });

    setPhysica1Array(updatedDataArraySection1);

    console.log('updatedDataArraySection1', updatedDataArraySection1);
    console.log("Count of objects with 'q1' as 'independent' for ID", id, ":", countq1);
    console.log("Count of objects with 'q2' as 'independent' for ID", id, ":", countq2);
    console.log("Count of objects with 'q3' as 'independent' for ID", id, ":", countq3);
    console.log("Count of objects with 'q4' as 'independent' for ID", id, ":", countq4);
  }

  console.log('SECTION COUNT', section1);


  const handleChangeWorkSection3 = (event, id, property) => {
    console.log('event', event.target.value);

    const updatedDataArraySection3 = regularityArray.map(item => {
      if (item.id === id) {
        return { ...item, [property]: event.target.value };
      }
      return item;
    });

    setRegularityArray(updatedDataArraySection3);

    console.log('updatedDataArraySection3', updatedDataArraySection3);


    console.log("Count of objects with 'q1' as 'independent' for ID", id, ":", countq1);
    console.log("Count of objects with 'q2' as 'independent' for ID", id, ":", countq2);
    console.log("Count of objects with 'q3' as 'independent' for ID", id, ":", countq3);
    console.log("Count of objects with 'q4' as 'independent' for ID", id, ":", countq4);
  }



  const handleChangeWorkSection4 = (event, id, property) => {
    console.log('event', event.target.value);

    const updatedDataArraySection4 = communicationArrayData.map(item => {
      if (item.id === id) {
        return { ...item, [property]: event.target.value };
      }
      return item;
    });

    setCommunicationArrayData(updatedDataArraySection4);

    console.log('updatedDataArraySection4', updatedDataArraySection4);


    console.log("Count of objects with 'q1' as 'independent' for ID", id, ":", countq1);
    console.log("Count of objects with 'q2' as 'independent' for ID", id, ":", countq2);
    console.log("Count of objects with 'q3' as 'independent' for ID", id, ":", countq3);
    console.log("Count of objects with 'q4' as 'independent' for ID", id, ":", countq4);
  }



  const handleChangeSection5 = (event, id, property) => {
    console.log('event', event.target.value);

    const updatedDataArraySection5 = qualityArray.map(item => {
      if (item.id === id) {
        return { ...item, [property]: event.target.value };
      }
      return item;
    });

    setQualityArray(updatedDataArraySection5);

    console.log('updatedDataArraySection5', updatedDataArraySection5);


    console.log("Count of objects with 'q1' as 'independent' for ID", id, ":", countq1);
    console.log("Count of objects with 'q2' as 'independent' for ID", id, ":", countq2);
    console.log("Count of objects with 'q3' as 'independent' for ID", id, ":", countq3);
    console.log("Count of objects with 'q4' as 'independent' for ID", id, ":", countq4);
  }



  useEffect(() => {
    if (location.state === null) {
      setFormEnable(false);
      setEditForm(true);
    }
  }, []);


  const fetchWorkbyId = async (id) => {
    console.log('id for fethc',)
    const result = await inventoryApi.fetchWorkBehaveById(id);
    setWorkerData(result);
    console.log('resultwork', result)
    let filterData = result.data[0].filter((value, index, array) => value.sectionheading === '1');
    console.log('filterData', filterData)
    setPhysica1Array(filterData)

    let filterDataIndex1 = result.data[1].filter((value, index, array) => value.sectionheading === '2');
    setPersonalArrayData(filterDataIndex1)

    let filterDataIndex2 = result.data[2].filter((value, index, array) => value.sectionheading === '3');
    setRegularityArray(filterDataIndex2)


    let filterDataIndex3 = result.data[3].filter((value, index, array) => value.sectionheading === '4');
    setCommunicationArrayData(filterDataIndex3)

    let filterDataIndex4 = result.data[4].filter((value, index, array) => value.sectionheading === '5');
    setQualityArray(filterDataIndex4)

  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    let completeData = {};
    completeData = { ...workerData, data: [physicalArray, personalArrayData, regularityArray, communicationArrayData, qualityArray] };
    console.log('completeData', completeData)

    let completeDataid = completeData.id
    if (completeData.id) {
      console.log('edit call', completeData)
      delete completeData.id
      const result = await inventoryApi.saveWorkBhaviour(completeDataid, completeData);
      console.log("result>>", result);

      if (result) {
        fetchWorkbyId(completeDataid);
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record Update successfully' });
        navigate(`/students/${completeData.studentid}`);
      }
    } else {
      completeData.studentid = locationdata.id
      delete completeData.success
      delete completeData.message

      console.log('create call', completeData);
      const result = await inventoryApi.createWorkBehaviour(completeData);
      PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record Create successfully' });
      navigate(`/students/${completeData.studentid}`);
      console.log('result create', result)
    }
  };


  const handleCancel = () => {
    console.log('workerData',workerData);
    console.log('locationdata',locationdata);
    if (locationdata.id) {
      navigate(`/students/${locationdata.id}`);
    }
  };



  return (
    <Container>
      <Row>
        <Col lg={12} className="mx-2">
          <Form
            className="mt-4"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={8}>WORK BEHAVIOUR ASSESMENT CHECKLIST</Col>


              <Col lg={4} className="d-flex justify-content-end">
                <Button
                  className="btn-sm mx-2"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {" "}
                  Save{" "}
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  {" "}
                  Cancel{" "}
                </Button>
              </Col>

            </Row>
          </Form>
        </Col>
        <Col />
      </Row>
      <Col lg={12} className="section-header my-4 mx-4">
        Periodical Evaluation
      </Col>

      <Row lg={12}>

        <Col lg={6}>
          <Form.Group className="mx-4 " controlId="formBasicDate">
            <Form.Label className="form-view-label">Date</Form.Label>
            <Form.Control
              type="date"
              required
              name="workbehavedate"
              value={locationdata.workbehavedate? locationdata.workbehavedate :  moment(workerData?.workbehavedate).format("YYYY-MM-DD")}
              onChange={handleChangeDate}
            />
          </Form.Group>
        </Col>

        <Col lg={12} className="section-header my-3 mx-2">
          1. Physical_appearance
        </Col>
        <Col lg={12}>
          {physicalArray.map(item => (
            <div key={item.id} >
              <Row lg={12}>
                <Col className="my-3 ">{item.id}</Col>
                <Col lg={3} className="my-3 form-label">
                  {item.name}

                </Col>
                <Col lg={2}>
                  <select
                    className="form-select my-3"
                    value={item?.q1}
                    onChange={event => handleChangeSection1(event, item.id, 'q1')}
                  >
                    <option value="">-- Select --</option>
                    <option value="3">Always</option>
                    <option value="2">Often</option>
                    <option value="1">Rare</option>
                    <option value="0">Never</option>
                  </select>

                </Col>
                <Col lg={2}>
                  <select
                    className="form-select my-3"
                    value={item?.q2}
                    onChange={event => handleChangeSection1(event, item.id, 'q2')}
                  >
                    <option value="">-- Select --</option>
                    <option value="3">Always</option>
                    <option value="2">Often</option>
                    <option value="1">Rare</option>
                    <option value="0">Never</option>
                  </select>
                </Col>
                <Col lg={2}>
                  <select
                    className="form-select my-3"
                    value={item?.q3}
                    onChange={event => handleChangeSection1(event, item.id, 'q3')}
                  >
                    <option value="">-- Select --</option>
                    <option value="3">Always</option>
                    <option value="2">Often</option>
                    <option value="1">Rare</option>
                    <option value="0">Never</option>
                  </select>
                </Col>
                <Col lg={2}>
                  <select
                    className="form-select my-3"
                    value={item?.q4}
                    onChange={event => handleChangeSection1(event, item.id, 'q4')}
                  >
                    <option value="">-- Select --</option>
                    <option value="3">Always</option>
                    <option value="2">Often</option>
                    <option value="1">Rare</option>
                    <option value="0">Never</option>
                  </select>
                </Col>
              </Row>
            </div>
          ))}

          <Row lg={12} >
            <Col lg={4}></Col>
            <Col lg={2}>{physicalArray.reduce((accumulator, obj) => (obj.q1 != '' ? accumulator + parseInt(obj.q1) : accumulator), 0)}</Col>
            <Col lg={2}>{physicalArray.reduce((accumulator, obj) => (obj.q2 != '' ? accumulator + parseInt(obj.q2) : accumulator), 0)}</Col>
            <Col lg={2}>{physicalArray.reduce((accumulator, obj) => (obj.q3 != '' ? accumulator + parseInt(obj.q3) : accumulator), 0)}</Col>
            <Col lg={2}>{physicalArray.reduce((accumulator, obj) => (obj.q4 != '' ? accumulator + parseInt(obj.q4) : accumulator), 0)}</Col>
          </Row>
        </Col>


      </Row>


      {/* PERSONAL INTRECTION  */}



      <Col lg={12} className="section-header my-3 mx-2">
        2. Personal Intrection
      </Col>
      <Col lg={12}>
        {personalArrayData.map(item => (
          <div key={item.id} >
            <Row lg={12}>
              <Col className="my-3 ">{item.id}</Col>
              <Col lg={3} className="my-3 form-label">
                {item.name}

              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q1}
                  onChange={event => handleChangeSection2(event, item.id, 'q1')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>

              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q2}
                  onChange={event => handleChangeSection2(event, item.id, 'q2')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q3}
                  onChange={event => handleChangeSection2(event, item.id, 'q3')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q4}
                  onChange={event => handleChangeSection2(event, item.id, 'q4')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
            </Row>
          </div>
        ))}

        <Row lg={12} >
          <Col lg={4}></Col>
          <Col lg={2}>{personalArrayData.reduce((accumulator, obj) => (obj.q1 != '' ? accumulator + parseInt(obj.q1) : accumulator), 0)}</Col>
          <Col lg={2}>{personalArrayData.reduce((accumulator, obj) => (obj.q2 != '' ? accumulator + parseInt(obj.q2) : accumulator), 0)}</Col>
          <Col lg={2}>{personalArrayData.reduce((accumulator, obj) => (obj.q3 != '' ? accumulator + parseInt(obj.q3) : accumulator), 0)}</Col>
          <Col lg={2}>{personalArrayData.reduce((accumulator, obj) => (obj.q4 != '' ? accumulator + parseInt(obj.q4) : accumulator), 0)}</Col>
        </Row>
      </Col>



      {/* RAGULARITY AND PUNCTUALITY  */}

      <Col lg={12} className="section-header my-3 mx-2">
        3.  Regularity and punctuality
      </Col>
      <Col lg={12}>
        {regularityArray.map(item => (
          <div key={item.id} >
            <Row lg={12}>
              <Col className="my-3 ">{item.id}</Col>
              <Col lg={3} className="my-3 form-label">
                {item.name}

              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q1}
                  onChange={event => handleChangeWorkSection3(event, item.id, 'q1')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>

              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q2}
                  onChange={event => handleChangeWorkSection3(event, item.id, 'q2')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q3}
                  onChange={event => handleChangeWorkSection3(event, item.id, 'q3')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q4}
                  onChange={event => handleChangeWorkSection3(event, item.id, 'q4')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
            </Row>
          </div>
        ))}

        <Row lg={12} >
          <Col lg={4}></Col>
          <Col lg={2}>{regularityArray.reduce((accumulator, obj) => (obj.q1 != '' ? accumulator + parseInt(obj.q1) : accumulator), 0)}</Col>
          <Col lg={2}>{regularityArray.reduce((accumulator, obj) => (obj.q2 != '' ? accumulator + parseInt(obj.q2) : accumulator), 0)}</Col>
          <Col lg={2}>{regularityArray.reduce((accumulator, obj) => (obj.q3 != '' ? accumulator + parseInt(obj.q3) : accumulator), 0)}</Col>
          <Col lg={2}>{regularityArray.reduce((accumulator, obj) => (obj.q4 != '' ? accumulator + parseInt(obj.q4) : accumulator), 0)}</Col>
        </Row>
      </Col>



      {/*  COMMUNICATION AND SOCIAL MANNER */}
      <Col lg={12} className="section-header my-3 mx-2">
        4. Communication /Social Manner
      </Col>
      <Col lg={12}>
        {communicationArrayData.map(item => (
          <div key={item.id} >
            <Row lg={12}>
              <Col className="my-3 ">{item.id}</Col>
              <Col lg={3} className="my-3 form-label">
                {item.name}

              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q1}
                  onChange={event => handleChangeWorkSection4(event, item.id, 'q1')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>

              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q2}
                  onChange={event => handleChangeWorkSection4(event, item.id, 'q2')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q3}
                  onChange={event => handleChangeWorkSection4(event, item.id, 'q3')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q4}
                  onChange={event => handleChangeWorkSection4(event, item.id, 'q4')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
            </Row>
          </div>
        ))}

        <Row lg={12} >
          <Col lg={4}></Col>
          <Col lg={2}>{communicationArrayData.reduce((accumulator, obj) => (obj.q1 != '' ? accumulator + parseInt(obj.q1) : accumulator), 0)}</Col>
          <Col lg={2}>{communicationArrayData.reduce((accumulator, obj) => (obj.q2 != '' ? accumulator + parseInt(obj.q2) : accumulator), 0)}</Col>
          <Col lg={2}>{communicationArrayData.reduce((accumulator, obj) => (obj.q3 != '' ? accumulator + parseInt(obj.q3) : accumulator), 0)}</Col>
          <Col lg={2}>{communicationArrayData.reduce((accumulator, obj) => (obj.q4 != '' ? accumulator + parseInt(obj.q4) : accumulator), 0)}</Col>
        </Row>
      </Col>

      {/* QUALITY AND QUATITY Aspects of work  */}


      <Col lg={12} className="section-header my-3 mx-2">
        5. Quality and Quantity Aspects of Work
      </Col>
      <Col lg={12}>
        {qualityArray.map(item => (
          <div key={item.id} >
            <Row lg={12}>
              <Col className="my-3 ">{item.id}</Col>
              <Col lg={3} className="my-3 form-label">
                {item.name}

              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q1}
                  onChange={event => handleChangeSection5(event, item.id, 'q1')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>

              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q2}
                  onChange={event => handleChangeSection5(event, item.id, 'q2')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q3}
                  onChange={event => handleChangeSection5(event, item.id, 'q3')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
              <Col lg={2}>
                <select
                  className="form-select my-3"
                  value={item?.q4}
                  onChange={event => handleChangeSection5(event, item.id, 'q4')}
                >
                  <option value="">-- Select --</option>
                  <option value="3">Always</option>
                  <option value="2">Often</option>
                  <option value="1">Rare</option>
                  <option value="0">Never</option>
                </select>
              </Col>
            </Row>
          </div>
        ))}

        <Row lg={12}>
          <Col lg={4}></Col>
          <Col lg={2}>{qualityArray.reduce((accumulator, obj) => (obj.q1 !== '' ? accumulator + parseInt(obj.q1) : accumulator), 0)}</Col>
          <Col lg={2}>{qualityArray.reduce((accumulator, obj) => (obj.q2 !== '' ? accumulator + parseInt(obj.q2) : accumulator), 0)}</Col>
          <Col lg={2}>{qualityArray.reduce((accumulator, obj) => (obj.q3 !== '' ? accumulator + parseInt(obj.q3) : accumulator), 0)}</Col>
          <Col lg={2}>{qualityArray.reduce((accumulator, obj) => (obj.q4 !== '' ? accumulator + parseInt(obj.q4) : accumulator), 0)}</Col>
        </Row>

      </Col>
      {/*  */}
      {/* <div className="App container mt-5" style={{ display: 'none' }}>
            <div id="divToPrint">
              <html>
                <head>
                  <meta charset="UTF-8" />
                </head>
                <body>
                  <WorkaviourPdf data={workerData} />
                </body>
              </html>
            </div>
          </div> */}

    </Container >
  );
}

export default WorkerBehaviour;
