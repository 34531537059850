import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import CardHeader from "react-bootstrap/esm/CardHeader";
import inventoryApi from "../api/inventoryApi";
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal';
import PubSub from 'pubsub-js';

const EditProfile = ({ userId }) => {
  const fileInputRef = useRef();
  const [profile, setProfile] = useState({});
  const [body, setBody] = useState();
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({password: '', confirmpassword: ''});
  const [validated, setValidated] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [file,setFile] = useState();
  useEffect(() => {
    async function init() {
      let result = await inventoryApi.getLoginUserData();
      setProfile(result);
      console.log('result:', result);
      setBody(localStorage.getItem("myimage"));
      
    }

    init();
  }, []);


  const handlePasswordOnchange = (e) => {
    console.log(e.target.value)
    setUser({ ...user, [e.target.name]: e.target.value });
};


  const handleChange = (e) => {
    console.log('value ki value >>> ', e, "=>", e.target.value)
    setProfile({...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('selected file',selectedFiles)
    let result = {};
    if(selectedFiles === null){
      result = await inventoryApi.saveStaffMember(profile);
      console.log('staff update',result);
      if(result.errors){
        PubSub.publish('RECORD_ERROR_TOAST', { title: 'Error Saved', message: result.errors });
      }else{
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });

      }
    }else{
      let result = await inventoryApi.saveStaffMemberEditProfile(profile.id,selectedFiles, JSON.stringify(JSON.stringify(profile)));
      localStorage.setItem('myimage',body);
      console.log('file update',result);
      PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });

    }
  }


  const handlePhotoUpload = (event) => {
    setBody(URL.createObjectURL(event.target.files[0]))
    setSelectedFiles(event.target.files[0]);
  };

  const handleClose = () => setShow(false);


  const handleChangeSubmit = async(e) => {
    console.log('user',user)
    setShow(true)
    e.preventDefault();
    console.log('userId', userId);
    console.log('user', user)
    if (user.password === user.confirmpassword && user.password !== '') {
        const result = await inventoryApi.updateUser(user);
        setShow(false)
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Password updated successfully' });
        console.log('result',result);
       
    } else {
        console.log('Password is null, please enter a valid password.');
    }
};




  return (
    <Container>
    <Row>
      <Col lg={1}></Col>
      <Col lg={3}>
        <Card>
          <Card.Body className="text-center">
            <Card.Title style={{ textAlign: "center" }}>
              {profile.firstname} {profile.lastname}
            </Card.Title>
            <Image variant="top"
              src={body}
              className="rounded-circle"
              thumbnail
              style={{ width: "200px" }}></Image>
            <br />
            <Button className="btn my-3 " variant="secondary" style={{ width: "100%", display: "block" }} onClick={() => fileInputRef.current.click()}>
              Image Upload
            </Button>
            <Button className="btn" style={{ width: "100%", display: "block" }} variant="danger" onClick={handleChangeSubmit}>
              Change Password
            </Button>
            <input
              onChange={handlePhotoUpload}
              name="profilephotourl"

              ref={fileInputRef}
              type="file"
              hidden
            />
          </Card.Body>
        </Card>
      </Col>

      <Col style={{ backgroundColor: "#fff" }} lg={7}>
        <Row className="view-form-header align-items-center">
          <Col lg={6}>
           Edit Profile
          </Col>
          <Col lg={6} className="d-flex justify-content-end">
            <Button className="btn-sm mx-2" onClick={handleSubmit}>
              Save
            </Button>
          </Col>
        </Row>

        <Row className="pb-5 py-3">
          <Col lg={6}>
            <Form.Group className="mx-3 mb-3" controlId="formBasicPhone">
              <Form.Label
                className="form-view-label"
                htmlFor="formBasicPhone"
              >
                First Name
              </Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                value={profile.firstname}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group
              className="mx-3 mb-3"
              controlId="formBasicLastName"
            >
              <Form.Label
                className="form-view-label"
                htmlFor="formBasicPhone"
              >
                Last Name
              </Form.Label>
              <Form.Control
                type="text"
                name="lasttname"
                placeholder="Enter Last Name"
                value={profile.lastname}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group
              className="mx-3 mb-3"
              controlId="formBasicLastEmail"
            >
              <Form.Label
                className="form-view-label"
                htmlFor="formBasicPhone"
              >
                Email
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter Email"
                value={profile.email}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group
              className="mx-3 mb-3"
              controlId="formBasicLastName"
            >
              <Form.Label
                className="form-view-label"
                htmlFor="formBasicPhone"
              >
                Phone
              </Form.Label>
              <Form.Control
                type="phone"
                name="phone"
                placeholder="Enter Phone"
                value={profile.phone}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>



      </Col>
      <Col lg={1}></Col>
    </Row>

  <Modal show={show} onHide={handleClose} className= "modal-sm" style={{marginLeft : "45%"}}> 
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col></Col>
          <Col lg={12}>
            <Form className="mt-3" >
              <Row>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicFirstName">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      New Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Enter Your password"
                      onChange={handlePasswordOnchange}
                      required
                    />
                  </Form.Group>
                </Col>

              </Row>
              <Row>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicPhone">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPhone"
                    >
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmpassword"
                      placeholder="Enter confirm password"
                      onChange={handlePasswordOnchange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

            </Form>
          </Col>
          <Col></Col>
        </Row>


      </Modal.Body>
      <Modal.Footer>

        <Button variant="primary" onClick={handleChangeSubmit}>
          Save
        </Button>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>




  </Container>

  );
};

export default EditProfile;
