import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { ShimmerTable } from "react-shimmer-effects";
import * as constants from "../constants/CONSTANT";
import Confirm from "./Confirm";
import moment from "moment";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import PubSub from "pubsub-js";

const SpeechHearingList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  useEffect(() => {
    async function init() {
      let result = [];
       result = await inventoryApi.fetchspeechhearing();
    
      if (result) {
        setBody(result);
      } else {
        setBody([]);
      }
    }
    init();
  }, []);

  // Create table headers consisting of 4 columns.
  const header = [
    {
      title: "Case Name",
      prop: "childname",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/speechhearings/" + row.id} 
          state={row.id}
          >
          {row.childname}
        </Link>
      ),
    },
    { title: "Age", prop: "age", isFilterable: true },
    { title: "Information", prop: "imformation", isFilterable: true },
    {
        title: "DOB",
        prop: "dob",
        isFilterable: true,
        cell: (row) => {
          if (row?.dob) {
            return moment(row.dob).format("MM/DD/YYYY");
          } else {
            return "";
          }
        },
      },
      
    { title: "Phone No.", prop: "phoneno", isFilterable: true },
    
  ];

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createSpeechHearing = () => {
    navigate(`/speechhearings/e`);
  };

  return (
    <Row className="g-0">
      <Col lg={2} className="mx-2">
        <Link className="nav-link">
          Home <i class="fa-solid fa-chevron-right"></i> Speech Hearing{" "}
        </Link>
      </Col>
      <Col lg={12} className="p-lg-4">
        {body ? (
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 20,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              >
                <PaginationOptions labels={labels} />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={() => createSpeechHearing(true)}
                >
                  Create Speech Hearing
                </Button>
              </Col>
            </Row>
            <Table striped className="data-table">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />
          </DatatableWrapper>
        ) : (
          ""
        )}
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};

export default SpeechHearingList;
