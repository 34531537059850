import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as constants from "../constants/CONSTANT";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  return (
    <>
      <nav id="sidebar" style={{ fontSize: "small" }}>
        <div className="sidebar-header text-center">
          <div className="pb-1"></div> <h3>RMKM</h3>
        </div>

        <ul class="list-unstyled components">
          <li className={`${location.pathname === '/' ? 'active' : ''}`}>
            <Link to="/">
              <i class="fa-solid fa-home mx-2 fa-fw"></i>Dashboard
            </Link>
          </li>


          {localStorage.getItem("permissions") &&
            (localStorage
              .getItem("permissions")
              .indexOf(constants.VIEW_SERVICE) >= 0 ||
              localStorage.getItem("permissions").indexOf(constants.MODIFY_ALL) >=
              0) ? (
            <li className={`${location.pathname.includes('/serviceareas') ? 'active' : ''}`}>
              <Link to="/serviceareas">
                <i class="fa-solid fa-building mx-2 fa-fw"></i>Service Area
              </Link>
            </li>
          ) : (
            ""
          )}
          {localStorage.getItem("permissions") &&
            (localStorage.getItem("permissions").indexOf(constants.VIEW_STAFF) >=
              0 ||
              localStorage.getItem("permissions").indexOf(constants.MODIFY_ALL) >=
              0) ? (
            <li className={`${location.pathname.includes('/staffmembers') ? 'active' : ''}`}>
              <Link to="/staffmembers">
                {" "}
                <i class="mx-2 fa-solid fa-users fa-fw"> </i>Staff Management
              </Link>
            </li>
          ) : (
            ""
          )}

          <li>
            {localStorage.getItem("permissions") &&
              (localStorage
                .getItem("permissions")
                .indexOf(constants.VIEW_STUDENT) >= 0 ||
                localStorage.getItem("permissions").indexOf("MODIFY_ALL") >=
                0) ? (
              <li className={`${location.pathname.includes('/students') ? 'active' : ''}`}>
                <Link to="/students">
                  {" "}
                  <i class="fa-solid fa-child-reaching mx-2 fa-fw"></i>Case History
                </Link>
              </li>
            ) : (
              ""
            )}
          </li>
          {/* <li>
            <Link to="/indianautism"> <i class="fa-solid fa-users-rays mx-2"></i>ISAA</Link>
          </li> */}
          {/* <li className={`${location.pathname === '/assessments' ? 'active' : ''}`}>
            <Link to="/assessments"> <i class="fa-solid fa-list-check mx-2 fa-fw"></i>Assessment</Link>
          </li> */}


          <li>
            <a
              href="#AssesmentsSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
            >
              <i class=" mx-2 fa-regular fa-file-lines fa-fw" aria-hidden="true"></i>Assessments
            </a>
            <ul class="collapse list-unstyled" id="AssesmentsSubmenu">


              {/* <li className={`${location.pathname.includes('/assessments') ? 'active' : ''}`}>
                  <Link to="/assessments" style={{ fontSize: "small" }}>
                    <div style={{ marginLeft: "30px" }}>Assessment</div>
                  </Link>
                </li> */}

              <li className={`${location.pathname.includes('/ieps') ? 'active' : ''}`}>
                <Link to="/ieps" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "30px" }}> IEP Assessment</div>
                </Link>
              </li>

              <li className={`${location.pathname.includes('/indianautism') ? 'active' : ''}`}>
                <Link to="/indianautism" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "30px" }}> ISAA</div>
                </Link>
              </li>
              {/* <Link to="/indianautism"> <i class="fa-solid fa-users-rays mx-2"></i>ISAA</Link> */}

              <li className={`${location.pathname.includes('/speechhearings') ? 'active' : ''}`}>
                <Link to="/speechhearings" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "30px" }}>Speech Hearing Assessment</div>
                </Link>
              </li>

              <li className={`${location.pathname.includes('/finalassessmentchecklist') ? 'active' : ''}`}>
                <Link to="/finalassessmentchecklist" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "30px" }}>Vision And Hearing Assessment</div>
                </Link>
              </li>

              {/* <li className={`${location.pathname.includes('/analysislist') ? 'active' : ''}`}>
                  <Link to="/analysislist" style={{ fontSize: "small" }}>
                    <div style={{ marginLeft: "30px" }}>VAPS (Job Analysis)</div>
                  </Link>
                </li> */}

              {/* <li className={`${location.pathname.includes('/vocationalprofile') ? 'active' : ''}`}>
                  <Link to="/vocationalprofile" style={{ fontSize: "small" }}>
                    <div style={{ marginLeft: "30px" }}>Vocational/VAPS/Home Based Behavior</div>
                  </Link>
                </li> */}
            </ul>
          </li>
          {/* {localStorage.getItem("permissions") &&
            (localStorage.getItem("permissions").indexOf(constants.VIEW_ADMIN) >=
              0 ||
              localStorage.getItem("permissions").indexOf("MODIFY_ALL") >= 0
              || localStorage.getItem("permissions").indexOf("VIEW_QUESTION") >= 0) ? (
            <li>
              <a
                href="#QuestionsSubmenu"
                data-toggle="collapse"
                aria-expanded="false"
                class="dropdown-toggle"
              >
                <i class=" mx-2 fa-regular fa-file-lines fa-fw" aria-hidden="true"></i>Questions
              </a>
              <ul class="collapse list-unstyled" id="QuestionsSubmenu">


                <li className={`${location.pathname.includes('/ieps') ? 'active' : ''}`}>
                  <Link to="/ieps" style={{ fontSize: "small" }}>
                    <div style={{ marginLeft: "55px" }}> IEP Assessment</div>
                  </Link>
                </li>

                <li className={`${location.pathname.includes('/questions') ? 'active' : ''}`}>
                  <Link to="/questions" style={{ fontSize: "small" }}>
                    <div style={{ marginLeft: "55px" }}>All Questions</div>
                  </Link>
                </li>

              </ul>
            </li>
          ) : (
            ""
          )} */}

          <li>
            <Link to="/questions"> <i class="fa-solid fa-database mx-2"></i> Questions</Link>
          </li>

          {/* Add By Aamir khan  07-11-2023*/}
          {/* <li>
            <a
              href="#vocationalmenu"
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
            >
              <i class=" mx-2 fa-regular fa-file-lines fa-fw" aria-hidden="true"></i>Student Analysis Assessment
            </a>
            <ul class="collapse list-unstyled" id="vocationalmenu">

              <li className={`${location.pathname.includes('/analysislist') ? 'active' : ''}`}>
                <Link to="/analysislist" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>VAPS(Job Analysis)</div>
                </Link>
              </li>

              <li className={`${location.pathname.includes('/vocationalprofile') ? 'active' : ''}`}>
                <Link to="/vocationalprofile" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>Vocational Profile</div>
                </Link>
              </li>

              <li className={`${location.pathname.includes('/homebasedcbr') ? 'active' : ''}`}>
                <Link to="/homebasedcbr" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>Home Based CBR</div>
                </Link>
              </li>

            </ul>
          </li> */}




          {/* <li>
            <a
              href="#ReportSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
            >
              <i class=" mx-2 fa-regular fa-file-lines fa-fw" aria-hidden="true"></i>Assessments
            </a>
            <ul class="collapse list-unstyled" id="ReportSubmenu">

              <li >
                <Link to="/assessments" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>Student Assessment</div>
                </Link>
              </li>

              <li >
                <Link to="/cbrworkerreport" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>FACP Assessments</div>
                </Link>
              </li>

              <li>
                <Link to="/cbrcmhworkerreports" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>DST Assessments</div>
                </Link>
              </li>

              <li>
                <Link to="/cbrcmmworkerreports" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>Physio Assessments</div>
                </Link>
              </li>

              <li>
                <Link to="/analysislist" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>Job Analysis</div>
                </Link>
              </li>

              <li>
                <Link to="/vocationalprofile" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>Vocational Profile</div>
                </Link>
              </li>
            </ul>
          </li> */}




          <li>
            <a
              href="#ReportSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              class="dropdown-toggle"
            >
              <i class=" mx-2 fa-regular fa-file-lines fa-fw" aria-hidden="true"></i>Reports
            </a>
            <ul class="collapse list-unstyled" id="ReportSubmenu">

              <li>
                <Link to="/homebasedcbr" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>Home Based Format</div>
                </Link>
              </li>

              <li >
                <Link to="/cbrworkerreport" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>CBR Worker</div>
                </Link>
              </li>

              <li>
                <Link to="/cbrcmhworkerreports" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>CBR CMH Worker</div>
                </Link>
              </li>

              <li>
                <Link to="/cbrcmmworkerreports" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>CBR CMM Worker</div>
                </Link>
              </li>

              {/* <li>
                <Link to="/analysislist" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>Job Analysis</div>
                </Link>
              </li> */}

              {/* <li>
                <Link to="/vocationalprofile" style={{ fontSize: "small" }}>
                  <div style={{ marginLeft: "55px" }}>Vocational Profile</div>
                </Link>
              </li> */}
            </ul>
          </li>




          {/* <li>
            <Link to="/speechhearings"> <i class="fa-solid fa-users mx-2"></i>Speech Hearing Assessment</Link>
          </li> */}
          {/* <li>
            <Link to="/finalassessmentchecklist"> <i className="fa fa-id-badge mx-2"></i>Vision and hearing assessment</Link>
          </li> */}
          {localStorage.getItem("permissions") &&
            (localStorage.getItem("permissions").indexOf(constants.VIEW_ATTENDANCE) >= 0) ? (

            <li className={`${location.pathname === '/attendance' ? 'active' : ''}`}>
              <Link to="/attendance"> <i class="fa-solid fa-location-dot mx-2 fa-fw"></i>Attendance</Link>
            </li>
          ) : (
            ""
          )}

          <li>
            <Link to="/backup"> <i class="fa-solid fa-database mx-2"></i> Backup</Link>
          </li>
          <li className={`${location.pathname.includes('/editprofiles') ? 'active' : ''}`}>
            <Link to="/editprofiles"> <i className="fa fa-user mx-2"></i>My profile</Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
