import React, { useState, useEffect } from "react";
import inventoryApi from "../api/inventoryApi";
import moment from 'moment';

const FacpSectionResult = (props) => {

    let student = props.data;
    console.log(props.reportInfo)
    let reportInfoData = props.reportInfo || '';
    console.log('reportInfoData', reportInfoData)
    const uniqueCategories = [...new Set(reportInfoData.map((item) => item.items.category))];

    useEffect(() => {

        facpAccess()
    }, []);

    const facpAccess = async () => {
        let result = await inventoryApi.fetchFACPAssessmentInfo(student.id);
        console.log('result facp', result)
    }
    console.log()
    let username = localStorage.getItem('username');

    const formattedStartDate = moment(reportInfoData[0]?.items.startdate).format('DD-MM-YYYY');
    const formattedEndDate = moment(reportInfoData[0]?.items.enddate).format('YYYY-MM-DD');

    return (

        <div className="" style={{ fontSize: "10px" }}>
            <table style={{ width: '100%' }}>
                <tr>
                    <td style={{ border: "None", textAlign: 'center' }}>
                        <h5>{student.servicearea} - {student.servicecategoryname}</h5>
                        <h6>Supported By: Rajasthan Mahila Kalyan Mandal</h6>
                    </td>
                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ width: "250px", border: "None" }}>
                        <b>Name Of Student : {props?.data?.name} </b>
                    </td>
                    <td style={{ width: "200px", border: "None" }}>Father Name : {props?.data?.fathername}</td>

                </tr>
                <tr>
                    <td style={{ width: "100px", border: "None" }}>Age : {props?.data?.childregistrationage}</td>
                    <td style={{ width: "100px", border: "None" }}>Gender : {props?.data?.gender}</td>
                </tr>

                <tr>
                    <td style={{ width: "20px", border: "None" }}>Name of Staff : {username}</td>
                    <td style={{ width: "200px", border: "None" }}>
                        Assessment Date: {formattedStartDate} 
                    </td>
                </tr>

            </table>

            <table>
                <tr>
                    <td style={{ width: "250px", border: "None" }}>
                        {/* <b>Date :  </b> */}
                    </td>
                    <td style={{ width: "120px", border: "None" }}></td>
                    <td style={{ width: "100px", border: "None" }}></td>
                    <td style={{ width: "100px", border: "None" }}>

                    </td>
                </tr>
            </table>
            <div>
                {uniqueCategories.map((category, index) => (
                    <div key={index}>
                        <b>
                            <h5>{category}</h5>
                        </b>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th style={{ width: "50px" }}>Sr.No</th>
                                    <th style={{ width: "200px" }}>Question</th>
                                    <th style={{ width: "130px" }}>Area</th>
                                    <th style={{ width: "130px" }}>Grade</th>
                                    <th style={{ width: "130px" }}>Total Percentage Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportInfoData
                                    .filter((item) => item.items.category === category)
                                    .map((data, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{data.items.question}</td>
                                            <td>{data.items.section}</td>
                                            <td>{data.items.selectedoptionid}</td>
                                            <td>{data.items.total_marks}%</td>
                                        </tr>
                                    ))}

                                {/* Calculate and display total marks for the section */}
                                {/* <tr>
                                    <td colSpan="2"></td>
                                    <td>Total Marks:</td>
                                    <td>
                                        {reportInfoData
                                            .filter((item) => item.items.category === category)
                                            .reduce((total, data) => total + parseInt(data.items.total_marks), 0)}
                                        %
                                    </td>
                                </tr> */}
                            </tbody>

                        </table>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default FacpSectionResult