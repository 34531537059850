import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import moment from "moment";
import CbrCmhPdf from "./CbrCmhPdf";
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake";
import * as constants from "../constants/CONSTANT";
const CbrCmhList = () => {
    const navigate = useNavigate();
    const [body, setBody] = useState([]);
    const [totalCbrCrm, setTotalCbrCrm] = useState({});
    useEffect(() => {
        async function init() {
            const result = await inventoryApi.fetchCmhWorkerReport();
            if (result && result.length) {
                setBody(result);
            } else {
                setBody([]);
            }
        }
        init();
    }, []);
    const sumOfVocational = async (id) => {
        console.log('sumOfVocational@@=>', id);
        let result = await inventoryApi.fetchCmhWorkerReportById(id);
        console.log("result of totalAnalysis>>", result);
        if (result != null) {
            setTotalCbrCrm(result);
            setTimeout(function () {
                pdfShow()
            }, 500);


        }
        else {
            setTotalCbrCrm({});
        }

    }
    const handleOpenPdf = (row) => {
        console.log('handlePfdfCBR@@@+>', row);
        sumOfVocational(row?.id);
    };
    const pdfShow = () => {
        const pdfTable = document.getElementById("divToPrintCbr");
        console.log("pdf table india", pdfTable);
        var html = htmlToPdfmake(pdfTable.innerHTML, {
            tableAutoSize: true,
        });
        console.log('html', html)
        const documentDefinition = {
            content: html, defaultStyle: {
                font: 'arialunicodems1'
            }
        };
        //console.log('documentDefinition', documentDefinition)
        //pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.fonts = {
            arialunicodems1: {
                normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
                bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            },


            // download default Roboto font from cdnjs.com
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            }
        }
        //console.log(pdfMake.vfs)

        pdfMake.createPdf(documentDefinition).open();


    }
    const header = [
        {
            title: "Name Of Cmh",
            prop: "name",
            childname: true,
            isFilterable: true,
            cell: (row) => (
                <Link to={"/cbrcmhworkerreports/" + row.id + `/e`} state={row}>
                    {row.name}
                </Link>
            ),
        },
        { title: "Name Of Proect", prop: "projectname", isFilterable: true },
        // { title: 'Child Name', prop: 'childname', childname: true },
        { title: 'Date', prop: 'cmhdate', isFilterable: true, cell: (row) => moment(row.cmhdate).format('MM/DD/YYYY'), },
        { title: "CBR Field Area", prop: "cmhfieldarea", isFilterable: true },
        { title: 'Gender', prop: 'gender', isFilterable: true },
        {
            title: "Action",
            prop: "id",
            cell: (row) => (
                <Button variant="danger" className="btn-sm mx-2" onClick={() => handleOpenPdf(row)}>
                    <i class="fa-regular fa-file-pdf"></i>
                </Button>
            ),
        }
    ];

    const labels = {
        beforeSelect: "",
    };

    const createCbrCmh = () => {
        navigate(`/cbrcmhworkerreports/e`);
    };

    return (
        <Row className="g-0">
            <Col lg={2} className="mx-2">
                <Link className="nav-link">
                    Home <i class="fa-solid fa-chevron-right"></i> CBR List
                </Link>
            </Col>
            <Col lg={12} className="p-lg-4">
                {body ? (
                    <DatatableWrapper
                        body={body}
                        headers={header}
                        paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 10,
                                options: [5, 10, 15, 20],
                            },
                        }}
                    >
                        <Row className="mb-4">
                            <Col
                                xs={12}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Filter />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-start align-items-start"
                            >
                                <PaginationOptions labels={labels} />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                lg={4}
                                className="d-flex flex-col justify-content-end align-items-end"
                            >
                                <Button
                                    className="btn-sm"
                                    variant="outline-primary"
                                    onClick={() => createCbrCmh(true)}
                                >
                                    Create CBR CMH
                                </Button>
                            </Col>
                        </Row>
                        <Table striped className="data-table">
                            <TableHeader />
                            <TableBody />
                        </Table>
                        <Pagination />
                    </DatatableWrapper>
                ) : (
                    ""
                )}
            </Col>
            <Col lg={2}></Col>
            <div className="App container mt-5" style={{ display: 'none' }}>
                <div id="divToPrintCbr">
                    <CbrCmhPdf totalCbrCrm={totalCbrCrm} />
                </div>
            </div>
        </Row>
    );
};

export default CbrCmhList;