import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table} from "react-bootstrap";
import {useNavigate} from 'react-router-dom'
import inventoryApi from "../api/inventoryApi";

import {
DatatableWrapper,
Filter,
Pagination,
PaginationOptions,
TableBody,
TableHeader
} from 'react-bs-datatable';
import { Link } from "react-router-dom";

import moment from "moment"

const StaffMemberList = () => {


const navigate = useNavigate();
const [body, setBody] = useState([]);
useEffect(() => {
  async function init() {
    const result = await inventoryApi.fetchStaffMember();
    //(result)
    if (result) {
        setBody(result);
        
    } else {
        setBody([]);
        
    }

}
init();


}, []);

// Create table headers consisting of 4 columns.
const header = [
  {title: 'Username', prop: 'username', isFilterable: true,
  cell: (row) => (
    <Link
      to={"/staffmembers/" + row.id}
      state={row}
    >
      {row.username}
    </Link>
  )},   
  { title: 'Email', prop: 'email', isFilterable: true },
  { title: 'Phone', prop: 'phone', isFilterable: true },
  { title: 'DOB', prop: 'dob', isFilterable: true,cell: (row) => moment(row.dob).format('MM/DD/YYYY'),},
  { title: 'Gender', prop: 'gender', isFilterable: true },
  { title: 'City', prop: 'city', isFilterable: true },
  { title: 'User Role', prop: 'userrole', isFilterable: true },
  { title: 'Service Area', prop: 'servicearea', isFilterable: true },
  { title: 'User Type', prop: 'usertype', isFilterable: true },


  
];

// Randomize data of the table columns.
// Note that the fields are all using the `prop` field of the headers.
const labels = {
  beforeSelect: " "
}

const createStaffMember = () =>{
    navigate(`/staffmembers/e`);
  }


return (
  <Row className="g-0">
    <Col lg={2} className="mx-2">
      <Link className="nav-link">Home <i class="fa-solid fa-chevron-right"></i> Staff Member</Link>
    </Col>
    <Col lg={12} className = "p-lg-4">

      {body ?
        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 20,
            options: [5, 10, 15, 20]
          }
        }}>
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
              <PaginationOptions labels={labels} />

            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
            <Button className="btn-sm" variant="outline-primary" onClick={() => createStaffMember(true)}>Create Staff Member</Button>

            </Col>
          </Row>
          <Table striped className="data-table">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />

        </DatatableWrapper> : ''}
    </Col>
    <Col lg={2}></Col>
  </Row>
);
};

export default StaffMemberList;

