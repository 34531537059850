import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom';
import inventoryApi from '../../api/inventoryApi';

const BarChart = () => {
  const location = useLocation();

  // Extracting section and marks from assessment data
  const data = location?.state || [];
  const [studentData, setStudentData] = useState({});
  const [age, setAge] = useState(''); // Add age state
  const [serviceAreaName, setServiceAreaName] = useState(''); // Add service area name state

  useEffect(() => {
    fetchStudentData();
  }, []);

  const fetchStudentData = async () => {
    try {
      const result = await inventoryApi.fetchStudentById(data.studentid);
      console.log('result student:', result);
      setStudentData(result);

      // Assuming result contains age and serviceareaname properties
      setAge(result.age || ''); // Set age state
      setServiceAreaName(result.serviceareaname || ''); // Set service area name state
    } catch (error) {
      console.error('Error fetching student data:', error);
    }
  };

  // Aggregate marks based on sections
  const sectionData = {};
  data.forEach(item => {
    const section = item.section;
    if (section in sectionData) {
      sectionData[section] += item.marks;
    } else {
      sectionData[section] = item.marks;
    }
  });

  const sections = Object.keys(sectionData);
  const marks = Object.values(sectionData);

  // Define custom colors
  const colors = ['rgba(255, 165, 0, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(0, 0, 255, 0.7)', 'rgba(0, 128, 0, 0.7)'];

  const chartData = {
    labels: sections,
    datasets: [
      {
        label: 'Marks',
        backgroundColor: colors,
        borderColor: '#fff',
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: marks,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend if not needed
      },
    },
  };

  // Function to handle graph download


  return (
    <div style={{ maxWidth: '900px', margin: 'auto' }}>
      <h2 style={{ textAlign: 'center' }}>राजस्थान महिला कल्याण मंडल अजमेर</h2>
      <div>
        <p>Name of Student: {studentData.name}</p>
        <p>Age: {studentData.childregistrationage}</p>
        <p>Service Area: {studentData.servicearea}</p>
        <p>Service Area: {studentData.servicecategoryname}</p>
      </div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;
