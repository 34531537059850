import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Col, Table } from 'react-bootstrap';
import moment from 'moment';
import AnalysisReportPdf from './AnalysisReportPdf';

const StudentBehaviourPdf = (props) => {
  console.log('data props', props.data);

  return (
    <>
      <div>
        <hr />
        <table style={{ width: "100%" }}>
          <tr>
            <td style={{ border: "None", textAlign: "center" }}>
              <h6>WORK BEHAVIOUR REPORT </h6>
            </td>
          </tr>
        </table>
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              Date :
              {
                moment(props?.fieldsData?.jobanalysisdate).format('YYYY-MM-DD')
              }
            </td>
            <td>

            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              Job Title : {props?.fieldsData?._1_1j}
            </td>

            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              Job Title : {props?.fieldsData?._1_2j}
            </td>
          </tr>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          MAIN WORK AREAS
        </Col><br />
        

        <table style={{ width: '100%', fontSize: '10px', justifyContent: 'center', marginLeft: '0px' }}>
          <tbody>
          <thead>
            <tr>
              <th>Name</th>
              <th>Q1</th>
              <th>Q2</th>
              <th>Q3</th>
              <th>Q4</th>
            </tr>
          </thead>
            {Array.isArray(props?.complatedata) &&
              props?.complatedata?.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.q1}</td>
                  <td>{item.q2}</td>
                  <td>{item.q3}</td>
                  <td>{item.q4}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          WORK BEHAVIOUR
        </Col><br />
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          1. General Information
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              1. General Information : {props?.fieldsData?._5_1_1_g}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              2 Age/Sex : {props?.fieldsData?._5_1_2_g}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              3.Level of Mental Retardation : {props?.fieldsData?._5_1_1_g}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4 Job site : {props?.fieldsData?._5_1_4_g}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              5. Job Identified : {props?.fieldsData?._5_1_5_g}
            </td>
            <td>

            </td>
          </tr>
        </table>

        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          2. REPORT (BASED ON THE CHECKLIST)
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              1. Physical appearance : {props?.fieldsData?._5_2_1_r}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              2. Personal interaction : {props?.fieldsData?._5_2_2_r}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              3 Regularity and punctuality : {props?.fieldsData?._5_2_3_r}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4. Communication and Social manners {props?.fieldsData?._5_2_4_r}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              5. Quality and quantity aspects : {props?.fieldsData?._5_2_5_r}
            </td>
            <td>

            </td>
          </tr>
        </table>

        <table style={{ width: "100%" }}>
          <tr>
            <td style={{ border: "None", textAlign: "center" }}>
              {/* <h5>{studentInfo["servicecategoryname"]}</h5>
              <h6>{studentInfo.location}</h6> */}
              <h6> ANALYISREPORTPDF </h6>
            </td>
          </tr>
        </table>
        <hr />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ width: "50px", border: "None" }}></td>
            <td style={{ border: "None" }}>
              <h4 style={{ fontSize: "17px" }}> <b>Areas</b></h4>
            </td>
            <td style={{ width: "250px", border: "None" }}></td>

            <td style={{ border: "None" }}>
              <h4 style={{ fontSize: "17px" }}> <b>Total</b></h4>
            </td>
            <td style={{ width: "120px", border: "None" }}></td>

            <td style={{ border: "None" }}>
              <h4 style={{ fontSize: "17px" }}> <b>Date</b></h4>
            </td>
            <td style={{ width: "200px", border: "None" }}></td>
          </tr>

        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          COMMENTS
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              Comments : {props?.fieldsData?._5_3_c}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>

            </td>
          </tr>

        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          6. JOB REQUIREMENTS
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              1. Equipments and materials {props?.fieldsData?._6_1_1}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              2 Cost of production {props?.fieldsData?._6_1_2}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              3. Profit expected {props?.fieldsData?._6_1_3}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4. Adaptive devices {props?.fieldsData?._6_1_4}
            </td>
          </tr>
        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          7. JOB TRAINING PROCEDURES
        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              1. Training Strategies {props?.fieldsData?._7_1_1}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              2. Training in simulated setting {props?.fieldsData?._7_1_2}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              3. On the Job training {props?.fieldsData?._7_1_3}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4. Reinforcement/wages/salary {props?.fieldsData?._7_1_4}
            </td>
          </tr>

        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          7.5 JOB PLACEMNT

        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              a Open/ Supported/group/sheltered : {props?.fieldsData?._7_1_5_1_1a}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              b.Self/home based : {props?.fieldsData?._7_1_5_1_1b}
            </td>
          </tr>

        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          8. TRAINER'S RESPONSIBILIIES

        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              1. Employer contacts: {props?.fieldsData?._7_1_5_1_1a}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              2. Co-workers awareness : {props?.fieldsData?._7_1_5_1_1b}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              3. Employer contacts: {props?.fieldsData?._9_1_3}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4. Co-workers awareness : {props?.fieldsData?._9_1_4}
            </td>
          </tr>

        </table>
        <Col lg={12} style={{ border: "1px solid gray", width: "50%", height: "30px", background: "lightgray", textAlign: "center" }} className="section-header my-3 mx-2">
          9. PARENTS COOPERATION

        </Col><br />
        <table style={{ width: "100%", fontSize: "10px", justifyContent: "center", marginLeft: "0px" }}>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              1. Employer contacts: {props?.fieldsData?._9_1_1}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              2. Providing healthy food / lunch : {props?.fieldsData?._9_1_2}
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              3. Implementation of programme :  {props?.fieldsData?._9_1_3}
            </td>
            <td style={{ border: "1px solid gray", width: "50%", height: "30px" }}>
              4. Discussion with employer : {props?.fieldsData?._9_1_4}
            </td>
          </tr>

        </table>


      </div>
    </>
  );
};

export default StudentBehaviourPdf;
