import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import inventoryApi from "../api/inventoryApi";
import { ShimmerTable } from "react-shimmer-effects";
import PdfGenerator from "./PdfGenerator";
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import * as constants from "../constants/CONSTANT";
import Confirm from './Confirm';
import moment from "moment"

import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { Link } from "react-router-dom";
import PubSub from 'pubsub-js';
import VocationalPdfFile from "./VocationalPdfFile";


const VocationalProfileList = () => {
    const navigate = useNavigate();
    const [body, setBody] = useState([]);
    const [totalVocational,setTotalVocational] = useState({});
    const [rowId,setRowId] = useState()
    const [vocdata,setvocdata] = useState({});

    useEffect(() => {
      fetchById()
      async function init() {
        let result = []
         result = await inventoryApi.fetchVoc();
        console.log("===list fetchVov result=====",result);
        if (result) {
            setBody(result);
            
        } else {
            setBody([]);
            
        }
    
    }
    init();
    
    
    }, []);   
    
    const fetchById =async(id)=>{
      let result = await inventoryApi.fetchVocById(id);
      console.log('@@@result =====> ', result);
      setvocdata(result)

    }
    const sumOfVocational = async (id)=>{
      let result = await inventoryApi.fetchSumOfVocational(id);
      console.log("result of totalAnalysis>>",result);
      if(result != null){
        setTotalVocational(result);
        setTimeout(function() {
                 pdfShow()
          }, 500);
          
                  
      }
      else{
        setTotalVocational({});
      }   

    }


    


    const handleOpenPdf =(row)=>{
      fetchById(row?.id)
      sumOfVocational(row?.id);
      
    };

    const pdfShow =()=>{
      const pdfTable = document.getElementById("divToPrint");
      //console.log("pdf table", pdfTable);
      var html = htmlToPdfmake(pdfTable.innerHTML, {
          tableAutoSize: true,
      });
      console.log('html', html)
      const documentDefinition = {
          content: html, defaultStyle: {
              font: 'arialunicodems1'
          }
      };
      //console.log('documentDefinition', documentDefinition)
      //pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
          arialunicodems1: {
              normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
              bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
          },
  
  
          // download default Roboto font from cdnjs.com
          Roboto: {
              normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
              bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
              italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
              bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
          }
      }
      //console.log(pdfMake.vfs)
    
        pdfMake.createPdf(documentDefinition).open();
     
        
    }
    
    // Create table headers consisting of 4 columns.
    const header = [
      {title: 'Traineename', prop: 'traineename', isFilterable: true,
      cell: (row) => (
        <Link
          to={"/vocationalprofile/" + row.id}
          state={row.id}
        >
          {row.traineename}
        </Link>
      )},   
      { title: 'Mental', prop: 'mentalretardational', isFilterable: true },
      { title: 'Marital Status', prop: 'marital', isFilterable: true },
      {
        title: "Action",
        prop: "id",
        cell: (row) => (
            <Button variant="danger" className="btn-sm mx-2" onClick={() => handleOpenPdf(row)}>
                <i class="fa-regular fa-file-pdf"></i>
            </Button>
        ),
    },
      
    ];
    
    // Randomize data of the table columns.
    // Note that the fields are all using the `prop` field of the headers.
    const labels = {
      beforeSelect: " "
    }
    
    const createVocationalProfile = () =>{
        navigate(`/vocationalprofile/e`);
      }
    
    
    return (
      <Row className="g-0">
        <Col lg={2} className="mx-2">
          <Link className="nav-link">Home <i class="fa-solid fa-chevron-right"></i> Vocational Profile</Link>
        </Col>
        <Col lg={12} className = "p-lg-4">
    
          {body ?
            <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
              initialState: {
                rowsPerPage: 20,
                options: [5, 10, 15, 20]
              }
            }}>
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
    
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                <Button className="btn-sm" variant="outline-primary" onClick={() => createVocationalProfile(true)}>Create Vocational Profile</Button>
    
                </Col>
              </Row>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
    
            </DatatableWrapper> : ''}
        </Col>
        <Col lg={2}></Col>
        <div className="App container mt-5" style={{ display: 'none' }}>
            <div id="divToPrint">
                 <VocationalPdfFile totalVocational = {totalVocational} completedata = {vocdata}/>
            </div>
        </div>
      </Row>
    );
    };


export default VocationalProfileList
