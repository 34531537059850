import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Form, FormSelect } from "react-bootstrap";
import moment from "moment";
import inventoryApi from "../api/inventoryApi";
import PubSub from "pubsub-js";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import html2pdf from 'html2pdf.js';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Select from "react-select";
import VisionHearingPdf from "./VisionHearingPdf";
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import * as constants from "../constants/CONSTANT";

// Add by Aamir khan 26-11-2023
const FinalAssessmentEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let [state, setstate] = useState({});
    // const [YesCount1, setYesCount1] = useState(0);

    const [option, setoption] = useState();
    const [optionUsers, setOptionUsers] = useState([]);

    const [finalassessment, setfinalassessment] = useState(location.state ? location.state : {});
    console.log('finalassessment edit',finalassessment)
    console.log('finalassessment.sectioncount1', finalassessment.sectioncount1);
    console.log('finalassessment', finalassessment);

    useEffect(() => {
        console.log('call useeffect', finalassessment)
        let tempValue = {};
        tempValue.value = finalassessment.clientid;
        tempValue.label = finalassessment.clientname;
        setoption(tempValue);
    }, []);


    const [existingCount, setExistingCount] = useState(finalassessment.sectioncount1 || 0);
    console.log('existingCount==>',existingCount);
    const [newCount, setNewCount] = useState(existingCount);
    console.log('newCount===>',newCount);

    const [existingCount1, setExistingCount1] = useState(finalassessment.sectioncount2 || 0);
    const [newCount1, setNewCount1] = useState(existingCount1);

    const [existingCount2, setExistingCount2] = useState(finalassessment.sectioncount3 || 0);
    const [newCount2, setNewCount2] = useState(existingCount2);

    const [existingCount3, setExistingCount3] = useState(finalassessment.sectioncount4 || 0);
    const [newCount3, setNewCount3] = useState(existingCount3);

    const [existingCount4, setExistingCount4] = useState(finalassessment.sectioncount5 || 0);
    const [newCount4, setNewCount4] = useState(existingCount4);

    const [existingCount5, setExistingCount5] = useState(finalassessment.sectioncount6 || 0);
    const [newCount5, setNewCount5] = useState(existingCount5);

    const [existingCount6, setExistingCount6] = useState(finalassessment.sectioncount7 || 0);
    const [newCount6, setNewCount6] = useState(existingCount6);

    const [existingCount7, setExistingCount7] = useState(finalassessment.sectioncount8 || 0);
    const [newCount7, setNewCount7] = useState(existingCount7);

    const [existingCount8, setExistingCount8] = useState(finalassessment.sectioncount9 || 0);
    const [newCount8, setNewCount8] = useState(existingCount8);
    const [namedArray, setNamedArray] = useState([{key: '',value: ''}]);


    // const [existingCount3, setExistingCount3] = useState(finalassessment.sectioncount4 || 0);
    // const [newCount3, setNewCount3] = useState(existingCount3);



    useEffect(() => {

        if (finalassessment) {
            setstate(finalassessment.data);
            console.log('test')
        }
    }, []);


    const handleCancel = () => {
        navigate(`/finalassessmentchecklist`)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        finalassessment.sectioncount1 = newCount;
        finalassessment.sectioncount2 = newCount1;
        finalassessment.sectioncount3 = newCount2;
        finalassessment.sectioncount4 = newCount3;
        finalassessment.sectioncount5 = newCount4;
        finalassessment.sectioncount6 = newCount5;
        finalassessment.sectioncount7 = newCount6;
        finalassessment.sectioncount8 = newCount7;
        finalassessment.sectioncount9 = newCount8;
        console.log('inside handle submit-->', finalassessment.sectioncount1);
        finalassessment.data = state;
        console.log('handle submit method called', finalassessment);
        let result = await inventoryApi.saveFinalAssessment(finalassessment);
        console.log('result', result)
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });

        navigate(`/finalassessmentchecklist`);
        console.log(finalassessment)
    }

    let isChecked = true;
    const [checkedBoxes, setCheckedBoxes] = useState([]);

    const handleChange = (e) => {
       
        let yesCount = 0;
       [...document.querySelectorAll('.family-history-checkbox')].forEach(x =>{
           if(x.querySelector('input').checked){
                ++yesCount;
           }
        });
        console.log('checkedInputs', yesCount);

        setNewCount(yesCount);

        console.log('console 1');
        const { name, value, checked } = e.target;
        // const value = e.target.value;
        isChecked = e.target.checked;

        const itemIndex = namedArray.findIndex((item) => item.key === name);
        console.log('itemIndex==>',itemIndex);

        if (itemIndex !== -1) {
            console.log('If part1');
            console.log('console 2');
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
          } else {
            setNamedArray([...namedArray, { key: name, value }]);
        }


        setCheckedBoxes((prevState) => {
            console.log('setCheckedBoxes');
            const increment = checked ? value === 'Yes' ? 1 : namedArray.reduce((acc, res) => {
                console.log('#acc=>',acc);
                console.log('#res=>',res);
                 console.log('ching');
                 if (res.key === name && res.value === 'Yes'){
                    return acc - 1;
                  }
                  console.log('#return acc=>',acc);
                  return acc;
                },0)
            : 0;
            console.log('increment',increment)
            
            // setNewCount((prevCount) => value === 'Yes' ? Math.max(0, parseInt(prevCount)+ 1) : Math.max(0, parseInt(prevCount)- 1));
      
            if (checked && !prevState.includes(value)){
                return [...prevState, value];
            } else if (!checked){
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });
        
 
        setfinalassessment({ ...finalassessment, sectioncount1: newCount });
        setstate({ ...state, [e.target.name]: value });
        
        if (isChecked) {
            console.log("if call");
            setCheckedBoxes((prevState) => [...prevState, value]);
        } else {
            setCheckedBoxes((prevState) => prevState.filter((box) => box !== value));
        }
        setfinalassessment({ ...finalassessment, [e.target.name]: e.target.value });
    }

    const handleChange1 = (e) => {
        console.log('console 1', document.querySelectorAll('.family-history-checkbox'));

        let yesCount = 0;
        [...document.querySelectorAll('.family-history-checkbox1')].forEach(x =>{
            if(x.querySelector('input').checked){
                 ++yesCount;
            }
         });
         console.log('checkedInputs', yesCount);
 
         setNewCount1(yesCount);

        const { name, value, checked } = e.target;
        // const value = e.target.value;
        isChecked = e.target.checked;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            console.log('console 2');
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
          } else {
           
            setNamedArray([...namedArray, { key: name, value }]);
        }

        setCheckedBoxes((prevState) => {
            
            const increment = checked
            ? value === 'Yes'
              ? 1
              : namedArray.reduce((acc, res) => {
                  if (res.key === name && res.value === 'Yes') {
                    return acc - 1;
                  }
                  return acc;
                }, 0)
            : 0;
            // setNewCount1((prevCount) => Math.max(0, parseInt(prevCount) + increment));
      
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        setfinalassessment({ ...finalassessment, sectioncount1: newCount });
        setstate({ ...state, [e.target.name]: value });
        
        if (isChecked) {
            console.log("if call");
            setCheckedBoxes((prevState) => [...prevState, value]);
        } else {
            setCheckedBoxes((prevState) => prevState.filter((box) => box !== value));
        }
        setfinalassessment({ ...finalassessment, [e.target.name]: e.target.value });

    }
    const handleChange2 = (e) => {

        let yesCount = 0;
        [...document.querySelectorAll('.family-history-checkbox2')].forEach(x =>{
            if(x.querySelector('input').checked){
                 ++yesCount;
            }
         });
         console.log('checkedInputs', yesCount);
 
         setNewCount2(yesCount);

        console.log('console 1');
        const { name, value, checked } = e.target;
        // const value = e.target.value;
        isChecked = e.target.checked;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            console.log('console 2');
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
          } else {
           
            setNamedArray([...namedArray, { key: name, value }]);
        }

        setCheckedBoxes((prevState) => {
            
            const increment = checked
            ? value === 'Yes'
              ? 1
              : namedArray.reduce((acc, res) => {
                  if (res.key === name && res.value === 'Yes') {
                    return acc - 1;
                  }
                  return acc;
                }, 0)
            : 0;
            // setNewCount2((prevCount) => Math.max(0, parseInt(prevCount) + increment));
      
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        setfinalassessment({ ...finalassessment, sectioncount1: newCount });
        setstate({ ...state, [e.target.name]: value });
        
        if (isChecked) {
            console.log("if call");
            setCheckedBoxes((prevState) => [...prevState, value]);
        } else {
            setCheckedBoxes((prevState) => prevState.filter((box) => box !== value));
        }
        setfinalassessment({ ...finalassessment, [e.target.name]: e.target.value });

    }

    const handleChange3 = (e) => {
        console.log('console 1');
        let yesCount = 0;
        [...document.querySelectorAll('.family-history-checkbox3')].forEach(x =>{
            if(x.querySelector('input').checked){
                 ++yesCount;
            }
         });
         console.log('checkedInputs', yesCount);
 
         setNewCount3(yesCount);
        const { name, value, checked } = e.target;
        // const value = e.target.value;
        isChecked = e.target.checked;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            console.log('console 2');
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
          } else {
           
            setNamedArray([...namedArray, { key: name, value }]);
        }

        setCheckedBoxes((prevState) => {
            
            const increment = checked
            ? value === 'Yes'
              ? 1
              : namedArray.reduce((acc, res) => {
                  if (res.key === name && res.value === 'Yes') {
                    return acc - 1;
                  }
                  return acc;
                }, 0)
            : 0;
            // setNewCount3((prevCount) => Math.max(0, parseInt(prevCount) + increment));
      
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        setfinalassessment({ ...finalassessment, sectioncount1: newCount });
        setstate({ ...state, [e.target.name]: value });
        
        if (isChecked) {
            console.log("if call");
            setCheckedBoxes((prevState) => [...prevState, value]);
        } else {
            setCheckedBoxes((prevState) => prevState.filter((box) => box !== value));
        }
        setfinalassessment({ ...finalassessment, [e.target.name]: e.target.value });

    }

    const handleChange4 = (e) => {
        console.log('console 1');
    
        let yesCount = 0;
        [...document.querySelectorAll('.family-history-checkbox4')].forEach(x => {
            if (x.querySelector('input').checked) {
                ++yesCount;
            }
        });
        console.log('checkedInputs', yesCount);
    
        setNewCount4(yesCount);
        const { name, value, checked } = e.target;
        isChecked = e.target.checked;
    
        const itemIndex = namedArray.findIndex((item) => item.key === name);
    
        if (itemIndex !== -1) {
            console.log('console 2');
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
        } else {
            setNamedArray([...namedArray, { key: name, value }]);
        }
    
        setCheckedBoxes((prevState) => {
    
            const increment = checked
                ? value === 'Yes'
                    ? 1
                    : namedArray.reduce((acc, res) => {
                        if (res.key === name && res.value === 'Yes') {
                            return acc - 1;
                        }
                        return acc;
                    }, 0)
                : 0;
    
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });
    
        setfinalassessment({ ...finalassessment, sectioncount1: newCount4 });
        setstate({ ...state, [e.target.name]: value });
    
        if (isChecked) {
            console.log("if call");
            setCheckedBoxes((prevState) => [...prevState, value]);
        } else {
            setCheckedBoxes((prevState) => prevState.filter((box) => box !== value));
        }
        setfinalassessment({ ...finalassessment, [e.target.name]: value });
    }
    

    const handleChange5 = (e) => {

        let yesCount = 0;
        [...document.querySelectorAll('.family-history-checkbox5')].forEach(x =>{
            if(x.querySelector('input').checked){
                 ++yesCount;
            }
         });
         console.log('checkedInputs', yesCount);
 
         setNewCount5(yesCount);
        console.log('console 1');
        const { name, value, checked } = e.target;
        // const value = e.target.value;
        isChecked = e.target.checked;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            console.log('console 2');
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
          } else {
           
            setNamedArray([...namedArray, { key: name, value }]);
        }

        setCheckedBoxes((prevState) => {
            
            const increment = checked
            ? value === 'Yes'
              ? 1
              : namedArray.reduce((acc, res) => {
                  if (res.key === name && res.value === 'Yes') {
                    return acc - 1;
                  }
                  return acc;
                }, 0)
            : 0;
            // setNewCount5((prevCount) => Math.max(0, parseInt(prevCount) + increment));
      
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        setfinalassessment({ ...finalassessment, sectioncount1: newCount });
        setstate({ ...state, [e.target.name]: value });
        
        if (isChecked) {
            console.log("if call");
            setCheckedBoxes((prevState) => [...prevState, value]);
        } else {
            setCheckedBoxes((prevState) => prevState.filter((box) => box !== value));
        }
        setfinalassessment({ ...finalassessment, [e.target.name]: e.target.value });

    }

    const handleChange6 = (e) => {

        let yesCount = 0;
        [...document.querySelectorAll('.family-history-checkbox6')].forEach(x =>{
            if(x.querySelector('input').checked){
                 ++yesCount;
            }
         });
         console.log('checkedInputs', yesCount);
 
         setNewCount6(yesCount);
        console.log('console 1');
        const { name, value, checked } = e.target;
        // const value = e.target.value;
        isChecked = e.target.checked;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            console.log('console 2');
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
          } else {
           
            setNamedArray([...namedArray, { key: name, value }]);
        }

        setCheckedBoxes((prevState) => {
            
            const increment = checked
            ? value === 'Yes'
              ? 1
              : namedArray.reduce((acc, res) => {
                  if (res.key === name && res.value === 'Yes') {
                    return acc - 1;
                  }
                  return acc;
                }, 0)
            : 0;
            // setNewCount6((prevCount) => Math.max(0, parseInt(prevCount) + increment));
      
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        setfinalassessment({ ...finalassessment, sectioncount1: newCount });
        setstate({ ...state, [e.target.name]: value });
        
        if (isChecked) {
            console.log("if call");
            setCheckedBoxes((prevState) => [...prevState, value]);
        } else {
            setCheckedBoxes((prevState) => prevState.filter((box) => box !== value));
        }
        setfinalassessment({ ...finalassessment, [e.target.name]: e.target.value });

    }

    const handleChange7 = (e) => {

        let yesCount = 0;
        [...document.querySelectorAll('.family-history-checkbox7')].forEach(x =>{
            if(x.querySelector('input').checked){
                 ++yesCount;
            }
         });
         console.log('checkedInputs', yesCount);
 
         setNewCount7(yesCount);
        console.log('console 1');
        const { name, value, checked } = e.target;
        // const value = e.target.value;
        isChecked = e.target.checked;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            console.log('console 2');
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
          } else {
           
            setNamedArray([...namedArray, { key: name, value }]);
        }

        setCheckedBoxes((prevState) => {
            
            const increment = checked
            ? value === 'Yes'
              ? 1
              : namedArray.reduce((acc, res) => {
                  if (res.key === name && res.value === 'Yes') {
                    return acc - 1;
                  }
                  return acc;
                }, 0)
            : 0;
            // setNewCount7((prevCount) => Math.max(0, parseInt(prevCount) + increment));
      
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        setfinalassessment({ ...finalassessment, sectioncount1: newCount });
        setstate({ ...state, [e.target.name]: value });
        
        if (isChecked) {
            console.log("if call");
            setCheckedBoxes((prevState) => [...prevState, value]);
        } else {
            setCheckedBoxes((prevState) => prevState.filter((box) => box !== value));
        }
        setfinalassessment({ ...finalassessment, [e.target.name]: e.target.value });

    }

    const handleChange8 = (e) => {

        let yesCount = 0;
        [...document.querySelectorAll('.family-history-checkbox8')].forEach(x =>{
            if(x.querySelector('input').checked){
                 ++yesCount;
            }
         });
         console.log('checkedInputs', yesCount);
 
         setNewCount8(yesCount);
        console.log('console 1');
        const { name, value, checked } = e.target;
        // const value = e.target.value;
        isChecked = e.target.checked;

        const itemIndex = namedArray.findIndex((item) => item.key === name);

        if (itemIndex !== -1) {
            console.log('console 2');
            const updatedArray = [...namedArray];
            updatedArray[itemIndex] = { ...updatedArray[itemIndex], value };
            setNamedArray(updatedArray);
          } else {
           
            setNamedArray([...namedArray, { key: name, value }]);
        }

        setCheckedBoxes((prevState) => {
            
            const increment = checked
            ? value === 'Yes'
              ? 1
              : namedArray.reduce((acc, res) => {
                  if (res.key === name && res.value === 'Yes') {
                    return acc - 1;
                  }
                  return acc;
                }, 0)
            : 0;
            // setNewCount8((prevCount) => Math.max(0, parseInt(prevCount) + increment));
      
            if (checked && !prevState.includes(value)) {
                return [...prevState, value];
            } else if (!checked) {
                console.log('data:', prevState.filter((box) => box !== value));
                return prevState.filter((box) => box !== value);
            }
            return prevState;
        });

        setfinalassessment({ ...finalassessment, sectioncount1: newCount });
        setstate({ ...state, [e.target.name]: value });
        
        if (isChecked) {
            console.log("if call");
            setCheckedBoxes((prevState) => [...prevState, value]);
        } else {
            setCheckedBoxes((prevState) => prevState.filter((box) => box !== value));
        }
        setfinalassessment({ ...finalassessment, [e.target.name]: e.target.value });

    }
    console.log('hatho hath check kiya gya bahar-->',finalassessment);
    console.log('existingCount outside',existingCount);
    console.log('newCount outside',newCount);
    console.log('finalassessment', finalassessment)


    const handleChangeTextbox = (e) => {
        const { name, value } = e.target;

        console.log("handleChangeTextbox");
        console.log("e.target.name", name);
        console.log("e.target.value", value);

        setstate({ ...state, [name]: value });

        setfinalassessment({
            ...finalassessment,
            data: {
                ...finalassessment.data,
                [name]: value,
            },
        });
    };


    const handlePrint = () => {
        const pdfTable = document.getElementById("divToPrint");
        //console.log("pdf table", pdfTable);
        var html = htmlToPdfmake(pdfTable.innerHTML, {
            tableAutoSize: true,
        });
        console.log('html', html)
        const documentDefinition = {
            content: html, defaultStyle: {
                font: 'arialunicodems1'
            }
        };
        pdfMake.fonts = {
            arialunicodems1: {
                normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
                bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            },


            // download default Roboto font from cdnjs.com
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            }
        }
        //console.log(pdfMake.vfs)

        pdfMake.createPdf(documentDefinition).open();


    };


    const [selectedUser, setSelectedUser] = useState('');

    const handleUsers = async (event) => {
        console.log('event', event)
        let result = await inventoryApi.fetchStudentById(event.value);
        console.log("result of Student>>", result);
        finalassessment.clientage = result.childregistrationage
        finalassessment.clientsex = result.gender
        finalassessment.clientregistration = result.regnumber
        finalassessment.clientname = result.name
        setoption(event)
        setSelectedUser(event)
        setfinalassessment({ ...finalassessment, clientid: event.value, clientname: event.label });
    }




    return (

        <>

            <Container id="marbt">

                <Row>
                    <Col lg={12} className="mx-3">
                        <Form className="mt-3" onSubmit={handleSubmit}>
                            <Row className="view-form-header align-items-center">
                                <Col lg={6}>Vision screening format </Col>
                                <Col lg={6} className="d-flex justify-content-end">
                                    <Button variant="info" onClick={handlePrint}> PDF</Button>

                                    <Button
                                        className="btn-sm mx-2"
                                        type="submit"
                                        onSubmit={handleSubmit}
                                    >
                                        Save
                                    </Button>
                                    <Button className="btn-sm" variant="danger" onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <div id="pdfcontainer" className="responsive-pdf-container">
                    <div className="section-header my-3">
                        <b className="align-item-center" style={{ marginLeft: "36%" }}> Rajasthan Mahila Kalyan Mandal (RMKM)<br></br></b>
                        <b className="align-item-center" style={{ marginLeft: "38%" }}>Vision And Hearing Assessment </b>
                    </div>
                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Case Name</Form.Label>
                                <Select
                                    name="clientname"
                                    value={option}
                                    onChange={handleUsers}
                                    options={optionUsers}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className=" my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Age</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="clientage"
                                    value={finalassessment.clientage}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className=" mx-3 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Gender</Form.Label>
                                <Form.Select
                                    aria-label="Enter Gender"
                                    name="clientsex"
                                    value={finalassessment.clientsex}
                                    onChange={handleChange}
                                >
                                    <option value="" >--Select--</option>
                                    <option value="Male">Male </option>
                                    <option value="Female">Female</option>
                                    <option value="Others">Others</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group className=" my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Registration No:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="clientregistration"
                                    //placeholder="maritalstatus"
                                    value={finalassessment.clientregistration}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Date </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="datescreening"
                                    //placeholder="Name of the trainee"
                                    value={finalassessment.datescreening}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group className="mx-2 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Place of screening: </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="placeofscreening"
                                    //placeholder="Name of the trainee"
                                    value={finalassessment.placeofscreening}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row lg={12}>
                        <Col lg={12} className="section-header mx-4 my-3">
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">

                                <p>This schedule is for screening of vision and hearing problems among children and adults in                  special schools, villages or in camps. Observe the child in his familiar environment and              answer the following questions in YES or NO. Consult with other family members too. If you get consistent and frequent YES answers, on the screening schedule for vision problems then please refer the child/person to an Ophthalmologist/Eye Specialist. Similarly, if you get consistent and frequent YES answers, on the screening schedule for hearing problems then please refer the child/person to ENT/Audiologist for a clinical hearing testing at the nearest centre.</p>

                            </Form.Group>
                        </Col>
                    </Row>
                    <Col lg={12} className="section-header my-3 mx-2">
                        A) Family History
                    </Col>
                    <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does anyone in your family have a severe vision loss or eye
                                disease? (e.g., Albinism, amblyopia, cataracts, glaucoma,
                                strabismus, retinoblastoma)
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox"
                            name="familyhistory_1"
                            value="Yes"
                            checked={finalassessment?.familyhistory_1 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory_1"
                            value="No"
                            type="radio"
                            checked={finalassessment?.familyhistory_1 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="retinoblastomatextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.retinoblastomatextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Did the child's mother have any serious infection or diseases
                                during pregnancy? (e.8., Rubella, cytomegalovirus,
                                toxoplasmosis, syphilis, herpes)
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="familyhistory_2"
                            className="family-history-checkbox"
                            value="Yes"
                            checked={finalassessment.familyhistory_2 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory_2"
                            value="No"
                            type="radio"
                            checked={finalassessment?.familyhistory_2 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="herpestextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.herpestextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Did the child mother use drugs or alcohol during pregnancy?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox"
                            name="familyhistory_3"
                            value="Yes"
                            checked={finalassessment.familyhistory_3 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory_3"
                            value="No"
                            type="radio"
                            checked={finalassessment.familyhistory_3 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="pregnancytextboxes"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.pregnancytextboxes}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Has a vision problem been identified or suspected?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox"
                            name="familyhistory_4"
                            value="Yes"
                            checked={finalassessment.familyhistory_4 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory_4"
                            value="No"
                            type="radio"
                            checked={finalassessment.familyhistory_4 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="suspectedtextboxes1"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.suspectedtextboxes1}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Do you have any concern about your child's vision ?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox"
                            name="familyhistory_5"
                            value="Yes"
                            checked={finalassessment.familyhistory_5 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory_5"
                            value="No"
                            type="radio"
                            checked={finalassessment.familyhistory_5 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="visiontextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.visiontextbox}
                               
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <p style={{ marginLeft: "62%" }}> {newCount}</p>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    B) Appearances of the Eye (Screening Schedule for Vision Problem in
                    Children)
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Encrusted eye lashes
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2}className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_1"
                            className="family-history-checkbox1"
                            value="Yes"
                            checked={finalassessment.apperenceofeyes_1 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_1"
                            value="No"
                            type="radio"
                            checked={finalassessment.apperenceofeyes_1 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="childrentextbox"
                                //placeholder="Name of the trainee"
                               
                                value={finalassessment?.data?.childrentextbox}

                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Swollen or red eyelid
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox1"
                            name="apperenceofeyes_2"
                            value="Yes"
                            checked={finalassessment.apperenceofeyes_2 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_2"
                            value="No"
                            type="radio"
                            checked={finalassessment.apperenceofeyes_2 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="eyelidtextbox"
                                //placeholder="Name of the trainee"
                                // 
                                value={finalassessment?.data?.eyelidtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Frequent (stye)
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_3"
                            className="family-history-checkbox1"
                            value="Yes"
                            checked={finalassessment.apperenceofeyes_3 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_3"
                            value="No"
                            type="radio"
                            checked={finalassessment.apperenceofeyes_3 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="Frequenttextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.Frequenttextbox}
                                // eyelidtextbox
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Discharge in the eye or along the eye lids
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_4"
                            className="family-history-checkbox1"
                            value="Yes"
                            checked={finalassessment.apperenceofeyes_4 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_4"
                            value="No"
                            type="radio"
                            checked={finalassessment.apperenceofeyes_4 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="lidstextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.lidstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Do you have concerns about your child's vision ?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="apperenceofeyes_5"
                            className="family-history-checkbox1"
                            value="Yes"
                            checked={finalassessment.apperenceofeyes_5 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_5"
                            value="No"
                            type="radio"
                            checked={finalassessment.apperenceofeyes_5 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="childtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.childtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Eyes that are usually red or watery
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2}className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox1"
                            name="apperenceofeyes_6"
                            value="Yes"
                            checked={finalassessment.apperenceofeyes_6 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_6"
                            value="No"
                            type="radio"
                            checked={finalassessment.apperenceofeyes_6 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="waterytextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.waterytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Pupils of different sizes or clouding of pupils
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2}className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox1"
                            name="apperenceofeyes_7"
                            value="Yes"
                            checked={finalassessment.apperenceofeyes_7 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_7"
                            value="No"
                            type="radio"
                            checked={finalassessment.apperenceofeyes_7 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="pupilstextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.pupilstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Drooping eyelids
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox1"
                            name="apperenceofeyes_8"
                            value="Yes"
                            checked={finalassessment.apperenceofeyes_8 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_8"
                            value="No"
                            type="radio"
                            checked={finalassessment.apperenceofeyes_8 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="droopingtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.droopingtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Eyes that do not appear straight (Crossed eyes)
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox1"
                            name="apperenceofeyes_9"
                            value="Yes"
                            checked={finalassessment.apperenceofeyes_9 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange1}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="apperenceofeyes_9"
                            value="No"
                            type="radio"
                            checked={finalassessment.apperenceofeyes_9 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange1}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="appeartextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.appeartextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <p style={{ marginLeft: "62%" }}> {newCount1}</p>
                </Row>
                {/*A) Visual behaviour******* */}
                <Col lg={12} className="section-header my-3 mx-2">
                    A) Visual behaviours
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Complains of aches or pain in the eyes
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_1"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_1 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_1"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_1 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="behaviourstextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.behaviourstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Excessive headaches
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_2"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_2 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_2"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_2 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="headachestextboxes1"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.headachestextboxes1}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Dizziness or nausea after close eye work
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="mentalretardlevel"
                //placeholder="Level of Mental Retard "
                // value={vocProfile.mentalretardlevel}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_3"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_3 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_3"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_3 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="eyeworktextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.eyeworktextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Squinting and constant blinking
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_4"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_4 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_4"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_4 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="blinkingtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.blinkingtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Frowning, facial distortion
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_5"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_5 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_5"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_5 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="distortiontextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.distortiontextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Constant rubbing of eyes or attempts to brush away a shadow
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_6"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_6 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_6"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_6 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="shadowtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.shadowtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    {/* value={finalassessment?.data?.distortiontextbox} */}
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Tilting of the heads to see
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_7"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_7 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_7"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_7 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="headstextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.headstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Closing or covering one eye when looking or reading
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2}className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_8"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_8 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_8"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_8 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="readingtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.readingtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Child constantly requests someone to tell what is going on
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_9"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_9 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_9"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_9 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="goingtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.goingtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Child has poor eye hand coordination and unusual
                                awkwardness/clumsiness during task using hands or the child has
                                balance problem during walking
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2}className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_10"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_10 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_10"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_10 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="walkingtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.walkingtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Child bumps/clumsy during walking particularly in new
                                environment
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_11"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_11 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_11"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_11 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="environmenttextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.environmenttextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Unduly sensitive to bright light, glare or shiny objects
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_12"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_12 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_12"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_12 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="shinytextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.shinytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does the child have difficulty seeing at night or in dark?
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_13"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_13 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_13"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_13 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="darktextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.darktextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                The child need glasses to see objects
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox2"
                            name="visualbehaviour_14"
                            value="Yes"
                            checked={finalassessment.visualbehaviour_14 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange2}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualbehaviour_14"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualbehaviour_14 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange2}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="glassestextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.glassestextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <p style={{ marginLeft: "62%" }}>{newCount2}</p>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    Visual Screening Outcome:
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Vision problem suspected
                            </Form.Label>

                            {/* <Form.Control
                // required
                type="text"
                name="traineename"
                //placeholder="Name of the trainee"
                // value={vocProfile.traineename}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox3"
                            name="visualscreeningoutcome_1"
                            value="Yes"
                            checked={finalassessment.visualscreeningoutcome_1 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange3}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualscreeningoutcome_1"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualscreeningoutcome_1 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange3}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="suspectedtextboxse1"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.suspectedtextboxse1}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col lg={2}>
                    <Form.Check
                        inline
                        label="No"
                        name="trainingrecv"
                        type="checkbox"
                        value="Normal school"
                        // onChange={handleFieldChange}
                    />
            </Col> */}
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Referred to Ophthalmologist for clinical assessment
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox3"
                            name="visualscreeningoutcome_2"
                            value="Yes"
                            checked={finalassessment.visualscreeningoutcome_2 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange3}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualscreeningoutcome_2"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualscreeningoutcome_2 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange3}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="ophthalmologisttextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.ophthalmologisttextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Referred for Functional Vision Assessment
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox3"
                            name="visualscreeningoutcome_3"
                            value="Yes"
                            checked={finalassessment.visualscreeningoutcome_3 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange3}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="visualscreeningoutcome_3"
                            value="No"
                            type="radio"
                            checked={finalassessment.visualscreeningoutcome_3 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange3}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="referredtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.referredtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <p style={{ marginLeft: "62%" }}>{newCount3}</p>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    Screening Schedule for hearing Problem in Children
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                # Family History
                            </Form.Label>

                            {/* <Form.Control
                // required
                type="text"
                name="traineename"
                //placeholder="Name of the trainee"
                // value={vocProfile.traineename}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox4"
                            name="familyhistory2_1"
                            value="Yes"
                            checked={finalassessment.familyhistory2_1 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange4}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory2_1"
                            value="No"
                            type="radio"
                            checked={finalassessment.familyhistory2_1 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange4}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="historytextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.historytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col lg={2}>
                    <Form.Check
                        inline
                        label="No"
                        name="trainingrecv"
                        type="checkbox"
                        value="Normal school"
                        // onChange={handleFieldChange}
                    />
            </Col> */}
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does anyone in your family have a severe hearing loss or ear
                                disease? (e.g.,tinitus, otitis media, perforation of ear drum,
                                Meniere's disease, etc.) Pls. Mention
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox4"
                            name="familyhistory2_3"
                            value="Yes"
                            checked={finalassessment.familyhistory2_3 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange4}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory2_3"
                            value="No"
                            type="radio"
                            checked={finalassessment.familyhistory2_3 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange4}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="diseasetextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.diseasetextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Did the child's mother have any serious infections or diseases
                                during Pregnancy? (e.g., rubella, cytomegalovirus,
                                toxoplasmosis, syphilis, Herpes
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox4"
                            name="familyhistory2_4"
                            value="Yes"
                            checked={finalassessment.familyhistory2_4 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange4}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory2_4"
                            value="No"
                            type="radio"
                            checked={finalassessment.familyhistory2_4 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange4}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="herpestextboxes1"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.herpestextboxes1}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Did the child's mother use drugs or alcohol during pregnancy?
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox4"
                            name="familyhistory2_5"
                            value="Yes"
                            checked={finalassessment.familyhistory2_5 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange4}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="familyhistory2_5"
                            value="No"
                            type="radio"
                            checked={finalassessment.familyhistory2_5 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange4}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="pregnancytextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.pregnancytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Has a hearing problem been identified or suspected?
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox4"
                            name="earproblem_1"
                            value="Yes"
                            checked={finalassessment.earproblem_1 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange4}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_1"
                            value="No"
                            type="radio"
                            checked={finalassessment.earproblem_1 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange4}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="suspectedtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.suspectedtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Do you have any concerns about your child's hearing?
                            </Form.Label>
                         
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox4"

                            name="earproblem_1"
                            value="Yes"
                            checked={finalassessment.earproblem_1 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_1"
                            value="No"
                            type="radio"
                            checked={finalassessment.earproblem_1 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="behaviourstextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.distortiontextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col> 
                </Row>
                    <p style={{ marginLeft: "62%" }}>{newCount4}</p>
                <Col lg={12} className="section-header my-3 mx-2">
                    A) Ear problem
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Complaints of ear aches
                            </Form.Label>

                            {/* <Form.Control
                // required
                type="text"
                name="traineename"
                //placeholder="Name of the trainee"
                // value={vocProfile.traineename}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox5"
                            name="complaintofear"
                            value="Yes"
                            checked={finalassessment.complaintofear === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="complaintofear"
                            value="No"
                            type="radio"
                            checked={finalassessment.complaintofear === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="complaintstextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.complaintstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col lg={2}>
                    <Form.Check
                        inline
                        label="No"
                        name="trainingrecv"
                        type="checkbox"
                        value="Normal school"
                        // onChange={handleFieldChange}
                    />
            </Col> */}
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Complaints of headaches
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="earproblem_2"
                            className="family-history-checkbox5"
                            value="Yes"
                            checked={finalassessment.earproblem_2 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_2"
                            value="No"
                            type="radio"
                            checked={finalassessment.earproblem_2 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="headachestextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.headachestextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Complaints of ringing sound/sensation in ears or of hearing
                                voices
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="earproblem_3"
                            className="family-history-checkbox5"
                            value="Yes"
                            checked={finalassessment.earproblem_3 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_3"
                            value="No"
                            type="radio"
                            checked={finalassessment.earproblem_3 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="voicestextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.voicestextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Reoccurring ear infections
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2}className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="earproblem_4"
                            className="family-history-checkbox5"
                            value="Yes"
                            checked={finalassessment.earproblem_4 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_4"
                            value="No"
                            type="radio"
                            checked={finalassessment.earproblem_4 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="infectionstextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.infectionstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Frequent colds
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="earproblem_5"
                            value="Yes"
                            className="family-history-checkbox5"
                            checked={finalassessment.earproblem_5 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_5"
                            value="No"
                            type="radio"
                            checked={finalassessment.earproblem_5 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="coldstextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.coldstextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Any external ear or facial abnormality
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox5"
                            name="earproblem_6"
                            value="Yes"
                            checked={finalassessment.earproblem_6 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange5}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="earproblem_6"
                            value="No"
                            type="radio"
                            checked={finalassessment.earproblem_6 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange5}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlIdYesCount1="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="abnormalitytextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.abnormalitytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <p style={{ marginLeft: "62%" }}>{newCount5}</p>
                </Row>
                {/* *****hearing Behaviour******** */}
                <Col lg={12} className="section-header my-3 mx-2">
                    B) hearing behaviour
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Turning of one ear towards or away from the sound source
                            </Form.Label>

                            {/* <Form.Control
                // required
                type="text"
                name="traineename"
                //placeholder="Name of the trainee"
                // value={vocProfile.traineename}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2}className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_1"
                            className="family-history-checkbox6"
                            value="Yes"
                            checked={finalassessment.hearingbeahviour_1 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_1"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_1 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="sourcetextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.sourcetextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col lg={2}>
                    <Form.Check
                        inline
                        label="No"
                        name="trainingrecv"
                        type="checkbox"
                        value="Normal school"
                        // onChange={handleFieldChange}
                    />
            </Col> */}
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Turning up the radio or television or headset
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox6"
                            name="hearingbeahviour_2"
                            value="Yes"
                            checked={finalassessment.hearingbeahviour_2 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_2"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_2 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="televisiontextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.televisiontextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Sitting very close to the television or radio
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="mentalretardlevel"
                //placeholder="Level of Mental Retard "
                // value={vocProfile.mentalretardlevel}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_3"
                            className="family-history-checkbox6"
                            value="Yes"
                            checked={finalassessment.hearingbeahviour_3 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_3"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_3 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="radiotextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.radiotextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Appears to ignore conversation or directives
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2}className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox6"
                            name="hearingbeahviour_4"
                            value="Yes"
                            checked={finalassessment.hearingbeahviour_4 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_4"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_4 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="directivestextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.directivestextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Seems engrossed in work, oblivious to sounds around
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2}className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox6"
                            name="hearingbeahviour_5"
                            value="Yes"
                            checked={finalassessment.hearingbeahviour_5 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_5"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_5 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="oblivioustextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.oblivioustextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Humming (Constant) to possibly provide stimulation
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox6"
                            name="hearingbeahviour_6"
                            value="Yes"
                            checked={finalassessment.hearingbeahviour_6 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_6"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_6 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="stimulationtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.stimulationtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Startling behaviour to everyday sounds
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2}className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox6"
                            name="hearingbeahviour_7"
                            value="Yes"
                            checked={finalassessment.hearingbeahviour_7 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_7"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_7 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="everydaytextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.everydaytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Relies on gestures excessively
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox6"
                            name="hearingbeahviour_8"
                            value="Yes"
                            checked={finalassessment.hearingbeahviour_8 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_8"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_8 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="excessivelytextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.excessivelytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Poor spoken language
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox6"
                            name="hearingbeahviour_9"
                            value="Yes"
                            checked={finalassessment.hearingbeahviour_9 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_9"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_9 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="spokentextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.spokentextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does the child complain that everyone either speaks too loudly
                                or too softly?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_10"
                            value="Yes"
                            className="family-history-checkbox6"
                            checked={finalassessment.hearingbeahviour_10 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_10"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_10 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="softlytextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.softlytextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Not able to hear others from distance or from behind
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_11"
                            value="Yes"
                            className="family-history-checkbox6"
                            checked={finalassessment.hearingbeahviour_11 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_11"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_11 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="behindtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.behindtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does the child have difficulty identifying familiar persons by
                                their voice?
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox6"
                            name="hearingbeahviour_12"
                            value="Yes"
                            checked={finalassessment.hearingbeahviour_12 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_12"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_12 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="voicetextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.voicetextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Difficulty in listening speech in background noise
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_13"
                            value="Yes"
                            className="family-history-checkbox6"
                            checked={finalassessment.hearingbeahviour_13 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_13"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_13 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="noisetextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.noisetextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Not able to hear when called from other nearby rooms inside
                                house or inside school
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_14"
                            value="Yes"
                            className="family-history-checkbox6"
                            checked={finalassessment.hearingbeahviour_14 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_14"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_14 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="schooltextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.schooltextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does the child need hearing aids to listen?
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_15"
                            value="Yes"
                            className="family-history-checkbox6"
                            checked={finalassessment.hearingbeahviour_15 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_15"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_15 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="listentextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.listentextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Does the child have problems in balance?
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="namaritalstatusme"
                //placeholder="maritalstatus"
                // value={vocProfile.maritalstatus}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2}className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            name="hearingbeahviour_16"
                            value="Yes"
                            className="family-history-checkbox6"
                            checked={finalassessment.hearingbeahviour_16 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange6}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingbeahviour_16"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingbeahviour_16 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange6}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="balancetextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.balancetextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <p style={{ marginLeft: "62%" }}>{newCount6}</p>
                </Row>
                <Col lg={12} className="section-header my-3 mx-2">
                    hearing Screening Outcome:
                </Col>
                <Row lg={12}>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                hearing problem suspected
                            </Form.Label>

                            {/* <Form.Control
                // required
                type="text"
                name="traineename"
                //placeholder="Name of the trainee"
                // value={vocProfile.traineename}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox7"
                            name="hearingscreeningoutcome_1"
                            value="Yes"
                            checked={finalassessment.hearingscreeningoutcome_1 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange7}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingscreeningoutcome_1"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingscreeningoutcome_1 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange7}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="hearingproblemtextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.hearingproblemtextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    {/* <Col lg={2}>
                    <Form.Check
                        inline
                        label="No"
                        name="trainingrecv"
                        type="checkbox"
                        value="Normal school"
                        // onChange={handleFieldChange}
                    />
            </Col> */}
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Referred to Audiologist for Audiometry
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2}  className="mx-5 my-4" >
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox7"
                            name="hearingscreeningoutcome_2"
                            value="Yes"
                            checked={finalassessment.hearingscreeningoutcome_2 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange7}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingscreeningoutcome_2"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingscreeningoutcome_2 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange7}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="Audiologisttextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.Audiologisttextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Referred to ENT specialist for clinical assessment
                            </Form.Label>
                            {/* <Form.Control
                // required
                type="text"
                name="sexagedob"
                //placeholder="Sex/age / Date of Birth"
                // value={vocProfile.sexagedob}
                // onChange={handleFieldChange}
              /> */}
                        </Form.Group>
                    </Col>
                    <Col lg={2}  className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox7"
                            name="hearingscreeningoutcome_3"
                            value="Yes"
                            checked={finalassessment.hearingscreeningoutcome_3 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange7}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingscreeningoutcome_3"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingscreeningoutcome_3 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange7}
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label"></Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="clinicaltextbox"
                                //placeholder="Name of the trainee"
                                value={finalassessment?.data?.clinicaltextbox}
                                onChange={handleChangeTextbox}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={7}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                Referred for Functional hearing Assessment
                            </Form.Label>
                           
                        </Form.Group>
                    </Col>
                    <Col lg={2} className="mx-5 my-4">
                        <Form.Check
                            inline
                            label="Yes"
                            className="family-history-checkbox7"
                            name="hearingscreeningoutcome_4"
                            value="Yes"
                            checked={finalassessment.hearingscreeningoutcome_4 === "Yes"}
                            type="radio"
                            id={`inline-radio-1x`}
                            onChange={handleChange7}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="hearingscreeningoutcome_4"
                            value="No"
                            type="radio"
                            checked={finalassessment.hearingscreeningoutcome_4 === "No"}
                            id={`inline-radio-2`}
                            onChange={handleChange7}
                        />
                    </Col>
                        <Col lg={2}>
                            <Form.Group className="" controlId="formBasicname">
                                <Form.Label className="form-view-label"></Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="hearingtextbox"
                                    //placeholder="Name of the trainee"
                                    value={finalassessment?.data?.hearingtextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                        <Stack direction="horizontal" gap={2} style={{ marginLeft: "55%" }}>

                            <Badge pill bg="primary">
                                Total
                            </Badge>
                            <Badge pill bg="secondary">
                                {newCount7}
                            </Badge>
                        </Stack>
                        {/* <p style={{ marginLeft: "62%" }}>{newCount7}</p> */}
                    </Row>
                    <Row>

                    </Row>
                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Opinion of the Screener:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    //disabled
                                    as="textarea"
                                    name="opinionofthescreener"
                                    //placeholder="Referral comment"
                                    value={finalassessment.opinionofthescreener}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>

                        {/* <Col lg={12} className="section-header my-3 mx-2">
                        Opinion of the Screener:
                    </Col> */}

                        <Col lg={6}>
                            <Form.Group controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Referral comment:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    //disabled
                                    as="textarea"
                                    name="reffrealcomment"
                                    //placeholder="Referral comment"
                                    value={finalassessment.reffrealcomment}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Screener's Observations (if any):
                                </Form.Label>
                                <Form.Control
                                    // required
                                    //disabled
                                    type="text"
                                    name="screeningobservation"
                                    //placeholder="Screener's Observations"
                                    value={finalassessment.screeningobservation}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Screener's Inference:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    //disabled
                                    type="text"
                                    name="screenerinfrence"
                                    //placeholder="Screener's Inference"
                                    value={finalassessment.screenerinfrence}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>


                    </Row>
                    <Col lg={12} className="section-header my-3 mx-2">
                        I:Functional visual Assessment Format for Multi-sensory impaired
                        Learners (  Stage One: : Basic awareness)
                    </Col>


                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Name of Learner
                                </Form.Label>
                                <Form.Control
                                    // required
                                    //disabled
                                    type="text"
                                    name="nameoflearner"
                                    //placeholder="Screener's Inference"
                                    value={finalassessment.nameoflearner}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group className="mx-3 my-2" controlId="formBasicDate">
                                <Form.Label className="form-view-label">Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="dateoflearner"
                                    value={finalassessment.dateoflearner}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>


                    {/* START COPY */}
                    <Row lg={12} className="">
                        <Table responsive="sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Yes</th>
                                    <th>No</th>
                                    <th>comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Pupillary Response</td>
                                    <td>
                                        Observe without stimulation <br></br>

                                        <ul><li>Do pupils moveconstantly?</li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="constantly"
                                                value="Yes"
                                                checked={finalassessment.constantly === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="constantly"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.constantly === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.constantly} name="constantly" onChange={handleChangeTextbox}></input>
                                        </td>


                                        <br></br> <ul><li>Do pupils react to changes in light?</li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="light"
                                                value="Yes"
                                                checked={finalassessment.light === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="light"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.light === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.light} name="light" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="pupillaryresponse"
                                            value="Yes"
                                            checked={finalassessment.pupillaryresponse === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="pupillaryresponse"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.pupillaryresponse === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.pupcomment} name="pupcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Blink reflex</td>
                                    <td>
                                        Move hand, without making a draught, towards the learner’s
                                        face.<br></br>

                                        Does she/he blink?
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="face"
                                                value="Yes"
                                                checked={finalassessment.face === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="face"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.face === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.face} name="face" onChange={handleChangeTextbox}></input>
                                        </td>

                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="blinkreflex"
                                            value="Yes"
                                            checked={finalassessment.blinkreflex === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="blinkreflex"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.blinkreflex === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.blinkreflexcomment} name="blinkreflexcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Response to light</td>
                                    <td>
                                        Using different lights in darkened room, does the learner
                                        respond to:<br></br><ul><li>Continuous light?</li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="darkened"
                                                value="Yes"
                                                checked={finalassessment.darkened === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="darkened"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.darkened === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.darkened} name="darkened" onChange={handleChangeTextbox}></input>
                                        </td><br></br><ul><li> Flashing
                                            light?</li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="flashing"
                                                value="Yes"
                                                checked={finalassessment.flashing === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="flashing"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.flashing === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.flashing} name="flashing" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <br></br><ul><li>Moving light?</li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="moving"
                                                value="Yes"
                                                checked={finalassessment.moving === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="moving"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.moving === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.moving} name="moving" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="responseoflight"
                                            value="Yes"
                                            checked={finalassessment.responseoflight === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="responseoflight"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.responseoflight === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.responseoflightcomment} name="responseoflightcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Fixation</td>
                                    <td>
                                        Using motivating objects and verbal encouragement, observe
                                        whether he/she can fix his/her eyes on a bright object at:
                                        <br></br><ul><li>7 inches</li></ul> <br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="inches"
                                                value="Yes"
                                                checked={finalassessment.inches === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="inches"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.inches === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.inches} name="inches" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>2 feet</li></ul> <br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="feet2"
                                                value="Yes"
                                                checked={finalassessment.feet2 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="feet2"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.feet2 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.feet2} name="feet2" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>10 feet</li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="feet10"
                                                value="Yes"
                                                checked={finalassessment.feet10 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="feet10"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.feet10 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.feet10} name="feet10" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="fixation"
                                            value="Yes"
                                            checked={finalassessment.fixation === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="fixation"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.fixation === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.fixationcomment} name="fixationcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Eye preference</td>
                                    <td>
                                        Does the learner consistently hold objects near one eye?
                                        <br></br>Show the learner an interesting object. Cover each
                                        eye in turn and observe the response<br></br>
                                        {/* <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="constantly"
                                        value="Yes"
                                        checked={finalassessment.pupillaryresponse === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange}
                                    />
                                </td>

                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="constantly"
                                        value="No"
                                        type="radio"
                                        checked={finalassessment.pupillaryresponse === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange}
                                    />
                                </td> 

                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment?.data?.constantly} name="constantly" onChange={handleChangeTextbox}></input>
                                </td> */}
                                        .Left eye
                                        <br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="lefteye"
                                                value="Yes"
                                                checked={finalassessment.lefteye === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="lefteye"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.lefteye === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.lefteye} name="lefteye" onChange={handleChangeTextbox}></input>
                                        </td>
                                        .Right eye
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="righteye"
                                                value="Yes"
                                                checked={finalassessment.righteye === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="righteye"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.righteye === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.righteye} name="righteye" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="eyepreference"
                                            value="Yes"
                                            checked={finalassessment.eyepreference === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="eyepreference"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.eyepreference === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.eyepreferencecomment} name="eyepreferencecomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Visual fields</td>
                                    <td>
                                        Using a visually interesting object or a light, stand behind
                                        the child and bring it slowly into the visual field. Note when
                                        he/she sees the object<br></br><ul><li>Above</li></ul><br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="above"
                                                value="Yes"
                                                checked={finalassessment.above === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="above"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.above === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.above} name="above" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>below</li></ul><br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="below"
                                                value="Yes"
                                                checked={finalassessment.below === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="below"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.below === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.below} name="below" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>right</li></ul><br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="right1"
                                                value="Yes"
                                                checked={finalassessment.right1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="right1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.right1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.right1} name="right1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>left</li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="left1"
                                                value="Yes"
                                                checked={finalassessment.left1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="left1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.left1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.left1} name="left1" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="visualfield"
                                            value="Yes"
                                            checked={finalassessment.visualfield === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="visualfield"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.visualfield === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.visualfieldcomment} name="visualfieldcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>

                                {/* wait */}
                                <tr>
                                    <td>Focus on objects</td>
                                    <td>
                                        Show interesting objects at<br></br>various distances Observe
                                        which he/she sees at:<br></br><ul><li>7 inches</li></ul> <br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="focusinches"
                                                value="Yes"
                                                checked={finalassessment.focusinches === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="focusinches"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.focusinches === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.focusinches} name="focusinches" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>2 feet</li></ul>
                                        <br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="focusfeet2"
                                                value="Yes"
                                                checked={finalassessment.focusfeet2 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="focusfeet2"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.focusfeet2 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.focusfeet2} name="focusfeet2" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>10 feet</li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="focusfeet10"
                                                value="Yes"
                                                checked={finalassessment.focusfeet10 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="focusfeet10"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.focusfeet10 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.focusfeet10} name="focusfeet10" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="focusonobjects"
                                            value="Yes"
                                            checked={finalassessment.focusonobjects === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="focusonobjects"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.focusonobjects === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.focusonobjectscomment} name="focusonobjectscomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Eye contact</td>
                                    <td>
                                        Observe the learner with familiar people and in a variety of
                                        situations e.g. meal times. Does he/she make eye contact:
                                        <br></br>.Briefly<br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="briefly1"
                                                value="Yes"
                                                checked={finalassessment.briefly1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="briefly1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.briefly1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.briefly1} name="briefly1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        .For three seconds or more<br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="three1"
                                                value="Yes"
                                                checked={finalassessment.three1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="three1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.three1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.three1} name="three1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        .Maintained
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="maintained1"
                                                value="Yes"
                                                checked={finalassessment.maintained1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="maintained1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.maintained1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.maintained1} name="maintained1" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="eyecontact"
                                            value="Yes"
                                            checked={finalassessment.eyecontact === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="eyecontact"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.eyecontact === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.eyecontactcomment} name="eyecontactcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Visual behaviours</td>
                                    <td>
                                        Observe carefully for behaviours which may indicate use of
                                        residual vision such as:<br></br>

                                        .Light gazing<br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="gazing1"
                                                value="Yes"
                                                checked={finalassessment.gazing1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="gazing1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.gazing1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.gazing1} name="gazing1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        .Hand  flicking

                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="flicking1"
                                                value="Yes"
                                                checked={finalassessment.flicking1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="flicking1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.flicking1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.flicking1} name="flicking1" onChange={handleChangeTextbox}></input>
                                        </td>

                                        <br></br>

                                        .Any other
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="anyother1"
                                                value="Yes"
                                                checked={finalassessment.anyother1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="anyother1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.anyother1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.anyother1} name="anyother1" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="visualbehaviour"
                                            value="Yes"
                                            checked={finalassessment.visualbehaviour === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="visualbehaviour"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.visualbehaviour === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.visualbehaviourcomment} name="visualbehaviourcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <Col lg={12} className="section-header my-3 mx-2">
                        II. Functional Visual Assessment for Multi-sensory Impaired Learners
                    </Col>
                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Stage Two: Development of Basic Awareness into Functional use of
                                    Vision
                                </Form.Label>

                            </Form.Group>
                        </Col>
                    </Row>
                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Name of Learner
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="stage2_nameoflearner"
                                    //placeholder="Name of Learner"
                                    value={finalassessment.stage2_nameoflearner}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-3 my-2" controlId="formBasicDate">
                                <Form.Label className="form-view-label">Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="stage2_dateoflearner"
                                    value={finalassessment.stage2_dateoflearner}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row lg={12}>
                        <Table responsive="sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Yes</th>
                                    <th>No</th>
                                    <th>comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Hand regard</td>
                                    <td>
                                        Place a visually interesting toy in the learner's hand. Does
                                        he/she look at it?<br></br>Play hand games. Does the learner
                                        look at your hands?
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="handregard"
                                            value="Yes"
                                            checked={finalassessment.handregard === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="handregard"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.handregard === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.handregardcomment} name="handregardcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tracking</td>
                                    <td>
                                        Choose motivating objects. Move them slowly and note if the
                                        learner can visually track the object<br></br>
                                        {/* <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="visually1"
                                        value="Yes"
                                        checked={finalassessment.visually1 === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange}
                                    />
                                </td>

                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="visually1"
                                        value="No"
                                        type="radio"
                                        checked={finalassessment.visually1 === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange}
                                    />
                                </td> 

                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment?.data?.visually1} name="visually1" onChange={handleChangeTextbox}></input>
                                </td> */}
                                        <ul><li>from the
                                            mid-line to the left<br></br></li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="visually1"
                                                value="Yes"
                                                checked={finalassessment.visually1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="visually1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.visually1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.visually1} name="visually1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>from the mid-line to the right</li></ul>
                                        <br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="midline1"
                                                value="Yes"
                                                checked={finalassessment.midline1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="midline1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.midline1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.midline1} name="midline1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>right to left (across mid-line)<br></br></li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="across1"
                                                value="Yes"
                                                checked={finalassessment.across1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="across1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.across1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>
                                        {/* across1 */}
                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.across1} name="across1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>left to
                                            right (across mid-line)<br></br></li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="rightacross1"
                                                value="Yes"
                                                checked={finalassessment.rightacross1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="rightacross1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.rightacross1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.rightacross1} name="rightacross1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>going down<br></br></li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="goingdown1"
                                                value="Yes"
                                                checked={finalassessment.goingdown1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="goingdown1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.goingdown1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.goingdown1} name="goingdown1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>going
                                            up<br></br></li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="goingup1"
                                                value="Yes"
                                                checked={finalassessment.goingup1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="goingup1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.goingup1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.goingup1} name="goingup1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>diagonally<br></br></li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="diagonally1"
                                                value="Yes"
                                                checked={finalassessment.diagonally1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="diagonally1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.diagonally1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.diagonally1} name="diagonally1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>in a circular movement</li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="circular1"
                                                value="Yes"
                                                checked={finalassessment.circular1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="circular1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.circular1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.circular1} name="circular1" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="tracking"
                                            value="Yes"
                                            checked={finalassessment.tracking === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="tracking"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.tracking === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.trackingcomment} name="trackingcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Transfer of attention from one object to another</td>
                                    <td>
                                        Using two torches or two of equal interest held a foot apart,
                                        shake one first then the other to see if the learner can shift
                                        his gaze between two objects:<br></br>At the same distance
                                        <br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="same1"
                                                value="Yes"
                                                checked={finalassessment.same1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="same1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.same1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.same1} name="same1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        Near/distant<br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="distant1"
                                                value="Yes"
                                                checked={finalassessment.distant1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="distant1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.distant1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.distant1} name="distant1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        Distant/near
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="near1"
                                                value="Yes"
                                                checked={finalassessment.near1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="near1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.near1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.near1} name="near1" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="transferofattention"
                                            value="Yes"
                                            checked={finalassessment.transferofattention === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="transferofattention"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.transferofattention === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.transferofattentioncomment} name="transferofattentioncomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Scanning</td>
                                    <td>
                                        Place three objects in a line. Point along the line. Can the
                                        learner scan the line?
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="scanning"
                                            value="Yes"
                                            checked={finalassessment.scanning === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="scanning"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.scanning === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.scanningcomment} name="scanningcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Turning eyes Towards object</td>
                                    <td>
                                        Touch the learner with an object. Does he/she turn to look?
                                        <br></br><ul><li>To the left<br></br></li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="touch1"
                                                value="Yes"
                                                checked={finalassessment.touch1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="touch1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.touch1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.touch1} name="touch1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>To the right</li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="theright1"
                                                value="Yes"
                                                checked={finalassessment.theright1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="theright1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.theright1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.theright1} name="theright1" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="turningeyes"
                                            value="Yes"
                                            checked={finalassessment.turningeyes === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="turningeyes"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.turningeyes === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.turningeyescomment} name="turningeyescomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Attempts to reach visually for objects</td>
                                    <td>
                                        Place objects within the learners reach. Does he attempt to
                                        reach them?
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="attemptstoreach"
                                            value="Yes"
                                            checked={finalassessment.attemptstoreach === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="attemptstoreach"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.attemptstoreach === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.attemptstoreachcomment} name="attemptstoreachcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Visual exploration</td>
                                    <td>
                                        Does the learner turn objects in his/her hand to explore
                                        visually?<br></br>
                                        {/* <td>
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="constantly"
                                        value="Yes"
                                        checked={finalassessment.pupillaryresponse === "Yes"}
                                        type="radio"
                                        id={`inline-radio-1x`}
                                        onChange={handleChange}
                                    />
                                </td>

                                <td>
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="constantly"
                                        value="No"
                                        type="radio"
                                        checked={finalassessment.pupillaryresponse === "No"}
                                        id={`inline-radio-2`}
                                        onChange={handleChange}
                                    />
                                </td> 

                                <td>
                                    <input type="textarea" className="form-control" value={finalassessment?.data?.constantly} name="constantly" onChange={handleChangeTextbox}></input>
                                </td> */}
                                        <ul><li>Sometimes<br></br></li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="sometimes1"
                                                value="Yes"
                                                checked={finalassessment.sometimes1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="sometimes1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.sometimes1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.sometimes1} name="sometimes1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <ul><li>Often</li></ul>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="often1"
                                                value="Yes"
                                                checked={finalassessment.often1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="often1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.often1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.often1} name="often1" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="visualexploration"
                                            value="Yes"
                                            checked={finalassessment.visualexploration === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="visualexploration"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.visualexploration === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.visualexplorationcomment} name="visualexplorationcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Object recognition</td>
                                    <td>
                                        Does the learner recognise familiar objects?<br></br>- a few
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="afew1"
                                                value="Yes"
                                                checked={finalassessment.afew1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="afew1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.afew1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.afew1} name="afew1" onChange={handleChangeTextbox}></input>
                                        </td>

                                        <br></br>- many
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="many1"
                                                value="Yes"
                                                checked={finalassessment.many1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="many1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.many1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.many1} name="many1" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="objectrecognization"
                                            value="Yes"
                                            checked={finalassessment.objectrecognization === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="objectrecognization"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.objectrecognization === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.objectrecognizationcomment} name="objectrecognizationcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Visual discrimination</td>
                                    <td>
                                        Does the learner discriminate visually?<br></br>- Size
                                        <br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="size1"
                                                value="Yes"
                                                checked={finalassessment.size1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="size1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.size1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.size1} name="size1" onChange={handleChangeTextbox}></input>
                                        </td>

                                        - Colour<br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="colour1"
                                                value="Yes"
                                                checked={finalassessment.colour1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="colour1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.colour1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.colour1} name="colour1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        - Shape<br></br>
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="shape1"
                                                value="Yes"
                                                checked={finalassessment.shape1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="shape1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.shape1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.shape1} name="shape1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        - family members
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="familymembers1"
                                                value="Yes"
                                                checked={finalassessment.familymembers1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="familymembers1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.familymembers1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.familymembers1} name="familymembers1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <br></br>- carers
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="carers1"
                                                value="Yes"
                                                checked={finalassessment.carers1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="carers1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.carers1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.carers1} name="carers1" onChange={handleChangeTextbox}></input>
                                        </td>
                                        <br></br>- strangers
                                        <td>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="strangers1"
                                                value="Yes"
                                                checked={finalassessment.strangers1 === "Yes"}
                                                type="radio"
                                                id={`inline-radio-1x`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="strangers1"
                                                value="No"
                                                type="radio"
                                                checked={finalassessment.strangers1 === "No"}
                                                id={`inline-radio-2`}
                                                onChange={handleChange}
                                            />
                                        </td>

                                        <td>
                                            <input type="textarea" className="form-control" value={finalassessment?.data?.strangers1} name="strangers1" onChange={handleChangeTextbox}></input>
                                        </td>
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="visualdiscrimination"
                                            value="Yes"
                                            checked={finalassessment.visualdiscrimination === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="visualdiscrimination"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.visualdiscrimination === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.visualdiscriminationcomment} name="visualdiscriminationcomment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <Col lg={12} className="section-header my-3 mx-2">
                        Functional hearing Assessment
                    </Col>
                    <Row lg={12}>
                        <Table responsive="sm">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Observations</th>
                                    <th>Yes</th>
                                    <th>No</th>
                                    <th>comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>
                                        Does the learner show any response to sound?<br></br>e.g.
                                        startle to sudden loud sound, blink at a sound, smile or calm
                                        down in response to music
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_1"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_1 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_1"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_1 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_1comment} name="functionalhearing_1comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2.</td>
                                    <td>
                                        Does the learner appear to notice or attend to any particular
                                        sounds?<br></br>e.g. a familiar voice or noise
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_2"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_2 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_2"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_2 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_2comment} name="functionalhearing_2comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3.</td>
                                    <td>
                                        How interested is the learner in sound in the environment?
                                        <br></br>Do they respond consistently or inconsistently to
                                        auditory stimuli
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_3"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_3 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_3"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_3 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_3comment} name="functionalhearing_3comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4.</td>
                                    <td>
                                        What kind of sound is he or she interested in?<br></br>loud
                                        sounds, music, familiar environmental sounds duration of
                                        sound?<br></br>Is the interest affected by pitch, volume,
                                        direction,
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_4"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_4 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_4"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_4 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.hearingscreeningoutcome_4comment} name="hearingscreeningoutcome_4comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5.</td>
                                    <td>
                                        Does the learner show interest in voices?<br></br>e.g. their
                                        mother or fathers
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_5"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_5 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_5"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_5 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_5comment} name="functionalhearing_5comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6.</td>
                                    <td>
                                        Does the response vary according to the distance between the
                                        learner and the sound?
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_6"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_6 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_6"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_6 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_6comment} name="functionalhearing_6comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7.</td>
                                    <td>
                                        How loud does a sound have to be before attention is gained?
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_7"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_7 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_7"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_7 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_7comment} name="functionalhearing_7comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8.</td>
                                    <td>
                                        Is the learner's ability to respond affected by background
                                        noise?
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_8"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_8 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_8"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_8 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_8comment} name="functionalhearing_8comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9.</td>
                                    <td>
                                        Does the learner show more auditory interest to sounds coming
                                        from one side?<br></br>e.g. left, right, behind, etc.<br></br>
                                        Does the learner turn towards sounds?<br></br>Can they turn to
                                        sounds in all directions?<br></br>left, right, above, below
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_9"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_9 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_9"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_9 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_9comment} name="functionalhearing_9comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10.</td>
                                    <td>Do they seem to recognise familiar sounds?</td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_10"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_10 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_10"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_10 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_10comment} name="functionalhearing_10comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11.</td>
                                    <td>Can they pick out one sound from a range of sounds?</td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_11"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_11 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_11"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_11 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_11comment} name="functionalhearing_11comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>12.</td>
                                    <td>
                                        Do they show an awareness of sound/no sound?<br></br>E.g. does
                                        their behaviour change when a particular sound stops
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_12"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_12 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_12"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_12 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_12comment} name="functionalhearing_12comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>13.</td>
                                    <td>
                                        Does the learner move towards sounds?<br></br>How accurate are
                                        they in using the sound to orientate themselves?
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_13"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_13 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_13"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_13 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_13comment} name="functionalhearing_13comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>14.</td>
                                    <td>
                                        Observe the speed at which she/he notices and responds to
                                        sounds.<br></br>Is this affected by time or place?<br></br>Is
                                        it affected by the learner's position, comfort level or by
                                        competition from other sensory stimuli?
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_14"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_14 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_14"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_14 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_14comment} name="functionalhearing_14comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>15.</td>
                                    <td>
                                        Are there any sounds that the learner appears to dislike or
                                        that cause distress?<br></br>Which sounds?
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_15"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_15 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_15"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_15 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_15comment} name="functionalhearing_15comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>16.</td>
                                    <td>Does the learner vocalise?</td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_16"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_16 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_16"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_16 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_16comment} name="functionalhearing_16comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>17.</td>
                                    <td>
                                        Are there any consonant sounds as well as open vowels sounds?
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="functionalhearing_17"
                                            value="Yes"
                                            checked={finalassessment.functionalhearing_17 === "Yes"}
                                            type="radio"
                                            id={`inline-radio-1x`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="functionalhearing_17"
                                            value="No"
                                            type="radio"
                                            checked={finalassessment.functionalhearing_17 === "No"}
                                            id={`inline-radio-2`}
                                            onChange={handleChange8}
                                        />
                                    </td>
                                    <td>
                                        <input type="textarea" className="form-control" value={finalassessment.functionalhearing_17comment} name="functionalhearing_17comment" onChange={handleChange}></input>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <p style={{ marginLeft: "68%" }}>{newCount8}</p>
                    </Row>
                </div>

                <Row>
                    <Col lg={6}>
                        <Form.Group className="" controlId="formBasicname">
                            <Form.Label className="form-view-label">Signature</Form.Label>
                            <Form.Control
                                // required
                                //disabled
                                as="textarea"
                                name="signature"
                                //placeholder="Screener's Inference"
                                value={finalassessment.signature}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <div className="App container mt-5" style={{ display: 'none' }}>
                    <div id="divToPrint">
                        <VisionHearingPdf finalassessment={finalassessment} state={state} newCount1={newCount1} newCount2={newCount2} newCount3={newCount3} newCount4={newCount4} newCount5={newCount5} newCount6={newCount6} newCount7={newCount7} newCount8={newCount8} />
                    </div>
                </div>

            </Container>
        </>
    )
}

export default FinalAssessmentEdit