import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PubSub from "pubsub-js";
import inventoryApi from "../api/inventoryApi";
import html2pdf from 'html2pdf.js';
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import PhysioAssessmentPdf from "./PhysioAssessmentPdf";
import * as constants from "../constants/CONSTANT";

const PhysioAssessment = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [studentData, setStudentData] = useState(
        location.state ? location.state : {}
    );
    console.log('studentData', studentData)
    const [psyoAssessment, setPsyoAssessment] = useState({});
    var [checkedBoxes, setCheckedBoxes] = useState([]);
    const [checkedValues, setCheckedValues] = useState([]);
    const [posturalbalance, setposturalbalance] = useState([]);
    const [upperextremityshoulder, setupperextremityshoulder] = useState([]);
    const [upperextremityelbow, setupperextremityelbow] = useState([]);

    const [upperextremitywrist, setupperextremitywrist] = useState([]);
    const [jrshoulder, setjrshoulder] = useState([]);


    const [upperextremityhip, setupperextremityhip] = useState([]);
    const [upperextremityknee, setupperextremityknee] = useState([]);
    const [upperextremityankle, setupperextremityankle] = useState([]);




    const [gradeupperextremityhip, setgradeupperextremityhip] = useState([]);
    const [gradeupperextremityknee, setgradeupperextremityknee] = useState([]);

    const [gradeupperextremityankle, setgradeupperextremityankle] = useState([]);





    const [textboxdata, settextboxdata] = useState({});
    const [textboxArray, settextboxArray] = useState([]);
    const [state, setstate] = useState({});
    const [checkedValues1, setCheckedValues1] = useState([]);
    const [muscletone, setmuscletone] = useState([]);
    const [involuntarymovement, setinvoluntarymovement] = useState([]);
    const [lastgmf, setlastgmf] = useState([]);
    const [initialgmf, setinitialgmf] = useState([]);
    const [musculoskeletaldeformity, setmusculoskeletaldeformity] = useState([]);
    const [developmentalreflexes, setdevelopmentalreflexes] = useState([]);
    const [fmfgrip, setfmfgrip] = useState([]);
    const [fmfprehension, setfmfprehension] = useState([]);
    const [superficialsensation, setsuperficialsensation] = useState([]);
    const [deepsensation, setdeepsensation] = useState([]);

    const [corticalsensation, setcorticalsensation] = useState([]);
    const [deeptendonreflex, setdeeptendonreflex] = useState([]);

    const [gradeshoulder, setgradeshoulder] = useState([]);
    const [gradeelbow, setgradeelbow] = useState([]);
    const [gradeextremitywrist, setgradeextremitywrist] = useState([]);

    useEffect(() => {
        const suggestivenoteData = studentData?.suggestivenote?.map(item => ({ value: item.value }));
        setTableData(suggestivenoteData);

    }, []);

    // =======handle change work =====================

    const handleChange = (e) => {

        // if(e.target.name === "jrshoulder"){  

        //   psyoAssessment.jrshoulder = checkedBoxes.join(",");

        //   const { value, checked} = e.target;

        //   let updatedCheckedValues;
        //   if (checked) {
        //     updatedCheckedValues = [...jrshoulder, value];
        //   } else {
        //     updatedCheckedValues = jrshoulder.filter((item) => item !== value);
        //   }
        //   setjrshoulder(updatedCheckedValues);
        // }

        if (e.target.name === "deeptendonreflex") {

            psyoAssessment.deeptendonreflex = checkedBoxes.join(",");

            const { value, checked } = e.target;

            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...deeptendonreflex, value];
            } else {
                updatedCheckedValues = deeptendonreflex.filter((item) => item !== value);
            }
            setdeeptendonreflex(updatedCheckedValues);
        }

        if (e.target.name === "corticalsensation") {

            psyoAssessment.corticalsensation = checkedBoxes.join(",");

            const { value, checked } = e.target;

            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...corticalsensation, value];
            } else {
                updatedCheckedValues = corticalsensation.filter((item) => item !== value);
            }
            setcorticalsensation(updatedCheckedValues);
        }

        if (e.target.name === "deepsensation") {

            psyoAssessment.deepsensation = checkedBoxes.join(",");

            const { value, checked } = e.target;

            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...deepsensation, value];
            } else {
                updatedCheckedValues = deepsensation.filter((item) => item !== value);
            }
            setdeepsensation(updatedCheckedValues);
        }


        if (e.target.name === "superficialsensation") {

            psyoAssessment.superficialsensation = checkedBoxes.join(",");

            const { value, checked } = e.target;

            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...superficialsensation, value];
            } else {
                updatedCheckedValues = superficialsensation.filter((item) => item !== value);
            }
            setsuperficialsensation(updatedCheckedValues);
        }


        if (e.target.name === "fmfprehension") {

            psyoAssessment.fmfgrip = checkedBoxes.join(",");

            const { value, checked } = e.target;

            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...fmfprehension, value];
            } else {
                updatedCheckedValues = fmfprehension.filter((item) => item !== value);
            }
            setfmfprehension(updatedCheckedValues);
        }



        if (e.target.name === "fmfgrip") {

            psyoAssessment.fmfgrip = checkedBoxes.join(",");

            const { value, checked } = e.target;

            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...fmfgrip, value];
            } else {
                updatedCheckedValues = fmfgrip.filter((item) => item !== value);
            }
            setfmfgrip(updatedCheckedValues);

        }


        if (e.target.name === "developmentalreflexes") {

            psyoAssessment.developmentalreflexes = checkedBoxes.join(",");

            const { value, checked } = e.target;

            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...developmentalreflexes, value];
            } else {
                updatedCheckedValues = developmentalreflexes.filter((item) => item !== value);
            }
            setdevelopmentalreflexes(updatedCheckedValues);

        }

        if (e.target.name === "musculoskeletaldeformity") {

            psyoAssessment.musculoskeletaldeformity = checkedBoxes.join(",");

            const { value, checked } = e.target;

            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...musculoskeletaldeformity, value];
            } else {
                updatedCheckedValues = musculoskeletaldeformity.filter((item) => item !== value);
            }
            setmusculoskeletaldeformity(updatedCheckedValues);

        }



        //======== lastgmf

        if (e.target.name === "lastgmf") {
            psyoAssessment.lastgmf = checkedBoxes.join(",");
            const { value, checked } = e.target;
            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...lastgmf, value];
            } else {
                updatedCheckedValues = lastgmf.filter((item) => item !== value);
            }
            setlastgmf(updatedCheckedValues);
        }






        if (e.target.name === "gait") {
            psyoAssessment.gait = checkedBoxes.join(",");

            const { value, checked } = e.target;
            console.log(" ", checked);
            console.log("value jj", value);

            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...checkedValues, value];
            } else {
                updatedCheckedValues = checkedValues.filter((item) => item !== value);
            }
            setCheckedValues(updatedCheckedValues);
        }


        if (e.target.name === "gaitdirection") {
            psyoAssessment.gaitdirection = checkedBoxes.join(",");
            const { value, checked } = e.target;
            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...checkedValues1, value];
            } else {
                updatedCheckedValues = checkedValues1.filter((item) => item !== value);
            }
            setCheckedValues1(updatedCheckedValues);
        }

        if (e.target.name === "posturalbalance") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...posturalbalance, value];
            } else {
                updatedCheckedValues = posturalbalance.filter((item) => item !== value);
            }

            setposturalbalance(updatedCheckedValues);
        }

        if (e.target.name === "initialgmf") {
            psyoAssessment.initialgmf = checkedBoxes.join(",");
            const { value, checked } = e.target;
            let updatedCheckedValues;
            if (checked) {
                updatedCheckedValues = [...initialgmf, value];
            } else {
                updatedCheckedValues = initialgmf.filter((item) => item !== value);
            }
            setinitialgmf(updatedCheckedValues);
        }

        //======== involuntarymovement

        if (e.target.name === "involuntarymovement") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...involuntarymovement, value];
            } else {
                updatedCheckedValues = involuntarymovement.filter(
                    (item) => item !== value
                );
            }

            setinvoluntarymovement(updatedCheckedValues);
        }

        //=======

        if (e.target.name === "muscletone") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...muscletone, value];
            } else {
                updatedCheckedValues = muscletone.filter((item) => item !== value);
            }

            setmuscletone(updatedCheckedValues);
        }

        if (e.target.name === "upperextremityshoulder") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...upperextremityshoulder, value];
            } else {
                updatedCheckedValues = upperextremityshoulder.filter(
                    (item) => item !== value
                );
            }

            setupperextremityshoulder(updatedCheckedValues);
        }

        if (e.target.name === "upperextremityelbow") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...upperextremityelbow, value];
            } else {
                updatedCheckedValues = upperextremityelbow.filter(
                    (item) => item !== value
                );
            }

            setupperextremityelbow(updatedCheckedValues);
        }

        if (e.target.name === "upperextremitywrist") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...upperextremitywrist, value];
            } else {
                updatedCheckedValues = upperextremitywrist.filter(
                    (item) => item !== value
                );
            }

            setupperextremitywrist(updatedCheckedValues);
        }

        if (e.target.name === "upperextremityhip") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...upperextremityhip, value];
            } else {
                updatedCheckedValues = upperextremityhip.filter(
                    (item) => item !== value
                );
            }

            setupperextremityhip(updatedCheckedValues);
        }

        //gradeupperextremityhip

        if (e.target.name === "gradeupperextremityhip") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...gradeupperextremityhip, value];
            } else {
                updatedCheckedValues = gradeupperextremityhip.filter(
                    (item) => item !== value
                );
            }

            setgradeupperextremityhip(updatedCheckedValues);
        }




        //==============


        if (e.target.name === "upperextremityknee") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...upperextremityknee, value];
            } else {
                updatedCheckedValues = upperextremityknee.filter(
                    (item) => item !== value
                );
            }

            setupperextremityknee(updatedCheckedValues);
        }

        ////gradeupperextremityknee

        if (e.target.name === "gradeupperextremityknee") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...gradeupperextremityknee, value];
            } else {
                updatedCheckedValues = gradeupperextremityknee.filter(
                    (item) => item !== value
                );
            }

            setgradeupperextremityknee(updatedCheckedValues);
        }

        //==========================


        if (e.target.name === "upperextremityankle") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...upperextremityankle, value];
            } else {
                updatedCheckedValues = upperextremityankle.filter(
                    (item) => item !== value
                );
            }

            setupperextremityankle(updatedCheckedValues);
        }



        //=====gradeupperextremityankle  


        if (e.target.name === "gradeupperextremityankle") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...gradeupperextremityankle, value];
            } else {
                updatedCheckedValues = gradeupperextremityankle.filter(
                    (item) => item !== value
                );
            }

            setgradeupperextremityankle(updatedCheckedValues);
        }




        // ==

        if (e.target.name === "shouldergrade") {
            console.log("shouldergrade  Call")
            // psyoAssessment.shouldergrade = checkedBoxes.join(",");

            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...gradeshoulder, value];
            } else {
                updatedCheckedValues = gradeshoulder.filter(
                    (item) => item !== value
                );
            }
            console.log('updatedCheckedValues===>', updatedCheckedValues)
            setgradeshoulder(updatedCheckedValues);
        }

        if (e.target.name === "gradeelbow") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...gradeelbow, value];
            } else {
                updatedCheckedValues = gradeelbow.filter(
                    (item) => item !== value
                );
            }

            setgradeelbow(updatedCheckedValues);
        }

        if (e.target.name === "gradeextremitywrist") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...gradeextremitywrist, value];
            } else {
                updatedCheckedValues = gradeextremitywrist.filter(
                    (item) => item !== value
                );
            }

            setgradeextremitywrist(updatedCheckedValues);
        }


        setPsyoAssessment({ ...psyoAssessment, [e.target.name]: e.target.value });
    };

    // =========== Join String ============================
    const shouldergradejoin = gradeshoulder.join(", ");
    const gradeelbowjoin = gradeelbow.join(", ");
    const gradeextremitywristjoin = gradeextremitywrist.join(", ");
    console.log('shouldergradejoin=====>', shouldergradejoin)

    const checkedValuesString = checkedValues.join(", ");

    const checkedValuesString1 = checkedValues1.join(", ");

    console.log("checkedValuesString", checkedValuesString);

    const posturalbalanceString = posturalbalance.join(", ");
    const involuntarymovementString = involuntarymovement.join(", ");

    const deeptendonreflexString = deeptendonreflex.join(", ");
    const corticalsensationString = corticalsensation.join(", ");
    const deepsensationString = deepsensation.join(", ");
    const superficialsensationString = superficialsensation.join(", ");
    const fmfgripString = fmfgrip.join(", ");
    const fmfprehensionString = fmfprehension.join(", ");
    const developmentalreflexesString = developmentalreflexes.join(", ");
    const musculoskeletaldeformityString = musculoskeletaldeformity.join(", ");
    const lastgmfString = lastgmf.join(", ");
    const initialgmfString = initialgmf.join(", ");
    const muscletoneString = muscletone.join(", ");

    console.log("posturalbalanceString", posturalbalanceString);
    console.log("muscletoneString", muscletoneString);


    // const jrshoulderString = jrshoulder.join(", ");
    const upperextremityshoulderString = upperextremityshoulder.join(", ");
    console.log("upperextremityshoulderString", upperextremityshoulderString);

    const upperextremityelbowString = upperextremityelbow.join(", ");
    console.log("upperextremityelbowString", upperextremityelbowString);

    const upperextremitywristString = upperextremitywrist.join(", ");
    console.log("upperextremitywristString", upperextremitywristString);

    const upperextremityhipString = upperextremityhip.join(", ");
    console.log("upperextremityhipString", upperextremityhipString);




    const gradeupperextremityhipString = gradeupperextremityhip.join(", ");

    console.log("gradeupperextremityhipString", gradeupperextremityhipString);





    const upperextremitykneeString = upperextremityknee.join(", ");
    console.log("upperextremitykneeString", upperextremitykneeString);


    //gradeupperextremityknee


    const gradeupperextremitykneeString = gradeupperextremityknee.join(", ");
    console.log("gradeupperextremitykneeString", gradeupperextremitykneeString);





    const upperextremityankleString = upperextremityankle.join(", ");
    console.log("String", upperextremityankleString);

    //gradeupperextremityankle  



    const gradeupperextremityankleString = gradeupperextremityankle.join(", ");
    console.log("String", gradeupperextremityankleString);


    // ======================== useEffect WOrk =====================

    useEffect(() => {
        // Check if studentData and studentData.suggestivenote are defined
        if (studentData && studentData.suggestivenote && Array.isArray(studentData.suggestivenote.value)) {
            const suggestivenoteData = studentData.suggestivenote.value.map(item => ({ value: item.value }));
            setTableData(suggestivenoteData);
        }
    }, [studentData]);


    useEffect(() => {
        if (studentData?.studentid) {
            setPsyoAssessment(studentData);
            console.log("studentData", studentData);
            setstate(studentData.data);
            console.log("student ", studentData);
            let gait = studentData.gait?.split(", ");
            let gaitdirection = studentData.gaitdirection?.split(", ");

            let involuntarymovement = studentData.involuntarymovement?.split(", ");

            let muscletone = studentData.muscletone?.split(", ");
            let lastgmf = studentData.lastgmf?.split(", ");
            let fmfgrip = studentData.fmfgrip?.split(", ");
            let fmfprehension = studentData.fmfprehension?.split(", ");

            let deeptendonreflex = studentData.deeptendonreflex?.split(", ");
            let corticalsensation = studentData.corticalsensation?.split(", ");
            let deepsensation = studentData.deepsensation?.split(", ");
            let superficialsensation = studentData.superficialsensation?.split(", ");
            let developmentalreflexes = studentData.developmentalreflexes?.split(", ");
            let musculoskeletaldeformity = studentData.musculoskeletaldeformity?.split(", ");
            let initialgmf = studentData.initialgmf?.split(", ");
            let posturalbalance = studentData.posturalbalance?.split(", ");
            let upperextremityshoulder =
                studentData.upperextremityshoulder?.split(", ");

            // let jrshoulder = studentData.jrshoulder?.split(", ");  
            let upperextremityelbow = studentData.upperextremityelbow?.split(", ");
            let upperextremitywrist = studentData.upperextremitywrist?.split(", ");
            let upperextremityhip = studentData.upperextremityhip?.split(", ");

            //gradeupperextremityhip

            let gradeupperextremityhip = studentData.gradeupperextremityhip?.split(", ");






            let upperextremityknee = studentData.upperextremityknee?.split(", ");

            let gradeupperextremityknee = studentData.gradeupperextremityknee?.split(", ");
            let upperextremityankle = studentData.upperextremityankle?.split(", ");


            //gradeupperextremityankle  

            let gradeupperextremityankle = studentData.gradeupperextremityankle?.split(", ");



            let checkboxes = document.querySelectorAll('input[type="checkbox"]');
            console.log("checkboxes===>>>", checkboxes);
            let shouldergradesplit = studentData.shouldergrade?.split(", ");
            let gradeelbowsplit = studentData.gradeelbow?.split(", ");
            let gradeextremitywristsplit = studentData.gradeextremitywrist?.split(", ");


            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "shouldergrade") {
                    let checkboxValue = checkbox.value;
                    if (shouldergradesplit?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setgradeshoulder((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "gradeelbow") {
                    let checkboxValue = checkbox.value;
                    if (gradeelbowsplit?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setgradeelbow((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "gradeextremitywrist") {
                    let checkboxValue = checkbox.value;
                    if (gradeextremitywristsplit?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setgradeextremitywrist((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            //fmfgripString
            //fmfprehensionString
            //superficialsensation
            //deepsensation
            //corticalsensation
            //deeptendonreflex

            // checkboxes.forEach((checkbox) => {
            //   if (checkbox?.name === "jrshoulder") {
            //     let checkboxValue = checkbox.value;
            //     if (jrshoulder?.includes(checkboxValue)) {
            //       checkbox.checked = true;
            //       setjrshoulder((prevState) => [...prevState, checkboxValue]);
            //     }
            //   }
            // });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "deeptendonreflex") {
                    let checkboxValue = checkbox.value;
                    if (deeptendonreflex?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setdeeptendonreflex((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "corticalsensation") {
                    let checkboxValue = checkbox.value;
                    if (corticalsensation?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setcorticalsensation((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "deepsensation") {
                    let checkboxValue = checkbox.value;
                    if (deepsensation?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setdeepsensation((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "superficialsensation") {
                    let checkboxValue = checkbox.value;
                    if (superficialsensation?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setsuperficialsensation((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "fmfprehension") {
                    let checkboxValue = checkbox.value;
                    if (fmfprehension?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setfmfprehension((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "fmfgrip") {
                    let checkboxValue = checkbox.value;
                    if (fmfgrip?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setfmfgrip((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });


            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "developmentalreflexes") {
                    let checkboxValue = checkbox.value;
                    if (developmentalreflexes?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setdevelopmentalreflexes((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "musculoskeletaldeformity") {
                    let checkboxValue = checkbox.value;
                    if (musculoskeletaldeformity?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setmusculoskeletaldeformity((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "lastgmf") {
                    let checkboxValue = checkbox.value;
                    if (lastgmf?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setlastgmf((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "initialgmf") {
                    let checkboxValue = checkbox.value;
                    if (initialgmf?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setinitialgmf((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "gait") {
                    let checkboxValue = checkbox.value;
                    if (gait?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setCheckedValues((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "gaitdirection") {
                    let checkboxValue = checkbox.value;
                    if (gaitdirection?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setCheckedValues1((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox.name === "posturalbalance") {
                    let checkboxValue = checkbox.value;
                    if (posturalbalance?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setposturalbalance((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "involuntarymovement") {
                    let checkboxValue = checkbox.value;
                    if (involuntarymovement?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setinvoluntarymovement((prevState) => [
                            ...prevState,
                            checkboxValue,
                        ]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "muscletone") {
                    let checkboxValue = checkbox.value;
                    if (muscletone?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setmuscletone((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "upperextremityshoulder") {
                    let checkboxValue = checkbox.value;
                    if (upperextremityshoulder?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setupperextremityshoulder((prevState) => [
                            ...prevState,
                            checkboxValue,
                        ]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "upperextremityelbow") {
                    let checkboxValue = checkbox.value;
                    if (upperextremityelbow?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setupperextremityelbow((prevState) => [
                            ...prevState,
                            checkboxValue,
                        ]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "upperextremitywrist") {
                    let checkboxValue = checkbox.value;
                    if (upperextremitywrist?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setupperextremitywrist((prevState) => [
                            ...prevState,
                            checkboxValue,
                        ]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "upperextremityhip") {
                    let checkboxValue = checkbox.value;
                    if (upperextremityhip?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setupperextremityhip((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });




            //gradeupperextremityhip

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "gradeupperextremityhip") {
                    let checkboxValue = checkbox.value;
                    if (gradeupperextremityhip?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setgradeupperextremityhip((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });



            //========

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "upperextremityknee") {
                    let checkboxValue = checkbox.value;
                    if (upperextremityknee?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setupperextremityknee((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            //gradeupperextremityknee  


            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "gradeupperextremityknee") {
                    let checkboxValue = checkbox.value;
                    if (gradeupperextremityknee?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setgradeupperextremityknee((prevState) => [...prevState, checkboxValue]);
                    }
                }
            });

            //gradeupperextremityankle


            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "upperextremityankle") {
                    let checkboxValue = checkbox.value;
                    if (upperextremityankle?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setupperextremityankle((prevState) => [
                            ...prevState,
                            checkboxValue,
                        ]);
                    }
                }
            });

            checkboxes.forEach((checkbox) => {
                if (checkbox?.name === "gradeupperextremityankle") {
                    let checkboxValue = checkbox.value;
                    if (gradeupperextremityankle?.includes(checkboxValue)) {
                        checkbox.checked = true;
                        setgradeupperextremityankle((prevState) => [
                            ...prevState,
                            checkboxValue,
                        ]);
                    }
                }
            });


        }
    }, []);

    // ======== handleSubmit  ========

    const handleSubmit = async (e) => {
        console.log('shouldergradejoin ====> ', shouldergradejoin)

        e.preventDefault();
        psyoAssessment.gait = checkedValuesString;
        psyoAssessment.gaitdirection = checkedValuesString1;
        psyoAssessment.posturalbalance = posturalbalanceString;
        psyoAssessment.involuntarymovement = involuntarymovementString;
        //psyoAssessment.jrshoulder = jrshoulderString;

        psyoAssessment.deeptendonreflex = deeptendonreflexString;
        psyoAssessment.corticalsensation = corticalsensationString;
        psyoAssessment.deepsensation = deepsensationString;
        psyoAssessment.superficialsensation = superficialsensationString;
        psyoAssessment.fmfprehension = fmfprehensionString;
        psyoAssessment.fmfgrip = fmfgripString;
        psyoAssessment.developmentalreflexes = developmentalreflexesString;

        psyoAssessment.musculoskeletaldeformity = musculoskeletaldeformityString;
        psyoAssessment.lastgmf = lastgmfString;
        psyoAssessment.initialgmf = initialgmfString;

        psyoAssessment.muscletone = muscletoneString;
        psyoAssessment.upperextremityshoulder = upperextremityshoulderString;
        psyoAssessment.upperextremityelbow = upperextremityelbowString;
        psyoAssessment.upperextremitywrist = upperextremitywristString;
        psyoAssessment.upperextremityhip = upperextremityhipString;



        psyoAssessment.gradeupperextremityhip = gradeupperextremityhipString;

        psyoAssessment.upperextremityknee = upperextremitykneeString;


        psyoAssessment.gradeupperextremityknee = gradeupperextremitykneeString;

        psyoAssessment.upperextremityankle = upperextremityankleString;


        //gradeupperextremityankle

        psyoAssessment.gradeupperextremityankle = gradeupperextremityankleString;
        console.log("PhysioAssessment Json >>>", psyoAssessment);
        psyoAssessment.shouldergrade = shouldergradejoin;
        psyoAssessment.gradeelbow = gradeelbowjoin;
        psyoAssessment.gradeextremitywrist = gradeextremitywristjoin;
        console.log('tableData', tableData)
        psyoAssessment.suggestivenote = tableData
        let result = {};
        if (psyoAssessment.id) {
            console.log("save call", psyoAssessment);
            psyoAssessment.data = state;
            console.log("textboxdata", state);
            console.log('psyoAssessment updaTE =====>', psyoAssessment)
            console.log("psyoAssessment.data", psyoAssessment.data);

            result = await inventoryApi.savePhysioAssess(psyoAssessment);
            console.log("save psyo result", result);
            PubSub.publish("RECORD_SAVED_TOAST", {
                title: "Record Saved",
                message: " Record update successfully",
            });
            navigate(`/students/${psyoAssessment.studentid}`, {
                state: psyoAssessment,
            });
        } else {
            delete psyoAssessment.success;
            delete psyoAssessment.message;
            console.log("textboxdata", state);
            psyoAssessment.studentid = studentData.id;
            console.log("psyoAssessment else call", psyoAssessment);
            psyoAssessment.data = state;
            console.log('psyoAssessment updaTE =====>', psyoAssessment)

            result = await inventoryApi.createPhysioAssess(psyoAssessment);
            console.log("create physio result", result);
            PubSub.publish("RECORD_SAVED_TOAST", {
                title: "Record Saved",
                message: " Record Create successfully",
            });
            if (result) {
                navigate(`/students/${result.studentid}`, { state: result });
            }
        }
    };

    console.log("checkedValues1 -->", checkedValues1);
    console.log("Muscle Tone", muscletone);
    console.log("involuntarymovement", involuntarymovement);
    console.log("lastgmf", lastgmf);
    console.log("initialgmf", initialgmf);

    const handleChangeTextbox = (e) => {
        console.log("e.target.name", e.target.name);
        console.log("e.target.value", e.target.value);

        setstate({ ...state, [e.target.name]: e.target.value });
        // settextboxdata({...textboxdata,[e.target.name] : e.target.value})
    };


    const handlePrint = () => {
        const element = document.getElementById('pdfcontainer');

        // Add a black border to the container
        element.style.border = '1px solid black';

        // Centered heading
        const heading = document.createElement('h1');
        heading.textContent = 'Centered Heading';
        heading.style.textAlign = 'center';
        element.appendChild(heading);

        html2pdf(element, {
            margin: 5,
            filename: `${studentdatainfo.name}_PhysioAssessment.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', putOnlyUsedFonts: true, compressText: true },
        });

        // Reset styles and remove added elements after generating the PDF
        element.style.border = '';
        element.removeChild(heading);
    };

    console.log("lastgmftextbox", state.lastgmftextbox);
    const [studentdatainfo, setstudentdatainfo] = useState({});
    useEffect(() => {
        fetchStudentById()
    }, []);

    const fetchStudentById = async () => {
        let result = await inventoryApi.fetchStudentById(studentData.studentid);
        console.log('result', result)
        setstudentdatainfo(result)

    }


    const [tableData, setTableData] = useState([{ value: '' }]);

    const addRow = () => {
      const newRow = { id: Date.now(), value: '' };
      setTableData((prevTableData) => (prevTableData ? [...prevTableData, newRow] : [newRow]));
    };
    
    const deleteRow = (index) => {
      setTableData((prevTableData) => prevTableData.filter((_, i) => i !== index));
    };
    
    const updateValue = (index, newValue) => {
      setTableData((prevTableData) =>
        prevTableData
          ? prevTableData.map((row, i) => (i === index ? { ...row, value: newValue } : row))
          : []
      );
    };
    const pdfShow = () => {
        const pdfTable = document.getElementById("physiopdfshow");
        console.log("pdf table india", pdfTable);
        var html = htmlToPdfmake(pdfTable.innerHTML, {
            tableAutoSize: true,
        });
        console.log('html', html)
        const documentDefinition = {
            content: html, defaultStyle: {
                font: 'arialunicodems1'
            }
        };
        //console.log('documentDefinition', documentDefinition)
        //pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.fonts = {
            arialunicodems1: {
                normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
                bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            },


            // download default Roboto font from cdnjs.com
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            }
        }
        //console.log(pdfMake.vfs)

        pdfMake.createPdf(documentDefinition).open();


    }


    return (
        <>
            <Container id="one">
                <Row className="view-form-header align-items-center  aslamBari">
                    <Col lg={6}>
                        <h4>PhysioAssessment</h4>
                    </Col>
                    <Col lg={6} className="d-flex justify-content-end">
                        <Button className="btn-sm mx-3 " onClick={pdfShow}>
                            Pdf
                        </Button>
                        <Button className="btn-sm " onClick={handleSubmit}>
                            Save
                        </Button>



                        {/* <Button className="btn-sm" variant="danger" onClick={handleCancel}>
                            Cancel
                        </Button> */}
                    </Col>
                </Row>

                <Row>



                </Row>
                <div id="pdfcontainer">
                    <Row lg={12} className="my-4">
                        <Col lg={6}>
                            <strong>Name:</strong> {studentdatainfo.name ? studentdatainfo.name : studentData.name}
                        </Col>
                        <Col lg={6}>
                            <strong>Child Registration Age:</strong> {studentdatainfo.childregistrationage ? studentdatainfo.childregistrationage : studentData.childregistrationage}
                        </Col>
                        <Col lg={6}>
                            <strong>Service Category:</strong> {studentdatainfo.servicecategoryname ? studentdatainfo.servicecategoryname : studentData.servicecategoryname}
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className=" mt-3" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Diagnosis</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="studentdignosis"
                                    value={state.studentdignosis}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Row lg={12}>
                            <Col lg={6}>

                                <Form.Group className="">
                                    <Form.Label className="form-view-label my-3">Gait</Form.Label>

                                    <Row xs="auto" className="my-1">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="Stance phase"
                                                name="gait"
                                                type="checkbox"
                                                value="Stance phase"
                                                id={`inline-checkbox-1`}
                                                onChange={handleChange}
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group>


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="gait"
                                                            type="checkbox"
                                                            value="StanceRight"
                                                            id={`inline-checkbox-2`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="gait"
                                                            type="checkbox"
                                                            value="StanceLeft"
                                                            id={`inline-checkbox-3`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>



                                    </Row>
                                    <Row xs="auto">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="Swing phase"
                                                name="gait"
                                                type="checkbox"
                                                value="Swing phase"
                                                id={`inline-checkbox-2`}
                                                onChange={handleChange}
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group>


                                                <Row xs="auto" className="">
                                                    <Col >
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="gait"
                                                            type="checkbox"
                                                            value="SwingRight"
                                                            id={`inline-checkbox-2`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="gait"
                                                            type="checkbox"
                                                            value="SwingLeft"
                                                            id={`inline-checkbox-3`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row xs="auto">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="NA"
                                                name="gait"
                                                type="checkbox"
                                                value="NA"
                                                id={`inline-checkbox-3`}
                                                onChange={handleChange}
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group>


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="gait"
                                                            type="checkbox"
                                                            value="NARight"
                                                            id={`inline-checkbox-2`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="gait"
                                                            type="checkbox"
                                                            value="NALeft"
                                                            id={`inline-checkbox-3`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className=" mt-3" controlId="formBasicGenderType">
                                    <Form.Label className="form-view-label">Note</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="gaittextbox"
                                        value={state.gaittextbox}
                                        onChange={handleChangeTextbox}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>


                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="dfdgo">
                                <Form.Label className="form-view-label form-label">
                                    Walking Aid Used
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="walkingaid"
                                    value={psyoAssessment.walkingaid}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>



                        {/* ========================================= Muscle Tone   ======================= */}
                        {/* ====================== Added   by Jaseem    9-5-2023 ============================ */}
                        <Col lg={6}>
                            <Form.Group className="">
                                <Form.Label className="form-view-label my-3">
                                    Muscle Tone
                                </Form.Label>

                                <Row xs="auto">
                                    <Col className="me-5">
                                        <Form.Check
                                            inline
                                            label="Normal"
                                            name="muscletone"
                                            type="checkbox"
                                            value="Normal"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col className="me-4">
                                        <Form.Check
                                            inline
                                            label="Hypertonic"
                                            name="muscletone"
                                            type="checkbox"
                                            value="Hypertonic"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-4">
                                        <Form.Check
                                            inline
                                            label="Hypotonic"
                                            name="muscletone"
                                            type="checkbox"
                                            value="Hypotonic"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-4">
                                        <Form.Check
                                            inline
                                            label="Dystonia"
                                            name="muscletone"
                                            type="checkbox"
                                            value="Dystonia"
                                            id={`inline-checkbox-4`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="">
                                        <Form.Check
                                            inline
                                            label="Wasting"
                                            name="muscletone"
                                            type="checkbox"
                                            value="Wasting"
                                            id={`inline-checkbox-5`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group className=" mt-2" controlId="dfdgo">
                                <Form.Label className="form-view-label form-label">
                                    Muscle Tone Note
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="muscletone"
                                    value={state.muscletone}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                        {/*
          <Col lg={6}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">
                Deep Tendon Reflex
              </Form.Label>
              <Form.Select
                // required
                name="deeptendonreflex"
                value={psyoAssessment.deeptendonreflex}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Biceps">Biceps </option>
                <option value="Triceps">Triceps </option>
                <option value="Knee jerk">Knee jerk </option>
                <option value="TA jerk">TA jerk </option>
                <option value="NA">NA</option>
              </Form.Select>
            </Form.Group>
          </Col> */}

                        <Col lg={6}>
                            <Form.Group className="">
                                <Form.Label className="form-view-label my-3">
                                    Deep Tendon Reflex
                                </Form.Label>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Biceps"
                                            name="deeptendonreflex"
                                            type="checkbox"
                                            value="Biceps"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Triceps"
                                            name="deeptendonreflex"
                                            type="checkbox"
                                            value="Triceps"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Knee"
                                            name="deeptendonreflex"
                                            type="checkbox"
                                            value="Knee"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="TA jerk"
                                            name="deeptendonreflex"
                                            type="checkbox"
                                            value="TA jerk"
                                            id={`inline-checkbox-4`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="NA"
                                            name="deeptendonreflex"
                                            type="checkbox"
                                            value="NA"
                                            id={`inline-checkbox-5`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                </Row>
                            </Form.Group>
                        </Col>







                        {/* ====================== Added   by Jaseem    9-5-2023 ============================ */}

                        <Col lg={6}>
                            <Form.Group className=" my-2">
                                <Form.Label className="form-view-label my-3">
                                    Involuntary Movement
                                </Form.Label>

                                <Row xs="auto">
                                    <Col className="me-5">
                                        <Form.Check
                                            inline
                                            label="Termors"
                                            name="involuntarymovement"
                                            type="checkbox"
                                            value="Termors"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col className="me-4">
                                        <Form.Check
                                            inline
                                            label="Clonus"
                                            name="involuntarymovement"
                                            type="checkbox"
                                            value="Clonus"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-4">
                                        <Form.Check
                                            inline
                                            label="Athetosis"
                                            name="involuntarymovement"
                                            type="checkbox"
                                            value="Athetosis"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-4">
                                        <Form.Check
                                            inline
                                            label="Chorea"
                                            name="involuntarymovement"
                                            type="checkbox"
                                            value="Chorea"
                                            id={`inline-checkbox-4`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    {/* <Col className=""><Form.Check
                                        inline
                                        label="Wasting"
                                        name="muscletone"
                                        type="checkbox"
                                        value="Wasting"
                                        id={`inline-checkbox-5`}
                                        onChange={handleChange}
                                    /></Col> */}
                                </Row>
                            </Form.Group>
                        </Col>
                        {/* <Col lg={6}>
                        <Form.Group className=" mt-2" controlId="dfdgo">
                            <Form.Label className="form-view-label form-label">
                                Involuntary Movement
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                name="involuntarymovement"
                                value={psyoAssessment.involuntarymovement}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col> */}
                    </Row>

                    <Row>
                        <Row lg={12}>
                            <Col lg={6}>
                                <Form.Group className="">
                                    <Form.Label className="form-view-label my-3">
                                        Postural Balance
                                    </Form.Label>

                                    <Row xs="auto">
                                        <Col className="">
                                            <Form.Check
                                                inline
                                                label="One Leg Stance"
                                                name="posturalbalance"
                                                type="checkbox"
                                                value="One Leg Stance"
                                                id={`inline-checkbox-1`}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                inline
                                                label="Heel to toe Stand"
                                                name="posturalbalance"
                                                type="checkbox"
                                                value="Heel to toe Stand"
                                                id={`inline-checkbox-2`}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                inline
                                                label="NA"
                                                name="posturalbalance"
                                                type="checkbox"
                                                value="NA"
                                                id={`inline-checkbox-3`}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                    <Form.Label className="form-view-label">
                                        Postural balance direction
                                    </Form.Label>
                                    <Form.Select
                                        // required
                                        name="posturalbalancedirection"
                                        value={psyoAssessment.posturalbalancedirection}
                                        onChange={handleChange}
                                    >
                                        <option>--Select--</option>
                                        <option value="Right">Right</option>
                                        <option value="Left">Left</option>
                                        <option value="Straight">Straight</option>
                                        <option value="Complete ">Complete </option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Row>

                    <Col lg={12} className="section-header my-3">
                        Joint Rom and Muscle Strength (Upper Extremity)  <b>IN DEGREE
                        </b>        </Col>

                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Shoulder</Form.Label>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="F"
                                            name="upperextremityshoulder"
                                            type="checkbox"
                                            value="F"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>


                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col>
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="FRight"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="FLeft"
                                                        id={`inline-checkbox-3`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row xs="auto" className="my-3">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="E"
                                            name="upperextremityshoulder"
                                            type="checkbox"
                                            value="E"
                                            id={`inline-checkbox-4`}
                                            onChange={handleChange}
                                        />
                                    </Col>


                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col >
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="ERight"
                                                        id={`inline-checkbox-5`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="ELeft"
                                                        id={`inline-checkbox-6`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>


                                </Row>

                                <Row xs="auto" className="my-3">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Ab"
                                            name="upperextremityshoulder"
                                            type="checkbox"
                                            value="Ab"
                                            id={`inline-checkbox-7`}
                                            onChange={handleChange}
                                        />
                                    </Col>


                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col >
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="AbRight"
                                                        id={`inline-checkbox-8`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="AbLeft"
                                                        id={`inline-checkbox-9`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>



                                </Row>




                                <Row xs="auto" className="my-3">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Ad"
                                            name="upperextremityshoulder"
                                            type="checkbox"
                                            value="Ad"
                                            id={`inline-checkbox-10`}
                                            onChange={handleChange}
                                        />
                                    </Col>



                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col >
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="AdRight"
                                                        id={`inline-checkbox-11`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="AdLeft"
                                                        id={`inline-checkbox-12`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>




                                <Row xs="auto" className="my-3">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Ir"
                                            name="upperextremityshoulder"
                                            type="checkbox"
                                            value="Ir"
                                            id={`inline-checkbox-13`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col>
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="IrRight"
                                                        id={`inline-checkbox-14`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="IrLeft"
                                                        id={`inline-checkbox-15`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>







                                </Row>


                                <Row xs="auto" className="my-3">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Er"
                                            name="upperextremityshoulder"
                                            type="checkbox"
                                            value="Er"
                                            id={`inline-checkbox-16`}
                                            onChange={handleChange}
                                        />
                                    </Col>


                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col>
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="ErRight"
                                                        id={`inline-checkbox-17`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityshoulder"
                                                        type="checkbox"
                                                        value="ErLeft"
                                                        id={`inline-checkbox-18`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Form.Group>
                        </Col>
                        {/*
          <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
              <Form.Select
                // required
                name="upperextremityshouldermmt"
                value={psyoAssessment.upperextremityshouldermmt}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Right">Right</option>
                <option value="Left">Left</option>
              </Form.Select>
            </Form.Group>
          </Col> */}

                        <Col lg={6}>
                            <Form.Group className=" mt-1" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="shouldertextbox"
                                    value={state.shouldertextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* --------------------------------------------------------------------------------------- */}
                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Elbow</Form.Label>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="F/su."
                                            name="upperextremityelbow"
                                            type="checkbox"
                                            value="F/su."
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityelbow"
                                                        type="checkbox"
                                                        value="FRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityelbow"
                                                        type="checkbox"
                                                        value="FLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>

                                </Row>


                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="E/pro."
                                            name="upperextremityelbow"
                                            type="checkbox"
                                            value="E/pro."
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityelbow"
                                                        type="checkbox"
                                                        value="ERight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityelbow"
                                                        type="checkbox"
                                                        value="ELeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>


                                </Row>
                            </Form.Group>
                        </Col>

                        {/* <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
              <Form.Select
                // required
                name="upperextremityelbowmmt"
                value={psyoAssessment.upperextremityelbowmmt}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Right">Right</option>
                <option value="Left">Left</option>
              </Form.Select>
            </Form.Group>
          </Col> */}
                        <Col lg={6}>
                            <Form.Group className=" mt-1" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="elbowtextbox"
                                    value={state.elbowtextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Wrist</Form.Label>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="F"
                                            name="upperextremitywrist"
                                            type="checkbox"
                                            value="F"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>


                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremitywrist"
                                                        type="checkbox"
                                                        value="FRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremitywrist"
                                                        type="checkbox"
                                                        value="FLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="E"
                                            name="upperextremitywrist"
                                            type="checkbox"
                                            value="E"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>


                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremitywrist"
                                                        type="checkbox"
                                                        value="ERight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremitywrist"
                                                        type="checkbox"
                                                        value="ELeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Ab"
                                            name="upperextremitywrist"
                                            type="checkbox"
                                            value="Ab"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremitywrist"
                                                        type="checkbox"
                                                        value="AbRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremitywrist"
                                                        type="checkbox"
                                                        value="AbLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Ad"
                                            name="upperextremitywrist"
                                            type="checkbox"
                                            value="Ad"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremitywrist"
                                                        type="checkbox"
                                                        value="AdRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremitywrist"
                                                        type="checkbox"
                                                        value="AdLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Form.Group>
                        </Col>

                        {/* <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
              <Form.Select
                // required
                name="upperextremitywristmmt"
                value={psyoAssessment.upperextremitywristmmt}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Right">Right</option>
                <option value="Left">Left</option>
              </Form.Select>
            </Form.Group>
          </Col> */}

                        <Col lg={6}>
                            <Form.Group className=" mt-1" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="wristtextbox"
                                    value={state.wristtextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* <Col lg={12} className="section-header my-3">
                    Lower Extremity (Same As for Grade and Degree)
                </Col> */}

                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Hip</Form.Label>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="F"
                                            name="upperextremityhip"
                                            type="checkbox"
                                            value="F"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="FRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="FLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>


                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="E"
                                            name="upperextremityhip"
                                            type="checkbox"
                                            value="E"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="ERight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="ELeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Ab"
                                            name="upperextremityhip"
                                            type="checkbox"
                                            value="Ab"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="AbRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="AbLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Ad"
                                            name="upperextremityhip"
                                            type="checkbox"
                                            value="Ad"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="AdRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="AdLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Ir"
                                            name="upperextremityhip"
                                            type="checkbox"
                                            value="Ir"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="IrRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="IrLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Er"
                                            name="upperextremityhip"
                                            type="checkbox"
                                            value="Er"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="FrRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityhip"
                                                        type="checkbox"
                                                        value="FrLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>

                        {/* <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
              <Form.Select
                name="upperextremityhipmmt"
                value={psyoAssessment.upperextremityhipmmt}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Right">Right</option>
                <option value="Left">Left</option>
              </Form.Select>
            </Form.Group>
          </Col> */}


                        <Col lg={6}>
                            <Form.Group className=" mt-1" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="lowerhiptextbox"
                                    value={state.lowerhiptextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Knee</Form.Label>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="F"
                                            name="upperextremityknee"
                                            type="checkbox"
                                            value="F"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityknee"
                                                        type="checkbox"
                                                        value="FRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityknee"
                                                        type="checkbox"
                                                        value="FLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="E"
                                            name="upperextremityknee"
                                            type="checkbox"
                                            value="E"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityknee"
                                                        type="checkbox"
                                                        value="ERight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityknee"
                                                        type="checkbox"
                                                        value="ELeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        {/*
          <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
              <Form.Select
                // required
                name="upperextremitykneemmt"
                value={psyoAssessment.upperextremitykneemmt}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Right">Right</option>
                <option value="Left">Left</option>
              </Form.Select>
            </Form.Group>
          </Col> */}

                        <Col lg={6}>
                            <Form.Group className=" mt-1" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="kneetextbox"
                                    value={state.kneetextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Ankle</Form.Label>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Df./In"
                                            name="upperextremityankle"
                                            type="checkbox"
                                            value="Df./In"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityankle"
                                                        type="checkbox"
                                                        value="DfRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityankle"
                                                        type="checkbox"
                                                        value="DfLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>



                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Pf./Ev"
                                            name="upperextremityankle"
                                            type="checkbox"
                                            value="Pf./Ev"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="upperextremityankle"
                                                        type="checkbox"
                                                        value="PfRight"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="upperextremityankle"
                                                        type="checkbox"
                                                        value="PfLeft"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>

                        {/* <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
              <Form.Select
                name="upperextremityanklemmt"
                value={psyoAssessment.upperextremityanklemmt}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Right">Right</option>
                <option value="Left">Left</option>
              </Form.Select>
            </Form.Group>
          </Col> */}
                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="ancletextbox"
                                    value={state.ancletextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        {/* <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">
                Musculoskeletal Deformity
              </Form.Label>
              <Form.Select
                // required
                name="musculoskeletaldeformity"
                value={psyoAssessment.musculoskeletaldeformity}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Shoulder">Shoulder</option>
                <option value="Elbow">Elbow</option>
                <option value="Wrist">Wrist</option>
                <option value="Hip">Hip</option>
                <option value="Knee">Knee</option>
                <option value="Ancle">Ankle</option>
                <option value="Spinal">Spinal</option>
                <option value="NA">NA</option>
              </Form.Select>
            </Form.Group>
          </Col> */}
                        <Col lg={3}>
                            <Form.Group className=" my-3">
                                <Form.Label className="form-view-label my-3"> Musculoskeletal  Deformity </Form.Label>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Shoulder"
                                            name="musculoskeletaldeformity"
                                            type="checkbox"
                                            value="Shoulder"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                </Row>
                                <Row xs="auto">

                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Elbow"
                                            name="musculoskeletaldeformity"
                                            type="checkbox"
                                            value="Elbow"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                </Row>

                                <Row xs="auto">

                                    <Col>
                                        <Form.Check
                                            inline
                                            label="Wrist"
                                            name="musculoskeletaldeformity"
                                            type="checkbox"
                                            value="Wrist"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">

                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Hip"
                                            name="musculoskeletaldeformity"
                                            type="checkbox"
                                            value="Hip"
                                            id={`inline-checkbox-4`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Knee"
                                            name="musculoskeletaldeformity"
                                            type="checkbox"
                                            value="Knee"
                                            id={`inline-checkbox-5`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Ankle"
                                            name="musculoskeletaldeformity"
                                            type="checkbox"
                                            value="Ankle"
                                            id={`inline-checkbox-6`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Spinal"
                                            name="musculoskeletaldeformity"
                                            type="checkbox"
                                            value="Spinal"
                                            id={`inline-checkbox-7`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="NA"
                                            name="musculoskeletaldeformity"
                                            type="checkbox"
                                            value="NA"
                                            id={`inline-checkbox-8`}
                                            onChange={handleChange}
                                        />
                                    </Col>



                                </Row>
                            </Form.Group>
                        </Col>

                        <Col lg={3}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="muscultextbox"
                                    value={state.muscultextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                        {/* <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">
                Developmental Reflexes
              </Form.Label>
              <Form.Select
                // required
                name="developmentalreflexes"
                value={psyoAssessment.developmentalreflexes}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Primitive reflexes">Primitive reflexes</option>
                <option value="ATNR/STNR">ATNR/STNR</option>
                <option value="Protective reactions">
                  Protective reactions
                </option>
                <option value="Babinski sign">Babinski sign</option>
                <option value="NA">NA</option>
              </Form.Select>
            </Form.Group>
          </Col> */}

                        <Col lg={3}>
                            <Form.Group className=" my-3">
                                <Form.Label className="form-view-label my-3"> Developmental Reflexes</Form.Label>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Primitive reflexes"
                                            name="developmentalreflexes"
                                            type="checkbox"
                                            value="Primitive reflexes"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                </Row>
                                <Row xs="auto">

                                    <Col >
                                        <Form.Check
                                            inline
                                            label="ATNR/STNR"
                                            name="developmentalreflexes"
                                            type="checkbox"
                                            value="ATNR/STNR"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                </Row>

                                <Row xs="auto">

                                    <Col>
                                        <Form.Check
                                            inline
                                            label="Protective reactions"
                                            name="developmentalreflexes"
                                            type="checkbox"
                                            value="Protective reactions"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">

                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Babinski sign"
                                            name="developmentalreflexes"
                                            type="checkbox"
                                            value="Babinski sign"
                                            id={`inline-checkbox-4`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="NA"
                                            name="developmentalreflexes"
                                            type="checkbox"
                                            value="NA"
                                            id={`inline-checkbox-5`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                </Row>

                            </Form.Group>
                        </Col>



                        <Col lg={3}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="devereftextbox"
                                    value={state.devereftextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Col lg={12} className="section-header my-3">
                        Gross motor functions (current condition)
                    </Col>
                    <Row>
                        {/* ========================================= Last GMF   ======================= */}
                        {/* ====================== Added   by Jaseem    9-5-2023 ============================ */}
                        <Col lg={3}>
                            <Form.Group className="">
                                <Form.Label className="form-view-label my-3">Last GMF</Form.Label>

                                <Row xs="auto">
                                    <Col className="me-5">
                                        <Form.Check
                                            inline
                                            label="Sitting"
                                            name="lastgmf"
                                            type="checkbox"
                                            value="Sitting"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col className="me-4">
                                        <Form.Check
                                            inline
                                            label="Standing"
                                            name="lastgmf"
                                            type="checkbox"
                                            value="Standing"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-4">
                                        <Form.Check
                                            inline
                                            label="Standing on one leg"
                                            name="lastgmf"
                                            type="checkbox"
                                            value="Standing on one leg"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-4">
                                        <Form.Check
                                            inline
                                            label="Walking"
                                            name="lastgmf"
                                            type="checkbox"
                                            value="Walking"
                                            id={`inline-checkbox-4`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-5">
                                        <Form.Check
                                            inline
                                            label="Running"
                                            name="lastgmf"
                                            type="checkbox"
                                            value="Running"
                                            id={`inline-checkbox-5`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-5">
                                        <Form.Check
                                            inline
                                            label="Hopping"
                                            name="lastgmf"
                                            type="checkbox"
                                            value="Hopping"
                                            id={`inline-checkbox-6`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-5">
                                        <Form.Check
                                            inline
                                            label="Climbing up"
                                            name="lastgmf"
                                            type="checkbox"
                                            value="Climbing up"
                                            id={`inline-checkbox-7`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-5">
                                        <Form.Check
                                            inline
                                            label="Climbing down"
                                            name="lastgmf"
                                            type="checkbox"
                                            value="Climbing down"
                                            id={`inline-checkbox-8`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-5">
                                        <Form.Check
                                            inline
                                            label="Throwing"
                                            name="lastgmf"
                                            type="checkbox"
                                            value="Throwing"
                                            id={`inline-checkbox-9`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-5">
                                        <Form.Check
                                            inline
                                            label="Catching"
                                            name="lastgmf"
                                            type="checkbox"
                                            value="Catching"
                                            id={`inline-checkbox-10`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col className="me-5">
                                        <Form.Check
                                            inline
                                            label="NA"
                                            name="lastgmf"
                                            type="checkbox"
                                            value="NA"
                                            id={`inline-checkbox-10`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>

                        {/* ======================================================== */}

                        <Col lg={3}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    // type="text"
                                    name="lastgmftextbox"
                                    value={state.lastgmftextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>

                        {/* ========================================= Initial GMF   ======================= */}
                        {/* ====================== Added   by Jaseem    9-5-2023 ============================ */}
                        <Col lg={3}>
                            <Form.Group className="">
                                <Form.Label className="form-view-label my-3">
                                    Initial GMF
                                </Form.Label>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Supine"
                                            name="initialgmf"
                                            type="checkbox"
                                            value="Supine"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Prone"
                                            name="initialgmf"
                                            type="checkbox"
                                            value="Prone"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Coming to sitting"
                                            name="initialgmf"
                                            type="checkbox"
                                            value="Coming to sitting"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Rolling"
                                            name="initialgmf"
                                            type="checkbox"
                                            value="Rolling"
                                            id={`inline-checkbox-4`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Crawling"
                                            name="initialgmf"
                                            type="checkbox"
                                            value="Crawling"
                                            id={`inline-checkbox-5`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Kneeling"
                                            name="initialgmf"
                                            type="checkbox"
                                            value="Kneeling"
                                            id={`inline-checkbox-6`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col>
                                        <Form.Check
                                            inline
                                            label="Kneel"
                                            name="initialgmf"
                                            type="checkbox"
                                            value="Kneel"
                                            id={`inline-checkbox-7`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>

                                <Row xs="auto">

                                    <Col >
                                        <Form.Check
                                            inline
                                            label="NA"
                                            name="initialgmf"
                                            type="checkbox"
                                            value="NA"
                                            id={`inline-checkbox-8`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>

                        <Col lg={3}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="intialtextbox"
                                    value={state.intialtextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Col lg={12} className="section-header my-3">
                        Fine motor functions
                    </Col>
                    <Row>
                        {/* <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">Grip</Form.Label>
              <Form.Select
                name="fmfgrip"
                value={psyoAssessment.fmfgrip}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Cylindrical">Cylindrical</option>
                <option value="Spherical">Spherical</option>
                <option value="NA">NA</option>
              </Form.Select>
            </Form.Group>
          </Col> */}

                        <Col lg={3}>
                            <Form.Group className="">
                                <Form.Label className="form-view-label my-3">
                                    Grip
                                </Form.Label>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Cylindrical"
                                            name="fmfgrip"
                                            type="checkbox"
                                            value="Cylindrical"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Spherical"
                                            name="fmfgrip"
                                            type="checkbox"
                                            value="Spherical"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="NA"
                                            name="fmfgrip"
                                            type="checkbox"
                                            value="NA"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>

                            </Form.Group>
                        </Col>

                        <Col lg={3}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="griptextbox"
                                    value={state.griptextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>


                        {/* <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">Prehension</Form.Label>
              <Form.Select
                // required
                name="fmfprehension"
                value={psyoAssessment.fmfprehension}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Tip to pad">Tip to pad</option>
                <option value="Pad to pad">Pad to pad</option>
                <option value="Tip to tip">Tip to tip</option>
                <option value="Hook">Hook</option>
                <option value="NA">NA</option>
              </Form.Select>
            </Form.Group>
          </Col> */}


                        <Col lg={3}>
                            <Form.Group className="">
                                <Form.Label className="form-view-label my-3">
                                    Prehension
                                </Form.Label>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Tip to pad"
                                            name="fmfprehension"
                                            type="checkbox"
                                            value="Tip to pad"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Pad to pad"
                                            name="fmfprehension"
                                            type="checkbox"
                                            value="Pad to pad"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Tip to tip"
                                            name="fmfprehension"
                                            type="checkbox"
                                            value="Tip to tip"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Hook"
                                            name="fmfprehension"
                                            type="checkbox"
                                            value="Hook"
                                            id={`inline-checkbox-4`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="NA"
                                            name="fmfprehension"
                                            type="checkbox"
                                            value="NA"
                                            id={`inline-checkbox-5`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                </Row>
                            </Form.Group>
                        </Col>



                        <Col lg={3}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="prehentiontextbox"
                                    value={state.prehentiontextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Col lg={12} className="section-header ">
                        Sensory Assessment
                    </Col>
                    <Row>

                        <Col lg={3}>
                            <Form.Group className="">
                                <Form.Label className="form-view-label my-3">
                                    Superficial sensation
                                </Form.Label>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Pain"
                                            name="superficialsensation"
                                            type="checkbox"
                                            value="Pain"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Touch"
                                            name="superficialsensation"
                                            type="checkbox"
                                            value="Touch"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Temperature"
                                            name="superficialsensation"
                                            type="checkbox"
                                            value="Temperature"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="NA"
                                            name="superficialsensation"
                                            type="checkbox"
                                            value="NA"
                                            id={`inline-checkbox-4`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>

                            </Form.Group>
                        </Col>


                        <Col lg={3}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="superficialtextbox"
                                    value={state.superficialtextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                        {/* <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">
                Deep sensation
              </Form.Label>
              <Form.Select
                // required
                name="deepsensation"
                value={psyoAssessment.deepsensation}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Joint position sense">
                  Joint position sense
                </option>
                <option value="Vibration sense">Vibration sense</option>
              </Form.Select>
            </Form.Group>
          </Col> */}


                        <Col lg={3}>
                            <Form.Group className="">
                                <Form.Label className="form-view-label my-3">
                                    Deep sensation
                                </Form.Label>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Joint position sense"
                                            name="deepsensation"
                                            type="checkbox"
                                            value="Joint position sense"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Vibration sense"
                                            name="deepsensation"
                                            type="checkbox"
                                            value="Vibration sense"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>


                            </Form.Group>
                        </Col>

                        <Col lg={3}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="deepsensationtextbox"
                                    value={state.deepsensationtextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>

                        {/* <Col lg={6}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">
                Cortical sensation
              </Form.Label>
              <Form.Select
                // required
                name="corticalsensation"
                value={psyoAssessment.corticalsensation}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Two point discrimination">
                  Two point discrimination
                </option>
                <option value="Stereognosis">Stereognosis</option>
              </Form.Select>
            </Form.Group>
          </Col> */}


                        <Col lg={3}>
                            <Form.Group className="">
                                <Form.Label className="form-view-label my-3">
                                    Cortical sensation
                                </Form.Label>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Two point discrimination"
                                            name="corticalsensation"
                                            type="checkbox"
                                            value="Two point discrimination"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col >
                                        <Form.Check
                                            inline
                                            label="Stereognosis"
                                            name="corticalsensation"
                                            type="checkbox"
                                            value="Stereognosis"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>


                            </Form.Group>
                        </Col>


                        <Col lg={9}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="coriticaltextbox"
                                    value={state.coriticaltextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* //================ */}

                    <Col lg={12} className="section-header my-3">
                        Joint Rom and Muscle Strength (Upper Extremity)  <b>IN GRADE
                        </b>
                    </Col>

                    <Row lg={12}>


                        <Row lg={12}>
                            <Col lg={6}>
                                <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                    <Form.Label className="form-view-label">Shoulder</Form.Label>
                                    <Row xs="auto">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="F"
                                                name="shouldergrade"
                                                type="checkbox"
                                                value="F"
                                                id={`inline-checkbox-1`}
                                                onChange={handleChange}
                                            />
                                        </Col>


                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="FRight"
                                                            id={`inline-checkbox-2`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="FLeft"
                                                            id={`inline-checkbox-3`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row xs="auto" className="my-3">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="E"
                                                name="shouldergrade"
                                                type="checkbox"
                                                value="E"
                                                id={`inline-checkbox-4`}
                                                onChange={handleChange}
                                            />
                                        </Col>


                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="ERight"
                                                            id={`inline-checkbox-5`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="ELeft"
                                                            id={`inline-checkbox-6`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>


                                    </Row>

                                    <Row xs="auto" className="my-3">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="Ab"
                                                name="shouldergrade"
                                                type="checkbox"
                                                value="Ab"
                                                id={`inline-checkbox-7`}
                                                onChange={handleChange}
                                            />
                                        </Col>


                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="AbRight"
                                                            id={`inline-checkbox-8`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="AbLeft"
                                                            id={`inline-checkbox-9`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>



                                    </Row>




                                    <Row xs="auto" className="my-3">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="Ad"
                                                name="shouldergrade"
                                                type="checkbox"
                                                value="Ad"
                                                id={`inline-checkbox-10`}
                                                onChange={handleChange}
                                            />
                                        </Col>



                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="AdRight"
                                                            id={`inline-checkbox-11`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="AdLeft"
                                                            id={`inline-checkbox-12`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>




                                    <Row xs="auto" className="my-3">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="Ir"
                                                name="shouldergrade"
                                                type="checkbox"
                                                value="Ir"
                                                id={`inline-checkbox-13`}
                                                onChange={handleChange}
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="IrRight"
                                                            id={`inline-checkbox-14`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="IrLeft"
                                                            id={`inline-checkbox-15`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>







                                    </Row>


                                    <Row xs="auto" className="my-3">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="Er"
                                                name="shouldergrade"
                                                type="checkbox"
                                                value="Er"
                                                id={`inline-checkbox-16`}
                                                onChange={handleChange}
                                            />
                                        </Col>


                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="ErRight"
                                                            id={`inline-checkbox-17`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="shouldergrade"
                                                            type="checkbox"
                                                            value="ErLeft"
                                                            id={`inline-checkbox-18`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className=" mt-1" controlId="formBasicGenderType">
                                    <Form.Label className="form-view-label">Note</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="gradeshouldernote"
                                        value={state.gradeshouldernote}
                                        onChange={handleChangeTextbox}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row lg={12}>
                            <Col lg={6}>
                                <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                    <Form.Label className="form-view-label">Elbow</Form.Label>
                                    <Row xs="auto">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="F/su."
                                                name="gradeelbow"
                                                type="checkbox"
                                                value="F/su."
                                                id={`inline-checkbox-1`}
                                                onChange={handleChange}
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col >
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="gradeelbow"
                                                            type="checkbox"
                                                            value="FRight"
                                                            id={`inline-checkbox-1`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="gradeelbow"
                                                            type="checkbox"
                                                            value="FLeft"
                                                            id={`inline-checkbox-2`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>

                                    </Row>


                                    <Row xs="auto">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="E/pro."
                                                name="gradeelbow"
                                                type="checkbox"
                                                value="E/pro."
                                                id={`inline-checkbox-2`}
                                                onChange={handleChange}
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="gradeelbow"
                                                            type="checkbox"
                                                            value="ERight"
                                                            id={`inline-checkbox-1`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="gradeelbow"
                                                            type="checkbox"
                                                            value="ELeft"
                                                            id={`inline-checkbox-2`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>


                                    </Row>
                                </Form.Group>
                            </Col>

                            {/* <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
              <Form.Select
                // required
                name="gradeextremityelbowmmt"
                value={psyoAssessment.gradeextremityelbowmmt}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Right">Right</option>
                <option value="Left">Left</option>
              </Form.Select>
            </Form.Group>
          </Col> */}
                            <Col lg={6}>
                                <Form.Group className=" mt-1" controlId="formBasicGenderType">
                                    <Form.Label className="form-view-label">Note</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="gradeelbownote"
                                        value={state.gradeelbownote}
                                        onChange={handleChangeTextbox}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row lg={12}>
                            <Col lg={6}>
                                <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                    <Form.Label className="form-view-label">Wrist</Form.Label>
                                    <Row xs="auto">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="F"
                                                name="gradeextremitywrist"
                                                type="checkbox"
                                                value="F"
                                                id={`inline-checkbox-1`}
                                                onChange={handleChange}
                                            />
                                        </Col>


                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="gradeextremitywrist"
                                                            type="checkbox"
                                                            value="FRight"
                                                            id={`inline-checkbox-1`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="gradeextremitywrist"
                                                            type="checkbox"
                                                            value="FLeft"
                                                            id={`inline-checkbox-2`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row xs="auto">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="E"
                                                name="gradeextremitywrist"
                                                type="checkbox"
                                                value="E"
                                                id={`inline-checkbox-2`}
                                                onChange={handleChange}
                                            />
                                        </Col>


                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="gradeextremitywrist"
                                                            type="checkbox"
                                                            value="ERight"
                                                            id={`inline-checkbox-1`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="gradeextremitywrist"
                                                            type="checkbox"
                                                            value="ELeft"
                                                            id={`inline-checkbox-2`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row xs="auto">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="Ab"
                                                name="gradeextremitywrist"
                                                type="checkbox"
                                                value="Ab"
                                                id={`inline-checkbox-3`}
                                                onChange={handleChange}
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="gradeextremitywrist"
                                                            type="checkbox"
                                                            value="AbRight"
                                                            id={`inline-checkbox-1`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="gradeextremitywrist"
                                                            type="checkbox"
                                                            value="AbLeft"
                                                            id={`inline-checkbox-2`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row xs="auto">
                                        <Col lg={4}>
                                            <Form.Check
                                                inline
                                                label="Ad"
                                                name="gradeextremitywrist"
                                                type="checkbox"
                                                value="Ad"
                                                id={`inline-checkbox-3`}
                                                onChange={handleChange}
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Group className="">


                                                <Row xs="auto" className="">
                                                    <Col>
                                                        <Form.Check
                                                            inline
                                                            label="Right"
                                                            name="gradeextremitywrist"
                                                            type="checkbox"
                                                            value="AdRight"
                                                            id={`inline-checkbox-1`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                    <Col className="">
                                                        <Form.Check
                                                            inline
                                                            label="Left"
                                                            name="gradeextremitywrist"
                                                            type="checkbox"
                                                            value="AdLeft"
                                                            id={`inline-checkbox-2`}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Form.Group>
                            </Col>


                            <Col lg={6}>
                                <Form.Group className=" mt-1" controlId="formBasicGenderType">
                                    <Form.Label className="form-view-label">Note</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="gradewristtextbox"
                                        value={state.gradewristtextbox}
                                        onChange={handleChangeTextbox}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                    </Row>

                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Hip</Form.Label>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="F"
                                            name="gradeupperextremityhip"
                                            type="checkbox"
                                            value="F1"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col>
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="F1Right"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="F1Left"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>


                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="E"
                                            name="gradeupperextremityhip"
                                            type="checkbox"
                                            value="E1"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col>
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="E1Right"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="E1Left"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Ab"
                                            name="gradeupperextremityhip"
                                            type="checkbox"
                                            value="Ab1"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col>
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="Ab1Right"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="Ab1Left"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Ad"
                                            name="gradeupperextremityhip"
                                            type="checkbox"
                                            value="Ad1"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col>
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="Ad1Right"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="Ad1Left"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Ir"
                                            name="gradeupperextremityhip"
                                            type="checkbox"
                                            value="Ir1"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col>
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="Ir1Right"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="Ir1Left"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Er"
                                            name="gradeupperextremityhip"
                                            type="checkbox"
                                            value="Er1"
                                            id={`inline-checkbox-3`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col>
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="Fr1Right"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="gradeupperextremityhip"
                                                        type="checkbox"
                                                        value="Fr1Left"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className=" mt-1" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="gradelowerhiptextboxes"
                                    value={state.gradelowerhiptextboxes}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>




                    {/* //============= */}


                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Knee</Form.Label>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="F"
                                            name="gradeupperextremityknee"
                                            type="checkbox"
                                            value="F1"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="gradeupperextremityknee"
                                                        type="checkbox"
                                                        value="F1Right"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="gradeupperextremityknee"
                                                        type="checkbox"
                                                        value="F1Left"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="E"
                                            name="gradeupperextremityknee"
                                            type="checkbox"
                                            value="E1"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="gradeupperextremityknee"
                                                        type="checkbox"
                                                        value="E1Right"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="gradeupperextremityknee"
                                                        type="checkbox"
                                                        value="E1Left"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>



                        <Col lg={6}>
                            <Form.Group className=" mt-1" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="kneetextboxesgrade"
                                    value={state.kneetextboxesgrade}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>




                    {/* ========================= */}

                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Ankle</Form.Label>
                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Df./In"
                                            name="gradeupperextremityankle"
                                            type="checkbox"
                                            value="Df./In1"
                                            id={`inline-checkbox-1`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="gradeupperextremityankle"
                                                        type="checkbox"
                                                        value="Df1Right"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="gradeupperextremityankle"
                                                        type="checkbox"
                                                        value="Df1Left"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>



                                <Row xs="auto">
                                    <Col lg={4}>
                                        <Form.Check
                                            inline
                                            label="Pf./Ev"
                                            name="gradeupperextremityankle"
                                            type="checkbox"
                                            value="Pf./Ev1"
                                            id={`inline-checkbox-2`}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="">


                                            <Row xs="auto" className="">
                                                <Col className="mx-4">
                                                    <Form.Check
                                                        inline
                                                        label="Right"
                                                        name="gradeupperextremityankle"
                                                        type="checkbox"
                                                        value="Pf1Right"
                                                        id={`inline-checkbox-1`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col className="">
                                                    <Form.Check
                                                        inline
                                                        label="Left"
                                                        name="gradeupperextremityankle"
                                                        type="checkbox"
                                                        value="Pf1Left"
                                                        id={`inline-checkbox-2`}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>

                        {/* <Col lg={3}>
            <Form.Group className=" mt-2" controlId="formBasicGenderType">
              <Form.Label className="form-view-label">M.M.T (grade)</Form.Label>
              <Form.Select
                name="upperextremityanklemmt"
                value={psyoAssessment.upperextremityanklemmt}
                onChange={handleChange}
              >
                <option>--Select--</option>
                <option value="Right">Right</option>
                <option value="Left">Left</option>
              </Form.Select>
            </Form.Group>
          </Col> */}
                        <Col lg={6}>
                            <Form.Group className=" mt-2" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="gradeancletextbox"
                                    value={state.gradeancletextbox}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>






                    <Col lg={12} className="section-header" style={{ marginTop: '60px' }}>
                        <b>Physio Therapy Suggestive Program</b>
                    </Col>

                    <table>
                            <tbody>
                                {tableData?.map((row, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: "10px", width: '800px' }}>
                                            <input
                                                type="text"
                                                name="recommendation"
                                                className="form-control"
                                                value={row.value}
                                                onChange={(e) => updateValue(index, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <Button onClick={() => deleteRow(index)}>Delete</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Button onClick={addRow} className="mx-3">Add Row</Button>
                    {/* <Col lg={12} >
                            <Form.Group className=" mt-1" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Note</Form.Label>
                                <Form.Control
                                    rows='9'
                                    as="textarea"
                                    name="suggestivenote"
                                    value={state.suggestivenote}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col> */}


                    <Row>
                        <Col lg={6} className="mx-3">
                            <Form.Group className=" mt-3" controlId="formBasicGenderType">
                                <Form.Label className="form-view-label">Professional's Signature</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="teachersignature"
                                    value={state.teachersignature}
                                    onChange={handleChangeTextbox}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div >

                <div className="App container mt-5" style={{ display: 'none' }}>
                    <div id="physiopdfshow">
                        <PhysioAssessmentPdf psyoAssessment={psyoAssessment} state={state} studentdatainfo={studentdatainfo} studentData={studentData} />
                    </div>
                </div>

            </Container>


        </>
    );
};

export default PhysioAssessment;
