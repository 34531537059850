import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import authApi from "../api/authApi";
import Badge from 'react-bootstrap/Badge';

const Header = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token"))
        navigate("/login");
  }, []);

  const [sidebar, setSidebar] = useState(false);
  const logout = () => {
    authApi.logout();
    navigate("/login");
  };

  const toggleSidebar = () => {
    if(sidebar === false){
      setSidebar(true);
      console.log(sidebar);
    document.querySelector("#sidebar").classList.toggle("active");

    }else{
      setSidebar(false);
      console.log(sidebar);
      document.querySelector("#sidebar").classList.toggle("active");
    }
    
  };
  return (
    <>
      <Navbar className="header px-2" bg="" expand="lg" variant="">
        <button
          type="button"
          id="sidebarCollapse"
          className="btn btn-info"
          onClick={toggleSidebar}
         
        >
          <i className="fas fa-align-left"></i>
        </button>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <img src={localStorage.getItem("myimage")} className="rounded-circle" style={{height: "30px"}}/> 
            <Nav.Link href="#">{localStorage.getItem("username")}  <Badge pill bg="success">
            {localStorage.getItem("userrole")}
      </Badge>{' '}</Nav.Link>
          </Nav>

          <Nav className="ml-auto">
            <Nav.Link href="/about">
              <i className="fa-solid fa-bell"></i>
            </Nav.Link>
            {localStorage.getItem("token") ? (
              <Button variant="btn btn-primary" onClick={logout} title="Logout">
                <i className="fa-solid fa-right-from-bracket"></i>
              </Button>
            ) : (
              <></>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
