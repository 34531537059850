import React, { useEffect, useState } from 'react'
import {  Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Pagination,
    TableBody,
    TableHeader,
  } from 'react-bs-datatable';

const RelatedListQuestions = (props) => {

    const[body, setBody] = useState([]);

    useEffect(()=>{
        console.log('question props =>',props)
        let tempBody = props?.questions?.map((data, index) => ({ ...data, index: index + 1 }));
        setBody(tempBody);
    },[])

    const header = [
       
        { title: "Questions", prop: "question", isFilterable: true },
        { title: "Category", prop: "category", isFilterable: true },
        { title: "Section", prop: "section", isFilterable: true },

      
      ];

  return (
    <>

      {body ? <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
        
      }}>
        <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
        <Pagination />
      </DatatableWrapper>: 'No records found!'}
    </>
  )
}

export default RelatedListQuestions
