import * as constants from '../constants/CONSTANT';
const authApi = {
    async login(email, password) {
        let response = await fetch(constants.API_BASE_URL + "/api/auth/login", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        const result = await response.json();
        console.log(response)
        console.log('login', result);
        if (result.success) {
            console.log('permissions', result.permissions)
            localStorage.setItem("token", result.authToken);
            localStorage.setItem("userrole", result.userrole);
            localStorage.setItem("servicearea", result.servicearea);
            localStorage.setItem("username",result.username)
            const arrayPermissions =  result.permissions;
            //const arrayPermissions= [{ name: 'EDIT_STUDENT' }, { name: 'DELETE_STUDENT' }, { name: 'VIEW_SERVICE' },{ name: 'VIEW_STUDENT' },{name : 'EDIT_SERVICE'}];
            console.log('ARRAY Permissions',arrayPermissions)
            var string = arrayPermissions.map(function (obj) {
                return obj.name;
            }).join(';')
            console.log(string)
           if(string.length >0){
          
            localStorage.setItem("permissions", string);
            console.log('local Storage', localStorage);
           }
            

            
        }
        return result;
    },

    async fetchRoles() {
        const token = localStorage.getItem("token");
        console.log(token)
        let response = await fetch(constants.API_BASE_URL + "/api/roles", {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
            // 'Content-Type': 'application/x-www-form-urlencoded',
          }
    
        });
        const result = await response.json();
        console.log(result)
        if (result.length > 0) {
          return result;
        }
        return null;
    
      },
    
      async createRoles(roles) {
        const token = localStorage.getItem("token");
        let response = await fetch(constants.API_BASE_URL + "/api/roles", 
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify(roles),
    
        });
        
        return await response.json();
    
      },
    
      async fetchMyImage() {
        //console.log("calling my image ");
        const token = localStorage.getItem("token");
        let response = await fetch(
          constants.API_BASE_URL + "/api/auth/myimage",
          {
            method: "GET",
            //mode: "cors",
    
            headers: {
              "Authorization": token
            }
          }
        );
        //console.log('response:', response);
        if(response.status === 200){
          const fileBody = await response.blob();
          return fileBody;
        }else{
          return null;
        }
      },



      async saveRole(roles) {
        const token = localStorage.getItem("token");
        let response = await fetch(
          constants.API_BASE_URL + "/api/roles",
          {
            method: "PUT",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Authorization": token,
            },
            body: JSON.stringify(roles),
          }
        );
        return await response.json();
    
      },
    
      async deleteRole(id) {
        const token = localStorage.getItem("token");
        let response = await fetch(
          constants.API_BASE_URL + "/api/roles/" + id,
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Authorization": token,
            }
          }
        );
        return await response.json();
      },
       //************************ Permission ***********************************//
      
       async fetchPermissions() {
        const token = localStorage.getItem("token");
        console.log(token)
        let response = await fetch(constants.API_BASE_URL + "/api/permissions", {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
            // 'Content-Type': 'application/x-www-form-urlencoded',
          }
    
        });
        const result = await response.json();
        console.log(result)
        if (result.length > 0) {
          return result;
        }
        return null;
    
      },
    
      async createPermissions(permissions) {
        const token = localStorage.getItem("token");
        let response = await fetch(constants.API_BASE_URL + "/api/permissions", 
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify(permissions),
    
        });
        
        return await response.json();
    
      },
    
      async savePermission(permissions) {
        const token = localStorage.getItem("token");
        let response = await fetch(
          constants.API_BASE_URL + "/api/permissions",
          {
            method: "PUT",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Authorization": token,
            },
            body: JSON.stringify(permissions),
          }
        );
    
        return await response.json();
    
      },
    
      async deletePermission(id) {
        const token = localStorage.getItem("token");
        let response = await fetch(
          constants.API_BASE_URL + "/api/permissions/" + id,
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Authorization": token,
            }
          }
        );
    
        return await response.json();
    
      },
       //************************ Roles And Permissions ***********************************//
      
       async fetchRolesAndPermissions() {
        const token = localStorage.getItem("token");
        console.log(token)
        let response = await fetch(constants.API_BASE_URL + "/api/rolesandpermissions", {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
            // 'Content-Type': 'application/x-www-form-urlencoded',
          }
    
        });
        const result = await response.json();
        console.log(result)
        if (result.length > 0) {
          return result;
        }
        return null;
    
      },
      async fetchRolesAndPermissionsByRoleId(id) {
      const token = localStorage.getItem("token");
        let response = await fetch(
          constants.API_BASE_URL + "/api/rolesandpermissions/" + id,
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Authorization": token,
            }
          }
        );
        let result = await response.json();
        console.log(result)
        if(!Array.isArray(result)){
          result = [result]
        }
        if (result.length > 0) {
          return result;
        }
        return null;
      },
      async createRolesPermissions(permissions) {
        const token = localStorage.getItem("token");
        let response = await fetch(constants.API_BASE_URL + "/api/rolesandpermissions", 
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify(permissions),
    
        });
        
        return await response.json();
    
      },
    
    
    
      async deleteRolesPermissions(rolesspermissionsids) {
        const token = localStorage.getItem("token");
        let response = await fetch(
          constants.API_BASE_URL + "/api/rolesandpermissions",
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              "Authorization": token,
            },
            body: JSON.stringify(rolesspermissionsids)
          }
        );
    
        return await response.json();
    
      },

    logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("permissions");
        localStorage.removeItem("userrole");
        window.location.href='/login';
    },
}

export default authApi
