import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import PubSub from "pubsub-js";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import html2pdf from 'html2pdf.js';

import * as constants from "../constants/CONSTANT";
import { SpeechHearingPdf } from "./SpeechHearingPdf";
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";

const SpeechHearingEdit = () => {
    const [studentlist, setstudentlist] = useState([]);

    useEffect(() => {
        setisloading(true)
        const fetchData = async () => {
            try {
                const result = await inventoryApi.fetchStudents();
                console.log(result);
                if (result && result.length > 0) {
                    setisloading(false)
                    const ar = result.map((item) => ({
                        value: item.id,
                        label: item.name
                    }));
                    setOptionUsers(ar);
                    setstudentlist(result);
                } else {
                    setOptionUsers([]);
                    setstudentlist([]);
                }
            } catch (error) {
                console.error('Error fetching students:', error);
            }
        };

        fetchData();

    }, []);



    const location = useLocation();
    const [locationData, setLocationData] = useState(location.state ? location.state : {});
    console.log('locationData', locationData);

    const navigate = useNavigate();
    const [checkedValues, setCheckedValues] = useState([]);
    const [parentalperception, setParentalperception] = useState([]);
    const [auditorybehaviour, setAuditorybehaviour] = useState([]);
    const [motordevelopement, setMotordevelopement] = useState([]);
    const [educationhistory, setEducationhistory] = useState([]);
    const [socialcognativeparentalperception, setSocialcognativeparentalperception] = useState([]);
    const [handnessplaywithtoy, setHandnessplaywithtoy] = useState([]);
    const [handnesswriting, setHandnesswriting] = useState([]);
    const [handnesseating, setHandnesseating] = useState([]);
    const [handnessgrasp, setHandnessgrasp] = useState([]);
    const [vocProfile, setVocProfile] = useState({});
    const [formEnable, setFormEnable] = useState(true);
    const [editForm, setEditForm] = useState(false);
    const [speechData, setSpeechData] = useState({});
    console.log('speechData', speechData)
    const [validated, setValidated] = useState(false);
    const [isloading, setisloading] = useState(false);

    const [selectedUser, setSelectedUser] = useState('');
    const [option, setoption] = useState();
    const [optionUsers, setOptionUsers] = useState([]);

    const pdfShow = () => {
        const pdfTable = document.getElementById("divToPrint");
        //console.log("pdf table", pdfTable);
        var html = htmlToPdfmake(pdfTable.innerHTML, {
            tableAutoSize: true,
        });
        console.log('html', html)
        const documentDefinition = {
            content: html, defaultStyle: {
                font: 'arialunicodems1'
            }
        };
        pdfMake.fonts = {
            arialunicodems1: {
                normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
                bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
            },


            // download default Roboto font from cdnjs.com
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            }
        }
        //console.log(pdfMake.vfs)

        pdfMake.createPdf(documentDefinition).open();


    }


    const fetchAllVocAnaly = async (locationData) => {
        console.log("fetchAllVocAnaly")
        let result = await inventoryApi.fetchspeechhearingById(locationData);
        console.log('result', result);
        if (result.recommendation) {
         
            setTableData(result.recommendation);

        }

        let tempValue = {};
        tempValue.value = result.casename;
        tempValue.label = result.childname;
        setoption(tempValue);

        let checkedValuesArray = result.purposevisiting?.split(", ");
        let parentalperceptionArray = result.parentalperception?.split(", ");
        let auditorybehaviourArray = result.auditorybehaviour?.split(", ");
        let motordevelopementArray = result.motordevelopement?.split(", ");
        let educationhistoryArray = result.educationhistory?.split(", ");
        let socialcognativeparentalperceptionArray = result.socialcognativeparentalperception?.split(", ");
        let handnessplaywithtoyArray = result.handnessplaywithtoy?.split(", ");
        let handnesswritingArray = result.handnesswriting?.split(", ");
        let handnesseatingArray = result.handnesseating?.split(", ");
        let handnessgraspArray = result.handnessgrasp?.split(", ");

        let checkboxes = document.querySelectorAll('input[type="checkbox"]');



        checkboxes.forEach((checkbox) => {
            if (checkbox.name === 'purposevisiting') {
                let checkboxValue = checkbox.value;
                if (checkedValuesArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setCheckedValues((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in checkedValuesArray");
                }
            }
            if (checkbox.name === 'parentalperception') {
                let checkboxValue = checkbox.value;
                if (parentalperceptionArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setParentalperception((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in checkedValuesArray");
                }
            }


            if (checkbox.name === 'auditorybehaviour') {
                let checkboxValue = checkbox.value;
                if (auditorybehaviourArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setAuditorybehaviour((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in checkedValuesArray");
                }
            }

            if (checkbox.name === 'motordevelopement') {
                let checkboxValue = checkbox.value;
                if (motordevelopementArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setMotordevelopement((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in checkedValuesArray");
                }
            }


            if (checkbox.name === 'handnessgrasp') {
                let checkboxValue = checkbox.value;
                if (handnessgraspArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setHandnessgrasp((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in checkedValuesArray");
                }
            }


            if (checkbox.name === 'handnesseating') {
                let checkboxValue = checkbox.value;
                if (handnesseatingArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setHandnesseating((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in checkedValuesArray");
                }
            }

            if (checkbox.name === 'handnesswriting') {
                let checkboxValue = checkbox.value;
                if (handnesswritingArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setHandnesswriting((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in checkedValuesArray");
                }
            }

            if (checkbox.name === 'handnessplaywithtoy') {
                let checkboxValue = checkbox.value;
                if (handnessplaywithtoyArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setHandnessplaywithtoy((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in checkedValuesArray");
                }
            }


            if (checkbox.name === 'socialcognativeparentalperception') {
                let checkboxValue = checkbox.value;
                if (socialcognativeparentalperceptionArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setSocialcognativeparentalperception((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in checkedValuesArray");
                }
            }


            if (checkbox.name === 'educationhistory') {
                let checkboxValue = checkbox.value;
                if (educationhistoryArray?.includes(checkboxValue)) {
                    checkbox.checked = true;
                    setEducationhistory((prevState) => [...prevState, checkboxValue]);

                    console.log("Checkbox value", checkboxValue, "is included in checkedValuesArray");
                }
            }


        });


        setSpeechData(result);
    }




    // ===============>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>===============>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    useEffect(() => {

        if (locationData) {
            console.log('if use effect call')
            fetchAllVocAnaly(locationData);
        }

    }, []);


    const handleUsers = async (event) => {
        console.log('event', event)
        let result = await inventoryApi.fetchStudentById(event.value);
        console.log("result of Student>>", result);
        speechData.age = result.childregistrationage
        speechData.dob = result.dob
        speechData.address = result.landmark
        speechData.childname = result.name
        speechData.refferby = result.referby
        speechData.fathername = result.fathername
        setoption(event)
        setSelectedUser(event)
        setSpeechData({ ...speechData, casename: event.value, childname: event.label });
    }

    const handleChange = async (e) => {
        console.log(e.target.name, e.target.value)
        // if(e.target.name === 'casename'){
        //     console.log('if call')
        //     let result = await inventoryApi.fetchStudentById(e.target.value);
        //     // ----------------------------------------------------------------------------------- WORKING
        //     console.log("result of Student>>", result);
        //     speechData.age = result.childregistrationage
        //     speechData.dob = result.dob
        //     speechData.address = result.landmark
        //     speechData.childname = result.name
        // }
        if (e.target.name === "purposevisiting") {
            console.log('first call')
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...checkedValues, value];
            } else {
                updatedCheckedValues = checkedValues.filter((item) => item !== value);
            }

            setCheckedValues(updatedCheckedValues);
        }

        if (e.target.name === "parentalperception") {
            console.log('call', e.target)
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...parentalperception, value];
            } else {
                updatedCheckedValues = parentalperception.filter((item) => item !== value);
            }
            console.log('updatedCheckedValues', e.target)
            setParentalperception(updatedCheckedValues);
        }

        if (e.target.name === "auditorybehaviour") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...auditorybehaviour, value];
            } else {
                updatedCheckedValues = auditorybehaviour.filter((item) => item !== value);
            }
            console.log('auditory', updatedCheckedValues)
            setAuditorybehaviour(updatedCheckedValues);
        }

        if (e.target.name === "motordevelopement") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...motordevelopement, value];
            } else {
                updatedCheckedValues = motordevelopement.filter((item) => item !== value);
            }
            console.log('updatedCheckedValues', updatedCheckedValues)
            setMotordevelopement(updatedCheckedValues);
        }

        if (e.target.name === "handnessgrasp") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...handnessgrasp, value];
            } else {
                updatedCheckedValues = handnessgrasp.filter((item) => item !== value);
            }
            setHandnessgrasp(updatedCheckedValues);
        }

        if (e.target.name === "handnesseating") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...handnesseating, value];
            } else {
                updatedCheckedValues = handnesseating.filter((item) => item !== value);
            }
            console.log(' eating updatedCheckedValues',updatedCheckedValues)
            setHandnesseating(updatedCheckedValues);
        }

        if (e.target.name === "handnesswriting") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...handnesswriting, value];
            } else {
                updatedCheckedValues = handnesswriting.filter((item) => item !== value);
            }
            setHandnesswriting(updatedCheckedValues);
        }

        if (e.target.name === "handnessplaywithtoy") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...handnessplaywithtoy, value];
            } else {
                updatedCheckedValues = handnessplaywithtoy.filter((item) => item !== value);
            }
            setHandnessplaywithtoy(updatedCheckedValues);
        }

        if (e.target.name === "socialcognativeparentalperception") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...socialcognativeparentalperception, value];
            } else {
                updatedCheckedValues = socialcognativeparentalperception.filter((item) => item !== value);
            }
            setSocialcognativeparentalperception(updatedCheckedValues);
        }

        if (e.target.name === "educationhistory") {
            const { value, checked } = e.target;
            let updatedCheckedValues;

            if (checked) {
                updatedCheckedValues = [...educationhistory, value];
            } else {
                updatedCheckedValues = educationhistory.filter((item) => item !== value);
            }
            setEducationhistory(updatedCheckedValues);
        }
        setSpeechData({ ...speechData, [e.target.name]: e.target.value });
    };

    const checkedValuesString = checkedValues.join(", ");
    const parentalperceptionvalue = parentalperception.join(", ");
    const auditorybehaviourvalue = auditorybehaviour.join(", ");
    const motordevelopementvalue = motordevelopement.join(", ");
    const educationhistoryvalue = educationhistory.join(", ");
    const socialcognativeparentalperceptionvalue = socialcognativeparentalperception.join(", ");
    const handnessplaywithtoyvalue = handnessplaywithtoy.join(", ");
    const handnesswritingvalue = handnesswriting.join(", ");
    const handnesseatingvalue = handnesseating.join(", ");
    const handnessgraspvalue = handnessgrasp.join(", ");

    console.log("checkedValuesString", checkedValuesString);
    console.log("auditorybehaviourvalue", auditorybehaviourvalue);

    console.log(speechData);


    const handleCancel = () => {
        navigate(`/speechhearings`);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        speechData.purposevisiting = checkedValuesString;
        console.log(parentalperceptionvalue)
        console.log('auditorybehaviourvalue', auditorybehaviourvalue)
        speechData.parentalperception = parentalperceptionvalue;
        speechData.auditorybehaviour = auditorybehaviourvalue;
        speechData.motordevelopement = motordevelopementvalue;
        speechData.educationhistory = educationhistoryvalue;
        speechData.socialcognativeparentalperception =
            socialcognativeparentalperceptionvalue;
        speechData.handnessplaywithtoy = handnessplaywithtoyvalue;
        speechData.handnesswriting = handnesswritingvalue;
        console.log('eatingbro',handnesseatingvalue)
        speechData.handnesseating = handnesseatingvalue;
        speechData.handnessgrasp = handnessgraspvalue;
        speechData.recommendation = tableData
        let result = {};
        if (speechData.id) {
            delete speechData.message
            delete speechData.success
            result = await inventoryApi.saveSpeechHearing(speechData);
            navigate(`/speechhearings`);
        } else {
            console.log('speechData', speechData)
            delete speechData.message
            result = await inventoryApi.createspeechhearing(speechData);
            if (result) {
                console.log("Succesfully");
                navigate(`/speechhearings`);
            }
        }
    };



    let index = 0;

    const [tableData, setTableData] = useState([{ value: '' }]);

    const addRow = () => {
        const newRow = { id: Date.now(), value: '' };
        setTableData((prevTableData) => (prevTableData ? [...prevTableData, newRow] : [newRow]));
    };

    console.log('tabledata',tableData)
    const deleteRow = (index) => {
        setTableData((prevTableData) => prevTableData.filter((_, i) => i !== index));
    };

    const updateValue = (index, newValue) => {
        setTableData((prevTableData) =>
            prevTableData
                ? prevTableData.map((row, i) => (i === index ? { ...row, value: newValue } : row))
                : []
        );
    };
    return (
        <>

            <Row>
                <Col lg={12} className="mx-3">
                    <Form
                        className="mt-3"
                        onSubmit={handleSubmit}
                        noValidate
                        validated={validated}
                    >
                        <Row className="view-form-header align-items-center">
                            <Col lg={6}> Speech Hearing Assessment </Col>
                            <Col lg={6} className="d-flex justify-content-end">
                                <Button
                                    className="btn-sm mx-2"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    {" "}
                                    Save{" "}
                                </Button>
                                <Button
                                    className="btn-sm mx-2"
                                    type="submit"
                                    onClick={pdfShow}
                                >
                                    {" "}
                                    PDF{" "}
                                </Button>
                                <Button
                                    className="btn-sm"
                                    variant="danger"
                                    onClick={handleCancel}
                                >
                                    {" "}
                                    Cancel{" "}
                                </Button>
                            </Col>

                        </Row>
                    </Form>
                </Col>
            </Row>
            <div id="pdfcontainer">
                <Container id="marbt">
                    {/* <div className="section-header my-3">
                        <b className="align-item-center" style={{ marginLeft: "36%" }}> Rajasthan Mahila Kalyan Mandal (RMKM)<br></br></b>
                        <b className="align-item-center" style={{ marginLeft: "38%" }}>Speech And Hearing Assessment </b>
                    </div> */}

                    <Col lg={12} className="section-header my-2 mx-2">
                        <Form.Group className="mx-3 my-2" controlId="formBasicname">
                            {/* <Form.Label className="form-view-label">
                Date and Place of screening:
              </Form.Label> */}
                            <p>
                                Minu Manovikas Mandir Inclusive School Village - Chachiyawas Sikar
                                Road Distt Ajmer 305023<br></br>"Sanjay Inclusive School"Seth
                                Ghishu Lal Munot ColonyOut Side Nehru Gate,Beawar Distt,Ajmer
                                <br></br>"ADWAIT" An Early Intervention Center Early Intervention
                                Centre C Block,Panchsheel Nagar Near Kshetrapal Hospital Makdwali
                                Road,Ajmer<br></br>Ummeed Day Care Centre C/O Old Rangji
                                Temple,Pushkar - 305001
                            </p>
                        </Form.Group>
                    </Col>
                    {/* <Col lg={12} className="section-header my-3 mx-2">
                    SPEECH & HEARING UNIT(GENERAL CASE HISTORY PERFORMA)
                </Col> */}
                    <Col lg={12} className="section-header my-3 mx-2">
                        PART-1 CASE PARTICULARS:
                    </Col>
                    <Row lg={12}>
                        {optionUsers.length > 0 ?

                            <Col lg={6}>
                                <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                    <Form.Label className="form-view-label">Case Name</Form.Label>
                                    <Select
                                        name="casename"
                                        value={option}
                                        onChange={handleUsers}
                                        options={optionUsers}
                                        getOptionValue={(option) => option.value}
                                    />
                                </Form.Group>
                            </Col> : ''}
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Age</Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="age"
                                    //placeholder="age"
                                    value={speechData.age}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Case No:</Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="caseno"
                                    //placeholder="Caseno"
                                    value={speechData.caseno}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicDate">
                                <Form.Label className="form-view-label">Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="date"
                                    value={moment(speechData.date).format("YYYY-MM-DD")}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Information</Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="imformation"
                                    //placeholder="information"
                                    value={speechData.imformation}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicDate">
                                <Form.Label className="form-view-label">Date of Birth</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="dob"
                                    value={moment(speechData.dob).format("YYYY-MM-DD")}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Referred By:</Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="referby"
                                    //placeholder="raferredby"
                                    value={speechData.referby}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        {/* <Col lg={2}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">Age</Form.Label>
                            <Form.Control
                                // required
                                type="age"
                                name="fatherguardianoccupaddr"
                            //placeholder="age"
                                value={speechData.age}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">Year</Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="year"
                                //placeholder="year"
                                value={speechData.year}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={2}>
                        <Form.Group className="mx-4 my-2" controlId="formBasicname">
                            <Form.Label className="form-view-label">Month</Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="month"
                                //placeholder="month"
                                value={speechData.month}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col> */}
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Address:</Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="address"
                                    //placeholder="address"
                                    value={speechData.address}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Phone:</Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="phoneno"
                                    //placeholder="phoneno"
                                    value={speechData.phoneno}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Village/House No:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="village"
                                    //placeholder="entervillage/houseno"
                                    value={speechData.village}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicServiceArea">
                                <Form.Label className="form-view-label">Place</Form.Label>
                                <Form.Select
                                    // required
                                    // aria-label="Enter Service Area"
                                    name="place"
                                    value={speechData.place}
                                    onChange={handleChange}
                                >
                                    <select
                                        onChange={handleChange}
                                        className="form-select"
                                    ></select>
                                    <option>--Select--</option>
                                    {/* <option value="Day Care">Day Care </option> */}
                                    <option value="Rural">Rural </option>
                                    <option value="Urban">Urban</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={2}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">P.O</Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="po"
                                    //placeholder="p.o"
                                    value={speechData.po}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={2}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">P.S</Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="ps"
                                    //placeholder="p.s"
                                    value={speechData.ps}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={2}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Dist.</Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="dist"
                                    //placeholder="dist"
                                    value={speechData.dist}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicServiceArea">
                                <Form.Label className="form-view-label">Community:</Form.Label>
                                <Form.Select
                                    // required
                                    // aria-label="Enter Service Area"
                                    name="community"
                                    value={speechData.community}
                                    onChange={handleChange}
                                >
                                    <select
                                        onChange={handleChange}
                                        className="form-select"
                                    ></select>
                                    <option>--Select--</option>
                                    {/* <option value="Day Care">Day Care </option> */}
                                    <option value="SC">SC </option>
                                    <option value="ST">ST</option>
                                    <option value="OBC">OBC </option>
                                    <option value="GEN">GEN</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">PIN:</Form.Label>
                                <Form.Control
                                    // required
                                    type="numeric"
                                    name="pincode"
                                    //placeholder="enterpin"
                                    value={speechData.pincode}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row lg={12}>
                        <Col lg={4}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Mother Tongue:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="motherto"
                                    //placeholder="motherto"
                                    value={speechData.motherto}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Language Know:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="languageknow"
                                    //placeholder="languageknow"
                                    value={speechData.languageknow}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">Religion:</Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="religion"
                                    //placeholder="religion"
                                    value={speechData.religion}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row lg={12}>
                        <Col lg={12} className="mx-2 my-2">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Parental Information</th>
                                        <th style={{ width: "20px" }}></th>
                                        <th>Name</th>
                                        <th style={{ width: "20px" }}></th>
                                        <th>Age</th>
                                        <th style={{ width: "20px" }}></th>
                                        <th>Education</th>
                                        <th style={{ width: "20px" }}></th>
                                        <th>Occupation</th>
                                        <th style={{ width: "20px" }}></th>
                                        <th>Income Slab</th>
                                        <th style={{ width: "20px" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Mother</td>
                                        <td>{ }</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={speechData.mothername}
                                                className="form-control"
                                                name="mothername"
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>{ }</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={speechData.motherage}
                                                className="form-control"
                                                name="motherage"
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>{ }</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={speechData.mothereducation}
                                                className="form-control"
                                                name="mothereducation"
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>{ }</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={speechData.motherocc}
                                                className="form-control"
                                                name="motherocc"
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>{ }</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={speechData.motherincome}
                                                className="form-control"
                                                name="motherincome"
                                                onChange={handleChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>Father</td>
                                        <td>{ }</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={speechData.fathername}
                                                className="form-control"
                                                name="fathername"
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>{ }</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={speechData.fatherage}
                                                className="form-control"
                                                name="fatherage"
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>{ }</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={speechData.fathereducation}
                                                className="form-control"
                                                name="fathereducation"
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>{ }</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={speechData.fatherocc}
                                                className="form-control"
                                                name="fatherocc"
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>{ }</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={speechData.fatherincome}
                                                className="form-control"
                                                name="fatherincome"
                                                onChange={handleChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="mx-4" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Consanguinity: (Specify relationship)
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="consan"
                                    //placeholder="consan"
                                    value={speechData.consan}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Family History: Nuclear /Joint Family
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="familyhistory"
                                    //placeholder="Family History"
                                    value={speechData.familyhistory}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    General complaint:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="generalcomplaint"
                                    //placeholder="generalComplaint"
                                    value={speechData.generalcomplaint}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group className="mx-4" controlId="formBasicname">
                                <Form.Label className="form-view-label">Pedigree</Form.Label>
                                {/* <Form.Control
                                // required
                                type="text"
                                name="padigree"
                                
                                value={speechData.padigree}
                                onChange={handleChange}
                            /> */}
                                <Alert variant="danger">Please Upload (Pedigree Chart) in related file section on casehistory</Alert>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Col lg={12} className="section-header mx-2">
                        Purpose of Visiting:
                    </Col>
                    <Row lg={12} className="mx-4">
                        <Col lg={4}>
                            <Form.Check
                                inline
                                label="Hearing Evaluation"
                                name="purposevisiting"
                                type="checkbox"
                                value="Hearing Evaluation"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={4}>
                            <Form.Check
                                inline
                                label="Hearing Aid Fitting"
                                name="purposevisiting"
                                type="checkbox"
                                value="Hearing Aid Fitting"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={4}>
                            <Form.Check
                                inline
                                label="Speech and Language Assessment & Therapy"
                                name="purposevisiting"
                                type="checkbox"
                                value="Speech and Language Assessment & Therapy"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={4}>
                            <Form.Check
                                inline
                                label="Ear Mould"
                                name="purposevisiting"
                                type="checkbox"
                                value="Ear Mould"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={4}>
                            <Form.Check
                                inline
                                label="Repair of Hearing Aid"
                                name="purposevisiting"
                                type="checkbox"
                                value="Repair of Hearing Aid"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={4}>
                            <Form.Check
                                inline
                                label="Voice Therapy"
                                name="purposevisiting"
                                type="checkbox"
                                value="Voice Therapy"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={4}>
                            <Form.Check
                                inline
                                label="Fluency Therapy"
                                name="purposevisiting"
                                type="checkbox"
                                value="Fluency Therapy"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={4}>
                            <Form.Check
                                inline
                                label="Eating & Swallowing"
                                name="purposevisiting"
                                type="checkbox"
                                value="Eating & Swallowing"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={4}>
                            <Form.Check
                                inline
                                label="Early Intervention"
                                name="purposevisiting"
                                type="checkbox"
                                value="Early Intervention"
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Age at which hearing/speech-language difficulty was noticed:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="agediffculty"
                                    //placeholder="agediffculty"
                                    value={speechData.agediffculty}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Earlier treatment/Investigation/Hearing aid used:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="earliertreatment"
                                    //placeholder="earliertreatment"
                                    value={speechData.earliertreatment}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Probable cause of problem:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="probablecause"
                                    //placeholder="probablecause"
                                    value={speechData.probablecause}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Progress of problem: Static/Improving/Deteriorating:
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="progressproblem"
                                    //placeholder="progressproblem"
                                    value={speechData.progressproblem}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mx-4 my-2" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Amount of quality time spend with the child (Hours)
                                </Form.Label>
                                <Form.Control
                                    // required
                                    type="text"
                                    name="amountquality" //amountquality
                                    //placeholder="amountquality"
                                    value={speechData.amountquality}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Col lg={12} className="section-header my-3 mx-2">
                        MEDICAL HISTORY:
                    </Col>
                    <Col lg={12} className="section-header my-3 mx-2">
                        PART-2 DEVELOPMENTAL HISTORY:(LANGUAGE DEVELOPMENT:PARENTAL
                        PERCEPTION)
                    </Col>
                    <Col lg={12} className="my-3 mx-2">
                        <b> LANGUAGE DEVELOPMENT:PARENTAL PERCEPTION-</b>
                    </Col>
                    <Row lg={12} className="mx-4 my-4">
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Age Appropriate"
                                name="parentalperception"
                                type="checkbox"
                                value="Age Appropriate"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Delayed"
                                name="parentalperception"
                                type="checkbox"
                                value="Delayed"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Parental Concern"
                                name="parentalperception"
                                type="checkbox"
                                value="Parental Concern"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Misarticulating"
                                name="parentalperception"
                                type="checkbox"
                                value="Misarticulating"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Fluency"
                                name="parentalperception"
                                type="checkbox"
                                value="Fluency"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Voice/Language Disorder"
                                name="parentalperception"
                                type="checkbox"
                                value="Voice/Language Disorder"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Differential Cry(0-3 Months)"
                                name="parentalperception"
                                type="checkbox"
                                value="Differential Cry(0-3 Months)"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Phrases(around 18 MOnths)"
                                name="parentalperception"
                                type="checkbox"
                                value="Phrases(around 18 MOnths)"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Babbling(3-8 Months)"
                                name="parentalperception"
                                type="checkbox"
                                value="Babbling(3-8 Months)"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Sentences(around 24 Months)"
                                name="parentalperception"
                                type="checkbox"
                                value="Sentences(around 24 Months)"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="First Meaningful word(12-18 Months)"
                                name="parentalperception"
                                type="checkbox"
                                value="First Meaningful word(12-18 Months)"
                                onChange={handleChange}
                            />
                        </Col>

                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Predominant mode of communication"
                                name="parentalperception"
                                type="checkbox"
                                value="Predominant mode of communication"
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    {/* //=================================================== */}
                    <Col lg={6} className="my-3 mx-2">
                        <b> AUDITORY BEHAVIOUR:</b>
                    </Col>
                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="mx-4" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Parents suspect hearing loss-
                                </Form.Label>
                            </Form.Group>
                        </Col>
                        <Col lg={3}>
                            <Form.Check
                                inline
                                label="Yes"
                                name="auditorybehaviour"
                                value="Yes"
                                type="checkbox"
                                onChange={handleChange}
                            />
                            <Form.Check
                                inline
                                label="No"
                                name="auditorybehaviour"
                                value="No"
                                type="checkbox"
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row lg={12} className="mx-4 my-4">
                        {/* <Col lg={6}>1 of 2</Col> */}
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Child awakes/Startles/Cries to loud sound (0-3 months)"
                                name="auditorybehaviour"
                                type="checkbox"
                                value="Child awakes/Startles/Cries to loud sound (0-3 months)"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Responds to 'No',recognizes months voice,turns towards the speaker(3-6 months)"
                                name="auditorybehaviour"
                                type="checkbox"
                                value="Responds to 'No',recognizes months voice,turns towards the speaker(3-6 months)"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Child response to his/her name & turns head to localize source of sound(6-9 months)"
                                name="auditorybehaviour"
                                type="checkbox"
                                value="Child response to his/her name & turns head to localize source of sound(6-9 months)"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Child responds to music by body and head movements(9-12 months)"
                                name="auditorybehaviour"
                                type="checkbox"
                                value="Child responds to music by body and head movements(9-12 months)"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Child can point to several body parts & follow action words: stop,sit,throw(18-20 months)"
                                name="auditorybehaviour"
                                type="checkbox"
                                value="Child can point to several body parts & follow action words: stop,sit,throw(18-20 months)"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Upon verbal request can pick up an item  from 5 or more item(2.6 year)"
                                name="auditorybehaviour"
                                type="checkbox"
                                value="Upon verbal request can pick up an item  from 5 or more item(2.6 year)"
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="mx-4 " controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Parental Perception-
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row lg={12} className="mx-4 my-2">
                        {/* <Col lg={6}>1 of 2</Col> */}
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Age Appropriate"
                                name="motordevelopement"
                                type="checkbox"
                                value="Age Appropriate"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Delayed"
                                name="motordevelopement"
                                type="checkbox"
                                value="Delayed"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Holds neck steady (2-6 months)"
                                name="motordevelopement"
                                type="checkbox"
                                value="Holds neck steady (2-6 months)"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Stands with support(9 months)"
                                name="motordevelopement"
                                type="checkbox"
                                value="Stands with support(9 months)"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Sits without support (8 months)"
                                name="motordevelopement"
                                type="checkbox"
                                value="Sits without support (8 months)"
                                onChange={handleChange}
                            />

                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Walk alone (15 months)"
                                name="motordevelopement"
                                type="checkbox"
                                value="Walk alone (15 months)"
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    <Col lg={6} className="my-3 mx-2">
                        <b> Handedness:</b>
                    </Col>
                    <Row lg={12}>
                        <Col lg={3}>
                            <Form.Group className="mx-4" controlId="formBasicname">
                                <Form.Label className="form-view-label">Grasp</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Right"
                                name="handnessgrasp"
                                value="Right"

                                type="checkbox"

                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Left"
                                name="handnessgrasp"
                                value="Left"

                                type="checkbox"

                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Mixed"
                                name="handnessgrasp"
                                value="Mixed"

                                type="checkbox"

                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Group className="mx-4" controlId="formBasicname">
                                <Form.Label className="form-view-label">Eating</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Right"
                                name="handnesseating"
                                value="Right"
                                type="checkbox"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Left"
                                name="handnesseating"
                                value="Left"
                                type="checkbox"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Mixed"
                                name="handnesseating"
                                value="Mixed"
                                type="checkbox"

                                onChange={handleChange}
                            />
                        </Col>

                        <Col lg={3}>
                            <Form.Group className="mx-4" controlId="formBasicname">
                                <Form.Label className="form-view-label">Writing</Form.Label>
                            </Form.Group>
                        </Col>

                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Right"
                                name="handnesswriting"
                                value="Right"

                                type="checkbox"

                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Left"
                                name="handnesswriting "
                                value="Left"

                                type="checkbox"

                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Mixed"
                                name="handnesswriting "
                                value="Mixed"

                                type="checkbox"

                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Group className="mx-4" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Play with toys
                                </Form.Label>
                            </Form.Group>
                        </Col>

                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Right"
                                name="handnessplaywithtoy"
                                value="Right"

                                type="checkbox"

                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Left"
                                name="handnessplaywithtoy"
                                value="Left"

                                type="checkbox"

                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={1}>
                            <Form.Check
                                inline
                                label="Mixed"
                                name="handnessplaywithtoy"
                                value="Mixed"

                                type="checkbox"

                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    {/* <Col lg={12} className="section-header my-3 mx-2">
  Visual Screening Outcome:
</Col> */}
                    <Col lg={6} className="my-3 mx-2">
                        <b> SOCIAL/COGNITIVE DEVELOPMENT:</b>
                    </Col>
                    <Row lg={12}>
                        <Col lg={6}>
                            <Form.Group className="mx-4" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    Parental Perception
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row lg={12} className="mx-4 my-2">
                        {/* <Col lg={6}>1 of 2</Col> */}
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Age Appropriate"
                                name="socialcognativeparentalperception"
                                type="checkbox"
                                value="Age Appropriate"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Delayed"
                                name="socialcognativeparentalperception"
                                type="checkbox"
                                value="Delayed"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Respond to Mother by smile (0-6 Months)"
                                name="socialcognativeparentalperception"
                                type="checkbox"
                                value="Respond to Mother by smile"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Recognizes in a photo (18-24 Months)"
                                name="socialcognativeparentalperception"
                                type="checkbox"
                                value="Recognizes in a photo"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Holds arms up to be hugged (6-12 Months)"
                                name="socialcognativeparentalperception"
                                type="checkbox"
                                value="Holds arms up"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Wants to help and please (24-30 Months)"
                                name="socialcognativeparentalperception"
                                type="checkbox"
                                value="Wants to help"
                                onChange={handleChange}
                            />
                        </Col>

                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Plays ball co-opretively identifies self in a mirror (12-18 Months)"
                                name="socialcognativeparentalperception"
                                type="checkbox"
                                value="Plays ball co-operatively"
                                onChange={handleChange}
                            />
                        </Col>

                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Enjoys pretend play(36-42 Months)"
                                name="socialcognativeparentalperception"
                                type="checkbox"
                                value="Enjoys pretend play"
                                onChange={handleChange}
                            />
                        </Col>

                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Poor eye contact"
                                name="socialcognativeparentalperception"
                                type="checkbox"
                                value="Poor eye"
                                onChange={handleChange}
                            />
                        </Col>

                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Prefers to stay alone"
                                name="socialcognativeparentalperception"
                                type="checkbox"
                                value="Prefers to stay"
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    <Col lg={6} className="my-3 mx-2">
                        <b> EDUCATION HISTORY:</b>
                    </Col>
                    <Row lg={12} className="mx-4 my-2">
                        {/* <Col lg={6}>1 of 2</Col> */}
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Age Appropriate"
                                name="educationhistory"
                                type="checkbox"
                                value={speechData.educationhistory}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Has learning difficulties"
                                name="educationhistory"
                                type="checkbox"
                                value="Has learning"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Yet to be admitted in school"
                                name="educationhistory"
                                type="checkbox"
                                value="Yet to be admitted"
                                onChange={handleChange}
                            />
                        </Col>

                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="Attends-regular school/special school"
                                name="educationhistory"
                                type="checkbox"
                                value="Attends-regular school"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col lg={6}>
                            <Form.Check
                                inline
                                label="School performance-Good/Fair/Poor"
                                name="educationhistory"
                                type="checkbox"
                                value="School performance"
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    <Col lg={6}>
                        <Form.Group className="mx-3 my-3" controlId="formBasicname">
                            <Form.Label className="form-view-label">
                                <b>RECOMMENDATION:</b>
                            </Form.Label>

                        </Form.Group>
                    </Col>
                    <div>
                        <table>
                            <tbody>
                                {tableData?.map((row, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: "10px", width: '800px' }}>
                                            <input
                                                type="text"
                                                name="recommendation"
                                                className="form-control"
                                                value={row.value}
                                                onChange={(e) => updateValue(index, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <Button onClick={() => deleteRow(index)}>Delete</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Button onClick={addRow} className="mx-3">Add Row</Button>

                    </div>
                    <Col lg={6}>
                        <Form.Group className="my-3 mx-2" controlId="contribution">
                            <Form.Label className="form-view-label">
                                {" "}
                                <b>REMARKS:</b>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                name="remark"
                                value={speechData.remark}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Row lg={12}>

                        <Col lg={6}>
                            <Form.Group className="mx-4 my-5" controlId="formBasicname">
                                <Form.Label className="form-view-label">
                                    (Name & Signature) <br /> ASLP
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="App container mt-5" style={{ display: 'none' }}>

                <div id="divToPrint">
                    <SpeechHearingPdf speechData={speechData} />
                </div>
            </div>
        </>
    );
};

export default SpeechHearingEdit;
