import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';
import inventoryApi from "../api/inventoryApi";
import moment from "moment";
import htmlToPdfmake from "html-to-pdfmake";
import * as constants from "../constants/CONSTANT";
import Confirm from './Confirm';

import {
  DatatableWrapper,
  Filter,
  TableBody,
  TableHeader
} from 'react-bs-datatable';

import pdfMake from "pdfmake";
import PdfCbrCmmWorker from "./PdfCbrCmmWorker";

const CbrWorkerCmmList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [cbrData, setcbrData] = useState({});

  useEffect(() => {
    async function init() {
      // try {
        const result = await inventoryApi.fetchCBRWCmmorkerList();
        if (result && result.length) {
          setBody(result);
        }
      // } catch (error) {
      //   console.error("Error fetching data:", error);
      // }
    }
    init();
  }, []);

  const sumOfVocational = async(id) => {
    // try {

      console.log("id Jass",id);
      let result = await inventoryApi.fetchCmmWorkerId(id); 

      console.log("result Jaseem",result);
      if (result) {
        setcbrData(result);
        // setTimeout(function () {
        //   pdfShow();
        // }, 500);
      } 
      //else {
      //   setcbrData({});
      // }
    // } catch (error) {
    //   console.error("Error fetching vocational data:", error);
    // }
  };

  const pdfShow = () => {
    const pdfTable = document.getElementById("divToPrint");
    const html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });

    const documentDefinition = {
      content: html,
      defaultStyle: {
        font: 'arialunicodems1'
      }
    };

    pdfMake.fonts = {
      arialunicodems1: {
        normal: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
        bold: `${constants.SERVER_BASE_URL}/font/ArialUnicodeMS.ttf`,
      }
    };

    pdfMake.createPdf(documentDefinition).open();
  };

  const handleOpenPdf = async (row) => {
    console.log('open pdf', row);
    try {
      let result = await inventoryApi.fetchCmmWorkerId(row.id);
      console.log('result Jaseem', result);
  
      if (result) {
        setcbrData(result);
        setTimeout(function () {
          pdfShow();
        }, 500);
      } else {
        setcbrData({});
      }
    } catch (error) {
      console.error('Error in handleOpenPdf:', error);
      // Handle the error as needed
    }
  };
  
  const header = [
    {
      title: 'Name', prop: 'name', isFilterable: true,
      cell: (row) => (
        <Link
          to={`/cmmworkerreports/${row.id}/e`}
          state={row}
        >
          {row.name}
        </Link>
      )
    },
    { title: 'Project Name', prop: 'projectname', isFilterable: true },
    { title: 'CMR Date', prop: 'cbrdate', isFilterable: true, cell: (row) => moment(row.cbrdate).format('MM/DD/YYYY') },
    { title: 'CMR Field', prop: 'cbrfieldarea', isFilterable: true },
    {
      title: "Action",
      prop: "id",
      cell: (row) => (
        <Button variant="danger" className="btn-sm mx-2" onClick={() => handleOpenPdf(row)}>
          <i className="fa-solid fa-file-pdf"></i>
        </Button>
      ),
    },
  ];

  const createCbrWorker = () => {
    navigate(`/cmmworkerreports/`);
  }

  return (
    <Row className="g-0">
      <Col lg={2} className="mx-2">
        <Link className="nav-link">Home <i className="fa-solid fa-chevron-right"></i> CBR CMM Worker</Link>
      </Col>
      <Col lg={12} className="p-lg-4">
        {body ?
          <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
            initialState: {
              rowsPerPage: 20,
              options: [5, 10, 15, 20]
            }
          }}>
            <Row className="mb-4">
              <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              >

              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button className="btn-sm" variant="outline-primary" onClick={() => createCbrWorker(true)}>Create Worker</Button>
              </Col>
            </Row>
            <Table striped className="data-table">
              <TableHeader />
              <TableBody />
            </Table>
          </DatatableWrapper> : ''}
      </Col>
      <Col lg={2}></Col>
      <div className="App container mt-5" style={{ display: 'none' }}>
        <div id="divToPrint">
          <PdfCbrCmmWorker cbrData = {cbrData}  />
        </div>
      </div>
    </Row>
  )
}

export default CbrWorkerCmmList;
