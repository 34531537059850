import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import { ShimmerTable } from "react-shimmer-effects";
import vsmsData from '../constants/VSMS_Score.json';
import PubSub from "pubsub-js";
import moment from 'moment';

const StudentScoring = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(true);
  const [sectinQuestions, setSectinQuestions] = useState([]);
  const [studentDetails, setStudentDetails] = useState({});
  const [sectionQsResult, setSectionQsResult] = useState([]);
  const [studentId, setStudentId] = useState();
  const [formType, setFormType] = useState();
  const [student, setStudent] = useState(location.state ? location.state : {});
  const [sqdata, setsqdata] = useState(0);
  ////console.log(student)
  let index = 0
  const [vsmsdatamark, setvsmsdatamark] = useState();
  const [score, setscore] = useState('');
  const [sumOfMarks, setsumOfMarks] = useState('');
  const [daYear, setdaYear] = useState();
  const [damonth, setdamonth] = useState();
  let stdId;
  let typeForm;

  useEffect(() => {

    //console.log('vsms json',vsmsData)
    if (
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      ////console.log("type => ", location.pathname.split("/")[2]);
      ////console.log("Student id => ", location.pathname.split("/")[3]);
      typeForm = location.pathname.split("/")[2];
      //setTypeForm(typeForm);
      stdId = location.pathname.split("/")[3];
      setFormType(location.pathname.split("/")[2]);
      setStudentId(location.pathname.split("/")[3]);
    }
    fetchStudentById(stdId);
    fetchCaseHistoryQuestions();

  }, []);


  let ageData = 0
  useEffect(() => {
    let registrationAge = null;
    const registrationDate = moment(student.childregistrationdate);
    let dobDate = moment(student.dob);

    const yearsDifference = registrationDate.diff(dobDate, 'years');
    console.log('yearsDifference', yearsDifference)
    dobDate.add(yearsDifference, 'years');
    const monthsDifference = registrationDate.diff(dobDate, 'months');
    console.log('monthsDifference', monthsDifference)
    let ageInDecimal = yearsDifference + (monthsDifference / 12);
    console.log('ageinDecimal', ageInDecimal);
    ageInDecimal = parseFloat(ageInDecimal.toFixed(1));
    console.log('Age difference in decimal:', ageInDecimal);

    ageData = ageInDecimal

  }, []);

  const fetchCaseHistoryQuestions = () => {
    let sectionQuestionsMap = new Map();
    let questionIdMarksMap = new Map();
    let array = []
    async function initQuestion() {
      let historyQuestions = await inventoryApi.fetchCaseHistoryQuestions(
        typeForm
      );
      console.log('historyQuestions', historyQuestions)
      let historyResults;
      try {
        historyResults = await inventoryApi.fetchCaseHistoryResults(
          stdId,
          typeForm
        );
        console.log('result history', historyResults);
        if (historyQuestions) {
          historyResults.forEach((record) => {

            questionIdMarksMap.set(record.casehistorytestid, record.marks);

          });
          ////console.log("questionIdMarksMap => ", questionIdMarksMap);

          let allQuestionResults = [];
          historyQuestions.forEach((record) => {
            // adding null marks to all questoins
            let marks = null;

            let tempQs = [];
            if (sectionQuestionsMap.has(record.sectionheading)) {
              tempQs = sectionQuestionsMap.get(record.sectionheading);
            }

            if (questionIdMarksMap.has(record.id)) {
              marks = questionIdMarksMap.get(record.id);
              tempQs.push({ ...record, marks });
            } else {
              tempQs.push({ ...record, marks });
            }
            allQuestionResults.push({
              casehistorytestid: record.id,
              studentid: stdId,
              marks: marks,
            });
            sectionQuestionsMap.set(record.sectionheading, tempQs);
          });
          ////console.log("sectionQuestionsMap => ", sectionQuestionsMap);
          array = Array.from(
            sectionQuestionsMap,
            ([sectionheading, questions]) => ({ sectionheading, questions })
          );
          console.log("sectionQuestionsArray => ", array);
          console.log("allQuestionResults => ", allQuestionResults);
          setSectinQuestions(array);
          setSectionQsResult(allQuestionResults);
        } else {
          ////console.log("no questions found!!!!!!!");
        }
        let totalMarks = 0;

        const sumOfMarks = historyResults.filter((value) => {
          if (value.marks)
            totalMarks += parseFloat(value.marks);

        })
        console.log('AGE', student.Currentage)
        ////console.log('total marks',totalMarks)
        ////console.log('FORM TYPE',typeForm)
        let calculatedScore = 0;
        if (typeForm === 'DST') {
          console.log('dst call')

          let totalMarks = 0;
          let questionArrayLength = 0;
          let sectionMarks3Month = 0;
          let sectionMarks6Month = 0;
          let sectionMarks9Month = 0;
          let sectionMarks1Y = 0;
          let sectionMarks1_2y = 0;
          let sectionMarks2y = 0;
          let sectionMarks3y = 0;
          let sectionMarks4y = 0;
          let sectionMarks5y = 0;
          let sectionMarks6y = 0;
          let sectionMarks7y = 0;
          let sectionMarks8y = 0;
          let sectionMarks9y = 0;
          let sectionMarks10y = 0;
          let sectionMarks11y = 0;
          let sectionMarks12y = 0
          let sectionMarks13y = 0
          let sectionMarks15y = 0
          let previousMonth = 0;
          array.forEach(section => {
            if (section.sectionheading === "3 Months") {
              questionArrayLength = section.questions.length;

              section.questions.forEach(question => {
                console.log(question.marks)
                sectionMarks3Month += parseFloat(question.marks) || 0;
              });
              console.log('sectionMarks3Month', sectionMarks3Month)
              totalMarks = (1 / questionArrayLength) * sectionMarks3Month * (parseFloat(section.sectionheading.replace(" Months", "")) || 0);
              previousMonth = section.sectionheading.replace(" Months", "");
              console.log('totalMarks 3 month', totalMarks)
            }
            else if (section.sectionheading === "6 Months") {
              questionArrayLength = section.questions.length
              let previousMonthLess = section.sectionheading.replace(" Months", "") - previousMonth;
              console.log('previousMonthLess', previousMonthLess)
              section.questions.forEach(question => {
                sectionMarks6Month += parseFloat(question.marks) || 0
              });
              console.log('sectionMarks6Month', sectionMarks6Month, 'previousMonthLess', previousMonthLess, 'totalMarks', totalMarks)
              totalMarks += (1 / questionArrayLength) * sectionMarks6Month * (parseFloat(previousMonthLess) || 0);
              console.log('6 Months totalMarks', totalMarks)
              previousMonth = section.sectionheading.replace(" Months", "");

            }

            else if (section.sectionheading === "9 Months") {

              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              let previousMonthLess = section.sectionheading.replace(" Months", "") - previousMonth;
              console.log('previousMonthLess 9', previousMonthLess)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks9Month += parseFloat(question.marks) || 0
              });
              console.log('sectionMarks9Month', sectionMarks9Month, 'totalMarks', totalMarks)
              totalMarks += (1 / questionArrayLength) * sectionMarks9Month * (parseFloat(previousMonthLess) || 0);
              console.log('9 Months totalMarks', totalMarks)

              previousMonth = section.sectionheading.replace(" Months", "");

            }
            else if (section.sectionheading === "1 Years") {
              console.log('1 year call')
              console.log(section.sectionheading.replace(" Years", ""))
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month', previousMonthLess, convertYearToMonth)
              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks1Y += parseFloat(question.marks) || 0

              });
              console.log('sectionMarks1Y', sectionMarks1Y, 'totalMarks', totalMarks)
              totalMarks += (1 / questionArrayLength) * sectionMarks1Y * (parseFloat(previousMonthLess) || 0);
              console.log('1 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;

            }
            else if (section.sectionheading === "1 - 1/2 Years") {
              let convertYearToMonth = 18;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month 1/2', previousMonthLess, convertYearToMonth)
              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                sectionMarks1_2y += parseFloat(question.marks) || 0

              });
              console.log('sectionMarks1_2y', sectionMarks1_2y, 'totalMarks', totalMarks)
              totalMarks += (1 / questionArrayLength) * sectionMarks1_2y * (parseFloat(previousMonthLess) || 0);
              console.log('1 - 1/2 Years  totalMarks', totalMarks)
              previousMonth = convertYearToMonth;
            }
            else if (section.sectionheading === "2 Years") {
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)
              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks2y += parseFloat(question.marks) || 0
              });
              console.log('sectionMarks2y', sectionMarks2y, 'totalMarks', totalMarks)
              totalMarks += (1 / questionArrayLength) * sectionMarks2y * (parseFloat(previousMonthLess) || 0);
              console.log('2 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;

            }
            else if (section.sectionheading === "3 Years") {
              console.log(section.sectionheading.replace(" Months", ""))
              questionArrayLength = section.questions.length
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)

              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks3y += parseFloat(question.marks) || 0

              });
              console.log('sectionMarks3y', sectionMarks3y, 'totalMarks', totalMarks)
              totalMarks += (1 / questionArrayLength) * sectionMarks3y * (parseFloat(previousMonthLess) || 0);
              console.log('3 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;

            }
            else if (section.sectionheading === "4 Years") {
              questionArrayLength = section.questions.length
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)

              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks4y += parseFloat(question.marks) || 0

              });
              console.log('sectionMarks4y', parseFloat(sectionMarks4y), 'totalMarks', totalMarks)
              totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks4y)) * (previousMonthLess)) || 0;
              console.log('4 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;

            }
            else if (section.sectionheading === "5 Years") {
              questionArrayLength = section.questions.length
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)

              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks5y += parseFloat(question.marks) || 0
              });
              console.log('sectionMarks5y', parseFloat(sectionMarks5y), 'totalMarks', totalMarks)
              totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks5y)) * (previousMonthLess)) || 0;
              console.log('5 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;
            }
            else if (section.sectionheading === "6 Years") {
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)

              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks6y += parseFloat(question.marks) || 0
              });
              console.log('sectionMarks6y', parseFloat(sectionMarks6y), 'totalMarks', totalMarks)
              totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks6y)) * (previousMonthLess)) || 0;
              console.log('6 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;
            }
            else if (section.sectionheading === "7 Years") {
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)

              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks7y += parseFloat(question.marks) || 0

              });
              console.log('sectionMarks7y', parseFloat(sectionMarks7y), 'totalMarks', totalMarks)
              totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks7y)) * (previousMonthLess)) || 0;
              console.log('7 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;
            }
            else if (section.sectionheading === "8 Years") {
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)

              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks8y += parseFloat(question.marks) || 0

              });
              console.log('sectionMarks8y', parseFloat(sectionMarks8y), 'totalMarks', totalMarks)
              totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks8y)) * (previousMonthLess)) || 0;
              console.log('8 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;
            }
            else if (section.sectionheading === "9 Years") {
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)
              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks9y += parseFloat(question.marks) || 0

              });
              console.log('sectionMarks9y', parseFloat(sectionMarks9y), 'totalMarks', totalMarks)
              totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks9y)) * (previousMonthLess)) || 0;
              console.log('9 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;
            }
            else if (section.sectionheading === "10 Years") {
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)
              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks10y += parseFloat(question.marks) || 0

              });
              console.log('sectionMarks10y', parseFloat(sectionMarks10y), 'totalMarks', totalMarks)
              totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks10y)) * (previousMonthLess)) || 0;
              console.log('10 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;

            }
            else if (section.sectionheading === "11 Years") {
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)

              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks11y += parseFloat(question.marks) || 0
              });
              console.log('sectionMarks11y', parseFloat(sectionMarks11y), 'totalMarks', totalMarks)
              totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks11y)) * (previousMonthLess)) || 0;
              console.log('11 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;
            }
            else if (section.sectionheading === "12 Years") {
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)
              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks12y += parseFloat(question.marks) || 0
              });
              console.log('sectionMarks12y', parseFloat(sectionMarks12y), 'totalMarks', totalMarks)
              totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks12y)) * (previousMonthLess)) || 0;
              console.log('12 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;

            }
            else if (section.sectionheading === "13 Years") {
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)
              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks13y += parseFloat(question.marks) || 0
              });
              console.log('sectionMarks13y', parseFloat(sectionMarks13y), 'totalMarks', totalMarks)
              totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks13y)) * (previousMonthLess)) || 0;
              console.log('13 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;

            }
            else if (section.sectionheading === "15 Years") {
              let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
              let previousMonthLess = convertYearToMonth - previousMonth;
              console.log('month less', previousMonthLess, convertYearToMonth)
              questionArrayLength = section.questions.length
              console.log('questionArrayLength', questionArrayLength)
              section.questions.forEach(question => {
                console.log('parseFloat(question.marks)')
                sectionMarks15y += parseFloat(question.marks) || 0
              });
              console.log('sectionMarks15y', parseFloat(sectionMarks15y), 'totalMarks', totalMarks)
              totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks15y)) * (previousMonthLess)) || 0;
              console.log('15 Year totalMarks', totalMarks)
              previousMonth = convertYearToMonth;
            }
          })
          console.log("Total marks for All section : " + totalMarks.toFixed(2));
          console.log('currentage', student.Currentage)
          let AgeMonth = student.Currentage * 12;
          console.log(AgeMonth)
          if (AgeMonth === 0) {
            alert('Age at Enrollment is 0 For Access All the question marks scoring you need to fill Age At Enrollment')
            setscore(0)
          } else {
            setsumOfMarks(totalMarks.toFixed(2));
            console.log('DA ===> ', totalMarks)
            const years = Math.floor(totalMarks);
            const months = Math.round((totalMarks - years) * 12);
            console.log('Years', years)
            console.log('months', months)
            setdamonth(months)
            setdaYear(years)
            let DstScoreMarks = totalMarks.toFixed(2) * (100 / AgeMonth);
            console.log('DstScoreMarks', DstScoreMarks)
            setscore(DstScoreMarks.toFixed(2));

            if (isNaN(DstScoreMarks) || !isFinite(DstScoreMarks)) {
              setscore(0);
            }
          }


        } else {
          if (typeof totalMarks === 'number' && totalMarks !== 0) {
            //VSMS
            console.log('AGEDATA IN VSMS', ageData)
            let ageConvertInMonth = student.Currentage * 12;
            console.log('ageConvertInMonth', ageConvertInMonth)
            const years = Math.floor(totalMarks);

            const months = Math.round((totalMarks - years) * 12);
            console.log('Years', years)
            console.log('months', months)
            console.log('total Marks ', totalMarks)
            let vsmsDataMarks = 0;
            {
              vsmsData?.map((item) => {
                if (item.marks === totalMarks) {
                  vsmsDataMarks = item.months
                }
              })
            }
            setvsmsdatamark(vsmsDataMarks)
            console.log('data for vsms',ageConvertInMonth,student.Currentage,vsmsDataMarks)
            let agemonth = student.Currentage*12
            let qeresult = vsmsDataMarks/agemonth*100
            console.log('qeresult',qeresult)
            setsqdata(qeresult.toFixed(2))
            if (vsmsDataMarks) {
             
              setscore(totalMarks);
            }
          } else {
            setscore(0);
          }

          if (isNaN(calculatedScore) || !isFinite(calculatedScore)) {
            setscore(0);
          }
        }

        ////console.log('value', totalMarks);



      } catch (e) {
        ////console.log("error studentId in fetchCaseHistoryResults=> ", e);
      }

    }
    initQuestion();
  };

  const fetchStudentById = (stdId) => {
    async function initStudent() {
      ////console.log("stdId => ", stdId);
      let result = await inventoryApi.fetchStudentById(stdId);
      setStudentDetails(result);
    }
    initStudent();
  };

  useEffect(() => {

  }, []);

  const handlesubmit = async () => {

    ////console.log("studentDetail => ", studentDetails);
    console.log("sectionQsResult => ", sectionQsResult);
    //setSectionQsResult(sectionQsResult);
    countOfMarks(sectionQsResult);
    if (sectionQsResult.length) {

      let result = await inventoryApi.saveCaseHistoryQuestions(sectionQsResult);
      //console.log('====>',result)
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: " Record saved successfully",
        });
      }
    }
  };

  const countOfMarks = async (sectionQsResult) => {

    let totalMarks = 0;
    let calculatedScore = 0;

    console.log(formType, stdId)



    if (formType === 'DST') {

      let historyQuestions = await inventoryApi.fetchCaseHistoryQuestions(
        formType
      );
      console.log('historyQuestions', historyQuestions)
      const updatedResult = sectionQsResult.map(result => {
        // Find the corresponding history question
        const question = historyQuestions.find(q => q.id === result.casehistorytestid);
        if (question) {
          return {
            ...result, sectionheading: question.sectionheading, question: question.question,
            category: question.category, sortorder: question.sortorder, days: question.days
          };
        }
        return result;
      });


      console.log('updatedResult', updatedResult)

      const groupedData = updatedResult.reduce((acc, curr) => {
        const section = curr.sectionheading;
        if (!acc[section]) {
          acc[section] = [];
        }
        acc[section].push(curr);
        return acc;
      }, {});
      console.log('groupedData', groupedData)

      // Next, transform the grouped data into the desired format
      const array = Object.keys(groupedData).map((section) => {
        const questions = groupedData[section].map((q) => ({
          id: q.casehistorytestid,
          studentid: q.studentid,
          marks: q.marks,
          category: q.category,
          question: q.question,
          days: q.days,
          months: q.months,
          sectionheading: q.sectionheading,
          sortorder: q.sortorder


        }));
        return { sectionheading: section, questions };
      });
      console.log('array', array)

      setSectinQuestions(array);

      console.log('if dst call')
      let questionArrayLength = 0;
      let sectionMarks3Month = 0;
      let sectionMarks6Month = 0;
      let sectionMarks9Month = 0;
      let sectionMarks1Y = 0;
      let sectionMarks1_2y = 0;
      let sectionMarks2y = 0;
      let sectionMarks3y = 0;
      let sectionMarks4y = 0;
      let sectionMarks5y = 0;
      let sectionMarks6y = 0;
      let sectionMarks7y = 0;
      let sectionMarks8y = 0;
      let sectionMarks9y = 0;
      let sectionMarks10y = 0;
      let sectionMarks11y = 0;
      let sectionMarks12y = 0
      let sectionMarks13y = 0
      let sectionMarks15y = 0
      let previousMonth = 0;
      array.forEach(section => {
        if (section.sectionheading === "3 Months") {
          console.log('call 3 month')
          questionArrayLength = section.questions.length;
          section.questions.forEach(question => {
            console.log(question.marks)
            sectionMarks3Month += parseFloat(question.marks) || 0;
          });
          console.log('sectionMarks3Month ===> ', sectionMarks3Month)
          console.log('questionArrayLength ===> ', questionArrayLength)

          
          totalMarks = (1 / questionArrayLength) * sectionMarks3Month * (parseFloat(section.sectionheading.replace(" Months", "")) || 0);
          previousMonth = section.sectionheading.replace(" Months", "");
          console.log('totalMarks 3 month', totalMarks)
        }
        else if (section.sectionheading === "6 Months") {
          questionArrayLength = section.questions.length
          let previousMonthLess = section.sectionheading.replace(" Months", "") - previousMonth;
          console.log('previousMonthLess', previousMonthLess)
          section.questions.forEach(question => {
            sectionMarks6Month += parseFloat(question.marks) || 0
          });
          console.log('sectionMarks6Month', sectionMarks6Month, 'previousMonthLess', previousMonthLess, 'totalMarks', totalMarks)
          totalMarks += (1 / questionArrayLength) * sectionMarks6Month * (parseFloat(previousMonthLess) || 0);
          console.log('6 Months totalMarks', totalMarks)
          previousMonth = section.sectionheading.replace(" Months", "");

        }

        else if (section.sectionheading === "9 Months") {

          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          let previousMonthLess = section.sectionheading.replace(" Months", "") - previousMonth;
          console.log('previousMonthLess 9', previousMonthLess)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks9Month += parseFloat(question.marks) || 0
          });
          console.log('sectionMarks9Month', sectionMarks9Month, 'totalMarks', totalMarks)
          totalMarks += (1 / questionArrayLength) * sectionMarks9Month * (parseFloat(previousMonthLess) || 0);
          console.log('9 Months totalMarks', totalMarks)

          previousMonth = section.sectionheading.replace(" Months", "");

        }
        else if (section.sectionheading === "1 Years") {
          console.log('1 year call')
          console.log(section.sectionheading.replace(" Years", ""))
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month', previousMonthLess, convertYearToMonth)
          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks1Y += parseFloat(question.marks) || 0

          });
          console.log('sectionMarks1Y', sectionMarks1Y, 'totalMarks', totalMarks)
          totalMarks += (1 / questionArrayLength) * sectionMarks1Y * (parseFloat(previousMonthLess) || 0);
          console.log('1 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;

        }
        else if (section.sectionheading === "1 - 1/2 Years") {
          let convertYearToMonth = 18;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month 1/2', previousMonthLess, convertYearToMonth)
          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            sectionMarks1_2y += parseFloat(question.marks) || 0

          });
          console.log('sectionMarks1_2y', sectionMarks1_2y, 'totalMarks', totalMarks)
          totalMarks += (1 / questionArrayLength) * sectionMarks1_2y * (parseFloat(previousMonthLess) || 0);
          console.log('1 - 1/2 Years  totalMarks', totalMarks)
          previousMonth = convertYearToMonth;
        }
        else if (section.sectionheading === "2 Years") {
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)
          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks2y += parseFloat(question.marks) || 0
          });
          console.log('sectionMarks2y', sectionMarks2y, 'totalMarks', totalMarks)
          totalMarks += (1 / questionArrayLength) * sectionMarks2y * (parseFloat(previousMonthLess) || 0);
          console.log('2 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;

        }
        else if (section.sectionheading === "3 Years") {
          console.log(section.sectionheading.replace(" Months", ""))
          questionArrayLength = section.questions.length
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)

          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks3y += parseFloat(question.marks) || 0

          });
          console.log('sectionMarks3y', sectionMarks3y, 'totalMarks', totalMarks)
          totalMarks += (1 / questionArrayLength) * sectionMarks3y * (parseFloat(previousMonthLess) || 0);
          console.log('3 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;

        }
        else if (section.sectionheading === "4 Years") {
          questionArrayLength = section.questions.length
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)

          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks4y += parseFloat(question.marks) || 0

          });
          console.log('sectionMarks4y', parseFloat(sectionMarks4y), 'totalMarks', totalMarks)
          totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks4y)) * (previousMonthLess)) || 0;
          console.log('4 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;

        }
        else if (section.sectionheading === "5 Years") {
          questionArrayLength = section.questions.length
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)

          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks5y += parseFloat(question.marks) || 0
          });
          console.log('sectionMarks5y', parseFloat(sectionMarks5y), 'totalMarks', totalMarks)
          totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks5y)) * (previousMonthLess)) || 0;
          console.log('5 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;
        }
        else if (section.sectionheading === "6 Years") {
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)

          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks6y += parseFloat(question.marks) || 0
          });
          console.log('sectionMarks6y', parseFloat(sectionMarks6y), 'totalMarks', totalMarks)
          totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks6y)) * (previousMonthLess)) || 0;
          console.log('6 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;
        }
        else if (section.sectionheading === "7 Years") {
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)

          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks7y += parseFloat(question.marks) || 0

          });
          console.log('sectionMarks7y', parseFloat(sectionMarks7y), 'totalMarks', totalMarks)
          totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks7y)) * (previousMonthLess)) || 0;
          console.log('7 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;
        }
        else if (section.sectionheading === "8 Years") {
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)

          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks8y += parseFloat(question.marks) || 0

          });
          console.log('sectionMarks8y', parseFloat(sectionMarks8y), 'totalMarks', totalMarks)
          totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks8y)) * (previousMonthLess)) || 0;
          console.log('8 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;
        }
        else if (section.sectionheading === "9 Years") {
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)
          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks9y += parseFloat(question.marks) || 0

          });
          console.log('sectionMarks9y', parseFloat(sectionMarks9y), 'totalMarks', totalMarks)
          totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks9y)) * (previousMonthLess)) || 0;
          console.log('9 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;
        }
        else if (section.sectionheading === "10 Years") {
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)
          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks10y += parseFloat(question.marks) || 0

          });
          console.log('sectionMarks10y', parseFloat(sectionMarks10y), 'totalMarks', totalMarks)
          totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks10y)) * (previousMonthLess)) || 0;
          console.log('10 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;

        }
        else if (section.sectionheading === "11 Years") {
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)

          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks11y += parseFloat(question.marks) || 0
          });
          console.log('sectionMarks11y', parseFloat(sectionMarks11y), 'totalMarks', totalMarks)
          totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks11y)) * (previousMonthLess)) || 0;
          console.log('11 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;
        }
        else if (section.sectionheading === "12 Years") {
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)
          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks12y += parseFloat(question.marks) || 0
          });
          console.log('sectionMarks12y', parseFloat(sectionMarks12y), 'totalMarks', totalMarks)
          totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks12y)) * (previousMonthLess)) || 0;
          console.log('12 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;

        }
        else if (section.sectionheading === "13 Years") {
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)
          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks13y += parseFloat(question.marks) || 0
          });
          console.log('sectionMarks13y', parseFloat(sectionMarks13y), 'totalMarks', totalMarks)
          totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks13y)) * (previousMonthLess)) || 0;
          console.log('13 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;

        }
        else if (section.sectionheading === "15 Years") {
          let convertYearToMonth = section.sectionheading.replace(" Years", "") * 12;
          let previousMonthLess = convertYearToMonth - previousMonth;
          console.log('month less', previousMonthLess, convertYearToMonth)
          questionArrayLength = section.questions.length
          console.log('questionArrayLength', questionArrayLength)
          section.questions.forEach(question => {
            console.log('parseFloat(question.marks)')
            sectionMarks15y += parseFloat(question.marks) || 0
          });
          console.log('sectionMarks15y', parseFloat(sectionMarks15y), 'totalMarks', totalMarks)
          totalMarks += ((1 / questionArrayLength) * (parseFloat(sectionMarks15y)) * (previousMonthLess)) || 0;
          console.log('15 Year totalMarks', totalMarks)
          previousMonth = convertYearToMonth;
        }

      })

      console.log("Total marks for All section : ", totalMarks.toFixed(2));
      let AgeMonth = student.Currentage * 12;
      console.log(AgeMonth)
      if (AgeMonth === 0) {
        alert('Age at Enrollment is 0 For Access All the question marks scoring you need to fill Age At Enrollment')
        setscore(0)
      } else {
        setsumOfMarks(totalMarks.toFixed(2));
        console.log('DA ===> ', totalMarks)
        // const years = Math.floor(totalMarks);
        // const months = Math.round((totalMarks - years) * 12);
        // console.log('Years', years)
        // console.log('months', months)
        setdamonth(totalMarks)
        
        let DstScoreMarks = totalMarks.toFixed(2) * (100 / AgeMonth);
        console.log('DstScoreMarks', DstScoreMarks)
        setscore(DstScoreMarks.toFixed(2));

        if (isNaN(DstScoreMarks) || !isFinite(DstScoreMarks)) {
          setscore(0);
        }
      }

    } else {

    }
    if (formType === 'VSMS') {
      console.log('vsms call')
      let registrationAge = null;
      const registrationDate = moment(student.childregistrationdate);
      let dobDate = moment(student.dob);

      const yearsDifference = registrationDate.diff(dobDate, 'years');
      console.log('yearsDifference', yearsDifference)
      dobDate.add(yearsDifference, 'years');
      const monthsDifference = registrationDate.diff(dobDate, 'months');
      console.log('monthsDifference', monthsDifference)
      let ageInDecimal = yearsDifference + (monthsDifference / 12);
      console.log('ageinDecimal', ageInDecimal);
      ageInDecimal = parseFloat(ageInDecimal.toFixed(1));
      console.log('Age difference in decimal:', ageInDecimal);
      console.log('total marks', totalMarks)
      console.log('VSMS SECTION QUESTION', sectionQsResult)
      sectionQsResult.forEach((value) => {
        if (value.marks) {
          totalMarks += parseFloat(value.marks)
          //totalMarksCount = totalMarks + parseFloat(value.marks) 
        }
      })

      console.log('VSMS HANDLESubmit call Total Marks : ', totalMarks)
      let vsmsDataMarks = 0;
      {
        vsmsData?.map((item) => {
          if (item.marks === totalMarks) {
            vsmsDataMarks = item.months
          }
        })
      }
      console.log('data on handlesubmit',student.Currentage,vsmsDataMarks,totalMarks)
      setvsmsdatamark(vsmsDataMarks);
      if (vsmsDataMarks) {
        let ageInMonths = student.Currentage * 12;
        let qeresult = vsmsDataMarks/ageInMonths*100
        console.log('qeresult',qeresult)
        setsqdata(qeresult.toFixed(2))
        setscore(totalMarks);
      }


      if (isNaN(calculatedScore) || !isFinite(calculatedScore)) {
        setscore(0);
      }

    }
    else {

    }
  }


  const handleCancel = () => {
    navigate(`/students/${studentDetails.id}`, { state: studentDetails });
  };

  const handleChange = (e, id, sectionHeading) => {
    console.log(e.target.name, e.target.value, id, sectionHeading);
    let marks = null;
    if (e.target.value !== "") {
      marks = e.target.value;
    }

    let allQuestionResults = [...sectionQsResult];
    console.log("allQuestionResults", allQuestionResults);
    allQuestionResults.forEach((obj) => {
      if (obj.casehistorytestid === id) {
        obj.marks = marks;

      }
    });

    console.log("allQuestionResults => ", allQuestionResults);

    setSectionQsResult(allQuestionResults);
  };

  return (
    <Container>
      <Row className="view-form-header align-items-center mx-1">
        <Col lg={3}>

          <h5>Edit {formType} Info</h5>
          <h4>{student.name}</h4>
        </Col>
        <Col lg={3}>
          {formType === 'DST' ? <h5>DA : {daYear} Month  </h5> : <h5>SQ : {sqdata}</h5>}
        </Col>
        <Col lg= {1}>
      {formType === 'DST' ? <h5>DQ : {score} </h5> : <h5>SA : {vsmsdatamark} Month </h5>}
      </Col>
        <Col lg={2}>
          {formType === 'DST' ? <h5></h5> : <h5>Score: {score} </h5>}

        </Col>
      
     
        <Col lg={3} className="d-flex justify-content-end">
          {isEditable && (
            <Button className="btn-sm mx-2" onClick={handlesubmit}>
              Save
            </Button>
          )}
          <Button className="btn-sm" variant="danger" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
      </Row>

      <Row className="align-items-center mx-1 mb-4">
        {sectinQuestions && sectinQuestions.length ? (
          sectinQuestions.map((rec, index) => {
            return (
              <div key={rec.sectionheading}>
                <Col lg={12} className="section-header mt-4 mb-1">
                  {rec.sectionheading}
                </Col>
                <Row>
                  {rec.questions && rec.questions.length
                    ? rec.questions.map((rec, recindex) => {
                      return (
                        <Col key={rec.id} lg={6}>
                          <Form.Group className="mx-3 mt-2">
                            <Form.Label className="form-view-label mb-0">
                              {<label className="ques-label">  {recindex + 1}. {rec.question}</label>}{" "}
                              - {rec.months ? rec.months + " months" : ""}{" "}
                              {rec.days && rec.months ? " ," : ""}{" "}
                              {rec.days ? +rec.days + " days" : ""}
                            </Form.Label>
                            <Form.Select
                              aria-label="Enter Select option"
                              name="birthcrypresent_3_m"
                              //value={DstDetails.birthcrypresent_3_m}
                              onChange={(e) => handleChange(e, rec.id, rec.sectionheading)}
                            >
                              <option

                                selected={rec.marks == "" ? true : false}
                              >
                                -- Select --
                              </option>
                              <option
                                value="0.00"
                                selected={rec.marks == "0.00" ? true : false}
                              >
                                0
                              </option>
                              {formType === 'DST' &&
                                <option
                                  value="0.5"
                                  selected={rec.marks == "0.50" ? true : false}
                                >
                                  0.5
                                </option>}
                              <option
                                value="1.00"
                                selected={rec.marks == "1.00" ? true : false}
                              >
                                1
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      );
                    })
                    : "No questions available in this section!"}
                </Row>
              </div>
            );
          })
        ) : (
          <ShimmerTable row={10} col={8} />
        )}
      </Row>
    </Container>
  );
};

export default StudentScoring;
