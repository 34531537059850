import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import moment from 'moment';

import * as constants from "../constants/CONSTANT";

const DSTForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [DstDetails, setDstDetails] = useState({});
  const [studentDetails, setStudentDetails] = useState({});
  const [isEditable, setIsEditable] = useState(true);
 
  useEffect(() => {
    if (location?.state) {
      if(location?.state?.id && location?.state?.studentid) {
        setDstDetails(location.state);
        fetchStudentById();
      }else{
        setStudentDetails(location.state);
        setDstDetails({...DstDetails, studentid: location.state.id});
      }
       
    }
  }, []);

  const fetchStudentById = () => {
    async function initStudent() {
      let result = await inventoryApi.fetchStudentById(location.state.studentid);
      setStudentDetails(result);
    }
    initStudent();
  };

  const handleChange = (e) => {
    setDstDetails({ ...DstDetails, [e.target.name]: e.target.value });
  };
  const handlesubmit = async (e) => {
    console.log("DstDetails ==>",DstDetails)
    if(DstDetails && DstDetails?.id){
      const result = await inventoryApi.saveDST(DstDetails);
      
    }else{
      const result = await inventoryApi.createDST(DstDetails);
    }
    navigate(`/students/${studentDetails.id}`, { state: studentDetails });
  };

  const handleCancel = () => {
    
    navigate(`/students/${studentDetails.id}`, { state: studentDetails });
  };

  return (
    <Container>
      <Row className="view-form-header align-items-center mx-1">
        <Col lg={3}>
          <h4>Edit DST Info</h4>
        </Col>
        <Col lg={9} className="d-flex justify-content-end">
        {isEditable && <Button className="btn-sm mx-2" onClick={handlesubmit}>
            Save
          </Button>}
          <Button className="btn-sm" variant="danger" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
      <fieldset disabled={!isEditable}>
      <Row className="align-items-center mx-1">
        <Col lg={12} className="section-header my-2">
          Developmental Screening Test
        </Col>

        <Col lg={6}>
          <Form.Group className="mx-3" controlId="dfdgo">
            <Form.Label className="form-view-label form-label">Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={studentDetails.name}
           
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">Enroll No.</Form.Label>
            <Form.Control
              type="text"
              name="respondedby"
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">Gender</Form.Label>
            <Form.Control
              type="text"
              name="gender"
              value={studentDetails.gender}
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">Age</Form.Label>
            <Form.Control
              type="text"
              name="age"
              value={studentDetails.age}
             
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">Date</Form.Label>
            <Form.Control
              type="text"
              name="respondedby"
         
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Developmental Age (DA){" "}
            </Form.Label>
            <Form.Control
              type="text"
              name="respondedby"
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Developmental Quotient (DQ)
            </Form.Label>
            <Form.Control
              type="text"
              name="respondedby"
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">D.O.B.</Form.Label>
            <Form.Control
              type="text"
              name="dob"
              value={moment(studentDetails.dob).format('DD-MM-YYYY')}
              disabled
            />
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          3 Months:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Birth cry present ....(13)
            </Form.Label>

            <Form.Select
              aria-label="Enter Select option"
              name="birthcrypresent_3_m"
              value={DstDetails.birthcrypresent_3_m}
              onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Equal bilateral movements ....(26)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="equalbilateralmovements_3_m"
            value={DstDetails.equalbilateralmovements_3_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Responds to bell ....(39)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="respondstobell_3_m"
            value={DstDetails.respondstobell_3_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Vocalises sounds ....(52)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="vocalisessounds_3_m"
            value={DstDetails.vocalisessounds_3_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Smiles spontaneously ....(65)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
             name="smilesspontaneousl_3_m"
             value={DstDetails.smilesspontaneousl_3_m}
             onChange={handleChange}
             >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Eyes follow moving object ....(78)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="eyesfollowmovingobject_3_m"
            value={DstDetails.eyesfollowmovingobject_3_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Head stead ....(90)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="headstead_3_m"
            value={DstDetails.headstead_3_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>

        {/* 6 Month */}
        <Col lg={12} className="section-header my-2">
          6 Months:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Reaches for objects ....(15)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="reachesforobjects_6_m"
            value={DstDetails.reachesforobjects_6_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Laughs aloud ....(30)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="laughsaloud_6_m"
            value={DstDetails.laughsaloud_6_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Recognises mother ....(45)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="recognisesmother_6_m"
            value={DstDetails.recognisesmother_6_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Vocalises for pleasure/babbies ....(60)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="vocalisesforpleasure_6_m"
            value={DstDetails.vocalisesforpleasure_6_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Carries objects to mouth ....(75)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="carriesobjectstomouth_6_m"
            value={DstDetails.carriesobjectstomouth_6_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Rolls over ....(90)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="rollsover_6_m"
            value={DstDetails.rollsover_6_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col lg={12} className="section-header my-2">
          9 Months:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Imitates speech sounds ....(23)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="imitatesspeechsounds_9_m"
            value={DstDetails.imitatesspeechsounds_9_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Sits by self ....(46)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="sitsbyself_9_m"
            value={DstDetails.sitsbyself_9_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Thumb flnger grasp ...(68)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="thumbflngergrasp_9_m"
            value={DstDetails.thumbflngergrasp_9_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Shows curiousity ....(90)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="showscuriousity_9_m"
            value={DstDetails.showscuriousity_9_m}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        {/* 1 Year */}

        <Col lg={12} className="section-header my-2">
          1 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Stay 3 words 'dada','mama'.etc ....(23)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="stay3words__1y"
            value={DstDetails.stay3words__1y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Stands alone well ....(46)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="standsalonewell__1y"
            value={DstDetails.standsalonewell__1y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Follows simple instructions ....(68)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="followssimpleinstructions__1y"
            value={DstDetails.followssimpleinstructions__1y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Cooperates for dressing ....(90)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="cooperatesfordressing__1y"
            value={DstDetails.cooperatesfordressing__1y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          1 - 1/2 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Many intelligibie words ....(1) ....(15)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="manyintelligibiewords__1p5y"
            value={DstDetails.manyintelligibiewords__1p5y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Walks, runs well .....(3)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="walksrunswell__1p5y"
            value={DstDetails.walksrunswell__1p5y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Indicates wants ....(4) .....(15)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="indicateswants__1p5y"
            value={DstDetails.indicateswants__1p5y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Scribbles spontaneously ....(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="scribblesspontaneously__1p5y"
            value={DstDetails.scribblesspontaneously__1p5y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col lg={12} className="section-header my-2">
          2 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Says sentences of 2/3 words ..(1) ..(15)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="sayssentencesof2_3words__2y"
            value={DstDetails.sayssentencesof2_3words__2y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Points out objects in pictures ....(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="pointsoutobjectsinpictures__2y"
            value={DstDetails.pointsoutobjectsinpictures__2y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Shows body parts ....(4) ....(15)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="showsbodyparts__2y"
            value={DstDetails.showsbodyparts__2y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Participates in play ....(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="participateinplay__2y"
            value={DstDetails.participateinplay__2y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          3 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Copies O .....(2)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="copieso__3y"
            value={DstDetails.copieso__3y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Relates experiences ....(4)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="relatesexperiences__3y"
            value={DstDetails.relatesexperiences__3y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Knows names, uses of common objects .(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="knowsnames__3y"
            value={DstDetails.knowsnames__3y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Begins to ask 'WAY' ....(8)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="beginstoaskway__3y"
            value={DstDetails.beginstoaskway__3y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Takes food by self ....(10)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="takesfoodbyself__3y"
            value={DstDetails.takesfoodbyself__3y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Toilet control present ....(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="toiletcontrolpresent__3y"
            value={DstDetails.toiletcontrolpresent__3y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col lg={12} className="section-header my-2">
          4 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Buttons up ....(2) ....(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="buttonsup__4y"
            value={DstDetails.buttonsup__4y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Compreshends 'hunger','cold' (4) (24)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="compreshends__4y"
            value={DstDetails.compreshends__4y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Plays cooperatively with children (7) (6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="playscooperativelywithchildren__4y"
            value={DstDetails.playscooperativelywithchildren__4y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Repeats 3 digits ....(9) ....(18)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="repeats3digits__4y"
            value={DstDetails.repeats3digits__4y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Tells scories ....(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="tellsscories__4y"
            value={DstDetails.tellsscories__4y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          5 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Detines words ....(2)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="detineswords__5y"
            value={DstDetails.detineswords__5y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Makes simple drawing ....(4)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="makessimpledrawing__5y"
            value={DstDetails.makessimpledrawing__5y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Dresses with no supervision .....(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="dresseswithnosupervision__5y"
            value={DstDetails.dresseswithnosupervision__5y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Describes actions in pictures .....(8)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="describesactionsinpictures__5y"
            value={DstDetails.describesactionsinpictures__5y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Gives sensible answers to questions .(10)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="givessensibleanswerstoquestions__5y"
            value={DstDetails.givessensibleanswerstoquestions__5y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Goes about neighbourhood ....(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="goesaboutneighbourhood__5y"
            value={DstDetails.goesaboutneighbourhood__5y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          6 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Can name primary colours ....(2) ....(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="cannameprimarycolours__6y"
            value={DstDetails.cannameprimarycolours__6y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Plays games governed by rules .(4) .(24)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="playsgamesgovernedbyrules__6y"
            value={DstDetails.playsgamesgovernedbyrules__6y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Write simple words ...(7) ....(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="writessimplewords__6y"
            value={DstDetails.writessimplewords__6y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Gains admission to school ..(9) ..(18)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="gainsadmissiontoschool__6y"
            value={DstDetails.gainsadmissiontoschool__6y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Enjoys Constructive Pla ....(2) ....(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="enjoysconstructiveplay__6y"
            value={DstDetails.enjoysconstructiveplay__6y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          7 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Tells differences of objects ...(4) ...(24)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="tellsdifferencesofobjects__7y"
            value={DstDetails.tellsdifferencesofobjects__7y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Spells, reads, writes simple words ...(7) ...(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="spellsreadswritessimplewords__7y"
            value={DstDetails.spellsreadswritessimplewords__7y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Enjoyes group play ...(9) ...(18)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="enjoyesgroupplay__7y"
            value={DstDetails.enjoyesgroupplay__7y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Knows comparative value of coins ....(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="knowscomparativevalueofcoins__7y"
            value={DstDetails.knowscomparativevalueofcoins__7y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          8 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Combs hair by self ....(3)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="combshairbyself__8y"
            value={DstDetails.combshairbyself__8y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Makes small purchases ...(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="makessmallpurchases__8y"
            value={DstDetails.makessmallpurchases__8y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Competition in school/play ...(9)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="competitioninschoolplay__8y"
            value={DstDetails.competitioninschoolplay__8y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Tells time ...(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="tellstime__8y"
            value={DstDetails.tellstime__8y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          9 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Tells day mouth, year ...(2)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="tellsdaymouthyear__9y"
            value={DstDetails.tellsdaymouthyear__9y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Reads on own intiative ...(4)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="readsonownintiative__9y"
            value={DstDetails.readsonownintiative__9y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Recognises property rights .../ ...(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="recognisespropertyrights__9y"
            value={DstDetails.recognisespropertyrights__9y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Favourite of fairy tales ...(8)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="favouriteoffairytales__9y"
            value={DstDetails.favouriteoffairytales__9y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Muscle coordination games (marbles) ...(10)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="musclecoordinationgames__9y"
            value={DstDetails.musclecoordinationgames__9y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Bathes self unaided ...(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="bathesselfunaided__9y"
            value={DstDetails.bathesselfunaided__9y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          10 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Cooperates keenly with companions ...(2) ...(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="cooperateskeenlywithcompanions__10y"
            value={DstDetails.cooperateskeenlywithcompanions__10y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Has various hobbies, collections ...(4) ...(24)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="hasvarioushobbiescollections__10y"
            value={DstDetails.hasvarioushobbiescollections__10y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Sex differences in play become marked ...(9) ...(18)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="sexdifferencesinplaybecomemarked__10y"
            value={DstDetails.sexdifferencesinplaybecomemarked__10y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Can stay away from home (Camps) ...(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="canstayawayfromhomecamps__10y"
            value={DstDetails.canstayawayfromhomecamps__10y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Goes about town freely ...(7) ...(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="goesabouttownfreely__10y"
            value={DstDetails.goesabouttownfreely__10y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          11 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Writes occasional short letters ...(3)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="writesoccasionalshortletters__11y"
            value={DstDetails.writesoccasionalshortletters__11y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Comprehends social situations ...(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="comprehendssocialsituations__11y"
            value={DstDetails.comprehendssocialsituations__11y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Physial feats liked ...(9)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="physialfeatsliked__11y"
            value={DstDetails.physialfeatsliked__11y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Able to discuss problems ...(2)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="abletodiscussproblems__11y"
            value={DstDetails.abletodiscussproblems__11y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          12 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Enyoys books, newspapers, magazines ...(4)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="enyoysbooks__12y"
            value={DstDetails.enyoysbooks__12y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              More independent in spending ...(8)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="moreindependentinspending__12y"
            value={DstDetails.moreindependentinspending__12y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Capable of self criticism ...(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="capableofselfcriticism__12y"
            value={DstDetails.capableofselfcriticism__12y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          13 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Interested in dressing ...(9) ...(18)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="interestedindressing__13y"
            value={DstDetails.interestedindressing__13y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Learns form experience ...(4) ...(24)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="learnsformexperience__13y"
            value={DstDetails.learnsformexperience__13y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Plays difficult games ...(7) ...(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="playsdifficultgames__13y"
            value={DstDetails.playsdifficultgames__13y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Shows foresight, planing, judgement ...(2) ...(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="showsforesight__13y"
            value={DstDetails.showsforesight__13y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Understands abstract ideas (Jusuce) ....(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="understandsabstractideas__13y"
            value={DstDetails.understandsabstractideas__13y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="section-header my-2">
          15 Year:
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Purchase for others ....(2year)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="purchaseforothers2y__15y"
            value={DstDetails.purchaseforothers2y__15y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Follows currents ...(9) ...(18)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="followscurrents__15y"
            value={DstDetails.followscurrents__15y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Boys own clothing ...(1year 2m) ...(12)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="boysownclothing1y2m__15y"
            value={DstDetails.boysownclothing1y2m__15y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Systematises own work (1year 7m) ...(6)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="systematisesownwork1y7m__15y"
            value={DstDetails.systematisesownwork1y7m__15y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mx-3">
            <Form.Label className="form-view-label">
              Makes sensible plans for future (Job) ...(4)...(24)
            </Form.Label>
            <Form.Select aria-label="Enter Select option"
            name="makessensibleplansforfuture__15y"
            value={DstDetails.makessensibleplansforfuture__15y}
            onChange={handleChange}
            >
              <option value="">-- Select --</option>
              <option value="0">0</option>
              <option value="0.5">0.5</option>
              <option value="1">1</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col></Col>
      </Row>
      </fieldset>
    </Container>
  );
};

export default DSTForm;