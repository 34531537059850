import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import inventoryApi from "../api/inventoryApi";
import PubSub from 'pubsub-js';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


const CbrWorkerCmm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [formEnable, setFormEnable] = useState(true);
    const [editForm, setEditForm] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [workerData, setWorkerData] = useState(location.state ? location.state : {});
    const [nameChild, setNameChild] = useState();


    useEffect(() => {
        async function init() {
            const result = await inventoryApi.fetchStudents();

            console.log("fetchStudents", result)
            if (result) {
                setNameChild(result);
                //setRegFormList(result);
            } else {
                setNameChild([]);
                //setRegFormList([]);
            }
        }
        init();

    }, []);

    useEffect(() => {
        if (location.state === null) {
            setFormEnable(false);
            setEditForm(true);
        }
    }, []);


    const handleSubmit = async (e) => {

        e.preventDefault();
        if (workerData.id) {
            console.log("====edit-====");
            const result = await inventoryApi.saveCmmWorkerData(workerData);
            if (result.errors) {
                setShowError(true);
                setErrorMessage(result.errors);
                return;
            }
            if (result.success) {
                console.log('if result true');
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });
                setFormEnable(true);
                setEditForm(false);
            }
        } else {
            console.log('call')
            
            const result = await inventoryApi.createWorkerCmmData(workerData);
            if (result) {
                PubSub.publish("RECORD_SAVED_TOAST", {
                    title: "Record Saved",
                    message: "Record create successfully",
                });
                navigate(`/cbrcmmworkerreports/`, { state: result });
            }
            if (result.errors) {
                setShowError(true);
                setErrorMessage(result.errors);
                return;
            }
        }
    };

    const handleEdit = () => {
        setFormEnable(false);
        setEditForm(true);
    }


    const handleCancel = () => {

        navigate(`/cbrcmmworkerreports`);
    };

    const handleChange = (e) => {
        console.log('Hello');
        setWorkerData({ ...workerData, [e.target.name]: e.target.value });
        console.log('reportData', workerData)
    };

    return (
        <Container>
            <Row>
                <Col lg={12} className="mx-3">
                    <Form
                        className="mt-3"
                        onSubmit={handleSubmit}
                        noValidate
                        validated={validated}

                    >
                        <Row className="view-form-header align-items-center">
                            <Col lg={6}>CMM Worker Report </Col>
                            {editForm === false && (
                                <Col lg={6} className="d-flex justify-content-end">
                                    {/* <Button className="btn-sm mx-2" variant="primary" onClick={() => { pdfMaker() }}>
                    <i class="fa-solid fa-download ml-2 mr-4" style={{ color: '#ffffff' }} ></i>
                    <b>CBR CMM Report</b>
                  </Button> */}
                                    <Button className="btn-sm mx-2" variant="primary" onClick={handleEdit}> <i class="fa-regular fa-pen-to-square"></i> </Button>
                                </Col>


                            )}
                            {editForm === true && (
                                <Col lg={6} className="d-flex justify-content-end">
                                    <Button className="btn-sm mx-2" type="submit" onClick={handleSubmit}> Save </Button>
                                    <Button className="btn-sm" variant="danger" onClick={handleCancel} > Cancel </Button>
                                </Col>)
                            }
                        </Row>
                    </Form>
                </Col>

            </Row>
            <fieldset disabled={formEnable}>
                <Row lg={12}>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-4" controlId="formBasicname">
                            <Form.Label
                                className="form-view-label"
                            >
                                Name of CMM
                            </Form.Label>
                            <Form.Control
                                // required
                                type="text"
                                name="name"
                                placeholder="Enter Name"
                                value={workerData.name}
                                onChange={handleChange}
                            />

                        </Form.Group>

                    </Col>
                    <Col lg={6}>
                        <Form.Group className="my-4" controlId="formBasicProjectName">
                            <Form.Label
                                className="form-view-label"

                            >
                                Name Of Project
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="projectname"
                                placeholder="Enter Project Name"
                                value={workerData.projectname}
                                onChange={handleChange}
                            />

                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 " controlId="formBasicDate">
                            <Form.Label
                                className="form-view-label"
                            >
                                Date
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="cbrdate"
                                value={moment(workerData.cbrdate).format('YYYY-MM-DD')}
                                onChange={handleChange}
                            />

                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group controlId="formBasicFiledArea">
                            <Form.Label
                                className="form-view-label"
                            >
                                CBR Field Area
                            </Form.Label>
                            <Form.Select name="cbrfieldarea" value={workerData.cbrfieldarea} onChange={handleChange}>
                                <option value="">-- Select --</option>
                                <option value="School">School</option>
                                <option value="CBR">CBR</option>
                                <option value="Day Care">Day Care</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid service area.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col lg={12} className="section-header my-4 mx-4">
                        1. Details of social security schemes
                    </Col>
                    <Col>
                        <table className="table">
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th></th>
                                    <th>1.1 <br></br> Rail Pass</th>
                                    <th>1.2 <br></br> Bus Pass</th>
                                    <th>1.4 <br></br> Disability pension</th>
                                    <th>1.4  <br></br> Niramaya enrollment</th>
                                    <th>1.5  <br></br> UID registration</th>
                                    <th>1.6  <br></br> Astha Cards</th>
                                    <th>1.7  <br></br> Guardianship </th>
                                    <th>1.8  <br></br> Aadhar Card</th>
                                    <th>1.9  <br></br> </th>
                                    <th>1.10  <br></br>  </th>
                                    <th>1.11  <br></br>  </th>
                                    <th>1.12  <br></br>  </th>
                                    <th>1.13  <br></br>  </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>Target</td>
                                    <td><input type="number" name="_1_1t" value={workerData._1_1t} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_1_2t" value={workerData._1_2t} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_1_3t" value={workerData._1_3t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_4t" value={workerData._1_4t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_5t" value={workerData._1_5t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_6t" value={workerData._1_6t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_7t" value={workerData._1_7t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_8t" value={workerData._1_8t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_9t" value={workerData._1_9t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_10t" value={workerData._1_10t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_11t" value={workerData._1_11t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_12t" value={workerData._1_12t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_13t" value={workerData._1_13t} className="form-control" onChange={handleChange} /></td>

                                </tr>

                                <tr>
                                    <td>Applied</td>
                                    <td><input type="number" name="_1_1a" value={workerData._1_1a} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_1_2a" value={workerData._1_2a} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_1_3a" value={workerData._1_3a} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_4a" value={workerData._1_4a} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_5a" value={workerData._1_5a} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_6a" value={workerData._1_6a} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_7a" value={workerData._1_7a} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_8a" value={workerData._1_8a} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_9a" value={workerData._1_9a} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_10a" value={workerData._1_10a} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_11a" value={workerData._1_11a} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_12a" value={workerData._1_12a} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_13a" value={workerData._1_13a} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td>Received</td>
                                    <td><input type="number" name="_1_1r" value={workerData._1_1r} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_1_2r" value={workerData._1_2r} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_1_3r" value={workerData._1_3r} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_4r" value={workerData._1_4r} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_5r" value={workerData._1_5r} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_6r" value={workerData._1_6r} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_7r" value={workerData._1_7r} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_8r" value={workerData._1_8r} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_9r" value={workerData._1_9r} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_10r" value={workerData._1_10r} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_11r" value={workerData._1_11r} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_12r" value={workerData._1_12r} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_1_13r" value={workerData._1_13r} className="form-control" onChange={handleChange} /></td>
                                </tr>
                            </tbody>

                        </table>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        2. Details of assistive devices providing
                    </Col>

                    <Col>
                        <table className="table">
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th></th>
                                    <th>2.1<br></br> Wheel chairs</th>
                                    <th>2.2 <br></br> Calipers</th>
                                    <th>2.3 <br></br> Specs</th>
                                    <th>2.4 <br></br> MR kits</th>
                                    <th>2.5<br></br> Hearing aids</th>
                                    <th>2.6 <br></br>Tri cycles</th>
                                    <th>2.7 <br></br> Neck holders</th>
                                    <th>2.8 <br></br> Splints</th>
                                    <th>2.9 <br></br> Brail kits</th>
                                    <th>2.10 <br></br>Soft ball</th>
                                    <th>2.11 <br></br>Other</th>
                                    <th>2.12 <br></br>Other</th>
                                    <th>2.13 <br></br>Other</th>
                                    <th>2.14 <br></br>Other</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>Target</td>
                                    <td><input type="number" name="_2_1t" value={workerData._2_1t} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_2_2t" value={workerData._2_2t} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_2_3t" value={workerData._2_3t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_4t" value={workerData._2_4t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_5t" value={workerData._2_5t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_6t" value={workerData._2_6t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_7t" value={workerData._2_7t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_8t" value={workerData._2_8t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_9t" value={workerData._2_9t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_10t" value={workerData._2_10t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_11t" value={workerData._2_11t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_12t" value={workerData._2_12t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_13t" value={workerData._2_13t} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_14t" value={workerData._2_14t} className="form-control" onChange={handleChange} /></td>

                                </tr>

                                <tr>
                                    <td>Distributed</td>
                                    <td><input type="number" name="_2_1d" value={workerData._2_1d} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_2_2d" value={workerData._2_2d} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_2_3d" value={workerData._2_3d} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_4d" value={workerData._2_4d} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_5d" value={workerData._2_5d} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_6d" value={workerData._2_6d} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_7d" value={workerData._2_7d} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_8d" value={workerData._2_8d} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_9d" value={workerData._2_9d} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_10d" value={workerData._2_10d} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_11d" value={workerData._2_11d} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_12d" value={workerData._2_12d} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_13d" value={workerData._2_13d} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_14d" value={workerData._2_14d} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td>Supported by</td>
                                    <td><input type="number" name="_2_1s" value={workerData._2_1s} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_2_2s" value={workerData._2_2s} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_2_3s" value={workerData._2_3s} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_4s" value={workerData._2_4s} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_5s" value={workerData._2_5s} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_6s" value={workerData._2_6s} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_7s" value={workerData._2_7s} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_8s" value={workerData._2_8s} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_9s" value={workerData._2_9s} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_10s" value={workerData._2_10s} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_11s" value={workerData._2_11s} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_12s" value={workerData._2_12s} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_13s" value={workerData._2_13s} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="number" name="_2_14s" value={workerData._2_14s} className="form-control" onChange={handleChange} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        3. Details of contact with stakeholders for mainstreaming and livelihood
                    </Col>
                    <Col>
                        <table className="table">
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th></th>
                                    <th>3.1<br></br> Name of Child</th>
                                    <th>3.2 <br></br> Age</th>
                                    <th>3.3 <br></br> UID</th>
                                    <th>3.4 <br></br> Name of stake holder</th>
                                    <th>3.5<br></br> Stakeholder Designation / company</th>
                                    <th>3.6 <br></br>Purpose of visit</th>
                                    <th>3.7 <br></br> Output</th>

                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td> 1. </td>
                                    {/* <td><input type="text"  name="_3_1_1"  value={workerData._3_1_1}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_3_1_1"
                                                onChange={handleChange}
                                                value={workerData._3_1_1}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>
                                    <td><input type="number" name="_3_2_1" value={workerData._3_2_1} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_3_3_1" value={workerData._3_3_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_4_1" value={workerData._3_4_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_5_1" value={workerData._3_5_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_6_1" value={workerData._3_6_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_7_1" value={workerData._3_7_1} className="form-control" onChange={handleChange} /></td>


                                </tr>
                                <tr>
                                    <td> 2. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_3_1_2"
                                                onChange={handleChange}
                                                value={workerData._3_1_2}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>
                                    {/* <td><input type="text"    name="_3_1_2"  value={workerData._3_1_2}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_3_2_2" value={workerData._3_2_2} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_3_3_2" value={workerData._3_3_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_4_2" value={workerData._3_4_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_5_2" value={workerData._3_5_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_6_2" value={workerData._3_6_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_7_2" value={workerData._3_7_2} className="form-control" onChange={handleChange} /></td>


                                </tr>
                                <tr>
                                    <td> 3. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_3_1_3"
                                                onChange={handleChange}
                                                value={workerData._3_1_3}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>
                                    {/* <td><input type="text"     name="_3_1_3"  value={workerData._3_1_3} className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_3_2_3" value={workerData._3_2_3} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_3_3_3" value={workerData._3_3_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_4_3" value={workerData._3_4_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_5_3" value={workerData._3_5_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_6_3" value={workerData._3_6_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_7_3" value={workerData._3_7_3} className="form-control" onChange={handleChange} /></td>


                                </tr>

                                <tr>
                                    <td> 4. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_3_1_4"
                                                onChange={handleChange}
                                                value={workerData._3_1_4}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>
                                    {/* <td><input type="text"  name="_3_1_4"  value={workerData._3_1_4}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_3_2_4" value={workerData._3_2_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_3_3_4" value={workerData._3_3_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_4_4" value={workerData._3_4_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_5_4" value={workerData._3_5_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_6_4" value={workerData._3_6_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_7_4" value={workerData._3_7_4} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 5. </td>
                                    <td style={{ width: '200px' }}>
                                        <Col>
                                            <Form.Group className="mx-3">
                                                <Form.Select
                                                    className="form-control"
                                                    name="_3_1_5"
                                                    onChange={handleChange}
                                                    value={workerData._3_1_5}
                                                >

                                                    <option value="">-- Select --</option>
                                                    {nameChild?.map((item, index) => (
                                                        <option value={item.name} key={item.id}>{item.name}</option>
                                                    ))}

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </td>
                                    {/* <td><input type="text"  name="_3_1_5"  value={workerData._3_1_5}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_3_2_5" value={workerData._3_2_5} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_3_3_5" value={workerData._3_3_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_4_5" value={workerData._3_4_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_5_5" value={workerData._3_5_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_6_5" value={workerData._3_6_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_3_7_5" value={workerData._3_7_5} className="form-control" onChange={handleChange} /></td>

                                </tr>


                            </tbody>
                        </table>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        4.  Details of intervantions with siblings / parents
                    </Col>
                    <Col>
                        <table className="table">
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th></th>
                                    <th>4.1<br></br> Name of child</th>
                                    <th>4.2 <br></br>UID</th>
                                    <th>4.3 <br></br>Age </th>
                                    <th>4.4 <br></br>Address</th>
                                    <th>4.5<br></br>Disability </th>
                                    <th>4.6 <br></br>Name of siblings / parents </th>
                                    <th>4.7 <br></br> What the intervantion done</th>
                                    <th>4.8 <br></br> Remarks </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> 1. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_4_1_1"
                                                onChange={handleChange}
                                                value={workerData._4_1_1}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.name}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>
                                    {/* <td><input type="text" name="_4_1_1"  value={workerData._4_1_1}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_4_2_1" value={workerData._4_2_1} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_4_3_1" value={workerData._4_3_1} className="form-control" onChange={handleChange} /></td>
                                    <td><textarea name="_4_4_1" value={workerData._4_4_1} className="form-control" onChange={handleChange}></textarea></td>
                                    <td><input type="number" name="_4_5_1" value={workerData._4_5_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_6_1" value={workerData._4_6_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_7_1" value={workerData._4_7_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_8_1" value={workerData._4_8_1} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>
                                    <td> 2. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_4_1_2"
                                                onChange={handleChange}
                                                value={workerData._4_1_2}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>
                                    {/* <td><input type="text"  name="_4_1_2"  value={workerData._4_1_2}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_4_2_2" value={workerData._4_2_2} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_4_3_2" value={workerData._4_3_2} className="form-control" onChange={handleChange} /></td>
                                    <td><textarea name="_4_4_2" value={workerData._4_4_2} className="form-control" onChange={handleChange}></textarea></td>
                                    <td><input type="number" name="_4_5_2" value={workerData._4_5_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_6_2" value={workerData._4_6_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_7_2" value={workerData._4_7_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_8_2" value={workerData._4_8_2} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 3. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_4_1_3"
                                                onChange={handleChange}
                                                value={workerData._4_1_3}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>
                                    {/* <td><input type="text"   name="_4_1_3"  value={workerData._4_1_3}    className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_4_2_3" value={workerData._4_2_3} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_4_3_3" value={workerData._4_3_3} className="form-control" onChange={handleChange} /></td>
                                    <td><textarea name="_4_4_3" value={workerData._4_4_3} className="form-control" onChange={handleChange}></textarea></td>
                                    <td><input type="number" name="_4_5_3" value={workerData._4_5_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_6_3" value={workerData._4_6_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_7_3" value={workerData._4_7_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_8_3" value={workerData._4_8_3} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 4. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_4_1_4"
                                                onChange={handleChange}
                                                value={workerData._4_1_4}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>
                                    {/* <td><input type="text"  name="_4_1_4"  value={workerData._4_1_4}    className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_4_2_4" value={workerData._4_2_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_4_3_4" value={workerData._4_3_4} className="form-control" onChange={handleChange} /></td>
                                    <td><textarea name="_4_4_4" value={workerData._4_4_4} className="form-control" onChange={handleChange}></textarea></td>
                                    <td><input type="number" name="_4_5_4" value={workerData._4_5_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_6_4" value={workerData._4_6_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_7_4" value={workerData._4_7_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_8_4" value={workerData._4_8_4} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 5. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_4_1_5"
                                                onChange={handleChange}
                                                value={workerData._4_1_5}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>
                                    {/* <td><input type="text"   name="_4_1_5" value={workerData._4_1_5} className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_4_2_5" value={workerData._4_2_5} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_4_3_5" value={workerData._4_3_5} className="form-control" onChange={handleChange} /></td>
                                    <td><textarea name="_4_4_5" value={workerData._4_4_5} className="form-control" onChange={handleChange}></textarea></td>
                                    <td><input type="number" name="_4_5_5" value={workerData._4_5_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_6_5" value={workerData._4_6_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_7_5" value={workerData._4_7_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_8_5" value={workerData._4_8_5} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 6. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_4_1_6"
                                                onChange={handleChange}
                                                value={workerData._4_1_6}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text"  name="_4_1_6" value={workerData._4_1_6}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_4_2_6" value={workerData._4_2_6}
                                        className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_4_3_6" value={workerData._4_3_6}
                                        className="form-control" onChange={handleChange} /></td>
                                    <td><textarea name="_4_4_6" value={workerData._4_4_6}
                                        className="form-control" onChange={handleChange}></textarea></td>
                                    <td><input type="number" name="_4_5_6" value={workerData._4_5_6}
                                        className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_6_6" value={workerData._4_6_6}
                                        className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_7_6" value={workerData._4_7_6}
                                        className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_8_6" value={workerData._4_8_6}
                                        className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>
                                    <td> 7. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_4_1_7"
                                                onChange={handleChange}
                                                value={workerData._4_1_7}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>
                                    {/* <td><input type="text"  name="_4_1_7" value={workerData._4_1_7}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_4_2_7" value={workerData._4_2_7} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_4_3_7" value={workerData._4_3_7} className="form-control" onChange={handleChange} /></td>
                                    <td><textarea name="_4_4_7" value={workerData._4_4_7} className="form-control" onChange={handleChange}></textarea></td>
                                    <td><input type="number" name="_4_5_7" value={workerData._4_5_7} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_6_7" value={workerData._4_6_7} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_7_7" value={workerData._4_7_7} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_8_7" value={workerData._4_8_7} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>
                                    <td> 8. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_4_1_8"
                                                onChange={handleChange}
                                                value={workerData._4_1_8}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text"  name="_4_1_8" value={workerData._4_1_8}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_4_2_8" value={workerData._4_2_8} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_4_3_8" value={workerData._4_3_8} className="form-control" onChange={handleChange} /></td>
                                    <td><textarea name="_4_4_8" value={workerData._4_4_8} className="form-control" onChange={handleChange}></textarea></td>
                                    <td><input type="number" name="_4_5_8" value={workerData._4_5_8} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_6_8" value={workerData._4_6_8} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_7_8" value={workerData._4_7_8} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_8_8" value={workerData._4_8_8} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>
                                    <td> 9. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_4_1_9"
                                                onChange={handleChange}
                                                value={workerData._4_1_9}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text" name="_4_1_9" value={workerData._4_1_9}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_4_2_9" value={workerData._4_2_9} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_4_3_9" value={workerData._4_3_9} className="form-control" onChange={handleChange} /></td>
                                    <td><textarea className="form-control" name="_4_4_9" value={workerData._4_4_9} onChange={handleChange}></textarea></td>
                                    <td><input type="number" name="_4_5_9" value={workerData._4_5_9} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_6_9" value={workerData._4_6_9} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_7_9" value={workerData._4_7_9} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_8_9" value={workerData._4_8_9} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>
                                    <td> 10. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_4_1_10"
                                                onChange={handleChange}
                                                value={workerData._4_1_10}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text"  name="_4_1_10" value={workerData._4_1_10}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_4_2_10" value={workerData._4_2_10} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_4_3_10" value={workerData._4_3_10} className="form-control" onChange={handleChange} /></td>
                                    <td><textarea name="_4_4_10" value={workerData._4_4_10} className="form-control" onChange={handleChange}></textarea></td>
                                    <td><input type="number" name="_4_5_10" value={workerData._4_5_10} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_6_10" value={workerData._4_6_10} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_7_10" value={workerData._4_7_10} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_4_8_10" value={workerData._4_8_10} className="form-control" onChange={handleChange} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>

                    <Col lg={12} className="section-header my-4 mx-4">
                        5. Details of intervantions done in classrooms
                    </Col>
                    <Col>
                        <table className="table">
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th> </th>
                                    <th>5.1<br></br> Name of child</th>
                                    <th>5.2 <br></br>Class</th>
                                    <th>5.3 <br></br>Name of teacher & school</th>
                                    <th>5.4 <br></br>Activity done</th>
                                    <th>5.5<br></br>Remarkrs</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> 1. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_5_1_1"
                                                onChange={handleChange}
                                                value={workerData._5_1_1}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text" name="_5_1_1" value={workerData._5_1_1 }  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_5_2_1" value={workerData._5_2_1} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_5_3_1" value={workerData._5_3_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_4_1" value={workerData._5_4_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_5_1" value={workerData._5_5_1} className="form-control" onChange={handleChange} /></td>

                                </tr>

                                <tr>
                                    <td> 2. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_5_1_2"
                                                onChange={handleChange}
                                                value={workerData._5_1_2}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text" name="_5_1_2" value={workerData._5_1_2}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_5_2_2" value={workerData._5_2_2} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_5_3_2" value={workerData._5_3_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_4_2" value={workerData._5_4_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_5_2" value={workerData._5_5_2} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 3. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_5_1_3"
                                                onChange={handleChange}
                                                value={workerData._5_1_3}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text"  name="_5_1_3" value={workerData._5_1_3}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_5_2_3" value={workerData._5_2_3} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_5_3_3" value={workerData._5_3_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_4_3" value={workerData._5_4_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_5_3" value={workerData._5_5_3} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 4. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_5_1_4"
                                                onChange={handleChange}
                                                value={workerData._5_1_4}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text"  name="_5_1_4" value={workerData._5_1_4}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_5_2_4" value={workerData._5_2_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_5_3_4" value={workerData._5_3_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_4_4" value={workerData._5_4_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_5_4" value={workerData._5_5_4} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 5. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_5_1_5"
                                                onChange={handleChange}
                                                value={workerData._5_1_5}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text"  name="_5_1_5" value={workerData._5_1_5}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_5_2_5" value={workerData._5_2_5} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_5_3_5" value={workerData._5_3_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_4_5" value={workerData._5_4_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_5_5" value={workerData._5_5_5} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 6. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_5_1_6"
                                                onChange={handleChange}
                                                value={workerData._5_1_6}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text"  name="_5_1_6" value={workerData._5_1_6}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_5_2_6" value={workerData._5_2_6} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_5_3_6" value={workerData._5_3_6} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_4_6" value={workerData._5_4_6} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_5_6" value={workerData._5_5_6} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 7. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_5_1_7"
                                                onChange={handleChange}
                                                value={workerData._5_1_7}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text" name="_5_1_7" value={workerData._5_1_7}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_5_2_7" value={workerData._5_2_7} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_5_3_7" value={workerData._5_3_7} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_4_7" value={workerData._5_4_7} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_5_7" value={workerData._5_5_7} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 8. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_5_1_8"
                                                onChange={handleChange}
                                                value={workerData._5_1_8}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text"  name="_5_1_8" value={workerData._5_1_8}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_5_2_8" value={workerData._5_2_8} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_5_3_8" value={workerData._5_3_8} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_4_8" value={workerData._5_4_8} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_5_8" value={workerData._5_5_8} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>
                                    <td> 9. </td>

                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_5_1_9"
                                                onChange={handleChange}
                                                value={workerData._5_1_9}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text" name="_5_1_9" value={workerData._5_1_9}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_5_2_9" value={workerData._5_2_9} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_5_3_9" value={workerData._5_3_9} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_4_9" value={workerData._5_4_9} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_5_9" value={workerData._5_5_9} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>
                                    <td> 10. </td>
                                    <td style={{ width: '200px' }}> <Col>
                                        <Form.Group className="mx-3">
                                            <Form.Select
                                                className="form-control"
                                                name="_5_1_10"
                                                onChange={handleChange}
                                                value={workerData._5_1_10}
                                            >

                                                <option value="">-- Select --</option>
                                                {nameChild?.map((item, index) => (
                                                    <option value={item.name} key={item.id}>{item.name}</option>
                                                ))}

                                            </Form.Select>
                                        </Form.Group>
                                    </Col> </td>

                                    {/* <td><input type="text"  name="_5_1_10" value={workerData._5_1_10}  className="form-control" onChange={handleChange} /> </td> */}
                                    <td><input type="number" name="_5_2_10" value={workerData._5_2_10} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_5_3_10" value={workerData._5_3_10} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_4_10" value={workerData._5_4_10} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_5_5_10" value={workerData._5_5_10} className="form-control" onChange={handleChange} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        6. Details of new IDPG formation
                    </Col>
                    <Col>
                        <table className="table">
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th></th>
                                    <th>6.1 <br></br> Name of new IDPG</th>
                                    <th>6.2 <br></br> No. of members</th>
                                    <th>6.3 <br></br> Address </th>
                                    <th>6.4 <br></br> Remark </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                </tr>
                                <tr>
                                    <td> 1. </td>

                                    <td><input type="text" name="_6_1_1" value={workerData._6_1_1} className="form-control" onChange={handleChange} />  </td>
                                    <td><input type="number" name="_6_2_1" value={workerData._6_2_1} className="form-control" onChange={handleChange} /> </td>
                                    <td><textarea className="form-control" name="_6_3_1" value={workerData._6_3_1} onChange={handleChange}></textarea></td>
                                    <td><input type="text" name="_6_4_1" value={workerData._6_4_1} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 2. </td>

                                    <td><input type="text" name="_6_1_2" value={workerData._6_1_2} className="form-control" onChange={handleChange} />  </td>
                                    <td><input type="number" name="_6_2_2" value={workerData._6_2_2} className="form-control" onChange={handleChange} /> </td>
                                    <td><textarea className="form-control" name="_6_3_2" value={workerData._6_3_2} onChange={handleChange}></textarea></td>
                                    <td><input type="text" name="_6_4_2" value={workerData._6_4_2} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 3. </td>

                                    <td><input type="text" name="_6_1_3" value={workerData._6_1_3} className="form-control" onChange={handleChange} />  </td>
                                    <td><input type="number" name="_6_2_3" value={workerData._6_2_3} className="form-control" onChange={handleChange} /> </td>
                                    <td><textarea className="form-control" name="_6_3_3" value={workerData._6_3_3} onChange={handleChange}></textarea></td>
                                    <td><input type="text" name="_6_4_3" value={workerData._6_4_3} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 4. </td>

                                    <td><input type="text" name="_6_1_4" value={workerData._6_1_4} className="form-control" onChange={handleChange} />  </td>
                                    <td><input type="number" name="_6_2_4" value={workerData._6_2_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><textarea className="form-control" name="_6_3_4" value={workerData._6_3_4} onChange={handleChange}></textarea></td>
                                    <td><input type="text" name="_6_4_4" value={workerData._6_4_4} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 5. </td>

                                    <td><input type="text" name="_6_1_5" value={workerData._6_1_5} className="form-control" onChange={handleChange} />  </td>
                                    <td><input type="number" name="_6_2_5" value={workerData._6_2_5} className="form-control" onChange={handleChange} /> </td>
                                    <td><textarea className="form-control" name="_6_3_5" value={workerData._6_3_5} onChange={handleChange}></textarea></td>
                                    <td><input type="text" name="_6_4_5" value={workerData._6_4_5} className="form-control" onChange={handleChange} /></td>

                                </tr>

                            </tbody>
                        </table>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        7. Details of IDPG meetings
                    </Col>
                    <Col>
                        <table className="table">
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th></th>
                                    <th>7.1 <br></br> Name of IDPG </th>
                                    <th>7.2 <br></br>  Number of members</th>
                                    <th>7.3 <br></br> Address</th>
                                    <th>7.4 <br></br> Issues discussed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> 1. </td>

                                    <td><input type="text" name="_7_1_1" value={workerData._7_1_1} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_7_2_1" value={workerData._7_2_1} className="form-control" onChange={handleChange} /> </td>
                                    <td><textarea className="form-control" name="_7_3_1" value={workerData._7_3_1} onChange={handleChange}></textarea></td>
                                    <td><input type="text" name="_7_4_1" value={workerData._7_4_1} className="form-control" onChange={handleChange} /></td>

                                </tr>
                                <tr>
                                    <td> 2. </td>

                                    <td><input type="text" name="_7_1_2" value={workerData._7_1_2} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_7_2_2" value={workerData._7_2_2} className="form-control" onChange={handleChange} /> </td>
                                    <td><textarea className="form-control" name="_7_3_2" value={workerData._7_3_2} onChange={handleChange}></textarea></td>
                                    <td><input type="text" name="_7_4_2" value={workerData._7_4_2} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>
                                    <td> 3. </td>

                                    <td><input type="text" name="_7_1_3" value={workerData._7_1_3} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_7_2_3" value={workerData._7_2_3} className="form-control" onChange={handleChange} /> </td>
                                    <td><textarea className="form-control" name="_7_3_3" value={workerData._7_3_3} onChange={handleChange}></textarea></td>
                                    <td><input type="text" name="_7_4_3" value={workerData._7_4_3} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>
                                    <td> 4. </td>




                                    <td><input type="text" name="_7_1_4" value={workerData._7_1_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_7_2_4" value={workerData._7_2_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><textarea className="form-control" name="_7_3_4" value={workerData._7_3_4} onChange={handleChange}></textarea></td>
                                    <td><input type="text" name="_7_4_4" value={workerData._7_4_4} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>
                                    <td> 5. </td>

                                    <td><input type="text" name="_7_1_5" value={workerData._7_1_5} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="number" name="_7_2_5" value={workerData._7_2_5} className="form-control" onChange={handleChange} /> </td>
                                    <td><textarea className="form-control" name="_7_3_5" value={workerData._7_3_5} onChange={handleChange}></textarea></td>
                                    <td><input type="text" name="_7_4_5" value={workerData._7_4_5} className="form-control" onChange={handleChange} /></td>

                                </tr>


                            </tbody>
                        </table>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        8. New identification during the month
                    </Col>
                    <Col>
                        <table className="table">
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th>8.1 <br></br>  Name</th>
                                    <th>8.2 <br></br> Father's Name</th>
                                    <th>8.3<br></br>  Age</th>
                                    <th>8.4<br></br>  Sex</th>
                                    <th>8.5 <br></br> DOB</th>
                                    <th>8.6 <br></br> Disability</th>
                                    <th>8.7 <br></br> Address</th>
                                    <th>8.8 <br></br> Date of enrollment </th>
                                    <th>8.9 <br></br> Contact number</th>
                                    <th>8.10 <br></br> Adhar no.</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <td><input type="text" name="_8_1_1" value={workerData._8_1_1} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_2_1" value={workerData._8_2_1} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_3_1" value={workerData._8_3_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_4_1" value={workerData._8_4_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="date" name="_8_5_1" value={workerData._8_5_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_6_1" value={workerData._8_6_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_7_1" value={workerData._8_7_1} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="date" name="_8_8_1" value={moment(workerData._8_8_1).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_9_1" value={workerData._8_9_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_10_1" value={workerData._8_10_1} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>

                                    <td><input type="text" name="_8_1_2" value={workerData._8_1_2} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_2_2" value={workerData._8_2_2} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_3_2" value={workerData._8_3_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_4_2" value={workerData._8_4_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="date" name="_8_5_2" value={moment(workerData._8_5_2).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_6_2" value={workerData._8_6_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_7_2" value={workerData._8_7_2} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="date" name="_8_8_2" value={moment(workerData._8_8_2).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_9_2" value={workerData._8_9_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_10_2" value={workerData._8_10_2} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>

                                    <td><input type="text" name="_8_1_3" value={workerData._8_1_3} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_2_3" value={workerData._8_2_3} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_3_3" value={workerData._8_3_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_4_3" value={workerData._8_4_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="date" name="_8_5_3" value={workerData._8_5_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_6_3" value={workerData._8_6_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_7_3" value={workerData._8_7_3} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="date" name="_8_8_3" value={moment(workerData._8_8_3).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_9_3" value={workerData._8_9_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_10_3" value={workerData._8_10_3} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>
                                    <td><input type="text" name="_8_1_4" value={workerData._8_1_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_2_4" value={workerData._8_2_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_3_4" value={workerData._8_3_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_4_4" value={workerData._8_4_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="date" name="_8_5_4" value={moment(workerData._8_5_4).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_6_4" value={workerData._8_6_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_7_4" value={workerData._8_7_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="date" name="_8_8_4" value={moment(workerData._8_8_4).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_9_4" value={workerData._8_9_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_10_4" value={workerData._8_10_4} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>

                                    <td><input type="text" name="_8_1_5" value={workerData._8_1_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_2_5" value={workerData._8_2_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_3_5" value={workerData._8_3_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_4_5" value={workerData._8_4_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="date" name="_8_5_5" value={moment(workerData._8_5_5).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_6_5" value={workerData._8_6_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_7_5" value={workerData._8_7_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="date" name="_8_8_5" value={moment(workerData._8_8_5).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_9_5" value={workerData._8_9_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_10_5" value={workerData._8_10_5} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>

                                    <td><input type="text" name="_8_1_6" value={workerData._8_1_6} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_2_6" value={workerData._8_2_6} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_3_6" value={workerData._8_3_6} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_4_6" value={workerData._8_4_6} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="date" name="_8_5_6" value={moment(workerData._8_5_6).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_6_6" value={workerData._8_6_6} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_7_6" value={workerData._8_7_6} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="date" name="_8_8_6" value={moment(workerData._8_8_6).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_9_6" value={workerData._8_9_6} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_10_6" value={workerData._8_10_6} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>

                                    <td><input type="text" name="_8_1_7" value={workerData._8_1_7} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_2_7" value={workerData._8_2_7} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_3_7" value={workerData._8_3_7} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_4_7" value={workerData._8_4_7} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="date" name="_8_5_7" value={moment(workerData._8_5_7).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_6_7" value={workerData._8_6_7} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_7_7" value={workerData._8_7_7} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="date" name="_8_8_7" value={moment(workerData._8_8_7).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_9_7" value={workerData._8_9_7} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_10_7" value={workerData._8_10_7} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>

                                    <td><input type="text" name="_8_1_8" value={workerData._8_1_8} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_2_8" value={workerData._8_2_8} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_3_8" value={workerData._8_3_8} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_4_8" value={workerData._8_4_8} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="date" name="_8_5_8" value={moment(workerData._8_5_8).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_6_8" value={workerData._8_6_8} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_7_8" value={workerData._8_7_8} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="date" name="_8_8_8" value={moment(workerData._8_8_8).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_9_8" value={workerData._8_9_8} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_10_8" value={workerData._8_10_8} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>

                                    <td><input type="text" name="_8_1_9" value={workerData._8_1_9} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_2_9" value={workerData._8_2_9} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_3_9" value={workerData._8_3_9} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_4_9" value={workerData._8_4_9} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="date" name="_8_5_9" value={moment(workerData._8_5_9).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_6_9" value={workerData._8_6_9} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_7_9" value={workerData._8_7_9} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="date" name="_8_8_9" value={moment(workerData._8_8_9).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_9_9" value={workerData._8_9_9} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_10_9" value={workerData._8_10_9} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>

                                    <td><input type="text" name="_8_1_10" value={workerData._8_1_10} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_2_10" value={workerData._8_2_10} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_3_10" value={workerData._8_3_10} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_4_10" value={workerData._8_4_10} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="date" name="_8_5_10" value={moment(workerData._8_5_10).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_6_10" value={workerData._8_6_10} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_7_10" value={workerData._8_7_10} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="date" name="_8_8_10" value={moment(workerData._8_8_10).format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_8_9_10" value={workerData._8_9_10} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_8_10_10" value={workerData._8_10_10} className="form-control" onChange={handleChange} /></td>
                                </tr>

                            </tbody>
                        </table>
                    </Col>



                    <Col lg={12} className="section-header my-4 mx-4">
                        9. Travel details
                    </Col>
                    <Col>
                        <table className="table">
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th>9.1 <br></br> Total KM traveled in last month </th>
                                    <th>9.2 <br></br> Total petrol used in last month </th>
                                    <th>9.3<br></br>  Average / KM</th>
                                    <th>9.4<br></br> Total KM traveled in current month</th>
                                    <th>9.5 <br></br>  Total petrol used in current month</th>
                                    <th>9.6 <br></br> Average / KM</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <td><input type="text" name="_9_1_1" value={workerData._9_1_1} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_9_2_1" value={workerData._9_2_1} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_9_3_1" value={workerData._9_3_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_4_1" value={workerData._9_4_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_5_1" value={workerData._9_5_1} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_6_1" value={workerData._9_6_1} className="form-control" onChange={handleChange} /></td>


                                </tr>

                                <tr>

                                    <td><input type="text" name="_9_1_2" value={workerData._9_1_2} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_9_2_2" value={workerData._9_2_2} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_9_3_2" value={workerData._9_3_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_4_2" value={workerData._9_4_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_5_2" value={workerData._9_5_2} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_6_2" value={workerData._9_6_2} className="form-control" onChange={handleChange} /></td>

                                </tr>

                                <tr>

                                    <td><input type="text" name="_9_1_3" value={workerData._9_1_3} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_9_2_3" value={workerData._9_2_3} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_9_3_3" value={workerData._9_3_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_4_3" value={workerData._9_4_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_5_3" value={workerData._9_5_3} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_6_3" value={workerData._9_6_3} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>

                                    <td><input type="text" name="_9_1_4" value={workerData._9_1_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_9_2_4" value={workerData._9_2_4} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_9_3_4" value={workerData._9_3_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_4_4" value={workerData._9_4_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_5_4" value={workerData._9_5_4} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_6_4" value={workerData._9_6_4} className="form-control" onChange={handleChange} /></td>
                                </tr>
                                <tr>

                                    <td><input type="text" name="_9_1_5" value={workerData._9_1_5} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_9_2_5" value={workerData._9_2_5} className="form-control" onChange={handleChange} /> </td>
                                    <td><input type="text" name="_9_3_5" value={workerData._9_3_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_4_5" value={workerData._9_4_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_5_5" value={workerData._9_5_5} className="form-control" onChange={handleChange} /></td>
                                    <td><input type="text" name="_9_6_5" value={workerData._9_6_5} className="form-control" onChange={handleChange} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>

                    <Col lg={12} className="section-header my-4 mx-4">
                        10.Donation/Community contribution raised during the month
                    </Col>
                    <Col lg={12}>
                        <Form.Group className="mx-4" controlId="contribution">
                            <Form.Control
                                as="textarea"
                                name="donation"
                                value={workerData.donation}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        11. Any Problem/Challenge occured during the month
                    </Col>
                    <Col lg={12}>
                        <Form.Group className="mx-4" controlId="FormControlProblemChallenge">
                            <Form.Control
                                as="textarea"
                                name="monthchallenge"
                                value={workerData.monthchallenge}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        12.Strategy used to resolve the problem/Challenge
                    </Col>
                    <Col lg={12}>
                        <Form.Group className="mx-4" controlId="strategyProblemChalenge">
                            <Form.Control
                                as="textarea"
                                name="strategy_resolve"
                                value={workerData.strategy_resolve}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="section-header my-4 mx-4">
                        13.Achievement During the month
                    </Col>
                    <Col lg={12}>
                        <Form.Group className="mx-4" controlId="AchieventDuringMonth">
                            <Form.Control
                                as="textarea"
                                name="achievement"
                                value={workerData.achievement}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>



                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="fromControlenchange">
                            <form-label>Signature of the Incharge </form-label>
                            <Form.Control
                                type="text"
                                name="signofincharge"
                                value={workerData.signofincharge}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="fromControlpathologist">
                            <form-label>Signature of CMM : </form-label>
                            <Form.Control
                                type="text"
                                name="signofcbrworkercmm"
                                value={workerData.signofcbrworkercmm}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="FromControlIncharge">
                            <form-label>Name of the Incharge </form-label>
                            <Form.Control
                                type="text"
                                name="inchargename"
                                value={workerData.inchargename}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="FronControlldate">
                            <form-label>Date </form-label>
                            <Form.Control
                                type="date"
                                name="date"
                                value={workerData.date}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="reportSubmitMonth">
                            <form-label>Report Should be Submitted by the last day of every month </form-label>
                            <Form.Control
                                type="text"
                                name="note"
                                value={workerData.note}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mx-4 my-2" controlId="contactnumber">
                            <form-label>Contact No.</form-label>
                            <Form.Control
                                type="number"
                                name="contactno"
                                value={workerData.contactno}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>

            {/* ........................................................... */}

        </Container>
    )
}

export default CbrWorkerCmm
