
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table} from "react-bootstrap";
import {useNavigate} from 'react-router-dom'
import inventoryApi from "../api/inventoryApi";
import { useLocation } from "react-router-dom";

// import { ExportJsonCsv } from 'react-export-json-csv';
import {
  DatatableWrapper,

  Pagination,
 
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import csvDownload from 'json-to-csv-export'



const ReportView = () => {
  const [arrName, setArrName] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const report = location.state;
  const [body, setBody] = useState([]);
  useEffect(() => {
    async function init() {
      console.log(report)
      console.log('reports',report.id)
      const result = await inventoryApi.fetchReportsById(report);
      console.log("api response", result);
      setArrName(Object.keys(result[0]));
      if (result)
        setBody(result);
    }
  
    init()
  }, []);


  
  const header = arrName.map((name) => ({
    title: name.charAt(0).toUpperCase() + name.slice(1),
    prop: name,
    isFilterable: true,
  }));
    
  const labels = {
      beforeSelect: " "
    }
    console.log(arrName)
    const ReportDataCSV = {
      data: body,
      filename: 'Report',
      delimiter: ',',
      headers: arrName
    }
return (

  <Row className="g-0">
  <Col lg={2} className="mx-2">
  
  </Col>

  <Col lg={12} className = "p-lg-4">

    {body ?
      <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          
        initialState: {
          rowsPerPage: 100,
         
        }
      }}>
      <row>
        <p style={{fontSize:"small"}}>Report Name</p>
      </row>

        <Row className="mb-4">
          {/* <Col
            xs={2}
            lg={2}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Filter/>
          </Col> */}
        <div class="row">
        <div class="col-6 col-sm-3"><b>{report.name}</b></div>
        <div class="col-6 col-sm-2"><Button className="btn-sm" variant="outline-primary" onClick={() => csvDownload(ReportDataCSV)} >Export CSV</Button></div>
        <div class="w-100"></div>
        </div>

        </Row>
        <Table striped className="data-table">
          <TableHeader />
          <TableBody/>
        </Table>
        <Pagination />

      </DatatableWrapper> : ''}
  </Col>
  <Col lg={2}></Col>
</Row>
);
}

export default ReportView