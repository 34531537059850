import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import * as constants from "../constants/CONSTANT";
import * as FormData from "form-data";
import axios from "axios";
import moment from "moment";
import fileDownload from 'js-file-download';
import PubSub from 'pubsub-js';

const Questionedit = (props) => {  
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [addQuestion, setAddQuestion] = useState({});
  const [selectedFiles, setSelectedFiles] = useState(null);

  useEffect(() => {
    if (location?.state?.row) {
      setAddQuestion(location.state.row);
    } else {
      setAddQuestion({ ...addQuestion, assessment: "FACP" });
    }
  }, []);

  const handleChangeFile = (event) => {
    setSelectedFiles(event.target.files[0]);
  };

  //Access all the value input values
  const handleChange = (e) => {
    setAddQuestion({ ...addQuestion, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    if (addQuestion || selectedFiles) {
      event.preventDefault();
      setValidated(false);
      console.log("files", selectedFiles);
      if (addQuestion.id) {
        console.log('if call')
        delete addQuestion.index;
        const result = await inventoryApi.saveQuestions(addQuestion.id, selectedFiles, JSON.stringify(JSON.stringify(addQuestion)));
        console.log('save', result)
        if (result) {
          PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });

          navigate(`/questions`);

        }
      } else {
        const result = await inventoryApi.createQuestions(selectedFiles,JSON.stringify(JSON.stringify(addQuestion)));
        console.log('create ', result)
        if (result) {
          PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });
          navigate(`/questions`);
        }
      }
    } else {
      setValidated(true);
    }
  };

  const handleCancel = () => {
    navigate(`/questions`);
  };

  const viewPdf = async (question) => {
    const result = await inventoryApi.downloadFiles(question.fileid);
    console.log('result:', question.filetitle);
    if(result){
      fileDownload(result, decodeURIComponent(question.filetitle));
    }
  };

  return (
    <Container className="view-form">
     
      <Row>
        <Col></Col>
        <Col lg={8}>
          <Form
            className="mt-3"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                <h5>Assessment</h5>
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicAssessments">
                  <Form.Label className="form-view-label">
                    Select Assessments
                  </Form.Label>

                  <Form.Select
                    aria-label="Enter Select option"
                    name="assessment"
                    value={addQuestion.assessment}
                    onChange={handleChange}
                    defaultValue="FACP"
                  >
                    <option value="FACP">FACP</option>
                    {/* <option value="DST">DST</option> */}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicsub-category">
                  <Form.Label className="form-view-label">
                    Select sub-category
                  </Form.Label>

                  <Form.Select
                    required
                    name="category"
                    value={addQuestion.category}
                    onChange={handleChange}
                  >
                    <option value="">-- Select --</option>
                    <option value="Pre-Primary">Pre-Primary</option>
                    <option value="Primary 1st">Primary 1st</option>
                    <option value="Primary 2nd">Primary 2nd</option>
                    <option value="Secondary">Secondary</option>
                    <option value="Pre-Vocational 1st">
                      Pre-Vocational 1st
                    </option>
                    <option value="Pre-Vocational 2nd">
                      Pre-Vocational 2nd
                    </option>
                    <option value="Care Group">Care Group</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid select sub-category.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicSection">
                  <Form.Label className="form-view-label">
                    Select Section
                  </Form.Label>

                  <Form.Select
                    aria-label="Enter Select option"
                    name="section"
                    value={addQuestion.section}
                    required
                    onChange={handleChange}
                  >
                    <option value="">-- Select --</option>
                    <option value="Personal">Personal</option>
                    <option value="Social">Social</option>
                    <option value="Academic">Academic</option>
                    <option value="Vocational">Vocational</option>
                    <option value="Recreational">Recreational</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid select section.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mx-3" controlId="formBasicQuestion">
                  <Form.Label className="form-view-label">Question</Form.Label>
                  <Form.Control
                    type="text"
                    name="question"
                    placeholder="Enter Question"
                    value={addQuestion.question}
                    required
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid question.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formFile" className="mx-3">
                  <Form.Label>Upload PDF</Form.Label>
                  <Form.Control
                    type="file"
                    name="filepdf"
                    onChange={handleChangeFile}
                    accept=".pdf"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please upload PDF.
                  </Form.Control.Feedback>
                  {addQuestion.fileid ? <Button
                className="btn-sm mt-2"
                variant="link"
                onClick={() => viewPdf(addQuestion)}
              ><i class="fa-regular fa-file-pdf text-danger fa-2xl"></i> View PDF</Button> : ''}
                </Form.Group>   
              </Col>
            </Row>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default Questionedit;

