import { Col, Container, Row, Button, Form, FormSelect,Table} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import moment from "moment"
import * as constants from "../constants/CONSTANT";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import { Link } from "react-router-dom";
const FinalAssesmentCheckList = () => {
  const navigate = useNavigate();
    const [body, setBody] = useState([]);
    useEffect(() => {
      async function init() {
        let result = []
        result = await inventoryApi.fetchAssessmentChecklist();
      console.log('result',result)
        if (result) {
            setBody(result);
            
        } else {
            setBody([]);
            
        }
    
    }
    init();
    
    
    }, []);
    
    // Create table headers consisting of 4 columns.
    const header = [
      {title: 'Client Name', prop: 'clientname', isFilterable: true,
      cell: (row) => (
        <Link
          to={"/finalassessmentchecklist/" + row.id}
          state={row}
        >
          {row.clientname}
        </Link>
      )},   
      { title: 'Client Age', prop: 'clientage', isFilterable: true },
      { title: 'Client Registration', prop: 'clientregistration', isFilterable: true },
      // { title: 'DOB', prop: 'dob', isFilterable: true,cell: (row) => moment(row.dob).format('MM/DD/YYYY'),},
      
    ];
    
    // Randomize data of the table columns.
    // Note that the fields are all using the `prop` field of the headers.
    const labels = {
      beforeSelect: " "
    }
    
    const createFinalAssesment = () =>{
        navigate(`/finalassessmentchecklist/e`);
      }
      
    
    return (
      <Row className="g-0">
        <Col lg={2} className="mx-2">
          <Link className="nav-link">Home <i class="fa-solid fa-chevron-right"></i> Final Assesment </Link>
        </Col>
        <Col lg={12} className = "p-lg-4">
    
          {body ?
            <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
              initialState: {
                rowsPerPage: 20,
                options: [5, 10, 15, 20]
              }
            }}>
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
    
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                <Button className="btn-sm" variant="outline-primary" onClick={() => createFinalAssesment(true)}>Create Final Assesment</Button>
    
                </Col>
              </Row>
              <Table striped className="data-table">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
    
            </DatatableWrapper> : ''}
        </Col>
        <Col lg={2}></Col>
      </Row>
    )
}

export default FinalAssesmentCheckList