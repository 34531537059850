import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import inventoryApi from "../api/inventoryApi";
import PubSub from 'pubsub-js';
import { clickOrFocusInput } from "react-bootstrap-typeahead/types/core/Typeahead";
import * as constants from "../constants/CONSTANT";

const ParentEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [parentsDetails, setparentsDetails] = useState(
    location.state ? location.state : {}
  );
  const [isEditable, setIsEditable] = useState(true);

  const [studentParent, setStudentParent] = useState({});
  const [ShowBplAlert, setShowBplAlert] = useState(false);

  useEffect(() => {

    if (parentsDetails.studentid) {
      setStudentParent(parentsDetails);
    }
  }, []);

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value)
    if (e.target.name === 'bplcardholder' && e.target.value === 'Yes') {
      setShowBplAlert(true);
    }
    
    else {
      setShowBplAlert(false);
    }
    setStudentParent({ ...studentParent, [e.target.name]: e.target.value });
    console.log(studentParent);
  };



  const checkRequredFields = () => {
    if (studentParent.mothername && studentParent.mothername.trim() !== "" &&
      studentParent.fathername && studentParent.fathername.trim() !== "") {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkRequredFields()) {
      console.log('====true======');
      setValidated(true);
      return;
    }

    console.log('studentParent>>>', studentParent);
    //return;

    //studentParent.studentid = parentsDetails.id;
    let result = {};
    if (studentParent.id) {
      console.log("true area")
      result = await inventoryApi.saveParentDetails(studentParent);
      PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record update successfully' });
      navigate(`/students/${studentParent.studentid}`, { state: studentParent });

    } else {
      console.log("else area")
      console.log(studentParent);
      delete studentParent.success;
      delete studentParent.message;
      studentParent.studentid = parentsDetails.id;
      result = await inventoryApi.createParentDetails(studentParent);
      console.log("create result", result);
      PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: ' Record Create successfully' });
      navigate(`/students/${parentsDetails.id}`, { state: parentsDetails });
    }
  };

  const handleCancel = () => {
    if (studentParent.id) {
      navigate(`/students/${studentParent.studentid}`, { state: studentParent });
    } else {
      navigate(`/students/${parentsDetails.id}`, { state: parentsDetails });
    }

  };


  const editPersonalInfo = () => {
    async function initStudent() {
      console.log('parentsDetails', parentsDetails)
      let result = await inventoryApi.fetchStudentPersonalInfoPartB(parentsDetails.studentid);
      console.log('call edit personal button for fetch result', result)
      if (result.id) {
        navigate(`/students/personalInfo/${result.id}`, { state: result });
      }
      else {
        navigate(`/students/${parentsDetails.id}/personalInfo`, { state: parentsDetails.studentid });
      }
    }
    initStudent();
  };

  return (
    <Container className="view-form">
      <Row>
        <Col></Col>
        <Col lg={8}>
          <Form className="mt-3" onSubmit={handleSubmit} noValidate validated={validated}>
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                <>Parent Information</>
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                {isEditable && <Button
                  className="btn-sm mx-2"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>}

                <Button
                  className="btn-sm mx-2"
                  variant="primary"
                  onClick={() => { editPersonalInfo() }}
                >
                  Personal Info <span className="numberCircle">C</span>
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <fieldset disabled={!isEditable}>
              <Row lg= {12}>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="fatherfffname">
                    <Form.Label className="form-view-label">
                      Student Name
                    </Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      name="name"
                      value={parentsDetails.name}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="fatherfffname">
                    <Form.Label className="form-view-label">
                      Father Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="fathername"
                      placeholder="Enter Father Name"
                      value={studentParent.fathername}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
             
             
              <Col lg= {6}>
                  <Form.Group className="mx-3" controlId="fatherincome">
                    <Form.Label className="form-view-label">
                      Father Income
                    </Form.Label>
                    <Form.Select name="fatherincome" onChange={handleChange} value={studentParent.fatherincome}>
                      <option value="">-- Select --</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Below 50000">Below 50000</option>
                      <option value="51000 - 100000">51000 - 100000</option>
                      <option value="100001 - 200000">100001 - 200000</option>
                      <option value="200001 - 300000">200001 - 300000</option>
                      <option value="300001 - 500000">300001 - 500000</option>
                      <option value="500001 - 1000000">500001 - 1000000</option>
                      <option value="More than 1000000">More than 1000000</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg= {6}>
                  <Form.Group className="mx-3" controlId="Education">
                    <Form.Label className="form-view-label">
                      Father Education
                    </Form.Label>

                    <Form.Select

                      required
                      name="fathereducation"
                      value={studentParent.fathereducation}
                      onChange={handleChange}
                    >
                      <option value="" >--Select--</option>
                      <option value="Illiterate">Illiterate</option>
                      <option value="Literate">Literate</option>
                      <option value="Primary">Primary</option>
                      <option value="Middle">Middle</option>
                      <option value="Secondary">Secondary </option>
                      <option value="Senior Secondary">Senior Secondary </option>
                      <option value="Graduate">Graduate</option>
                      <option value="Post Graduate">Post Graduate</option>

                    </Form.Select>

                  </Form.Group>
                </Col>
                <Col lg= {6}>
                  <Form.Group className="mx-3" controlId="Occupation">
                    <Form.Label className="form-view-label">
                      Father Occupation
                    </Form.Label>
                    <Form.Control
                      // required
                      type="text"
                      name="fatheroccupation"
                      placeholder="Enter father Occupation"
                      value={studentParent.fatheroccupation}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
            

              {/* Mother Section Start */}

             
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="Mothername">
                    <Form.Label className="form-view-label">
                      Mother Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="mothername"
                      placeholder="Enter Mother Name"
                      value={studentParent.mothername}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg= {6}>
                  <Form.Group className="mx-3" controlId="MotherEdu">
                    <Form.Label className="form-view-label">
                      Mother Education
                    </Form.Label>
                    <Form.Select
                      required
                      name="mothereducation"
                      value={studentParent.mothereducation}
                      onChange={handleChange}
                    >
                      <option value="" >--Select--</option>
                      <option value="Illiterate">Illiterate </option>
                      <option value="Literate">Literate</option>
                      <option value="Primary">Primary</option>
                      <option value="Middle">Middle</option>
                      <option value="Secondary">Secondary </option>
                      <option value="Senior Secondary">Senior Secondary </option>
                      <option value="Graduate">Graduate</option>
                      <option value="Post Graduate">Post Graduate</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
        
                <Col lg= {6}>
                  <Form.Group className="mx-3" controlId="Occupation">
                    <Form.Label className="form-view-label">
                      Mother Occupation
                    </Form.Label>
                    <Form.Control
                      // required
                      type="text"
                      name="motheroccupation"
                      placeholder="Enter Mother Occupation"
                      value={studentParent.motheroccupation}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="Income">
                    <Form.Label className="form-view-label">
                      Mother Income
                    </Form.Label>
                    <Form.Select name="motherincome" onChange={handleChange} value={studentParent.motherincome}>
                      <option value="">-- Select --</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Below 50000">Below 50000</option>
                      <option value="51000 - 100000">51000 - 100000</option>
                      <option value="100001 - 200000">100001 - 200000</option>
                      <option value="200001 - 300000">200001 - 300000</option>
                      <option value="300001 - 500000">300001 - 500000</option>
                      <option value="500001 - 1000000">500001 - 1000000</option>
                      <option value="More than 1000000">More than 1000000</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3">
                    <Form.Label className="form-view-label">
                      Family Income (Annual)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="familyincome"
                      value={studentParent.familyincome}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="EconomicStatus">
                    <Form.Label className="form-view-label">
                      Social Economic Status
                    </Form.Label>
                    <div key={`inline-radio`} className="form-view-radio">
                      <Form.Check
                        inline
                        label="High"
                        name="socialeconomicstatus"
                        value="High"
                        type="radio"
                        checked={studentParent.socialeconomicstatus === "High"}
                        id={`inline-radio-1`}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        label="Middle"
                        name="socialeconomicstatus"
                        value="Middle"
                        type="radio"
                        checked={studentParent.socialeconomicstatus === "Middle"}
                        id={`inline-radio-2`}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        label="Low"
                        name="socialeconomicstatus"
                        value="Low"
                        type="radio"
                        checked={studentParent.socialeconomicstatus === "Low"}
                        id={`inline-radio-2`}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Group>
                </Col>
                
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="BPLholder">
                    <Form.Label className="form-view-label">
                      BPL Card Holder
                    </Form.Label>
                    <div key={`inline-radio`} className="form-view-radio2">
                      <Form.Check
                        inline
                        label="Yes"
                        name="bplcardholder"
                        value="Yes"
                        type="radio"
                        checked={studentParent.bplcardholder === "Yes"}
                        id={`inline-radio-1`}
                        onChange={handleChange}
                      />
                      <Form.Check
                        inline
                        label="No"
                        name="bplcardholder"
                        value="No"
                        type="radio"
                        checked={studentParent.bplcardholder === "No"}
                        id={`inline-radio-2`}
                        onChange={handleChange}
                      />
                      {(ShowBplAlert === true || studentParent.bplcardholder === 'Yes') && 
                        <Alert variant="danger" className="alertBPL danger-alert">
                          Please upload your BPL information in the file section.
                        </Alert>
                      }


                    </div>
                  </Form.Group>
                </Col>
               
              </Row>
            </fieldset>
          </Form>
        </Col>
        <Col></Col>
      </Row>
      
    </Container>
  );
};

export default ParentEdit;

