import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormCheck,
  Row,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import ListGroup from "react-bootstrap/ListGroup";
import { useLocation } from "react-router-dom";

import { DatatableWrapper, TableBody, TableHeader, Pagination, PaginationOptions, } from "react-bs-datatable";

const AssignQuestions = () => {
  let [filterObj, setFilterObj] = useState({});

  const location = useLocation();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState(location.state ? location.state : {});
  const [body, setBody] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [assessmentquestions, setSelectedItems] = useState([]);
  const [IEPlist, setIEPlist] = useState([]);
  const [assessmentList, setassessmentList] = useState([]);
  const [questionIdArray, setquestionIdArray] = useState([]);
  const [previous,setPrevious] = useState(false);
  let [sectionValue, setSectionValue] = useState("");
  let [categoryValue, setCategoryValue] = useState("");
  const [categoryArray, setCategoryArra] = useState([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    //console.log(questions);
    //console.log("UsEffect");
    async function init() {
      if(!questions.assessmentquestions){
      const result = await inventoryApi.fetchQuestions();
      if (result) {
        //console.log("result", result);
        let tempBody = result.map((data, index) => ({
          ...data,
          index: index + 1,
        }));
        setBody(tempBody);
        //questions.allquestions = tempBody;
        //setFilterData(tempBody);
        setFilterObj({...filterObj, ['assessment'] : questions.category});

      } else {
        setBody([]);
        setFilterData([]);
      }
   }else{
    setBody(questions.allquestions);
    setFilterObj({...filterObj, ['assessment'] : questions.category});
    setSelectedItems(questions.assessmentquestions);
   }
    }
    init();
    fetchIEP();
    fetchAssessments();
  }, []);

  useEffect(() => {
    doFilterData();
  }, [filterObj]);

  const onFilterSelection = (event) => {
    setFilterObj({...filterObj, [event.target.name] : event.target.value});

 }

 const doFilterData = (event) => {
  let tempData = body.filter((item) => {
    if(!filterObj.assessment || filterObj.assessment === '' || item.assessment === filterObj.assessment){
     
        return item;
    }
  });
  
  tempData = tempData.filter((item) =>{
   
    if(!filterObj.category || filterObj.category === '' || item.category === filterObj.category){
      
        return item;
    }
  });

  tempData = tempData.filter((item) =>{
    if(!filterObj.section || filterObj.section === '' || item.section === filterObj.section){
        return item;
    }
  });

  setFilterData(tempData);

  

 }


  const fetchIEP = async (e) => {
    const result = await inventoryApi.fetchIEPs();
    if (result) {
      //console.log("result", result);
      //let tempBody = result.map((data, index) => ({ ...data, index: index + 1 }));
      setIEPlist(result);
    } else {
      setIEPlist([]);
    }
  };

  const fetchAssessments = async (e) => {
    const result = await inventoryApi.fetchAssessments();
    if (result) {
      //console.log("result", result);
      //let tempBody = result.map((data, index) => ({ ...data, index: index + 1 }));
      setassessmentList(result);
    } else {
      setassessmentList([]);
    }
  };
  const header = [
    {
      title: "#",
      prop: "id",
      cell: (row) => (
        <input
          to={row.id}
          name = {row.id}
          type="checkbox"
          checked={row.checked}
          onChange={(e) => handleValueChange(e, row)}
        />
      ),
    },
    { title: "Questions", prop: "question", isFilterable: true },
  ];

  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const handlePreviousButton = async (e) => {
    navigate(`/assessments/e`, { state: questions});
    setPrevious(true)
   };



  const handleNextButton = (e) => {
    //console.log('next:', questions);
    if(!questions.assessmentquestions || questions.assessmentquestions.length === 0){
      setShowError(true);
      return;
    }
    navigate('/assessments/e/assignstudents', { state: questions});
  };


  const handleValueChange = (e, item) => {
      const { name, checked } = e.target;

      
       let tempSelection = body.filter((item) =>{
        if(item.id === name){
          item['checked'] = checked;
          return item;
        }
        return item;
      });

      tempSelection = tempSelection.filter((item) =>{
        if(item.checked){
          return item;
        }
      });

      //console.log('tempSelection:', tempSelection);
      setSelectedItems(tempSelection);
      setQuestions({ ...questions, assessmentquestions: tempSelection});
      
    
  };
  
  
  const handleRemoveSelected = (id)=>{
    let tempSelection = assessmentquestions.filter((item) =>{
      if(item.id === id){
        item['checked'] = false;
      }else
        return item;
    });
    
    setSelectedItems(
      tempSelection
    );
    
    setQuestions({ ...questions, assessmentquestions: tempSelection});
  }

  

  

  return (
    <Container>
      
      <Row >
        <Col lg={12}>
          <ListGroup className="listGroup">
            <ListGroup.Item
              as="li"
              style={{ backgroundColor: "#1A293B", color: "white" }}
            >
              <b>Select Questions</b><b className="float-end">Step 2 of 3</b>

            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row className="justify-content-md-center mx-2 my-2">
      <Col className="mx-3 col-lg-3 ">
          <b>Filter by Assessment</b>
          <Form.Select
                    aria-label="Enter Select option"
                    name="assessment"
                    onChange={onFilterSelection}
                    value={questions.category}
                    disabled
                  >
                    <option value=''>--Select --</option>
                    <option value="FACP">FACP</option>
                    <option value="DST">DST</option>
                  </Form.Select>
        </Col>
        <Col className="mx-3 col-lg-3 ">
          <b>Filter by Category</b>
          <Form.Select name="category" onChange={onFilterSelection}>
          <option value="">-- Select --</option>
            <option value="Pre-Primary">Pre-Primary</option>
            <option value="Primary 1st">Primary 1st</option>
            <option value="Primary 2nd">Primary 2nd</option>
            <option value="Secondary">Secondary</option>
            <option value="Pre-Vocational 1st">Pre-Vocational 1st</option>
            <option value="Pre-Vocational 2nd">Pre-Vocational 2nd</option>
            <option value="Care Group">Care Group</option>
          </Form.Select>
        </Col>
        <Col className="mx-3 col-lg-3 ">
          <b>Filter by Section</b>
          <Form.Select
            aria-label="Enter Select option"
            name="section"
            onChange={onFilterSelection}
          >
            <option value="">-- Select --</option>
            <option value="Personal">Personal</option>
            <option value="Social">Social</option>
            <option value="Academic">Academic</option>
            <option value="Vocational">Vocational</option>
            <option value="Recreational">Recreational</option>
          </Form.Select>
        </Col>
      </Row>
     <Row className="m-2">
     {showError ? <Alert show={showError} variant="danger" className="error-alert">Select atleast one question</Alert> : ''}
     </Row>
        <Row className="g-0">
          <Col lg={6} className="p-lg-2 ">
            
          {filterData ?
        <DatatableWrapper body={filterData} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20]
          }
        }}>
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
            
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
              {/* <PaginationOptions labels={labels} /> */}

            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              {/* <Button className="btn-sm" variant="outline-primary" onClick={() => createService(true)}>Create Service Record</Button> */}
            </Col>
          </Row>
          <Table striped className="data-table childtable">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />

        </DatatableWrapper> : ''}
          
          </Col>

          <Col lg={6}>
            <ListGroup className = 'listGroup' >
              <ListGroup.Item
                as="li"
                className = "listItem"
                
              >
                <b style={{color: 'white'}}>Selected Question</b>{" "}
              </ListGroup.Item>
              {assessmentquestions?.map((item, index) => (
                <ListGroup.Item
                  as="li"
                  key={item.id}
                  style={{ height: "35px" }}
                >
                  <button
                    className="btn-sm mx-2"
                    style={{ borderStyle: "none" }}
                    variant="danger"
                    onClick={() => {
                      handleRemoveSelected(item.id)
                    }}
                  >
                    <i class="fa-solid fa-xmark text-danger"></i>
                  </button>
                  {item.question}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      
      
        <Row className="justify-content-center my-4">
          <Col className="col-lg-2">
            <Button variant="primary" onClick={handlePreviousButton} className="mx-2">
              Previous
            </Button>
            <Button variant="primary" onClick={handleNextButton} >
              Next
            </Button>
          </Col>

        </Row> 
    </Container>
  );
};

export default AssignQuestions;