import * as constants from "../constants/CONSTANT";
import authApi from "../api/authApi";

const inventoryApi = {

  async fetchStudents() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/students", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async editParentInfo() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/parent", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchUsers() {
    const token = localStorage.getItem("token");
    //console.log(constants.API_BASE_URL + "/api/auth");
    let response = await fetch(constants.API_BASE_URL + "/api/auth", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const result = await response.json();
    //console.log("----->", JSON.stringify(result));

    if (result.length > 0) {
      return result;
    }
    return null;
  },
  // --------------------------------Students ---------------------------------------------


  async fetchStudentById(studentId) {
    console.log('studentId', studentId)
    const token = localStorage.getItem("token");
    //console.log(studentId);
    let response = await fetch(
      constants.API_BASE_URL + "/api/students/" + studentId,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log('student fetched response ', response);
    return await response.json();
  },



  async createStudent(student) {
    //console.log("if data enter");
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/students", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(student),
    });
    //console.log("response,", response);
    return await response.json();
  },

  async saveStudent(student) {
    //console.log("Enter parentsDetails data", student);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/students/" + student.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(student),
      }
    );
    //console.log(response);
    return await response.json();
  },

  async fetchStudentParent(student) {
    //console.log("Enter in inventory", student);
    const token = localStorage.getItem("token");

    let response = await fetch(
      constants.API_BASE_URL + "/api/students/" + student.id + "/parent",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    //console.log("response", response);

    return await response.json();
  },


  async fetchAllStuWorkBehave() {
    const token = localStorage.getItem("token");

    let response = await fetch(
      constants.API_BASE_URL + "/api/workassessments",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log("response", response);

    return await response.json();
  },



  async fetchStuWorkBehaveById(student) {
    console.log('student', student)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/workassessments/student/" + student.id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log("response", response);
    return await response.json();
  },



  async fetchParent(parentsDetails) {
    //console.log("Enter in inventory", parentsDetails);
    const token = localStorage.getItem("token");

    let response = await fetch(
      constants.API_BASE_URL + "/api/students/" + parentsDetails.id + "/parent",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    //console.log("response", response);
    return await response.json();
  },

  async createParentDetails(studentParent) {
    console.log("Enter parent data", studentParent);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/parents", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(studentParent),
    });
    //console.log("response", response);

    return await response.json();
  },

  async saveParentDetails(studentParent) {
    //console.log("if data enter", studentParent);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/parents/" + studentParent.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(studentParent),
      }
    );
    //console.log("response,", response);
    return await response.json();
  },

  async fetchStudentAssessment(student) {
    //console.log("=====?", student.id);
    const token = localStorage.getItem("token");
    //console.log(student.id);
    let response = await fetch(
      constants.API_BASE_URL +
      "/api/students/" +
      student.id +
      "/studentpreassessment",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    //console.log("api ", response);
    return await response.json();
  },

  async fetchStudentPhysioAssessment(student) {
    //console.log("=====?", student.id);
    const token = localStorage.getItem("token");
    //console.log(student.id);
    let response = await fetch(
      constants.API_BASE_URL +
      "/api/students/" +
      student.id +
      "/physioassessment",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    //console.log("api ", response);
    return await response.json();
  },


  async fetchStudentAssessmentByPartC(student) {
    //console.log("=====?", student);
    const token = localStorage.getItem("token");

    let response = await fetch(
      constants.API_BASE_URL +
      "/api/students/" +
      student +
      "/studentpreassessment",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    //console.log("api ", response);
    return await response.json();
  },
  async savePreAssessment(studentassessment) {
    //console.log("Enter assessment data", studentassessment);
    //console.log("invention id", studentassessment);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL +
      "/api/studentpreassessments/" +
      studentassessment.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(studentassessment),
      }
    );
    //console.log(response);
    return await response.json();
  },


  async createPreAssessment(studentassessment) {
    //console.log("if data enter", studentassessment);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/studentpreassessments",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(studentassessment),
      }
    );
    //console.log("response,", response);
    return await response.json();
  },


  async saveAssessments(questions) {
    //console.log("Enter  data", questions);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/assessments/" + questions.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(questions),
      }
    );
    //console.log(response);
    return await response.json();
  },



  async fetchStudentPersonalInfo(student) {
    //console.log("=====?", student.id);
    const token = localStorage.getItem("token");
    //console.log(student.id);
    let response = await fetch(
      constants.API_BASE_URL +
      "/api/students/" +
      student.id +
      "/studentpersonalhistory",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    //console.log(response);
    return await response.json();
  },


  async fetchStudentPersonalInfoPartB(student) {
    //console.log("=====?", student);
    const token = localStorage.getItem("token");
    //console.log(student.id);
    let response = await fetch(
      constants.API_BASE_URL +
      "/api/students/" +
      student +
      "/studentpersonalhistory",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    //console.log(response);
    return await response.json();
  },



  async savePersonalInfo(state) {
    //console.log("Enter personalInfo data", state);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/studentpersonalhistories/" + state.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(state),
      }
    );
    //console.log(response);
    return await response.json();
  },

  async createPersonalInfo(state) {
    //console.log("if data enter", state);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/studentpersonalhistories",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(state),
      }
    );
    //console.log("response,", response);
    return await response.json();
  },



  async getCurrentUserTrackingRec() {
    const token = localStorage.getItem("token");
    //console.log("getCurrentUserTrackingRec");
    let response = await fetch(
      constants.API_BASE_URL + "/api/usertrackings/track/currentrecord",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (response.status === 401) {
      //authApi.logout();
    }
    const result = await response.json();
    //console.log("current record from api  in fetch =>", result);
    if (result) {
      ////console.log('result in fetch => ', result)
      return result;
    }
    return null;
  },


  async createCheckInRecord(locationRecord) {
    const token = localStorage.getItem("token");
    //console.log("UserLocationRecord => ", locationRecord);

    let response = await fetch(constants.API_BASE_URL + "/api/usertrackings", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(locationRecord),
    });

    if (response.status === 401) {
      //authApi.logout();
    }
    const result = await response.json();
    //console.log("result in fetch =>", result);
    if (result) {
      ////console.log('result in fetch => ', result)
      return result;
    }
    return null;
  },


  async handlCheckOut(locationRecord) {
    const token = localStorage.getItem("token");
    //console.log("handlCheckOut => ", locationRecord);
    let response = await fetch(
      constants.API_BASE_URL + "/api/usertrackings/" + locationRecord.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(locationRecord),
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log("rsult in checkout => ", result);
    if (result) {
      return result;
    }
    return null;
  },



  async fetchQuestions() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/questions", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },



  async fetchQuestionsByCategory(category) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/questions/assessment/" + category, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async createStaffMember(staffMember) {
    //console.log("Enter parent data", staffMember);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/createuser", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(staffMember),
    });
    //console.log("response", response);

    return await response.json();
  },





  async saveStaffMember(staffMember) {
    //console.log("Enter staff data", staffMember);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL +
      "/api/auth/" +
      staffMember.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(staffMember),
      }
    );
    //console.log(response);
    return await response.json();
  },



  async fetchProfileImage(parentId) {
    //console.log('--parentid--', parentId)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/" + parentId + "/profileimage", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });

    const result = await response.json();
    //console.log('result', result);
    //console.log(response)
    return result;

  },

  async fetchDashBoardData() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/dashboards", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log(result);

    return result;

  },


  async saveStaffMemberEditProfile(userid, selectedFiles, staffMember) {
    const formData = new FormData();
    formData.append('file', selectedFiles);
    formData.append('staffRecord', staffMember);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL +
      "/api/auth/" +
      userid + "/profile",
      {
        method: "PUT",
        mode: "cors",
        headers: {
          Authorization: token,
        },
        body: formData,
      }
    );
    return await response.json();
  },



  async fetchIEPs() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/ieps", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchActiceIEP() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/ieps/active", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async createIEP(iep) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/ieps",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(iep),
      }
    );
    return await response.json();
  },


  async saveIEP(iep) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/ieps/" + iep.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(iep),
      }
    );
    return await response.json();
  },
  async fetchStaffMember() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/users", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },


  async createQuestions(selectedFiles, addQuestion) {
    const formData = new FormData();
    console.log('selectedFiles',selectedFiles)
    formData.append('filepdf', selectedFiles);
    formData.append('question', addQuestion);
    
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/questions", {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: token,
      },
      body: formData,
    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },




  async saveQuestions(questionId, selectedFiles, addQuestion) {

    const formData = new FormData();
    const file_ = new File([selectedFiles], encodeURIComponent(selectedFiles.name), { type: selectedFiles.type });


    formData.append('filepdf', file_);
    formData.append('question', addQuestion);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/questions/" + questionId,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          Authorization: token,

        },
        body: formData,
      }
    );
    return await response.json();
  },


  async fetchAssessments() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/assessments", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchrequireddocuments(studentId) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/students/" + studentId + "/requireddocuments",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return await response.json();
  },

  async fetchPhysioAssessment(studentid) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/physioassessments/" + studentid, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async createAssessments(questions) {
    console.log('data',questions)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/assessments",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(questions),
      }
    );
    return await response.json();
  },


  async fetchRelatedQuesions(assessment) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/assessments" + assessment.id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },

    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },



  async fetchManager(userValue) {
    const token = localStorage.getItem("token");

    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/managers/" + userValue,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return await response.json();
  },


  async fetchServiceArea() {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/servicecategories",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return await response.json();
  },


  async fetchManagers() {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/managers/",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    return await response.json();
  },

  async saveServiceArea(servicearea) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/servicecategories/" + servicearea.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(servicearea),
      }
    );
    return await response.json();
  },

  async createServiceArea(servicearea) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/servicecategories", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(servicearea),
    });
    return await response.json();
  },
  async fetchFacpResult(studentid, assessmentId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/assessmentresults/getreport/" + studentid + '/' + assessmentId, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchAssessmentsAndRelated(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/assessments/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },




  async fetchFiles(parentId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + parentId + "/all", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });

    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  async fetchStudentPhoto(parentId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + parentId + "/all/Current Child Photo", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });

    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  async createFiles(selectedFiles, fileDescription, documenttype, parentId) {

    const formData = new FormData();
    formData.append('file', selectedFiles);
    formData.append('description', fileDescription);
    formData.append('documenttype', documenttype);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + parentId, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: token,
      },
      body: formData,
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },


  async getLoginUserData() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/getuser", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    return result;

  },
  async downloadFiles(fileid) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + fileid + "/download",
      {
        method: "GET",
        headers: {
          "Authorization": token
        }
      }
    );
    const fileBody = await response.blob();
    return fileBody;

  },


  async saveFiles(file) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + file.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(file),
      }
    );

    return await response.json();

  },

  async deleteFile(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();

  },



  
  async deleteQuestion(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/questions/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();

  },



  async deleteAssessment(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/assessments/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },

  // --------------------------------- Report ----------------------------------------


  async getQuestions(assesname, stdid) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/assessmentresults/questions/" + assesname + "/" + stdid, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async getAssessmentQuestions(assesname, stdid, assesmentId) {
    console.log('coming',assesname, stdid, assesmentId)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/assessmentresults/questions/" + assesname + "/" + stdid + "/" + assesmentId, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },




  async fetchOptionsByCategory(questionType) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/questions/" + questionType + "/options", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async getMarks(stdid, assesid) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/assessmentresults/getmarks/" + stdid + "/" + assesid, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async addMarks(studentassessment) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/assessmentresults/addmarks", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(studentassessment),
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async replaceStaffMember(staffMemberId, replacementStaffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/replacestaff/" + staffMemberId + '/' + replacementStaffId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async createDST(dst) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/dsts", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(dst),
    });
    return await response.json();
  },

  async saveDST(dsts) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/dsts/" + dsts.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(dsts),
      }
    );
    return await response.json();
  },

  async fetchStaffById(staffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/users/" + staffId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async fetchReports() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  async fetchReportsById(row) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports/" + row.id,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
    return await response.json();
  },

  async fetchUsertrackingsWithstaffId(staffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/usertrackings/staff/" + staffId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  async fetchDSTWithStudentId(studentId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/dsts/" + studentId + "/student", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async updateUser(user) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/updatepassword",
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(user),
      }
    );
    return await response.json();
  },

  async fetchCaseHistoryQuestions(type) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/casehistorytest/" + type, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;

  },

  async fetchCaseHistoryResults(studentid, category) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/casehistorytestresult/" + studentid + "/" + category, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;

  },

  async saveCaseHistoryQuestions(questionResult) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/casehistorytestresult", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(questionResult),

    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },
  async fetchFACPAssessmentInfo(studentid) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/assessments/student/" + studentid, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchCbrReportInfoById(cbrId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/cbrspeechpathologist/" + cbrId, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchCbrReportInfo() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/cbrspeechpathologist/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },


  async createCbrReportData(reportData) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/cbrspeechpathologist/", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(reportData),

    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },


  async saveCbrReportData(cbrId, reportData) {
    const token = localStorage.getItem("token");
    let response = await fetch(

      constants.API_BASE_URL + "/api/cbrspeechpathologist/" + cbrId,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(reportData),
      }
    );

    return await response.json();

  },

  //.................................PhysioAssessment Apis..........................

  async createPhysioAssess(physioData) {
    console.log('create inventory',physioData)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/physioassessments/", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(physioData),

    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async savePhysioAssess(physioData) {
    console.log('update inventory',physioData)
    const token = localStorage.getItem("token");
    let response = await fetch(

      constants.API_BASE_URL + "/api/physioassessments/" + physioData.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(physioData),
      }
    );

    return await response.json();

  },

  async fetchPhysioAssessments() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/physioassessments/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  //.................................CbrWorkerReport Apis..........................

  async createCbrWorkerReport(workerData) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/cbrworkerreports/", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(workerData),

    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async saveCbrWorkerReport(workerData) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/cbrworkerreports/" + workerData.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(workerData),
      }
    );
    return await response.json();

  },

  async fetchCbrWorkerReports() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/cbrworkerreports/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchCbrWorkerReportById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/cbrworkerreports/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result != null) {
      return result;
    }
    return null;
  },

  //.................................HomeBasedCbr Apis..........................

  async createHomeBasedCbr(newRec) {
    //console.log('called api')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/homebasedcbrs/", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(newRec),

    });
    const result = await response.json();
    ////console.log('result',result)
    if (result) {
      return result;
    }
    return null;
  },

  async saveHomeBasedCbr(updatedRec) {
    const token = localStorage.getItem("token");
    let response = await fetch(

      constants.API_BASE_URL + "/api/homebasedcbrs/" + updatedRec.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(updatedRec),
      }
    );

    return await response.json();

  },

  




  async updatestudentbehave(recordid,body) {
    console.log('recordid,body',recordid,body)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/studentbehaviours/" + recordid,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(body),
      }
    );

    return await response.json();

  },

  async fetchHomeBasedCbrRecs() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/homebasedcbrs/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchHomeBasedCbrById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/homebasedcbrs/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log('inventory ka method called >>> ', result);
    if (result != null) {
      return result;
    }
    return null;
  },


  async fetchHomeBasedCbrById(studentid) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/homebasedcbrs/student/" + studentid, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });


    const result = await response.json();
    //console.log('inventory ka method called >>> ', result);
    if (result != null) {
      return result;
    }
    return null;
  },




  //.................................CMH WorkerReport Apis..........................
  async createCmhWorkerReport(workerData) {
    //console.log('called api')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/cbrcmhreports", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(workerData),

    });
    const result = await response.json();
    ////console.log('result',result)
    if (result) {
      return result;
    }
    return null;
  },

  async fetchCmhWorkerReport() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/cbrcmhreports/", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchCmhWorkerReportById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/cbrcmhreports/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log('inventory ka method called >>> ', result);
    if (result != null) {
      return result;
    }
    return null;
  },

  async saveCmhWorkerReport(workerData) {
    const token = localStorage.getItem("token");
    let response = await fetch(

      constants.API_BASE_URL + "/api/cbrcmhreports/" + workerData.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(workerData),
      }
    );

    return await response.json();

  },

  //********************************* CBR CMM Worker Create ***********************************************/ 
  async createWorkerCmmData(workerData) {
    //console.log("if data enter");
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/cbrcmmreports", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(workerData),
    });
    //console.log("response,", response);
    return await response.json();
  },


  //********************************* CBR CMM  Worker GET ***********************************************/ 
  //async   

  async fetchCBRWCmmorkerList() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/cbrcmmreports", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  //********************************* CBR CMM Worker By Id ***********************************************/ 

  async fetchCmmWorkerId(workerId) {
    console.log('id',workerId)
    const token = localStorage.getItem("token");
    //console.log(workerId);
    let response = await fetch(
      constants.API_BASE_URL + "/api/cbrcmmreports/" + workerId,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log('student fetched ', response);
    return await response.json();
  },

  //********************************Save CBR CMM *********************************

  async saveCmmWorkerData(worker) {
    //console.log("Enter worker data", worker);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/cbrcmmreports/" + worker.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(worker),
      }
    );
    //console.log(response);
    return await response.json();
  },



  // WORK BEHAVIOUR 

  ////******************************* Work_bEhaviour ******************************************************8 */
  async createWorkBehaviour(work) {
    console.log("Enter parentsDetails data", work);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/workassessments/",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(work),
      }
    );
    console.log(response);
    return await response.json();
  },

  async fetchWorkBhaviour() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/work_behaviours/", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },





  async deleteworkBehaviour(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/work_behaviours/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const result = await response.json();
    console.log('res inventory => ', result);
    if (result) {
      return result;
    }
    return null;
  },



  async deleteStudent(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/students/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },






  ////******************************* Work_bEhaviour ******************************************************8 */
  async createWorkBehaviour(work) {
    console.log("Enter parentsDetails data", work);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/workassessments/",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(work),
      }
    );
    console.log(response);
    return await response.json();
  },

  async fetchWorkBhaviour() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/workassessments/", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },
  async fetchWorkBhaviourById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/workassessments/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const result = await response.json();
    console.log('res inventory => ', result);
    if (result) {
      return result;
    }
    return null;
  },




  async fetchWorkBehaveById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/workassessments/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const result = await response.json();
    console.log('res inventory => ', result);

    return result;

  },


  async fetchWorkBhaviourByStudentId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/workassessments/student/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const result = await response.json();
    console.log('res inventory => ', result);

    return result;

  },

  async saveWorkBhaviour(id, work) {
    console.log("Enter parentsDetails data", work);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/workassessments/" + id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(work),
      }
    );
    console.log(response);
    return await response.json();
  },


  async deleteQuotation(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/workassessments/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const result = await response.json();
    console.log('res inventory => ', result);
    if (result) {
      return result;
    }
    return null;
  },



  //******************************** Create JobAnalysis *********************************


  async createJob(job) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/jobanalysis",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(job),
      }
    );
    console.log("response,", response);
    return await response.json();
  },

  //********************************* GET JObAnalysis **********************************/ 

  async fetchJob() {
    console.log("**********CALLING CONSOLE***************");
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/jobanalysis", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  //********************************* GET Job  By Id ***********************************************/ 

  async fetchJobById(jobId) {
    const token = localStorage.getItem("token");
    console.log(jobId);
    let response = await fetch(
      constants.API_BASE_URL + "/api/jobanalysis/" + jobId,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log('student fetched ', response);
    return await response.json();
  },

  async fetchJobAnaStudentById(studentid) {
    const token = localStorage.getItem("token");
    console.log(studentid);
    let response = await fetch(
      constants.API_BASE_URL + "/api/jobanalysis/student/" + studentid,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log('student fetched ', response);
    return await response.json();
  },



  

  async fetchIndianAutishById(autismid) {
    const token = localStorage.getItem("token");
    console.log(autismid);
    let response = await fetch(
      constants.API_BASE_URL + "/api/indianautisms/" + autismid,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log('student fetched ', response);
    return await response.json();
  },


  //******************************** Update Job *********************************

  async saveJob(id, job) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/jobanalysis/" + id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(job),
      }
    );
    console.log(response);
    return await response.json();
  },

  //*********************************  Delete Job ***********************************************/ 
  async deleteJob(id) {
    console.log('delete call', id)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/jobanalysis/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },



  //******************************** Create Vocational *********************************


  async createVoc(voc) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/vocationals/",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(voc),
      }
    );
    console.log("response,", response);
    return await response.json();
  },
  //********************************* GET Vocational **********************************/ 

  async fetchVoc() {
    console.log("**********CALLING CONSOLE***************");
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/vocationals", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  //********************************* GET Vocational  By Id ***********************************************/ 

  async fetchVocById(vocId) {
    const token = localStorage.getItem("token");
    console.log(vocId);
    let response = await fetch(
      constants.API_BASE_URL + "/api/vocationals/" + vocId,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log('vocational fetched ', response);
    return await response.json();
  },


  async fetcStudentbeavebyid(studentid) {
    const token = localStorage.getItem("token");
    console.log(studentid);
    let response = await fetch(
      constants.API_BASE_URL + "/api/studentbehaviours/" + studentid,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log('vocational fetched ', response);
    return await response.json();
  },



  
  async fetchVocStudentByid(studentid) {
    const token = localStorage.getItem("token");
    console.log(studentid);
    let response = await fetch(
      constants.API_BASE_URL + "/api/vocationals/student/" + studentid,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log('vocational fetched ', response);
    return await response.json();
  },




  async saveVoc(id, voc) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/vocationals/" + id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(voc),
      }
    );
    console.log(response);
    return await response.json();
  },


  ////*******************************Sum Of Vocationals ******************************************************8 */

  async fetchSumOfVocational(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/vocationals/sum/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log("================", result);
    if (result != null) {
      return result;
    }
    return null;
  },

  async fetchSumOfStudents(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/workassessments/sum/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log("================", result);
    if (result != null) {
      return result;
    }
    return null;
  },


  ////*******************************Sum Of Job analysis ******************************************************8 */

  async fetchSumOfJobAnalysis(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/jobanalysis/sum/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log("================", result);
    if (result != null) {
      return result;
    }
    return null;
  },


  //********************************* Indian Autism Create ***********************************************/
  async createIndianAutism(indianautismData) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indianautisms", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(indianautismData),
    });
    return await response.json();
  },


  //********************************* Indian Autism GET ***********************************************/ //async

  async fetchIndianAutismList() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/indianautisms", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  //********************************* Indian Autism By Id ***********************************************/

  async fetchIndianAutismId(indianautismId) {
    console.log(indianautismId);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/indianautisms" + indianautismId,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log(response)
    return await response.json();
  },

  //********************************Save Indian Autism *********************************

  async saveIndianAutism(completeDataId, completeData) {
    console.log("==Enter indianautism data==>", completeDataId);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/indianautisms/" + completeDataId,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(completeData),
      }
    );
    return await response.json();
  },


  async fetchAssessmentChecklist() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/assesmentchecklist", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    
    const result = await response.json();
    console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },




  async createFinalAssessment(assesmentchecklist) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/assesmentchecklist", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(assesmentchecklist),
    });
    return await response.json();
  },




  async fetchAssessmentchecklistbyid(id) {
    console.log('id', id)
    const token = localStorage.getItem("token");
    //console.log(studentId);
    let response = await fetch(
      constants.API_BASE_URL + "/api/assesmentchecklist/" + id,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log('student fetched response ', response);
    return await response.json();
  },

  async saveFinalAssessment(finalassessment) {
    console.log("==Enter assesmentchecklist data==>", finalassessment);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/assesmentchecklist/" + finalassessment.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(finalassessment),
      }
    );
    return await response.json();
  },


  async fetchBackups() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/backup", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },
  async downloadBackupFile(filename) {
    //console.log("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/backup/download/" + filename,
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    const fileBody = await response.blob();
    console.log('filebody in api ', fileBody)
    return fileBody;

  },

  async deleteBackupFile(filename) {
    //console.log("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/backup/delete/" + filename,
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    return await response.json();


  },

  async createBackup(lead) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/backup",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(lead),

      });
    return await response.json();
  },

  async createspeechhearing(speechhearing) {
    //console.log("if data enter");
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/speechhearings", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(speechhearing),
    });
    //console.log("response,", response);
    return await response.json();
  },



  async saveSpeechHearing(speechdata) {
    let speechid = speechdata.id
    delete speechdata.id
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/speechhearings/" + speechid,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(speechdata),
      }
    );
    //console.log(response);
    return await response.json();
  },


  
  async fetchspeechhearing() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/speechhearings", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  
  
  async fetchspeechhearingById(speechid) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/speechhearings/" + speechid,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log('student fetched response ', response);
    return await response.json();
  },

  async fetchStudentBehaveById(studentId) {
    console.log('studentId', studentId)
    const token = localStorage.getItem("token");
    //console.log(studentId);
    let response = await fetch(
      constants.API_BASE_URL + "/api/studentbehaviours/student/" + studentId,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log('student fetched response ', response);
    return await response.json();
  },


  async createStudentBehave(body) {
    console.log("if data enter",body);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/studentbehaviours", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
    //console.log("response,", response);
    return await response.json();
  },


}
export default inventoryApi;
