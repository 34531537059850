import React from 'react'
import { Col, Row } from 'react-bootstrap';

const VisionHearingPdf = ({ finalassessment, state, newCount1, newCount2, newCount3, newCount4, newCount5, newCount6, newCount7, newCount8 }) => {
    const rowStyle = { border: '1px solid gray', padding: '10px', width: '50%' };
    const rowStyle1 = { border: '1px solid gray', padding: '10px', width: '34%' };
    const rowStyle2 = { border: '1px solid gray', padding: '10px', width: '25%' };

    return (
        <div
            className="d-flex justify-content-center border"
            style={{ fontSize: "10px" }}
        >
            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None", textAlign: "center" }}>
                        <h5>Vision and Hearing Assessment</h5>
                    </td>
                </tr>
            </table>
            <hr></hr>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Case Name : {finalassessment.clientname}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Age : {finalassessment.clientage}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Gender : {finalassessment.clientsex}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Registration No: : {finalassessment.clientregistration}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Date : {finalassessment.datescreening}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Registration No: : {finalassessment.placeofscreening}</td>

                </tr>
            </table>
            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None", textAlign: "center" }}>
                        <h5 style={{ fontSize: '10px' }}><p>This schedule is for screening of vision and hearing problems among children and adults in special schools, villages or in camps. Observe the child in his familiar environment and answer the following questions in YES or NO. Consult with other family members too. If you get consistent and frequent YES answers, on the screening schedule for vision problems then please refer the child/person to an Ophthalmologist/Eye Specialist. Similarly, if you get consistent and frequent YES answers, on the screening schedule for hearing problems then please refer the child/person to ENT/Audiologist for a clinical hearing testing at the nearest centre.</p>
                        </h5>
                    </td>
                </tr>
            </table>
            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None", textAlign: "center" }}>
                        <h5>Family History</h5>
                    </td>
                </tr>
            </table>
            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Does anyone in your family have a severe vision loss or eye
                        disease? (e.g., Albinism, amblyopia, cataracts, glaucoma,
                        strabismus, retinoblastoma) : {finalassessment.familyhistory_1}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.retinoblastomatextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Did the child's mother have any serious infection or diseases
                        during pregnancy? (e.8., Rubella, cytomegalovirus,
                        toxoplasmosis, syphilis, herpes,cogan syndrome,chikenpox,kanthmala ,meningitis) : {finalassessment.familyhistory_2}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.herpestextbox}</td>

                </tr>
            </table>


            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Did the child mother use drugs or alcohol during pregnancy? : {finalassessment.familyhistory_3}
                        : {finalassessment.familyhistory_3}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.pregnancytextboxes}</td>

                </tr>
            </table>


            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Has a vision problem been identified or suspected?

                        : {finalassessment.familyhistory_4}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.suspectedtextboxes1}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Do you have any concern about your child's vision ?
                        : {finalassessment.familyhistory_5}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.visiontextbox}</td>

                </tr>
            </table>


            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Total : </td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>{newCount1}</td>

                </tr>
            </table>

            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None" }}>
                        <h5>B Appearances of the Eye (Screening Schedule for Vision Problem in
                            Children)</h5>
                    </td>
                </tr>
            </table>
            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Encrusted eye lashes : {finalassessment.apperenceofeyes_1}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.childrentextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Swollen or red eyelid : {finalassessment.apperenceofeyes_2}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.eyelidtextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Frequent (style) : {finalassessment.apperenceofeyes_3}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.Frequenttextbox}</td>

                </tr>
            </table>
            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Discharge in the eye or along the eye lids : {finalassessment.apperenceofeyes_4}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.lidstextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Do you have concerns about your child's vision ?  : {finalassessment.apperenceofeyes_5}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.childtextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Eyes that are usually red or watery : {finalassessment.apperenceofeyes_6}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.waterytextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Pupils of different sizes or clouding of pupils : {finalassessment.apperenceofeyes_7}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.pupilstextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Drooping eyelids : {finalassessment.apperenceofeyes_8}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.droopingtextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Eyes that do not appear straight (Crossed eyes): {finalassessment.apperenceofeyes_9}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.appeartextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Total</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>{newCount1}</td>

                </tr>
            </table>

            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None" }}>
                        <h5>Visual behaviours</h5>
                    </td>
                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Complains of aches or pain in the eyes : {finalassessment.visualbehaviour_1}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.behaviourstextbox}</td>

                </tr>
            </table>
            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Excessive headaches : {finalassessment.visualbehaviour_2}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.headachestextboxes1}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Dizziness or nausea after close eye work : {finalassessment.visualbehaviour_3}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.eyeworktextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  Squinting and constant blinking : {finalassessment.visualbehaviour_4}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.blinkingtextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Frowning, facial distortion : {finalassessment.visualbehaviour_5}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.distortiontextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Constant rubbing of eyes or attempts to brush away a shadow : {finalassessment.visualbehaviour_6}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.shadowtextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Tilting of the heads to see : {finalassessment.visualbehaviour_7}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.headstextbox}</td>

                </tr>
            </table>
            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Closing or covering one eye when looking or reading : {finalassessment.visualbehaviour_8}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.readingtextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Child constantly requests someone to tell what is going on : {finalassessment.visualbehaviour_9}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.goingtextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Child has poor eye hand coordination and unusual
                        awkwardness/clumsiness during task using hands or the child has
                        balance problem during walking: {finalassessment.visualbehaviour_10}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.walkingtextbox}</td>

                </tr>
            </table>


            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Child bumps/clumsy during walking particularly in new
                        environment: {finalassessment.visualbehaviour_11}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.environmenttextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Unduly sensitive to bright light, glare or shiny objects : {finalassessment.visualbehaviour_12}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.shinytextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Does the child have difficulty seeing at night or in dark? : {finalassessment.visualbehaviour_13}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.darktextbox}</td>

                </tr>
            </table>


            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>The child need glasses to see objects : {finalassessment.visualbehaviour_14}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.glassestextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Total </td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>{newCount2}</td>

                </tr>
            </table>

            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None" }}>
                        <h5>Visual Screening Outcome:</h5>
                    </td>
                </tr>
            </table>
            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Vision problem suspected : {finalassessment.visualscreeningoutcome_1} </td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>{finalassessment?.data?.suspectedtextboxse1}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Referred to Ophthalmologist for clinical assessment : {finalassessment.visualscreeningoutcome_2} </td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>{finalassessment?.data?.ophthalmologisttextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Referred for Functional Vision Assessment  : {finalassessment.visualscreeningoutcome_3} </td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>{finalassessment?.data?.referredtextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Total</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>{newCount3}</td>

                </tr>
            </table>
            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None" }}>
                        <h5>Screening Schedule for hearing Problem in Children</h5>
                    </td>
                </tr>
            </table>
            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Does anyone in your family have a severe hearing loss or ear
                        disease? (e.g.,tinitus, otitis media, perforation of ear drum,
                        Meniere's disease, etc.) {finalassessment.familyhistory2_3}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.diseasetextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Did the child's mother have any serious infections or diseases
                        during Pregnancy? (e.g., rubella, cytomegalovirus,
                        toxoplasmosis, syphilis, Herpes :  {finalassessment.familyhistory2_4}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.herpestextboxes1}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>   Did the child's mother use drugs or alcohol during pregnancy? :  {finalassessment.familyhistory2_5}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.pregnancytextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  Has a hearing problem been identified or suspected? : {finalassessment.earproblem_1}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.suspectedtextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>   Do you have any concerns about your child's hearing? :  {finalassessment.earproblem_2}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.distortiontextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>   Total</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>{newCount4}</td>

                </tr>
            </table>

            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None" }}>
                        <h5>Ear problem</h5>
                    </td>
                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Complaints of ear aches : {finalassessment.complaintofear}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.complaintstextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Complaints of headaches : {finalassessment.earproblem_2}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.headachestextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Complaints of ringing sound/sensation in ears or of hearing
                        voices : {finalassessment.earproblem_3}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.voicestextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                        Reoccurring ear infections : {finalassessment.earproblem_4}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.infectionstextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                        Frequent colds : {finalassessment.earproblem_5}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.coldstextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                        Any external ear or facial abnormality : {finalassessment.earproblem_6}</td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Note : {finalassessment?.data?.abnormalitytextbox}</td>

                </tr>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                        Total </td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> {newCount5}</td>

                </tr>
            </table>

            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None" }}>
                        <h5>Hearing behaviour:</h5>
                    </td>
                </tr>
            </table>

            <table style={{ width: '100%', border: '1px solid gray', borderCollapse: 'collapse' }}>
                <tbody>
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Turning of one ear towards or away from the sound source
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.hearingbeahviour_1}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.sourcetextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Turning up the radio or television or headset
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.hearingbeahviour_2}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.televisiontextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Sitting very close to the television or radio
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.hearingbeahviour_3}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.radiotextbox}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table style={{ width: '100%', border: '1px solid gray', borderCollapse: 'collapse' }}>
                <tbody>
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Appears to ignore conversation or directives
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_4}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.directivestextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Seems engrossed in work, oblivious to sounds around
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_5}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.oblivioustextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Humming (Constant) to possibly provide stimulation
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_6}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.stimulationtextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Startling behaviour to everyday sounds
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_7}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.everydaytextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Relies on gestures excessively
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_8}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.excessivelytextbox}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Poor spoken language
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_9}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.spokentextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Does the child complain that everyone either speaks too loudly or too softly?
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_10}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.softlytextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Not able to hear others from distance or from behind
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_11}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.behindtextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Does the child have difficulty identifying familiar persons by their voice?
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_12}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.voicetextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Difficulty in listening speech in background noise
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_13}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.noisetextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Not able to hear when called from other nearby rooms inside house or inside school
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_14}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.schooltextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Does the child need hearing aids to listen?
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_15}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.listentextbox}
                        </td>
                    </tr>

                    {/* Repeat the structure for other rows */}
                    <tr>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                            Does the child have problems in balance?
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment.hearingbeahviour_16}
                        </td>
                        <td style={{ border: "1px solid gray", padding: "10px", width: '25%' }}>
                            {finalassessment?.data?.balancetextbox}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <tr>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                        Total
                    </td>
                    <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>
                        {newCount6}
                    </td>

                </tr>
            </table>

            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None" }}>
                        <h5>Hearing Screening Outcome:</h5>
                    </td>
                </tr>
            </table>

            <table border="1">
                <thead>
                    <tr>
                        <th style={rowStyle}>Field Name</th>
                        <th style={rowStyle}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={rowStyle}>Hearing Problem Suspected</td>
                        <td style={rowStyle}>{finalassessment.hearingscreeningoutcome_1 === 'Yes' ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Name of Trainee (Hearing Problem)</td>
                        <td style={rowStyle}>{finalassessment?.data?.hearingproblemtextbox}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Referred to Audiologist for Audiometry</td>
                        <td style={rowStyle}>{finalassessment.hearingscreeningoutcome_2 === 'Yes' ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Name of Audiologist</td>
                        <td style={rowStyle}>{finalassessment?.data?.Audiologisttextbox}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Referred to ENT specialist for clinical assessment</td>
                        <td style={rowStyle}>{finalassessment.hearingscreeningoutcome_3 === 'Yes' ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Name of ENT Specialist</td>
                        <td style={rowStyle}>{finalassessment?.data?.clinicaltextbox}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}> Referred for Functional Hearing Assessment</td>
                        <td style={rowStyle}>{finalassessment.hearingscreeningoutcome_4 === 'Yes' ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle} >Name of Hearing Specialist</td>
                        <td style={rowStyle} >{finalassessment?.data?.hearingtextbox}</td>
                    </tr>
                </tbody>
            </table>

            <table border="1">
                <tbody>
                    <tr>
                        {/* <td style={rowStyle} colSpan="2" >
              <strong>Total: {newCount7}</strong>
            </td> */}
                        <td style={rowStyle} >Total</td>
                        <td style={rowStyle} >{newCount7}</td>
                    </tr>
                </tbody>
            </table>

            <table border="1">
                <tbody>
                    <tr>
                        <td style={rowStyle} className="form-view-label">Opinion of the Screener:</td>
                        <td style={rowStyle}>{finalassessment.opinionofthescreener}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle} className="form-view-label">Referral Comment:</td>
                        <td style={rowStyle}>{finalassessment.reffrealcomment}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle} className="form-view-label">Screener's Observations (if any):</td>
                        <td style={rowStyle} >{finalassessment.screeningobservation}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle} className="form-view-label">Screener's Inference:</td>
                        <td style={rowStyle}>{finalassessment.screenerinfrence}</td>
                    </tr>
                </tbody>
            </table>
            <table border="1">
                <thead>
                    <tr>
                        <th style={rowStyle}>Field Name</th>
                        <th style={rowStyle}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={rowStyle}>Hearing Problem Suspected</td>
                        <td style={rowStyle}>{finalassessment.hearingscreeningoutcome_1 === 'Yes' ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Name of Trainee (Hearing Problem)</td>
                        <td style={rowStyle}>{finalassessment?.data?.hearingproblemtextbox}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Referred to Audiologist for Audiometry</td>
                        <td style={rowStyle}>{finalassessment.hearingscreeningoutcome_2 === 'Yes' ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Name of Audiologist</td>
                        <td style={rowStyle}>{finalassessment?.data?.Audiologisttextbox}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Referred to ENT specialist for clinical assessment</td>
                        <td style={rowStyle}>{finalassessment.hearingscreeningoutcome_3 === 'Yes' ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Name of ENT Specialist</td>
                        <td style={rowStyle}>{finalassessment?.data?.clinicaltextbox}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Referred for Functional Hearing Assessment</td>
                        <td style={rowStyle}>{finalassessment.hearingscreeningoutcome_4 === 'Yes' ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <td style={rowStyle}>Name of Hearing Specialist</td>
                        <td style={rowStyle}>{finalassessment?.data?.hearingtextbox}</td>
                    </tr>
                </tbody>
            </table>

            <table border="1">
                <tbody>
                    <tr>
                        {/* <td colSpan="2" style={{ ...rowStyle, textAlign: 'center' }}>
              <strong>Total: {newCount7}</strong>
            </td> */}
                        <td style={rowStyle} >Total</td>
                        <td style={rowStyle} >{newCount7}</td>
                    </tr>
                </tbody>
            </table>

            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None" }}>
                        <h5 style={{ fontSize: '10px' }}>I:Functional visual Assessment Format for Multi-sensory impaired
                            Learners (  Stage One: : Basic awareness):</h5>
                    </td>
                </tr>
            </table>

            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ border: "None" }}>
                        <h5 style={{ fontSize: '10px' }}>Functional hearing Assessment:</h5>
                    </td>
                </tr>
            </table>


            <table>
                <tr>
                    <td className="form-view-label" style={rowStyle}>Name of Learner : {finalassessment.nameoflearner}</td>
                    <td className="form-view-label" style={rowStyle}>Date : {finalassessment.dateoflearner}</td>

                </tr>


            </table>


            <table>
                <tr style={rowStyle2}>
                    <td>Pupillary Response</td>
                    <td>
                        <ul>
                            <li>Observe without stimulation</li>
                        </ul>
                    </td>
                    <td>{finalassessment.pupillaryresponse}</td>
                    <td>{finalassessment.pupcomment}</td>

                </tr>
                <tr>


                </tr>

            </table>

            <table>
                <tr style={rowStyle1}>

                    <td>
                        <ul>
                            <li>Do pupils move constantly?</li>
                        </ul>
                    </td>
                    <td>{finalassessment.constantly}</td>
                    <td>{state.constantly}</td>

                </tr>
            </table>
            <table>
                <tr style={rowStyle1}>
                    <td>
                        <ul>
                            <li>Do pupils react to changes in light?</li>
                        </ul>
                    </td>
                    <td>{finalassessment.light}</td>
                    <td>{state.light}</td>
                </tr>
            </table>
            <table>
                <tr style={rowStyle2}>
                    <td>Blink reflex</td>
                    <td>
                        <ul>
                            <li>Move hand, without making a draught, towards the learner’s face.</li>
                        </ul>
                    </td>
                    <td>{finalassessment.blinkreflex}</td>
                    <td>{finalassessment.blinkreflexcomment}</td>

                </tr>
            </table>
            <table>
                <tr style={rowStyle1}>
                    <td>
                        <ul>
                            <li>Does she/he blink?</li>
                        </ul>
                    </td>
                    <td>{finalassessment.face}</td>
                    <td>{state.face}</td>
                </tr>
            </table>
            <table>
                <tr style={rowStyle2}>
                    <td>Response to light</td>
                    <td>{finalassessment.responseoflight}</td>

                    <td>
                        <ul>
                            <li>Using different lights in darkened room, does the learner respond to:</li>
                        </ul>
                    </td>
                    <td>{finalassessment.responseoflightcomment}</td>



                </tr>
            </table>
            <table>
                <tr style={rowStyle1}>
                    <td>
                        <ul>
                            <li>Continuous light?</li>
                        </ul>
                    </td>
                    <td>{finalassessment.darkened}</td>
                    <td>{state.darkened}</td>
                </tr>
            </table>
            <table>
                <tr style={rowStyle1}>
                    <td>
                        <ul>
                            <li>Flashing light?</li>
                        </ul>
                    </td>
                    <td>{finalassessment.flashing}</td>
                    <td>{state.flashing}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle1}>
                    <td>
                        <ul>
                            <li>Moving light?</li>
                        </ul>
                    </td>
                    <td>{finalassessment.moving}</td>
                    <td>{state.moving}</td>
                </tr>
            </table>
            <table>
                <tr style={rowStyle2}>
                    <td>Fixation</td>
                    <td>{finalassessment.fixation}</td>

                    <td>
                        <ul>
                            <li>Using motivating objects and verbal encouragement, observe whether he/she can fix his/her eyes on a bright object at:</li>
                        </ul>
                    </td>
                    <td>{finalassessment.fixationcomment}</td>

                </tr>
            </table>
            <table>
                <tr style={rowStyle1}>
                    <td>
                        <ul>
                            <li>7 inches</li>
                        </ul>
                    </td>
                    <td>{finalassessment.inches}</td>
                    <td>{state.inches}</td>
                </tr>

            </table>

            <table>
                <tr style={rowStyle1}>
                    <td>
                        <ul>
                            <li>2 feet</li>
                        </ul>
                    </td>
                    <td>{finalassessment.feet2}</td>
                    <td>{state.feet2}</td>
                </tr>
            </table>
            <table >
                <tr style={rowStyle2}>
                    <td>Visual fields</td>
                    <td>{finalassessment.visualfield}</td>

                    <td>
                        <ul>
                            <li>Using a visually interesting object or a light, stand behind
                                the child and bring it slowly into the visual field. Note when
                                he/she sees the object</li>
                        </ul>
                    </td>
                    <td>{finalassessment.visualfieldcomment}</td>

                </tr>
            </table>
            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>Above</li></ul></td>
                    <td>{finalassessment.above}</td>
                    <td>{state.above}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>Focus on objects</td>
                    <td>{finalassessment.focusonobjects}</td>

                    <td>
                        <ul>
                            <li>Show interesting objects at various distances. Observe
                                which he/she sees at:</li>
                        </ul>
                    </td>

                    <td>{finalassessment.focusonobjectscomment}</td>

                </tr>
            </table>



            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>7 Inches</li></ul></td>
                    <td>{finalassessment.focusinches}</td>
                    <td>{state.focusinches}</td>

                </tr>
            </table>

            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>2 feet : </li></ul></td>
                    <td>{finalassessment.focusfeet2}</td>
                    <td>{state.focusfeet2}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>10 feet : </li></ul></td>
                    <td>{finalassessment.focusfeet10}</td>
                    <td>{state.focusfeet10}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>Eye Contact : Observe the learner with familiar people and in a variety of situations e.g. meal times. Does he/she make eye contact: : </li></ul></td>
                    <td>{finalassessment.eyecontact}</td>
                    <td>{state.eyecontactcomment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>Briefly: </li></ul></td>
                    <td>{finalassessment.briefly1}</td>
                    <td>{state.briefly1}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>For three seconds or more: </li></ul></td>
                    <td>{finalassessment.three1}</td>
                    <td>{state.three1}</td>
                </tr>
            </table>
            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>Maintained: </li></ul></td>
                    <td>{finalassessment.maintained1}</td>
                    <td>{state.maintained1}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>Visual behaviours: Observe carefully for behaviours</li></ul></td>
                    <td>{finalassessment.visualbehaviour}</td>
                    <td>{state.visualbehaviourcomment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>Light gazing : </li></ul></td>
                    <td>{finalassessment.gazing1}</td>
                    <td>{state.gazing1}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>Hand flicking : </li></ul></td>
                    <td>{finalassessment.flicking1}</td>
                    <td>{state.flicking1}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>Any other : </li></ul></td>
                    <td>{finalassessment.anyother1}</td>
                    <td>{state.anyother1}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle1}>
                    <td><ul><li>  II. Functional Visual Assessment :  </li></ul></td>
                    <td>Name of Learner : {finalassessment.stage2_nameoflearner}</td>
                    <td>Date {finalassessment.stage2_dateoflearner}</td>
                </tr>
            </table>

            {/* <table>
                <tr style={rowStyle1}>
                    <td>Name of Learner : {finalassessment.stage2_nameoflearner}</td>
                    <td>Date {finalassessment.stage2_dateoflearner}</td>
                </tr>
            </table> */}

            <table>


                <tr style={rowStyle2}>
                    <td>Hand regard</td>
                    <td>
                        Play hand games. Does the learner look at your hands?
                    </td>
                    <td>{finalassessment.handregard}</td>
                    <td>
                        {finalassessment.handregardcomment}
                    </td>
                </tr>

                <tr style={rowStyle2}>
                    <td>Tracking</td>
                    <td>
                        <ul>
                            <li>Choose motivating objects. Move them slowly and note if the learner can visually track the object:</li>
                        </ul>
                    </td>
                    <td>{finalassessment.tracking}</td>
                    <td>
                        {finalassessment.trackingcomment}
                    </td>
                </tr>
                <tr style={rowStyle2}>
                    <td></td>
                    <td>
                        <ul>
                            <li>From the mid-line to the left</li>
                        </ul>
                    </td>
                    <td>{finalassessment.visually1}</td>
                    <td>
                        {state.visually1}
                    </td>
                </tr>

            </table>

            <table>

                <thead>
                    <th></th>
                    <th>Comment</th>
                </thead>
                <tr style={rowStyle}>
                    <td>From the mid-line to the right : {finalassessment.midline1}</td>
                    <td>{state.midline1Comment}</td>
                </tr>

            </table>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Right to left (across mid-line) : {finalassessment.across1}</td>
                        <td>{state.across1Comment}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Left to right (across mid-line) : {finalassessment.rightacross1}</td>
                        <td>{state.rightacross1Comment}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>


                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Going down  : {finalassessment.goingdown1} </td>

                        <td>{state.goingdown1Comment}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>



                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Going up : {finalassessment.goingup1}</td>
                        <td></td>
                        <td>{state.goingup1Comment}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Diagonally : {finalassessment.diagonally1}</td>

                        <td>{state.diagonally1Comment}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>In a circular movement : {finalassessment.circular1}</td>

                        <td>{state.circular1}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Using two torches or two of equal interest held a foot apart,
                            shake one first then the other to see if the learner can shift
                            his gaze between two objects: : {finalassessment.transferofattention}</td>

                        <td>{state.transferofattentioncomment}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>At the same distance : {finalassessment.same1}</td>

                        <td>{state.state}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Near/distant : {finalassessment.distant1}</td>

                        <td>{state.distant1}</td>
                    </tr>
                </tbody>
            </table>


            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Distant/near : {finalassessment.near1}</td>

                        <td>{state.near1}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Place three objects in a line. Point along the line. Can the
                            learner scan the line? : {finalassessment.learner1}</td>

                        <td>{state.learner1}</td>
                    </tr>
                </tbody>
            </table>


            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Does he/she turn to look? : {finalassessment.turningeyes}</td>

                        <td>{finalassessment.turningeyescomment}</td>
                    </tr>
                </tbody>
            </table>


            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>To the left ? : {finalassessment.touch1}</td>

                        <td>{state.touch1}</td>
                    </tr>
                </tbody>
            </table>


            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>To the right ? : {finalassessment.theright1}</td>

                        <td>{state.theright1}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Does he attempt to reach them ? : {finalassessment.attemptstoreach}</td>

                        <td>{finalassessment.attemptstoreachcomment}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Does the learner turn objects in his/her hand to explore
                            visually ? : {finalassessment.visualexploration}</td>

                        <td>{finalassessment.visualexplorationcomment}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Sometimes ? : {finalassessment.sometimes1}</td>

                        <td>{state.sometimes1}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Often ? : {finalassessment.often1}</td>

                        <td>{state.often1}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Does the learner recognise familiar objects?  : {finalassessment.objectrecognization}</td>

                        <td>{finalassessment.objectrecognizationcomment}</td>
                    </tr>
                </tbody>
            </table>


            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>A few  : {finalassessment.afew1}</td>

                        <td>{state.afew1}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Many  : {finalassessment.many1}</td>

                        <td>{state.many1}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Visual discrimination  : {finalassessment.visualdiscrimination}</td>

                        <td>{finalassessment.visualdiscriminationcomment}</td>
                    </tr>
                </tbody>
            </table>


            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Size : {finalassessment.size1}</td>

                        <td>{state.size1}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Colour : {finalassessment.colour1}</td>

                        <td>{state.colour1}</td>
                    </tr>
                </tbody>
            </table>


            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Shape : {finalassessment.shape1}</td>

                        <td>{state.shape1}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Family members : {finalassessment.familymembers1}</td>

                        <td>{state.familymembers1}</td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Carers : {finalassessment.carers1}</td>

                        <td>{state.carers1}</td>
                    </tr>
                </tbody>
            </table>


            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle}>
                        <td>Strangers : {finalassessment.strangers1}</td>

                        <td>{state.strangers1}</td>
                    </tr>
                </tbody>
            </table>

            <table >
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Observations</th>
                        <th></th>
                        <th>comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={rowStyle2}>
                        <td>1.</td>
                        <td>
                            Does the learner show any response to sound?<br />
                            e.g., startle to sudden loud sound, blink at a sound, smile or calm
                            down in response to music
                        </td>
                        <td>{finalassessment.functionalhearing_1}</td>
                        <td>{finalassessment.functionalhearing_1comment}</td>
                    </tr>

                    {/* Add more rows as needed */}
                </tbody>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>2.</td>
                    <td>
                        Another observation here...
                    </td>
                    <td>{finalassessment.functionalhearing_2}</td>
                    <td>{finalassessment.functionalhearing_2comment}</td>
                </tr>

            </table>
            <table>
                <tr style={rowStyle2}>
                    <td>3.</td>
                    <td>
                        Yet another observation here...
                    </td>
                    <td>{finalassessment.functionalhearing_3}</td>
                    <td>{finalassessment.functionalhearing_3comment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>4.</td>
                    <td>
                        What kind of sound is he or she interested in?<br></br>loud
                        sounds, music, familiar environmental sounds duration of
                        sound?<br></br>Is the interest affected by pitch, volume,
                        direction,
                    </td>
                    <td>{finalassessment.functionalhearing_4}</td>
                    <td>{finalassessment.hearingscreeningoutcome_4comment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>5.</td>
                    <td>
                        Does the learner show interest in voices?<br></br>e.g. their
                        mother or fathers
                    </td>
                    <td>{finalassessment.functionalhearing_5}</td>
                    <td>{finalassessment.functionalhearing_5comment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>6.</td>
                    <td>
                        Does the response vary according to the distance between the
                        learner and the sound?
                    </td>
                    <td>{finalassessment.functionalhearing_6}</td>
                    <td>{finalassessment.functionalhearing_6comment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>7.</td>
                    <td>
                        How loud does a sound have to be before attention is gained?
                    </td>
                    <td>{finalassessment.functionalhearing_7}</td>
                    <td>{finalassessment.functionalhearing_7comment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>8.</td>
                    <td>
                        Is the learner's ability to respond affected by background
                        noise?                    </td>
                    <td>{finalassessment.functionalhearing_8}</td>
                    <td>{finalassessment.functionalhearing_8comment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>9.</td>
                    <td>
                        Does the learner show more auditory interest to sounds coming
                    </td>
                    <td>{finalassessment.functionalhearing_9}</td>
                    <td>{finalassessment.functionalhearing_9comment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>10.</td>
                    <td>
                        Do they seem to recognise familiar sounds?
                    </td>
                    <td>{finalassessment.functionalhearing_10}</td>
                    <td>{finalassessment.functionalhearing_10comment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>11.</td>
                    <td>
                        Can they pick out one sound from a range of sounds ?
                    </td>
                    <td>{finalassessment.functionalhearing_11}</td>
                    <td>{finalassessment.functionalhearing_11comment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>12.</td>
                    <td>
                        Do they show an awareness of sound/no sound?<br></br>E.g. does
                        their behaviour change when a particular sound stops                  </td>
                    <td>{finalassessment.functionalhearing_12}</td>
                    <td>{finalassessment.functionalhearing_12comment}</td>
                </tr>
            </table>


            <table>
                <tr style={rowStyle2}>
                    <td>13.</td>
                    <td>
                        Does the learner move towards sounds?<br></br>How accurate are
                        they in using the sound to orientate themselves? </td>
                    <td>{finalassessment.functionalhearing_13}</td>
                    <td>{finalassessment.functionalhearing_13comment}</td>
                </tr>
            </table>


            <table>
                <tr style={rowStyle2}>
                    <td>14.</td>
                    <td>
                        Observe the speed at which she/he notices and responds to
                        sounds.
                    </td>
                    <td>{finalassessment.functionalhearing_14}</td>
                    <td>{finalassessment.functionalhearing_14comment}</td>
                </tr>
            </table>


            <table>
                <tr style={rowStyle2}>
                    <td>15.</td>
                    <td>
                        Are there any sounds that the learner appears to dislike or
                        that cause distress?<br></br>Which sounds?
                    </td>
                    <td>{finalassessment.functionalhearing_15}</td>
                    <td>{finalassessment.functionalhearing_15comment}</td>
                </tr>
            </table>
            <table>
                <tr style={rowStyle2}>
                    <td>16.</td>
                    <td>
                        Does the learner vocalise?
                    </td>
                    <td>{finalassessment.functionalhearing_16}</td>
                    <td>{finalassessment.functionalhearing_16comment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle2}>
                    <td>17.</td>
                    <td>
                        Are there any consonant sounds as well as open vowels sounds?
                    </td>
                    <td>{finalassessment.functionalhearing_17}</td>
                    <td>{finalassessment.functionalhearing_17comment}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle}>

                    <td>
                        Total
                    </td>
                    <td>{newCount8}</td>
                </tr>
            </table>

            <table>
                <tr style={rowStyle}>

                    <td>
                    Signature
                    </td>
                    <td>{finalassessment.signature}</td>
                </tr>
            </table>




        </div>
    )
}

export default VisionHearingPdf