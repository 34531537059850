import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    Form,

    Row,

} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import inventoryApi from "../api/inventoryApi";
import ListGroup from "react-bootstrap/ListGroup";
import AssignQuestions from "./AssignQuestions";
import AssignStudents from "./AssignStudents";
import { useLocation } from "react-router-dom";


const Assessment = (props) => {
    console.log(props);
    const location = useLocation();
    const navigate = useNavigate();
    const [showQuestionStep, setShowQuestionStep] = useState(false);
    const [showStudentStep, setShowStudentStep] = useState(false);
    const [showFirstStep, setShowFirstStep] = useState(true);
    const [body, setBody] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [questions, setQuestions] = useState(location.state ? location.state : {});
    console.log('location data on assessment', questions)
    const [IEPlist, setIEPlist] = useState([]);
    const [assessmentList, setassessmentList] = useState([]);
    const [validated, setValidated] = useState(false);
    const [userrole, setUserrole] = useState(localStorage.getItem("userrole"));
    const [servicearea, setServicearea] = useState(localStorage.getItem("servicearea"));
    const [IEPlistclone, setIEPlistclone] = useState([]);

    useEffect(() => {



        console.log("UsEffect");
        async function init() {
            const result = await inventoryApi.fetchQuestions();
            if (result) {
                console.log("result", result);
                let tempBody = result.map((data, index) => ({
                    ...data,
                    index: index + 1,
                }));
                setBody(tempBody);
                setFilterData(tempBody);
            } else {
                setBody([]);
                setFilterData([]);
            }
        }
        init();
        fetchIEP();
        fetchAssessments();
    }, []);

    const fetchIEP = async (e) => {
        const result = await inventoryApi.fetchActiceIEP();
        if (result) {
            console.log('Logged in user', userrole, servicearea);
            let tempArr = result.filter((item) => {
                if (item.status === 'Active') {
                    if (userrole === 'ADMIN' || userrole === 'SUPER_ADMIN' || userrole === 'USER')
                        return true;
                    else if (servicearea === 'CBR' && (item.period === 'JAN to JULY' || item.period === 'AUG to DEC'))
                        return true;
                    else if (servicearea === 'School' && (item.period === 'JAN to MAR'
                        || item.period === 'APR to JUN'
                        || item.period === 'JULY to SEP'
                        || item.period === 'OCT to DEC'))
                        return true;
                }
                return false;
            });
            console.log('tempArr:', tempArr);
            //let tempBody = result.map((data, index) => ({ ...data, index: index + 1 }));
            setIEPlist(tempArr);
            setIEPlistclone(tempArr)
        } else {

            setIEPlist([]);
            setIEPlistclone([]);

        }
    };

    const fetchAssessments = async (e) => {
        const result = await inventoryApi.fetchAssessments();
        if (result) {
            console.log("result", result);
            //let tempBody = result.map((data, index) => ({ ...data, index: index + 1 }));
            setassessmentList(result);
        } else {
            setassessmentList([]);
        }
    };

    const checkRequredFields = () => {
        if (
            questions.name &&
            questions.name.trim() !== ""
            

        ) {
            return false;
        }
        return true;
    };


    const handleNextButton = (e) => {
        if (checkRequredFields()) {
            console.log('IF call')
            setValidated(true);
            return;
        }
        console.log(questions)
        if (questions.category === 'PHYSIO') {

            navigate('/assessments/e/assignstudents', { state: questions });
        }

        else {
            navigate('/assessments/e/assignquestion', { state: questions });
        }
    };


    const handleValueChange = (e) => {
        if (e.target.name === 'iepservicecategory') {
            console.log(e.target.value)
            let filterdata = IEPlistclone.filter((value, index, array) => value.iepcategory === e.target.value)
            console.log('filterdata', filterdata)
            setIEPlist(filterdata)
        }
        if (e.target && e.target.name) {
            setQuestions({ ...questions, [e.target.name]: e.target.value });
            console.log(questions)
        }
        if (e.target.name === 'category' && e.target.value === 'PHYSIO') {
            console.log('if psyo call')
            setQuestions({ ...questions, [e.target.name]: e.target.value });
            console.log(questions);
        }
    };

    return (
        <Container>
            {showFirstStep ?
                <div>
                    <Form className="mt-3" noValidate validated={validated} onSubmit={handleNextButton}>
                        <Row >
                            <Col lg={2} ></Col>
                            <Col lg={8}>
                                <ListGroup className="listGroup">
                                    <ListGroup.Item
                                        as="li"
                                        style={{ backgroundColor: "#1A293B", color: "white" }}
                                    >
                                        <b>Assessment Information</b><b className="float-end">Step 1 of 3</b>
                                    </ListGroup.Item>
                                </ListGroup></Col>
                            <Col lg={2}></Col>
                        </Row>
                        <Row >
                            <Row className="justify-content-center my-2 " >
                                {/* <Col className="mx-3 col-lg-7 ">
                                <Form.Group>
                                    <Form.Label className="form-view-label">
                                        Name
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="name"
                                        placeholder="Enter Name"
                                        value={questions.name}
                                        onChange={handleValueChange}
                                    />
                                </Form.Group>
                            </Col> */}

                                <Col className="mx-3 col-lg-7 ">
                                    <Form.Group controlId="formBasicname">
                                        <Form.Label
                                            className="form-view-label"
                                        >
                                            Name
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="name"
                                            placeholder="Enter Name"
                                            value={questions.name}
                                            onChange={handleValueChange}

                                        />

                                    </Form.Group>

                                </Col>
                            </Row>


                            <Row className="justify-content-center">
                                <Col className="mx-3 col-lg-7 ">
                                    <Form.Group
                                        className=" mt-2"
                                        controlId="formIEP"
                                        onChange={handleValueChange}
                                    >
                                        <Form.Label className="form-view-label">
                                            IEP Service Category
                                        </Form.Label>
                                        <Form.Select name="iepcategory" disabled required value={questions.iepcategory}>
                                            <option value="">-- Select--</option>
                                            <option value="School">School</option>
                                            <option value="CBR">CBR</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Select Assessment Category
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>


                            <Row className="justify-content-center">
                                <Col className="mx-3 col-lg-7">
                                    <Form.Group
                                        className=" mt-2"
                                        controlId="formIEP"
                                        onChange={handleValueChange}
                                    >
                                        <Form.Label className="form-view-label">
                                            IEP Period
                                        </Form.Label>

                                        <Form.Select
                                            aria-label="Enter Select option"
                                            name="period"
                                            disabled
                                            value={questions.period}
                                            required
                                           
                                        >
                                            <option value="">-- Select--</option>
                                            <option value="JAN TO JUNE">JAN  to JUNE</option>
                                            <option value="JULY TO DEC">JULY TO DEC</option>
                                            <option value="JAN TO MARCH">JAN TO MARCH</option>
                                            <option value="JULY TO SEPT">JULY TO SEPT</option>
                                            <option value="OCT TO DEC">OCT TO DEC</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Select IEP
                                        </Form.Control.Feedback>


                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col className="mx-3 col-lg-7 ">
                                    <Form.Group
                                        className=" mt-2"
                                        controlId="formIEP"
                                        onChange={handleValueChange}
                                    >
                                        <Form.Label className="form-view-label">
                                            Assessment Category
                                        </Form.Label>
                                        <Form.Select name="category" required value={questions.category}>
                                            <option value=''>--Select Category--</option>

                                            <option value="FACP">FACP</option>
                                            <option value="PHYSIO">PHYSIO</option>
                                            {/* <option value="Speech Hearing">Speech Hearing</option> */}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Select Assessment Category
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="justify-content-center my-4">
                                <Col className="col-lg-2 ">
                                    <Button variant="primary" onClick={handleNextButton}>
                                        Next
                                    </Button>
                                </Col>
                            </Row>
                        </Row>
                    </Form>
                </div> : ''}


        </Container >
    );
};

export default Assessment;
