import React from 'react'

const PhysioAssessmentPdf = ({ psyoAssessment, state, studentdatainfo, studentData }) => {
  return (
    <div
      className="d-flex justify-content-center border"
      style={{ fontSize: "10px" }}
    >
      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h5>Physio Assessment</h5>
          </td>
        </tr>
      </table>
      <hr></hr>
      {/* <table>
        <tr style={{ backgroundColor: "#DFE0E1" }}>
          <td style={{ width: "100%", border: "None" }}>
            <h4 style={{ fontSize: "17px" }}> <b>PART-1 CASE PARTICULARS:</b></h4>
          </td>
          <td style={{ width: "265px", border: "None" }}></td>
        </tr>
      </table>
     */}

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> <strong>Name:</strong> {studentdatainfo.name ? studentdatainfo.name : studentData.name}
          </td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> <strong>Child Registration Age:</strong> {studentdatainfo.childregistrationage ? studentdatainfo.childregistrationage : studentData.childregistrationage}
          </td>

        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  <strong>Service Category:</strong> {studentdatainfo.servicecategoryname ? studentdatainfo.servicecategoryname : studentData.servicecategoryname}
          </td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> <strong>Child Registration Age:</strong>
          </td>

        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Diagnosis : {state.studentdignosis}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Gait : {psyoAssessment.gait}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.gaittextbox}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  Walking Aid Used : {psyoAssessment.walkingaid}</td>
        </tr>
      </table>


      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Muscle Tone : {psyoAssessment.muscletone}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  Muscle Tone Note : {state.muscletone}</td>
        </tr>
      </table>


      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Deep Tendon Reflex : {psyoAssessment.deeptendonreflex}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Involuntary Movement :  {psyoAssessment.involuntarymovement}</td>
        </tr>
      </table>


      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Postural Balance : {psyoAssessment.posturalbalance}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Postural balance direction : {psyoAssessment.posturalbalancedirection} </td>
        </tr>
      </table>

      

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Joint Rom and Muscle Strength (Upper Extremity) IN DEGREE: {psyoAssessment.upperextremityshoulder}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.shouldertextbox}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Elbow: {psyoAssessment.upperextremityelbow}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.elbowtextbox}</td>
        </tr>
      </table>


      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Wrist: {psyoAssessment.upperextremitywrist}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.wristtextbox}</td>
        </tr>
      </table>


      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Hip: {psyoAssessment.upperextremityhip}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.lowerhiptextbox}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Knee: {psyoAssessment.upperextremityknee}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.kneetextbox}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Ankle: {psyoAssessment.upperextremityankle}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.ancletextbox}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Musculoskeletal  Deformity : {psyoAssessment.musculoskeletaldeformity}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.muscultextbox}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Developmental Reflexes : {psyoAssessment.developmentalreflexes}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.devereftextbox}</td>
        </tr>
      </table>
      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h5>Gross motor functions (current condition)</h5>
          </td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Last GMF : {psyoAssessment.lastgmf}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.lastgmftextbox}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>  Initial GMF : {psyoAssessment.initialgmf}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.intialtextbox}</td>
        </tr>
      </table>

      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h5>Fine motor functions</h5>
          </td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Grip : {psyoAssessment.fmfgrip}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.griptextbox}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Prehension : {psyoAssessment.fmfprehension}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.prehentiontextbox}</td>
        </tr>
      </table>




      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Superficial sensation : {psyoAssessment.superficialsensation}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.superficialtextbox}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Deep sensation : {psyoAssessment.deepsensation}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.deepsensationtextbox}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Cortical sensation : {psyoAssessment.corticalsensation}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.coriticaltextbox}</td>
        </tr>
      </table>

      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ border: "None", textAlign: "center" }}>
            <h5>Joint Rom and Muscle Strength (Upper Extremity) IN GRADE</h5>
          </td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Shoulder : {psyoAssessment.shouldergrade}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.gradeshouldernote}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Elbow : {psyoAssessment.gradeelbow}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.gradeelbownote}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Wrist : {psyoAssessment.gradeextremitywrist}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.gradewristtextbox}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Hip : {psyoAssessment.gradeupperextremityhip}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.gradelowerhiptextboxes}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Knee : {psyoAssessment.gradeupperextremityknee}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.kneetextboxesgrade}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Ankle : {psyoAssessment.gradeupperextremityankle}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}> Note : {state.gradeancletextbox}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '100%' }}>Physio Therapy Suggestive Program</td>
        </tr>
      </table>
      {psyoAssessment?.suggestivenote?.map((item, index) => (
        <table key={index}>
          <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '10%' }}>{index+1}</td>
            <td style={{ border: "1px solid gray", padding: "10px", width: '90%' }}>{item.value}</td>
          </tr>
        </table>
      ))}


      <table>
        <tr>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}>Professional's Signature : {state.teachersignature}</td>
          <td style={{ border: "1px solid gray", padding: "10px", width: '50%' }}></td>
        </tr>
      </table>


    </div>
  )
}

export default PhysioAssessmentPdf